import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DescriptionIcon from '@mui/icons-material/Description';
import DvrIcon from '@mui/icons-material/Dvr';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import WorkIcon from '@mui/icons-material/Work';
import { Link } from 'react-router-dom';
import ConnectifListUsersIcon from '../resources/icons/connectif-list-users';
import logo from '../resources/img/logo-menu.svg';
import './Sidebar.css';
import { RouterMatch } from '../models/RouterMatch';
import { startsWith } from 'lodash';
import { Tooltip, Divider, Theme } from '@mui/material';
import { useLocation } from 'react-router';

const drawerWidth = 240;

const useStyles = makeStyles<Theme>(theme => ({
    root: {
        display: 'flex'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: '#47535D'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: '50px !important',
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    }
}));

interface Props {
    match?: RouterMatch;
    onUserLogout: () => void;
}

export default function Sidebar(props: Props): ReactElement {
    const location = useLocation();
    const classes = useStyles();
    const [state, setState] = React.useState({
        subMenuIsOpen: false,
        collapsibleMenuIsOpen: false,
        open: false,
        currentSubMenu: ''
    });

    const subMenuBackdropClasses = clsx({
        'sub-menu-backdrop': state.open,
        'sub-menu-backdrop-sm': !state.open
    });

    const onCloseSubMenu = (isOpen: boolean) => (event: any) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, subMenuIsOpen: isOpen });
    };

    function toggleSubMenu(subMenuName = '') {
        const subMenuIsOpen = !state.subMenuIsOpen;
        setState({
            ...state,
            currentSubMenu: subMenuIsOpen ? subMenuName : '',
            subMenuIsOpen,
            open: state.subMenuIsOpen ? false : state.open
        });
    }

    function handleDrawerOpen() {
        setState({ ...state, open: true });
    }

    function handleDrawerClose() {
        setState({ ...state, open: false, subMenuIsOpen: false });
    }

    return (
        <div>
            <div
                className={`backdrop ${state.open ? 'visible' : ''}`}
                onClick={handleDrawerClose}
            ></div>
            <Drawer
                variant='permanent'
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: state.open,
                    [classes.drawerClose]: !state.open
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: state.open,
                        [classes.drawerClose]: !state.open,
                        sidebar: true,
                        'menu-theme': true
                    })
                }}
                open={state.open}
            >
                <List>
                    <Tooltip title='Home' placement='left'>
                        <ListItem
                            className={clsx({
                                'current-section': location.pathname === '/'
                            })}
                            onClick={handleDrawerClose}
                            button
                            key={'Home'}
                            component={Link}
                            to='/'
                        >
                            <ListItemIcon>
                                <img
                                    src={logo}
                                    className='App-logo'
                                    alt='logo'
                                    width='25px'
                                    height='25px'
                                />
                            </ListItemIcon>
                            <ListItemText primary={'Home'} />
                        </ListItem>
                    </Tooltip>
                    <Tooltip title='Plans' placement='left'>
                        <ListItem
                            className={clsx({
                                'current-section': startsWith(
                                    location.pathname,
                                    '/plan'
                                ),
                                'current-submenu':
                                    state.currentSubMenu === 'plans'
                            })}
                            button
                            key={'Plans'}
                            onClick={() => toggleSubMenu('plans')}
                        >
                            <ListItemIcon>
                                <DvrIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Plans'} />
                        </ListItem>
                    </Tooltip>
                    <Tooltip title='Invoicing' placement='left'>
                        <ListItem
                            className={clsx({
                                'current-section': startsWith(
                                    location.pathname,
                                    '/invoice'
                                ),
                                'current-submenu':
                                    state.currentSubMenu === 'invoicing'
                            })}
                            button
                            key={'Invoicing'}
                            onClick={() => toggleSubMenu('invoicing')}
                        >
                            <ListItemIcon>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Invoicing'} />
                        </ListItem>
                    </Tooltip>
                    <Tooltip title='Customers' placement='left'>
                        <ListItem
                            className={clsx({
                                'current-section': startsWith(
                                    location.pathname,
                                    '/customer'
                                )
                            })}
                            onClick={handleDrawerClose}
                            button
                            key={'Customers'}
                            component={Link}
                            to='/customer/list'
                        >
                            <ListItemIcon>
                                <ConnectifListUsersIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Customers'} />
                        </ListItem>
                    </Tooltip>
                    <Tooltip title='Partners' placement='left'>
                        <ListItem
                            className={clsx({
                                'current-section': startsWith(
                                    location.pathname,
                                    '/partner'
                                ),
                                'current-submenu':
                                    state.currentSubMenu === 'partners'
                            })}
                            button
                            key={'Partners'}
                            onClick={() => toggleSubMenu('partners')}
                        >
                            <ListItemIcon>
                                <WorkIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Partners'} />
                        </ListItem>
                    </Tooltip>
                    <Tooltip title='Management' placement='left'>
                        <ListItem
                            className={clsx({
                                'current-section': startsWith(
                                    location.pathname,
                                    '/system'
                                ),
                                'current-submenu':
                                    state.currentSubMenu === 'system'
                            })}
                            button
                            key={'Management'}
                            onClick={() => toggleSubMenu('system')}
                        >
                            <ListItemIcon>
                                <AvTimerIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Management'} />
                        </ListItem>
                    </Tooltip>
                </List>
                <List className={'list-bottom'}>
                    {state.open && (
                        <ListItem
                            button
                            key={'Collapse'}
                            onClick={handleDrawerClose}
                        >
                            <ListItemIcon>
                                <ChevronLeftIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Collapse'} />
                        </ListItem>
                    )}
                    {!state.open && (
                        <ListItem
                            button
                            key={'Expand'}
                            onClick={handleDrawerOpen}
                        >
                            <ListItemIcon>
                                <ChevronRightIcon />
                            </ListItemIcon>
                        </ListItem>
                    )}
                    <ListItem
                        button
                        key={'Logout'}
                        onClick={props.onUserLogout}
                    >
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Logout'} />
                    </ListItem>
                </List>

                <Drawer
                    open={state.subMenuIsOpen}
                    onClose={onCloseSubMenu(false)}
                    anchor='left'
                    BackdropProps={{
                        classes: { root: subMenuBackdropClasses }
                    }}
                    classes={{
                        root: subMenuBackdropClasses,
                        paper: clsx({
                            'sub-menu': state.open,
                            'sub-menu-sm': !state.open,
                            'menu-theme': true
                        })
                    }}
                >
                    {state.currentSubMenu === 'invoicing' && (
                        <List>
                            <ListItem
                                button
                                key={'Invoices'}
                                onClick={() => toggleSubMenu()}
                                component={Link}
                                to='/invoice/list'
                            >
                                <ListItemText
                                    className={'sub-menu-item'}
                                    primary={'Invoices'}
                                />
                            </ListItem>
                            <Divider variant='fullWidth' />
                            <ListItem
                                button
                                key={'NotBilled'}
                                onClick={() => toggleSubMenu()}
                                component={Link}
                                to='/subscription/notbilled'
                            >
                                <ListItemText
                                    className={'sub-menu-item'}
                                    primary={'Suscriptions not billed'}
                                />
                            </ListItem>
                            <Divider variant='fullWidth' />
                            <ListItem
                                button
                                key={'Settings'}
                                onClick={() => toggleSubMenu()}
                                component={Link}
                                to='/invoice/settings'
                            >
                                <ListItemText
                                    className={'sub-menu-item'}
                                    primary={'Settings'}
                                />
                            </ListItem>
                            <Divider variant='fullWidth' />
                        </List>
                    )}
                    {state.currentSubMenu === 'plans' && (
                        <List>
                            <ListItem
                                button
                                key={'Plans'}
                                onClick={() => toggleSubMenu()}
                                component={Link}
                                to='/plan/list'
                            >
                                <ListItemText
                                    className={'sub-menu-item'}
                                    primary={'Plans'}
                                />
                            </ListItem>
                            <Divider variant='fullWidth' />
                            <ListItem
                                button
                                key={'Settings'}
                                onClick={() => toggleSubMenu()}
                                component={Link}
                                to='/plan/settings'
                            >
                                <ListItemText
                                    className={'sub-menu-item'}
                                    primary={'Settings'}
                                />
                            </ListItem>
                            <Divider variant='fullWidth' />
                        </List>
                    )}
                    {state.currentSubMenu === 'partners' && (
                        <List>
                            <ListItem
                                button
                                onClick={() => toggleSubMenu()}
                                component={Link}
                                to='/partner/list'
                            >
                                <ListItemText
                                    className={'sub-menu-item'}
                                    primary={'Partners'}
                                />
                            </ListItem>
                            <Divider variant='fullWidth' />
                            <ListItem
                                button
                                onClick={() => toggleSubMenu()}
                                component={Link}
                                to='/report/list'
                            >
                                <ListItemText
                                    className={'sub-menu-item'}
                                    primary={'Reports'}
                                />
                            </ListItem>
                            <Divider variant='fullWidth' />
                            <ListItem
                                button
                                key={'Settings'}
                                onClick={() => toggleSubMenu()}
                                component={Link}
                                to='/partner/settings'
                            >
                                <ListItemText
                                    className={'sub-menu-item'}
                                    primary={'Settings'}
                                />
                            </ListItem>
                            <Divider variant='fullWidth' />
                        </List>
                    )}
                    {state.currentSubMenu === 'system' && (
                        <List>
                            <ListItem
                                button
                                key={'OperationLog'}
                                onClick={() => toggleSubMenu()}
                                component={Link}
                                to='/system/operation-log'
                            >
                                <ListItemText
                                    className={'sub-menu-item'}
                                    primary={'Operation Log'}
                                />
                            </ListItem>
                            <Divider variant='fullWidth' />
                        </List>
                    )}
                </Drawer>
            </Drawer>
        </div>
    );
}
