import React from 'react';
import { Link } from 'react-router-dom';
import { CustomerShopifyUpdatePlanInconsistencyOperationLog } from '../../../../../models/system/CustomerShopifyUpdatePlanInconsistencyOperationLog';

const CustomerShopifyUpdatePlanInconsistency = (
    operationLog: CustomerShopifyUpdatePlanInconsistencyOperationLog
) => (
    <div>
        <p>
            {operationLog.customerName && (
                <span>
                    Account name:{' '}
                    <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                        {operationLog.customerName}
                    </Link>
                </span>
            )}
            <br />
            {operationLog.shop && (
                <span>
                    This customer try to update plan account to this Shopify
                    shop: {operationLog.shop}
                </span>
            )}
            <br />
            {operationLog.updatePlanInconsistency?.reportCode && (
                <span>
                    Report code:{' '}
                    {operationLog.updatePlanInconsistency.reportCode}
                </span>
            )}
            <br />
            {operationLog.updatePlanInconsistency?.reportMessage && (
                <span>
                    Report message:{' '}
                    {operationLog.updatePlanInconsistency.reportMessage}
                </span>
            )}
        </p>
    </div>
);

export default CustomerShopifyUpdatePlanInconsistency;
