import React, { CSSProperties, ReactElement } from 'react';
import { Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
    page: number;
    rowsTotal: number;
    rowsPerPage: number;
    pageSelectorClasses?: PageSelectorClasses;
    onChange: (page: number) => void;
};

type PageSelectorClasses = {
    pageSelectorStyles?: CSSProperties;
    pageSelectorLabelStyles?: CSSProperties;
    pageSelectorSelectStyles?: CSSProperties;
};

const pageSelectorStylesDefault: CSSProperties = {
    display: 'inline-block',
    float: 'right',
    marginTop: '23px',
    fontSize: '12px',
    color: 'gray'
};

const pageSelectorLabelStylesDefault: CSSProperties = {
    display: 'inline-block',
    marginRight: '10px',
    fontSize: '10px'
};
const pageSelectorSelectStylesDefault: CSSProperties = {
    fontSize: '10px',
    color: 'gray'
};

export default function PageSelector({
    page,
    rowsTotal,
    rowsPerPage,
    pageSelectorClasses,
    onChange
}: Props): ReactElement {
    const pages = [];
    for (let p = 0; p < rowsTotal / rowsPerPage; p++) {
        pages.push(p);
    }

    const useStyles = makeStyles({
        select: {
            '& > div': {
                paddingLeft: '8px',
                textAlignLast: 'right'
            }
        }
    });

    const classes = useStyles();

    return (
        <div
            style={
                pageSelectorClasses?.pageSelectorStyles ||
                pageSelectorStylesDefault
            }
        >
            <p
                style={
                    pageSelectorClasses?.pageSelectorLabelStyles ||
                    pageSelectorLabelStylesDefault
                }
            >
                Page:{' '}
            </p>
            {rowsTotal && (
                <Select
                    style={
                        pageSelectorClasses?.pageSelectorSelectStyles ||
                        pageSelectorSelectStylesDefault
                    }
                    disableUnderline
                    className={classes.select}
                    value={page}
                    onChange={event => {
                        onChange(Number(event.target.value));
                    }}
                >
                    {pages.map(p => (
                        <MenuItem key={p} value={p}>
                            {p + 1}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </div>
    );
}
