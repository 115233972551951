import RequestService from './RequestService';
import GenericListFilters from '../models/GenericListFilters';
import { Subscription } from '../models/subscription/Subscription';

const SubscriptionService = {
    getNotBilledSubscriptionList(
        listFilters: GenericListFilters
    ): Promise<{ documents: Subscription[]; totalQuantity: number }> {
        const filters: any = {
            limit: listFilters.rowsPerPage,
            page: listFilters.page + 1,
            search: listFilters.search
        };

        if (listFilters.sortBy) {
            filters.sortKey = listFilters.sortBy;
        }

        if (listFilters.direction) {
            filters.sortDirection = listFilters.direction === 'asc' ? 1 : -1;
        }

        return RequestService.cnFetch(
            '/subscription/notbilled',
            {
                method: 'GET'
            },
            filters
        ).then(async response => {
            const respObject: {
                documents: Subscription[];
                totalQuantity: number;
            } = await response.json();
            return respObject;
        });
    },

    getCustomerSubscriptionsHistory(
        customerId: string,
        fromDate?: Date
    ): Promise<Subscription[]> {
        const filters: any = {};
        if (fromDate) {
            filters.fromDate = fromDate;
        }

        return RequestService.cnFetch(
            `/subscription/${customerId}/history`,
            {
                method: 'GET'
            },
            filters
        ).then(async response => {
            const respObject: Subscription[] = await response.json();
            return respObject;
        });
    },

    async billSubscription(subscriptionId?: string): Promise<void> {
        await RequestService.cnFetch(`/subscription/${subscriptionId}/bill`, {
            method: 'POST'
        });
    },

    getSubscription(subscriptionId: string): Promise<Subscription> {
        return RequestService.cnFetch(`/subscription/${subscriptionId}`, {
            method: 'GET'
        }).then(response => response.json());
    }
};
export default SubscriptionService;
