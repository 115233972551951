import React, { ReactElement } from 'react';
import { CSSProperties } from '@mui/styles';
import {
    Grid,
    FormControlLabel,
    Switch,
    TextField,
    Divider,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import NumberField from '../../../../components/NumberField';
import { CustomerBillingInfo } from '../../../../models/customer/CustomerBillingInfo';
import clsx from 'clsx';
import { CustomerDiscount } from '../../../../models/customer/CustomerDiscount';
import { CustomerExtraItem } from '../../../../models/customer/CustomerExtraItem';
import _ from 'lodash';
import CustomerExtraItems from './CustomerExtraItems';
import PercentageEuroSwitch from '../../../../components/PercentageEuroSwitch';
import DateSelector from '../../../../components/DateSelector';
import { CustomerPaymentInfo } from '../../../../models/customer/CustomerPaymentInfo';
import { ShopifyRecurringApplicationCharge } from '../../../../models/customer/ShopifyRecurringApplicationCharge';

interface Props {
    billingInfo: CustomerBillingInfo;
    showOveragePricing?: boolean;
    onUpdateBillingInfo: (customerBillingInfo: CustomerBillingInfo) => void;
    isDisabled?: boolean;
    paymentMethod?: CustomerPaymentInfo['paymentMethod'];
    shopifyRecurringApplicationCharge?: ShopifyRecurringApplicationCharge;
}

const useStyles = makeStyles<Theme>(theme => ({
    dense: {
        marginTop: 0
    },
    textField: {
        marginRight: theme.spacing(1),
        width: '100%'
    },
    formControlLabel: {
        marginLeft: '5px',
        marginBottom: '-8px',
        verticalAlign: 'bottom'
    }
}));

const formControlStyles: CSSProperties = {
    minWidth: '115px',
    width: '100%'
};

const datePickerStyle: CSSProperties = {
    width: 'calc(100% - 45px)'
};

const discountBoxStyles: CSSProperties = {
    padding: '20px',
    marginTop: '10px',
    marginBottom: '20px'
};

const overagePriceStyles: CSSProperties = {
    marginBottom: '20px'
};

const analyticsTierStyles: CSSProperties = {
    marginBottom: '20px'
};

const isActiveStyles: CSSProperties = {
    marginLeft: '10px',
    float: 'right'
};

export default function CustomerPlanPricing(props: Props): ReactElement {
    const classes = useStyles();
    const fixedPrice = props.billingInfo.fixedPrice || ({} as CustomerDiscount);
    const hiddenDiscount =
        props.billingInfo.hiddenDiscount ||
        ({
            isPercentage: false
        } as Required<CustomerBillingInfo>['hiddenDiscount']);
    const visibleDiscount =
        props.billingInfo.visibleDiscount ||
        ({
            isPercentage: false
        } as Required<CustomerBillingInfo>['visibleDiscount']);
    const fixedExtraActivityPrice =
        props.billingInfo.fixedExtraActivityPrice === undefined
            ? ''
            : props.billingInfo.fixedExtraActivityPrice;
    const fixedExtraEmailPrice =
        props.billingInfo.fixedExtraEmailPrice === undefined
            ? ''
            : props.billingInfo.fixedExtraEmailPrice;
    const extraItems =
        props.billingInfo.extraItems || ([] as CustomerExtraItem[]);
    const maxActivitiesHardLimitPercentage =
        props.billingInfo.maxActivitiesHardLimitPercentage === undefined
            ? ''
            : props.billingInfo.maxActivitiesHardLimitPercentage;
    const maxEmailsHardLimitPercentage =
        props.billingInfo.maxEmailsHardLimitPercentage === undefined
            ? ''
            : props.billingInfo.maxEmailsHardLimitPercentage;
    const discounts = {
        fixedPrice,
        hiddenDiscount,
        visibleDiscount
    };

    const updateDiscount = (
        discountName: string,
        fieldName: string,
        newValue: any
    ) => {
        const discount = (discounts as any)[discountName];
        discount[fieldName] = newValue;
        updateBillingInfo(discountName, discount);
    };

    const updateBillingInfo = (fieldName: string, newValue: any) => {
        props.onUpdateBillingInfo({
            ...props.billingInfo,
            [fieldName]: newValue
        });
    };

    const handleAddItem = (extraItem: CustomerExtraItem) => {
        let extraItems: CustomerExtraItem[] = [];
        if (props.billingInfo.extraItems) {
            extraItems = _.cloneDeep(props.billingInfo.extraItems);
        }
        extraItems.push(extraItem);
        props.onUpdateBillingInfo({ ...props.billingInfo, extraItems });
    };

    const handleUpdateItem = (extraItem: CustomerExtraItem, index: number) => {
        let extraItems: CustomerExtraItem[] = [];
        if (props.billingInfo.extraItems) {
            extraItems = _.cloneDeep(props.billingInfo.extraItems);
        }
        extraItems[index] = extraItem;
        props.onUpdateBillingInfo({ ...props.billingInfo, extraItems });
    };

    const handleRemoveItem = (index: number) => {
        let extraItems: CustomerExtraItem[] = [];
        if (props.billingInfo.extraItems) {
            extraItems = _.cloneDeep(props.billingInfo.extraItems);
        }
        extraItems.splice(index, 1);
        props.onUpdateBillingInfo({ ...props.billingInfo, extraItems });
    };

    const isEmpty = (value: any) =>
        value === undefined || value === null || value === '';

    const inputStyles: CSSProperties = {
        width: '100%'
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <p>
                    <b>Custom Plans Pricing</b>
                </p>
            </Grid>
            <Grid
                container
                spacing={1}
                style={discountBoxStyles}
                className={clsx({
                    inactive: !fixedPrice.isActive,
                    'discount-box': true,
                    active: fixedPrice.isActive
                })}
            >
                <Grid item xs={12} sm={12}>
                    FIXED PRICE
                    <FormControlLabel
                        control={
                            <Switch
                                checked={fixedPrice.isActive ? true : false}
                                onChange={event =>
                                    updateDiscount(
                                        'fixedPrice',
                                        'isActive',
                                        event.target.checked
                                    )
                                }
                                value='checkedB'
                                color='primary'
                            />
                        }
                        labelPlacement='start'
                        label={fixedPrice.isActive ? 'Active' : 'Not Active'}
                        style={isActiveStyles}
                        disabled={props.isDisabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <NumberField
                        adornment={'€'}
                        required={fixedPrice.isActive}
                        id='fixedPrice'
                        label='Fixed price'
                        value={fixedPrice.amount}
                        onChangeValue={newValue =>
                            updateDiscount('fixedPrice', 'amount', newValue)
                        }
                        disabled={props.isDisabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DateSelector
                        style={datePickerStyle}
                        value={fixedPrice.validUpTo}
                        onChange={date =>
                            updateDiscount(
                                'fixedPrice',
                                'validUpTo',
                                date?.toISOString()
                            )
                        }
                        label={'Valid up to'}
                        disabled={props.isDisabled}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                style={discountBoxStyles}
                className={clsx({
                    inactive: !hiddenDiscount.isActive,
                    'discount-box': true,
                    active: hiddenDiscount.isActive
                })}
            >
                <Grid item xs={12} sm={12}>
                    HIDDEN DISCOUNT
                    <FormControlLabel
                        control={
                            <Switch
                                checked={hiddenDiscount.isActive ? true : false}
                                onChange={event =>
                                    updateDiscount(
                                        'hiddenDiscount',
                                        'isActive',
                                        event.target.checked
                                    )
                                }
                                value='checkedB'
                                color='primary'
                                disabled={props.isDisabled}
                            />
                        }
                        labelPlacement='start'
                        label={
                            hiddenDiscount.isActive ? 'Active' : 'Not Active'
                        }
                        style={isActiveStyles}
                    />
                </Grid>
                <Grid item xs={4}>
                    <NumberField
                        adornment={hiddenDiscount.isPercentage ? '%' : '€'}
                        required={hiddenDiscount.isActive}
                        id='hiddenDiscount'
                        label='Discount'
                        value={hiddenDiscount.amount}
                        onChangeValue={newValue =>
                            updateDiscount('hiddenDiscount', 'amount', newValue)
                        }
                        disabled={props.isDisabled}
                    />
                    <PercentageEuroSwitch
                        isPercentage={hiddenDiscount.isPercentage}
                        onChange={isPercentage =>
                            updateDiscount(
                                'hiddenDiscount',
                                'isPercentage',
                                isPercentage
                            )
                        }
                        isDisabled={props.isDisabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DateSelector
                        style={datePickerStyle}
                        value={hiddenDiscount.validUpTo}
                        onChange={date =>
                            updateDiscount(
                                'hiddenDiscount',
                                'validUpTo',
                                date?.toISOString()
                            )
                        }
                        label={'Valid up to'}
                        disabled={props.isDisabled}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                style={discountBoxStyles}
                className={clsx({
                    inactive: !visibleDiscount.isActive,
                    'discount-box': true,
                    active: visibleDiscount.isActive
                })}
            >
                <Grid item xs={12} sm={12}>
                    VISIBLE DISCOUNT
                    <FormControlLabel
                        control={
                            <Switch
                                checked={
                                    visibleDiscount.isActive ? true : false
                                }
                                onChange={event =>
                                    updateDiscount(
                                        'visibleDiscount',
                                        'isActive',
                                        event.target.checked
                                    )
                                }
                                value='checkedB'
                                color='primary'
                                disabled={props.isDisabled}
                            />
                        }
                        labelPlacement='start'
                        label={
                            visibleDiscount.isActive ? 'Active' : 'Not Active'
                        }
                        style={isActiveStyles}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        id='name'
                        label='Concept'
                        error={
                            visibleDiscount.isActive &&
                            isEmpty(visibleDiscount.concept)
                        }
                        required={visibleDiscount.isActive}
                        className={clsx(classes.textField, classes.dense)}
                        margin='dense'
                        value={visibleDiscount.concept || ''}
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    {visibleDiscount.isPercentage && (
                                        <InputAdornment
                                            style={{ minWidth: '100px' }}
                                            position='end'
                                        >
                                            <p
                                                style={{
                                                    textAlign: 'right',
                                                    width: '100%'
                                                }}
                                            >{` - (${visibleDiscount.amount}%)`}</p>
                                        </InputAdornment>
                                    )}
                                </React.Fragment>
                            )
                        }}
                        onChange={event =>
                            updateDiscount(
                                'visibleDiscount',
                                'concept',
                                event.target.value
                            )
                        }
                        disabled={props.isDisabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <NumberField
                        adornment={visibleDiscount.isPercentage ? '%' : '€'}
                        required={visibleDiscount.isActive}
                        id='visibleDiscount'
                        label='Discount'
                        value={visibleDiscount.amount}
                        onChangeValue={newValue =>
                            updateDiscount(
                                'visibleDiscount',
                                'amount',
                                newValue
                            )
                        }
                        disabled={props.isDisabled}
                    />
                    <PercentageEuroSwitch
                        isPercentage={visibleDiscount.isPercentage}
                        onChange={isPercentage =>
                            updateDiscount(
                                'visibleDiscount',
                                'isPercentage',
                                isPercentage
                            )
                        }
                        isDisabled={props.isDisabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DateSelector
                        style={datePickerStyle}
                        value={visibleDiscount.validUpTo}
                        onChange={date =>
                            updateDiscount(
                                'visibleDiscount',
                                'validUpTo',
                                date?.toISOString()
                            )
                        }
                        label={'Valid up to'}
                        disabled={props.isDisabled}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} style={analyticsTierStyles}>
                <Grid item xs={12}>
                    <Divider variant='fullWidth' />
                    <p>
                        <b>Analytics Tier</b>
                    </p>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={formControlStyles}>
                        <InputLabel htmlFor='analytics-tier'>
                            Analytics Tier
                        </InputLabel>
                        <Select
                            className={clsx(classes.textField)}
                            value={props.billingInfo.analyticsTier}
                            onChange={event =>
                                updateBillingInfo(
                                    'analyticsTier',
                                    event.target.value
                                )
                            }
                            inputProps={{
                                name: 'Analytics Tier',
                                id: 'analytics-tier'
                            }}
                            disabled={props.isDisabled}
                        >
                            <MenuItem value={'restricted-free'}>
                                restricted-free
                            </MenuItem>
                            <MenuItem value={'standard-free'}>
                                standard-free
                            </MenuItem>
                            <MenuItem value={'standard'}>standard</MenuItem>
                            <MenuItem value={'premium-50-free'}>
                                premium-50-free
                            </MenuItem>
                            <MenuItem value={'premium-50'}>premium-50</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                className={clsx({ hidden: !props.showOveragePricing })}
                style={overagePriceStyles}
            >
                <Grid item xs={12} sm={12}>
                    <Divider variant='fullWidth' />
                </Grid>
                <Grid item xs={12}>
                    <p>
                        <b>Custom Overage Pricing</b>
                    </p>
                </Grid>
                <Grid item xs={4} style={{ paddingRight: '20px' }}>
                    <NumberField
                        adornment={'€'}
                        style={inputStyles}
                        required={false}
                        id='fixedExtraActivityPrice'
                        label='Activity Overage Unit Price'
                        value={fixedExtraActivityPrice}
                        onChangeValue={newValue =>
                            updateBillingInfo(
                                'fixedExtraActivityPrice',
                                newValue
                            )
                        }
                    />
                </Grid>
                <Grid item xs={4} style={{ paddingRight: '20px' }}>
                    <NumberField
                        style={inputStyles}
                        adornment={'€'}
                        required={false}
                        id='fixedExtraEmailPrice'
                        label='Email Overage Unit Price'
                        value={fixedExtraEmailPrice}
                        onChangeValue={newValue =>
                            updateBillingInfo('fixedExtraEmailPrice', newValue)
                        }
                    />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={4} style={{ paddingRight: '20px' }}>
                    <NumberField
                        adornment={'%'}
                        style={inputStyles}
                        required={false}
                        id='maxActivitiesHardLimitPercentage'
                        label='Activity Hard Limit(as % of Soft Limit)'
                        value={maxActivitiesHardLimitPercentage}
                        onChangeValue={newValue =>
                            updateBillingInfo(
                                'maxActivitiesHardLimitPercentage',
                                newValue
                            )
                        }
                    />
                </Grid>
                <Grid item xs={4} style={{ paddingRight: '20px' }}>
                    <NumberField
                        style={inputStyles}
                        adornment={'%'}
                        required={false}
                        id='maxEmailsHardLimitPercentage'
                        label='Email Hard Limit(as % of Soft Limit)'
                        value={maxEmailsHardLimitPercentage}
                        onChangeValue={newValue =>
                            updateBillingInfo(
                                'maxEmailsHardLimitPercentage',
                                newValue
                            )
                        }
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                className={clsx({ hidden: !props.showOveragePricing })}
                style={overagePriceStyles}
            >
                <Grid item xs={12} sm={12}>
                    <Divider variant='fullWidth' />
                </Grid>
                <Grid item xs={12}>
                    <p>
                        <b>Extra Charges</b>
                    </p>
                </Grid>
                <CustomerExtraItems
                    items={extraItems}
                    isEditable={true}
                    handleAddItem={handleAddItem}
                    handleUpdateItem={handleUpdateItem}
                    handleRemoveItem={handleRemoveItem}
                    paymentMethod={props.paymentMethod}
                    shopifyRecurringApplicationCharge={
                        props.shopifyRecurringApplicationCharge
                    }
                ></CustomerExtraItems>
            </Grid>
        </Grid>
    );
}
