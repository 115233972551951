import React, { useState, useEffect, ReactElement } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Tooltip,
    IconButton,
    TextField
} from '@mui/material';
import PartnerListItems from '../../../partner/list/components/PartnerListItems';
import PartnerService from '../../../../services/PartnerService';
import PartnerListFilters from '../../../../models/PartnerListFilters';
import Partner from '../../../../models/partner/Partner';
import { PagedList } from '../../../../models/PagedList';
import { PartnerTerm } from '../../../../models/partner/PartnerTerm';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PartnerTermList from '../../../partner/edit/components/PartnerTermList';
import { AssignedPartnerTerm } from '../../../../models/customer/AssignedPartnerTerm';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Spinner from '../../../../components/Spinner';
import { Moment } from 'moment';

type Props = {
    open: boolean;
    onAssignPartnerTerm: (
        partnerTerm: Omit<AssignedPartnerTerm, 'assignedOn'>
    ) => void;
    onClose: () => void;
};

export default function PartnerTermSelectDialog({
    open,
    onAssignPartnerTerm,
    onClose
}: Props): ReactElement {
    const [isLoading, setLoading] = useState(false);
    const [partners, setPartners] = useState<PagedList<Partner>>({
        totalQuantity: 0,
        documents: []
    });
    const [filters, setFilters] = useState<PartnerListFilters>({
        rowsPerPage: 10,
        page: 0,
        search: '',
        sortBy: 'createdAt',
        direction: 'desc'
    });
    useEffect(() => {
        setLoading(true);
        PartnerService.getPartnerList(filters)
            .then(setPartners)
            .finally(() => setLoading(false));
    }, [filters]);

    const [partnerId, setPartnerId] = useState<string>();
    const [terms, setTerms] = useState<PartnerTerm[]>();

    useEffect(() => {
        if (partnerId) {
            setLoading(true);
            PartnerService.getPartner(partnerId)
                .then(partner => setTerms(partner.terms))
                .finally(() => setLoading(false));
        }
    }, [partnerId]);

    const onSortByChange = (sortBy: string, direction?: 'asc' | 'desc') => {
        setFilters({ ...filters, sortBy, direction });
    };

    const onFilterChange = (
        filterId: keyof PartnerListFilters,
        filterValue: any
    ) => {
        setFilters({ ...filters, [filterId]: filterValue });
    };

    const onPaginationChange = (page: number, rowsPerPage: number) => {
        setFilters({ ...filters, page, rowsPerPage });
    };

    const [selectedTerm, setSelectedTerm] = useState<PartnerTerm>();
    const [startDecayDate, setStartDecayDate] = useState<Date | undefined>(
        new Date()
    );

    function resetState() {
        setPartnerId(undefined);
        setSelectedTerm(undefined);
    }

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>Select Partner</DialogTitle>
            <DialogContent style={{ height: '600px' }}>
                <Spinner visible={isLoading} />
                {!partnerId && (
                    <PartnerListItems
                        rowsTotal={partners.totalQuantity}
                        rows={partners.documents}
                        filters={filters}
                        onPaginationChange={onPaginationChange}
                        onSortByChange={onSortByChange}
                        onFilterChange={onFilterChange}
                        renderActions={partner => (
                            <Tooltip title='Select'>
                                <IconButton
                                    color='primary'
                                    onClick={() => setPartnerId(partner._id)}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                )}
                {partnerId && terms && !selectedTerm && (
                    <PartnerTermList
                        terms={terms}
                        renderActions={term => (
                            <Tooltip title='Select'>
                                <IconButton
                                    color='primary'
                                    onClick={() => setSelectedTerm(term)}
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                )}
                {selectedTerm && (
                    <DesktopDatePicker
                        closeOnSelect
                        inputFormat='DD/MM/YYYY'
                        value={startDecayDate}
                        onChange={(date: Moment | null) =>
                            setStartDecayDate(date?.toDate())
                        }
                        renderInput={props => <TextField {...props} />}
                        label={'Start decay date'}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose();
                        resetState();
                    }}
                >
                    Cancel
                </Button>
                {partnerId && selectedTerm && startDecayDate && (
                    <Button
                        color='primary'
                        onClick={() => {
                            onAssignPartnerTerm({
                                partnerId,
                                uuid: selectedTerm.uuid,
                                startDecayDate
                            });
                            resetState();
                        }}
                    >
                        Add
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
