import React, { useEffect, useState, ReactElement, CSSProperties } from 'react';
import Customer from '../models/customer/Customer';
import CustomerListFilters from '../models/list-filters/CustomerListFilters';
import CustomerService from '../services/CustomerService';
import { PagedList } from '../models/PagedList';
import ReactSelect, { ReactSelectOption } from './ReactSelect';
import { PropsWithShowToast } from '../PropsWithShowToast';
import { InputActionMeta } from 'react-select/lib/types';

type Props = PropsWithShowToast<{
    onChangeCustomer: (customerUuid: string) => void;
    selectedCustomerUuid?: string;
    addEmptyOption?: boolean;
    partnerId?: string;
}>;

interface State {
    customerSelectorOptions?: ReactSelectOption[];
    selectedCustomer?: { value: string; label: string };
}

const selectorStyles = {
    container: (provided: CSSProperties) => ({
        ...provided,
        marginTop: '10px',
        width: '100%'
    })
};

const timeoutToRefreshOptions = 500;

export default function CustomerSelector(props: Props): ReactElement {
    const [state, setState] = useState<State>({});
    let timerSelectorCustomer: any = null;

    const onChangeSelector = (option: ReactSelectOption) => {
        setState({ ...state, selectedCustomer: option });
        props.onChangeCustomer(option.value);
    };

    const onSearchSelector = (searchText: string, event: InputActionMeta) => {
        if (timerSelectorCustomer) {
            clearTimeout(timerSelectorCustomer);
            timerSelectorCustomer = null;
        }
        if (event.action === 'input-change') {
            timerSelectorCustomer = setTimeout(() => {
                loadCustomerList(searchText);
            }, timeoutToRefreshOptions);
        }

        if (event.action === 'menu-close') {
            loadCustomerList();
        }
    };

    const loadCustomerList = async (nameFilter?: string) => {
        const stateCopy = { ...state };
        timerSelectorCustomer = null;
        let customerList: PagedList<Customer> = {
            documents: [],
            totalQuantity: 0
        };
        try {
            const filters: CustomerListFilters = {
                page: 0,
                rowsPerPage: 10,
                search: nameFilter ? nameFilter : '',
                partnerId: props.partnerId,
                displayDeleted: false
            };
            customerList = await CustomerService.getCustomerList(filters);
        } catch {
            props.showToast('error', 'Error loading customers on selector');
        }
        const customers = customerList.documents;
        const customerSelectorOptions = customers.map(customer => {
            const option = {
                value: customer.uuid,
                label: `${customer.name} - ${customer.env}`
            };
            return option;
        });
        if (props.selectedCustomerUuid) {
            let selectedCustomer = customerSelectorOptions.find(
                option => option.value === props.selectedCustomerUuid
            );
            if (!selectedCustomer) {
                try {
                    const customerInfo = await CustomerService.getCustomer(
                        props.selectedCustomerUuid
                    );
                    selectedCustomer = {
                        value: customerInfo.customer.uuid,
                        label: `${customerInfo.customer.name} - ${customerInfo.customer.env}`
                    };
                    customerSelectorOptions.unshift(selectedCustomer);
                } catch (error) {
                    props.showToast('error', error.message);
                }
            }
            stateCopy.selectedCustomer = selectedCustomer;
        }
        if (props.addEmptyOption) {
            customerSelectorOptions.unshift({
                value: '',
                label: 'Not filtering'
            });
        }
        stateCopy.customerSelectorOptions = customerSelectorOptions;
        setState(stateCopy);
    };

    useEffect(() => {
        loadCustomerList();
    }, [props.selectedCustomerUuid]);

    return (
        <ReactSelect
            onChange={onChangeSelector}
            onInputChange={onSearchSelector}
            name='customerId'
            options={state.customerSelectorOptions}
            value={state.selectedCustomer}
            styles={selectorStyles}
        />
    );
}
