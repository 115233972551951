import React from 'react';
import { Link } from 'react-router-dom';
import { CustomerFulfilledCreditCardOperationLog } from '../../../../../models/system/CustomerFulfilledCreditCardOperationLog';

const CustomerCreditCard = (
    operationLog: CustomerFulfilledCreditCardOperationLog
) => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerAccountEmail}
                </Link>
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>
            </p>
        )}
        <p>
            Card number: {operationLog.creditCardNumber}{' '}
            {operationLog.creditCardExpirationMonth}/
            {operationLog.creditCardExpirationYear}
        </p>
        <p>
            Intent:{' '}
            <a
                target='_blank'
                rel='noopener noreferrer'
                href={
                    'https://dashboard.stripe.com/setup_intents/' +
                    operationLog.stripePaymentSetupIntentId
                }
            >
                {operationLog.stripePaymentSetupIntentId}
            </a>
        </p>
    </div>
);

export default CustomerCreditCard;
