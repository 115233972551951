import React, { useState, useEffect, Fragment, ReactElement } from 'react';
import { Table, TableRow, TableCell, TableBody, Grid } from '@mui/material';
import ReportService from '../../../../services/ReportService';
import { CustomerReportItem } from '../../../../models/report/CustomerReportItem';
import ReportDate from '../../../partner/edit/components/ReportDate';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Action from '../../../../components/Action';
import ReportListFiltersType from '../../../../models/ReportListFilters';
import DateRangeFilters from '../../../../components/DateRangeFilters';
import SortableTableHead from '../../../../components/SortableTableHead';
import { HeadRow } from '../../../../models/list-filters/HeadRow';
import Paginator from '../../../../components/Paginator';
import { PagedList } from '../../../../models/PagedList';
import Alert from '../../../../components/Alert';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import { PropsWithShowToast } from '../../../../PropsWithShowToast';

type Props = PropsWithShowToast<{
    customerUuid: string;
}>;

const headRows: HeadRow[] = [
    { id: 'reportFromDate', sortable: true, label: 'Report Date' },
    { id: 'company', sortable: true, label: 'Partner' },
    { id: 'type', sortable: true, label: 'Partner Type' },
    { id: 'item.planName', sortable: false, label: 'Plan' },
    {
        id: 'item.totalInvoiceCommissionableAmount',
        sortable: false,
        label: 'Commissionable Amount'
    },
    { id: 'item.amount', sortable: true, label: 'Commission Amount' },
    { id: 'item.percentage', sortable: true, label: 'Commission %' },
    { id: 'actions', sortable: false, label: 'Actions' }
];

export default function CustomerReports({
    customerUuid,
    showToast
}: Props): ReactElement {
    const [customerReportItems, setCustomerReportItems] =
        useState<PagedList<CustomerReportItem>>();
    const [filters, setFilters] = useState<ReportListFiltersType>({
        rowsPerPage: 10,
        page: 0,
        search: '',
        sortBy: 'reportFromDate',
        direction: 'desc'
    });
    useEffect(() => {
        ReportService.listCustomerReportItems(customerUuid, filters)
            .then(setCustomerReportItems)
            .catch(error => showToast('error', error.message));
    }, [customerUuid, filters, showToast]);

    if (!customerReportItems) {
        return <Fragment />;
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <DateRangeFilters
                    filters={filters}
                    onFilterChange={(filterId, filterValue) =>
                        setFilters({ ...filters, [filterId]: filterValue })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Paginator
                    page={filters.page}
                    rowsPerPage={filters.rowsPerPage}
                    rowsTotal={customerReportItems.totalQuantity}
                    onPaginationChange={(page, rowsPerPage) =>
                        setFilters({ ...filters, page, rowsPerPage })
                    }
                />
            </Grid>
            {customerReportItems.totalQuantity === 0 && (
                <Alert variant='info'>No reports</Alert>
            )}
            {customerReportItems.totalQuantity > 0 && (
                <Table>
                    <SortableTableHead
                        headRows={headRows}
                        sortBy={filters.sortBy}
                        direction={filters.direction}
                        onSortByChange={(sortBy, direction) =>
                            setFilters({ ...filters, sortBy, direction })
                        }
                    />
                    <TableBody>
                        {customerReportItems.documents.map(
                            (customerReportItem, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <ReportDate
                                            report={customerReportItem}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            to={`/partner/${customerReportItem.partnerId}/edit`}
                                        >
                                            {customerReportItem.company}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {customerReportItem.type}
                                    </TableCell>
                                    <TableCell>
                                        {customerReportItem.item.planName}
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyFormat
                                            value={
                                                customerReportItem.item
                                                    .totalInvoiceCommissionableAmount
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyFormat
                                            value={
                                                customerReportItem.item.amount
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumericFormat
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            value={
                                                customerReportItem.item
                                                    .percentage
                                            }
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            suffix={'%'}
                                        />
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <div className='actions'>
                                            <Action
                                                label='View'
                                                linkTo={`/report/${customerReportItem.reportId}/backTo/customer/${customerUuid}`}
                                                icon={RemoveRedEyeIcon}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            )}
        </Fragment>
    );
}
