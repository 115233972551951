import React, { useEffect, CSSProperties, ReactElement } from 'react';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Customer from '../../../../models/customer/Customer';
import CustomerPlanSummary from './CustomerPlanSummary';
import PlanSummary from '../../../../models/plan/PlanSummary';
import { Grid, FormControlLabel, Checkbox, Theme } from '@mui/material';
import GenericListFilters from '../../../../models/GenericListFilters';
import Plan from '../../../../models/plan/Plan';
import PlanService from '../../../../services/PlanService';
import CustomerPlanPricing from './CustomerPlanPricing';
import { ToastVariant } from '../../../../reducers/Snackbar';
import moment from 'moment-timezone';
import { CustomerBillingInfo } from '../../../../models/customer/CustomerBillingInfo';
import CustomerService from '../../../../services/CustomerService';
import UpdatePlanOptions from '../../../../models/customer/UpdatePlanOptions';
import { InvoiceSettings } from '../../../../models/invoice/InvoiceSettings';
import Moment from 'react-moment';
import ReactSelect from '../../../../components/ReactSelect';
import { Subscription } from '../../../../models/subscription/Subscription';
import InlineAlert from '../../../../components/InlineAlert';
import DateSelector from '../../../../components/DateSelector';

const infoBoxStyles: CSSProperties = {
    border: '1px solid rgba(224, 224, 224, 1)',
    width: '100%',
    fontSize: '14px',
    borderRadius: '2px',
    padding: '0px 30px 20px'
};

const useStyles = makeStyles<Theme>(theme => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

function getSteps() {
    return ['Plan Selection', 'Custom Plans Pricing', 'Plan Summary'];
}

interface Props {
    customer: Customer;
    invoiceSettings?: InvoiceSettings;
    currentSubscription: Subscription;
    onCancel: (changePlan: boolean) => void;
    showToast: (variant: ToastVariant, message: string) => void;
    startLoading: () => void;
    stopLoading: () => void;
}

interface State {
    activeStep: number;
    selectorSearchText: string;
    currentSelectedPlan: { value: string; label: string };
    planSelectorOptions: { value: string; label: string }[];
    applyInmediate: boolean;
    newRenewalDate?: Date;
    currentRenewalDate: Date;
    maxRenewalDate: moment.Moment;
    minRenewalDate: moment.Moment;
    selectedPlan?: Plan;
    customerBillingInfo: CustomerBillingInfo;
    customerPlanId: string;
}

export default function CustomerChangePlanWizard(props: Props): ReactElement {
    // TODO: spinner loading?
    const classes = useStyles();
    let currentPlan: PlanSummary;
    const [state, setState]: [State, any] = React.useState({
        activeStep: 0,
        currentRenewalDate: props.currentSubscription.scheduledFinishDate,
        maxRenewalDate: moment(props.currentSubscription.scheduledFinishDate)
            .add(-1, 'day')
            .startOf('day'),
        minRenewalDate: moment().utc().add(1, 'day').startOf('day'),
        selectorSearchText: '',
        currentSelectedPlan: { value: '', label: '' },
        planSelectorOptions: [] as { value: string; label: string }[],
        applyInmediate: false,
        customerBillingInfo: props.customer.billingInfo,
        customerPlanId: props.customer.planId
    });
    const steps = getSteps();
    let timerSelectorPlans: any = null;

    const defaultTimezone = 'Europe/Madrid';
    const getNextRenovationDateWithDay = function (
        nextRenovationDate: moment.Moment,
        renovationDayOfMonth: number
    ): moment.Moment {
        nextRenovationDate = nextRenovationDate.clone();
        const daysInMonth = nextRenovationDate.daysInMonth();
        if (renovationDayOfMonth > 0) {
            if (renovationDayOfMonth > daysInMonth) {
                nextRenovationDate.date(daysInMonth);
            } else {
                nextRenovationDate.date(renovationDayOfMonth);
            }
        }
        return nextRenovationDate;
    };

    const getNextRenovationDate = function (
        previousRenovationDate: Date,
        timezone?: string
    ): Date {
        timezone = timezone || defaultTimezone;
        const previousRenovationDateInTimezone = moment(previousRenovationDate)
            .tz(timezone)
            .startOf('day');
        const renovationDayOfMonth = previousRenovationDateInTimezone.date();
        let nextRenovationDate = previousRenovationDateInTimezone
            .add(1, 'month')
            .startOf('day');
        nextRenovationDate = getNextRenovationDateWithDay(
            nextRenovationDate,
            renovationDayOfMonth
        );
        return nextRenovationDate.toDate();
    };

    if (state.selectedPlan) {
        let renovationDate = props.currentSubscription?.scheduledFinishDate;
        let startDate = props.currentSubscription?.scheduledStartDate;
        if (!state.applyInmediate) {
            renovationDate = getNextRenovationDate(
                props.currentSubscription.scheduledFinishDate,
                defaultTimezone
            );
            startDate = getNextRenovationDate(
                props.currentSubscription.scheduledStartDate,
                defaultTimezone
            );
        }

        currentPlan = {
            defaultCost: state.selectedPlan.price,
            vat: state.customerBillingInfo.vat,
            paymentMethod: props.customer.paymentInfo.paymentMethod,
            renovationDate,
            startDate,
            selectedPlanName: state.selectedPlan.name,
            hiddenDiscount: state.customerBillingInfo.hiddenDiscount,
            visibleDiscount: state.customerBillingInfo.visibleDiscount,
            fixedPrice: state.customerBillingInfo.fixedPrice,
            analyticsTier: state.customerBillingInfo.analyticsTier
        };

        if (
            state.applyInmediate &&
            props.currentSubscription &&
            !props.currentSubscription.customPlanPrice
        ) {
            const globalSettingsVat =
                props.invoiceSettings && props.invoiceSettings.defaultVat
                    ? props.invoiceSettings.defaultVat
                    : 0;
            const vat =
                typeof currentPlan.vat === 'number' && !isNaN(currentPlan.vat)
                    ? currentPlan.vat
                    : globalSettingsVat;
            const { planPrice: finalCost } =
                PlanService.getPlanPrice(currentPlan);
            const finalCostWithVat = PlanService.getPlanPriceWithVat(
                finalCost,
                vat
            );

            if (finalCostWithVat) {
                currentPlan.startDate = new Date();
                currentPlan.renovationDate = getNextRenovationDate(
                    currentPlan.startDate,
                    defaultTimezone
                );
            }
        }

        if (state.newRenewalDate) {
            currentPlan.renovationDate = getNextRenovationDate(
                state.newRenewalDate,
                defaultTimezone
            );
            currentPlan.startDate = moment(state.newRenewalDate)
                .tz(defaultTimezone)
                .startOf('day')
                .toDate();
        }
    }

    const init = async () => {
        await loadPlans('', true);
    };

    const loadPlans = async (nameFilter?: string, setDefault = false) => {
        timerSelectorPlans = null;
        let planList: { documents: Plan[]; totalQuantity: number } = {
            documents: [],
            totalQuantity: 0
        };
        try {
            const filters: GenericListFilters = {
                page: 0,
                rowsPerPage: 10,
                search: nameFilter ? nameFilter : ''
            };
            planList = await PlanService.getPlanList(filters);
        } catch {
            props.showToast('error', 'Error on get plan list');
        }
        const plans = planList.documents;
        const planSelectorOptions = plans.map(plan => {
            const option = { value: plan._id, label: plan.name };
            return option;
        });
        const stateCopy = { ...state };
        if (setDefault) {
            const currentCustomerPlanId = stateCopy.customerPlanId;
            const customerPlanIdIndex = planSelectorOptions.findIndex(
                planSelectorOptions =>
                    planSelectorOptions.value === currentCustomerPlanId
            );
            let selectedPlan;
            if (customerPlanIdIndex >= 0) {
                selectedPlan = await PlanService.getPlan(
                    planSelectorOptions[customerPlanIdIndex].value
                );
                stateCopy.currentSelectedPlan =
                    planSelectorOptions[customerPlanIdIndex];
            } else {
                selectedPlan = await PlanService.getPlan(currentCustomerPlanId);
                planSelectorOptions.unshift({
                    value: selectedPlan._id,
                    label: selectedPlan.name
                });
                stateCopy.currentSelectedPlan = planSelectorOptions[0];
            }

            stateCopy.selectedPlan = selectedPlan;
        }
        stateCopy.planSelectorOptions = planSelectorOptions;
        setState(stateCopy);
    };

    const searchPlan = (searchText: string, event: any) => {
        const timeoutToRefreshOptions = 500;
        if (timerSelectorPlans) {
            clearTimeout(timerSelectorPlans);
            timerSelectorPlans = null;
        }
        if (event.action === 'input-change') {
            timerSelectorPlans = setTimeout(() => {
                setState({ ...state, selectorSearchText: searchText });
                loadPlans(searchText);
            }, timeoutToRefreshOptions);
        }

        if (event.action === 'menu-close' && state.selectorSearchText) {
            setState({ ...state, selectorSearchText: '' });
            loadPlans();
        }
    };

    const updatePlanId = async (currentSelectedPlan: any) => {
        props.startLoading();
        const selectedPlan = await PlanService.getPlan(
            currentSelectedPlan.value
        );
        setState({ ...state, currentSelectedPlan, selectedPlan });
        props.stopLoading();
    };

    const handleNext = async () => {
        if (state.activeStep === steps.length - 1) {
            const updatePlanOptions: UpdatePlanOptions = {
                applyInmediate: state.applyInmediate,
                billingInfo: state.customerBillingInfo
            };
            if (state.newRenewalDate) {
                updatePlanOptions.newScheduledFinishDate = moment(
                    state.newRenewalDate
                )
                    .tz(defaultTimezone)
                    .startOf('day')
                    .toDate();
            }
            try {
                await CustomerService.updatePlan(
                    props.customer.uuid,
                    state.currentSelectedPlan.value,
                    updatePlanOptions
                );
                props.showToast('success', 'Plan changed');
            } catch (err) {
                props.showToast('error', 'Error on change plan');
            }
            props.onCancel(true);
        }
        setState({ ...state, activeStep: state.activeStep + 1 });
    };

    function handleBack() {
        setState({ ...state, activeStep: state.activeStep - 1 });
    }

    function handleReset() {
        setState({ ...state, activeStep: 0 });
        props.onCancel(false);
    }

    function onChangeRenewalDate(date?: Date) {
        const newRenewalDate = date && date.toISOString();
        setState({ ...state, newRenewalDate });
    }

    function onApplyInmediateChange(applyInmediate: boolean) {
        if (applyInmediate) {
            setState({ ...state, applyInmediate, newRenewalDate: null });
        } else {
            setState({ ...state, applyInmediate });
        }
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <React.Fragment>
                        <Grid
                            container
                            spacing={1}
                            style={{ marginBottom: '40px' }}
                        >
                            <Grid container item xs={6}>
                                <div
                                    style={{
                                        ...infoBoxStyles,
                                        marginRight: '15px'
                                    }}
                                >
                                    <Grid item xs={12} sm={12}>
                                        <h4>
                                            <b>Select plan: </b>
                                        </h4>
                                        <ReactSelect
                                            onChange={updatePlanId}
                                            onInputChange={searchPlan}
                                            name='planId'
                                            options={state.planSelectorOptions}
                                            value={state.currentSelectedPlan}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value='Apply Plan Immediately'
                                                    checked={
                                                        state.applyInmediate
                                                    }
                                                    onChange={event =>
                                                        onApplyInmediateChange(
                                                            event.target.checked
                                                        )
                                                    }
                                                    color='primary'
                                                />
                                            }
                                            label='Apply Plan Immediately'
                                        />
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid container item xs={6}>
                                <div
                                    style={{
                                        ...infoBoxStyles,
                                        marginLeft: '15px'
                                    }}
                                >
                                    <Grid container item xs={12}>
                                        <Grid item xs={6} sm={6}>
                                            <DateSelector
                                                maxDate={state.maxRenewalDate.toDate()}
                                                minDate={state.minRenewalDate.toDate()}
                                                value={state.newRenewalDate}
                                                onChange={onChangeRenewalDate}
                                                label='New subscription Start Date'
                                                disabled={
                                                    state.applyInmediate ||
                                                    state.maxRenewalDate.isBefore(
                                                        state.minRenewalDate
                                                    )
                                                }
                                                style={{ marginTop: '10px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            {state.newRenewalDate &&
                                                !props.customer
                                                    .automaticInvoiceChargeDisabled && (
                                                    <InlineAlert
                                                        class='warning'
                                                        message='The automatic invoice charge will be disabled'
                                                    ></InlineAlert>
                                                )}
                                            <p>
                                                New subscription renewal date:{' '}
                                            </p>
                                            {state.newRenewalDate && (
                                                <Moment
                                                    date={state.newRenewalDate}
                                                    format='DD/MM/YYYY'
                                                />
                                            )}
                                            {!state.newRenewalDate && '-'}
                                            <p>
                                                Current subscription renewal
                                                date:{' '}
                                            </p>
                                            <Moment
                                                date={state.currentRenewalDate}
                                                format='DD/MM/YYYY'
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        {currentPlan && (
                            <CustomerPlanSummary
                                invoiceSettings={props.invoiceSettings}
                                planSummary={currentPlan}
                            >
                                Plan Summary
                            </CustomerPlanSummary>
                        )}
                    </React.Fragment>
                );
            case 1:
                return (
                    <React.Fragment>
                        <Grid
                            container
                            spacing={1}
                            style={{ marginBottom: '40px' }}
                        >
                            <CustomerPlanPricing
                                billingInfo={state.customerBillingInfo}
                                onUpdateBillingInfo={customerBillingInfo =>
                                    setState({ ...state, customerBillingInfo })
                                }
                                showOveragePricing={false}
                            ></CustomerPlanPricing>
                        </Grid>
                        <CustomerPlanSummary
                            invoiceSettings={props.invoiceSettings}
                            planSummary={currentPlan}
                        >
                            Plan Summary
                        </CustomerPlanSummary>
                    </React.Fragment>
                );
            case 2:
                return (
                    <React.Fragment>
                        <CustomerPlanSummary
                            invoiceSettings={props.invoiceSettings}
                            planSummary={currentPlan}
                        >
                            Plan Summary
                        </CustomerPlanSummary>
                    </React.Fragment>
                );
            default:
                return (
                    <Typography className={classes.instructions}>
                        Step error
                    </Typography>
                );
        }
    }

    useEffect(() => {
        init();
    }, []);
    return (
        <div className={classes.root}>
            <Stepper activeStep={state.activeStep}>
                {steps.map((label, index) => {
                    const stepProps = { completed: state.activeStep > index };
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {state.activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>
                            Plan change is done.
                        </Typography>
                        <Button
                            onClick={handleReset}
                            className={classes.button}
                        >
                            Done
                        </Button>
                    </div>
                ) : (
                    <div>
                        {getStepContent(state.activeStep)}
                        <div>
                            <Button
                                disabled={state.activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                            >
                                Previous
                            </Button>
                            {state.activeStep === steps.length - 1 && (
                                <Button
                                    disabled={state.activeStep === 0}
                                    onClick={handleReset}
                                    className={classes.button}
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button
                                style={{ float: 'right' }}
                                variant='contained'
                                color='primary'
                                onClick={handleNext}
                                className={classes.button}
                            >
                                {state.activeStep === steps.length - 1
                                    ? 'Apply'
                                    : 'Next'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
