import React, { Fragment, ReactElement } from 'react';
import { Component } from 'react';
import DateBlock from './DateBlock';
import TableSubCell from '../../../../../../components/TableSubCell';

interface Props {
    startDate?: Date;
    finishDate?: Date;
    customTimezone?: string | null;
}

class StartFinishDateCell extends Component<Props> {
    render(): ReactElement {
        const { startDate, finishDate, customTimezone } = this.props;
        return (
            <Fragment>
                <DateBlock date={startDate} customTimezone={customTimezone} />
                <TableSubCell>
                    <DateBlock
                        date={finishDate}
                        customTimezone={customTimezone}
                    />
                </TableSubCell>
            </Fragment>
        );
    }
}

export default StartFinishDateCell;
