import React, { ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Alert from '../../../../components/Alert';
import { CSSProperties } from '@mui/styles';
import PartnerListFilters from '../../../../models/PartnerListFilters';
import Partner from '../../../../models/partner/Partner';
import SortableTableHead, {
    SortDirection
} from '../../../../components/SortableTableHead';
import { HeadRow } from '../../../../models/list-filters/HeadRow';
import Paginator from '../../../../components/Paginator';
import SearchBox from '../../../../components/SearchBox';
import PartnerTypeSelector from '../../edit/components/PartnerTypeSelector';
import { Grid } from '@mui/material';
import DateFormat from '../../../../components/DateFormat';

interface Props {
    rows: Partner[];
    rowsTotal: number;
    filters: PartnerListFilters;
    onPaginationChange: (page: number, limit: number) => void;
    onSortByChange: (fieldId: string, direction: SortDirection) => void;
    onFilterChange: (
        filterId: keyof PartnerListFilters,
        filterValue: any
    ) => void;
    renderActions: (partner: Partner) => JSX.Element;
}

const containerTableStyle: CSSProperties = {
    overflowX: 'auto',
    width: '100%'
};

export default function PartnerListItems(props: Props): ReactElement {
    const headRows: HeadRow[] = [
        { id: 'partnerInfo.company', sortable: true, label: 'Name' },
        {
            id: 'contractSignedDate',
            sortable: true,
            label: 'Contract Signed Date'
        },
        { id: 'type', sortable: false, label: 'Type' },
        { id: 'actions', sortable: false, label: 'Actions' }
    ];

    return (
        <div style={{ width: '100%' }}>
            <Grid container>
                <Grid item xs={3}>
                    <SearchBox
                        value={props.filters.search}
                        onChange={search =>
                            props.onFilterChange('search', search)
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <PartnerTypeSelector
                        type={props.filters.type}
                        onChange={type => props.onFilterChange('type', type)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Paginator
                        page={props.filters.page}
                        rowsPerPage={props.filters.rowsPerPage}
                        rowsTotal={props.rowsTotal}
                        onPaginationChange={props.onPaginationChange}
                    ></Paginator>
                </Grid>
            </Grid>
            {props.rows.length > 0 && (
                <div style={containerTableStyle}>
                    <Table size='small' className={'filtered-list'}>
                        <SortableTableHead
                            headRows={headRows}
                            sortBy={props.filters.sortBy}
                            direction={props.filters.direction}
                            onSortByChange={props.onSortByChange}
                        />
                        <TableBody>
                            {props.rows.map(row => (
                                <TableRow key={row._id}>
                                    <TableCell>
                                        {row.partnerInfo.company}
                                    </TableCell>
                                    <TableCell>
                                        <DateFormat
                                            date={row.contractSignedDate}
                                        />
                                    </TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell align='right'>
                                        <div className='actions'>
                                            {props.renderActions(row)}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
            {props.rows.length === 0 && (
                <Alert visible={props.rows.length === 0} variant='info'>
                    No items found
                </Alert>
            )}
        </div>
    );
}
