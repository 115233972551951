import React, { PropsWithChildren, ReactElement } from 'react';

type Props = PropsWithChildren<unknown>;

export default function TableSubCell({ children }: Props): ReactElement {
    return (
        <div
            style={{
                fontSize: '11px',
                minWidth: '95px',
                color: 'grey'
            }}
        >
            {children}
        </div>
    );
}
