export function getBackToLink(
    backTo: string,
    partnerId?: string,
    customerUuid?: string
): string {
    switch (backTo) {
        case 'partner':
            return `/partner/${partnerId}/edit`;
        case 'customer':
            return `/customer/${customerUuid}/edit`;
        case 'operation-log':
            return '/system/operation-log';
        case 'report':
        default:
            return '/report/list';
    }
}
