import React, { ReactElement, PropsWithChildren } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link } from 'react-router-dom';

type Props = PropsWithChildren<{
    href?: string;
    onClick?: () => void;
}>;

export default function BackButton({
    href,
    onClick,
    children
}: Props): ReactElement {
    const props = {
        ...(href ? { component: Link, to: href } : {}),
        ...(onClick && !href ? { onClick } : {})
    };
    return (
        <Button
            variant='contained'
            color='primary'
            {...props}
            startIcon={<ChevronLeftIcon />}
        >
            {children}
        </Button>
    );
}

BackButton.propTypes = {
    children: PropTypes.node
};
