import React, { CSSProperties, ReactElement } from 'react';
import { Grid } from '@mui/material';
import Moment from 'react-moment';
import { ShopifyRecurringApplicationCharge as ShopifyRecurringApplicationChargeModel } from '../../../../models/customer/ShopifyRecurringApplicationCharge';
import SummaryHeader from '../../../../components/SummaryHeader';
import { NumericFormat } from 'react-number-format';

interface Props {
    applicationCharge?: ShopifyRecurringApplicationChargeModel;
}

const infoStyles: CSSProperties = {
    fontSize: '15px'
};

export default function ShopifyRecurringApplicationCharge(
    props: Props
): ReactElement {
    return (
        <React.Fragment>
            {props.applicationCharge && (
                <Grid item xs={12}>
                    <h4>Shopify recurring app charge:</h4>
                    {props.applicationCharge.id && (
                        <SummaryHeader
                            title='Subscription id'
                            style={infoStyles}
                        >
                            {props.applicationCharge.id}
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.activatedOn && (
                        <SummaryHeader title='Activated on' style={infoStyles}>
                            <Moment
                                date={props.applicationCharge.activatedOn}
                                format='DD/MM/YYYY'
                            />
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.billingOn && (
                        <SummaryHeader title='Billing on' style={infoStyles}>
                            <Moment
                                date={props.applicationCharge.billingOn}
                                format='DD/MM/YYYY'
                            />
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.cancelledOn && (
                        <SummaryHeader title='Cancelled on' style={infoStyles}>
                            <Moment
                                date={props.applicationCharge.cancelledOn}
                                format='DD/MM/YYYY'
                            />
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.cappedAmount && (
                        <SummaryHeader title='Capped amount' style={infoStyles}>
                            <span>
                                $
                                <NumericFormat
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}
                                    value={
                                        +props.applicationCharge.cappedAmount ||
                                        0
                                    }
                                />
                            </span>
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.createdAt && (
                        <SummaryHeader title='Created at' style={infoStyles}>
                            <Moment
                                date={props.applicationCharge.createdAt}
                                format='DD/MM/YYYY'
                            />
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.name && (
                        <SummaryHeader
                            title='Subscription name'
                            style={infoStyles}
                        >
                            {props.applicationCharge.name}
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.price && (
                        <SummaryHeader title='Price' style={infoStyles}>
                            <span>
                                $
                                <NumericFormat
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}
                                    value={props.applicationCharge.price || 0}
                                />
                            </span>
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.status && (
                        <SummaryHeader title='Status' style={infoStyles}>
                            {props.applicationCharge.status}
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.test && (
                        <SummaryHeader title='Test charge' style={infoStyles}>
                            {props.applicationCharge.test ? 'Yes' : 'No'}
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.trialDays !== null &&
                        !isNaN(props.applicationCharge.trialDays) && (
                            <SummaryHeader
                                title='Trial days'
                                style={infoStyles}
                            >
                                {props.applicationCharge.trialDays}
                            </SummaryHeader>
                        )}
                    {props.applicationCharge.trialEndsOn && (
                        <SummaryHeader title='Trial ends on' style={infoStyles}>
                            {props.applicationCharge.trialEndsOn}
                        </SummaryHeader>
                    )}
                    {props.applicationCharge.updatedAt && (
                        <SummaryHeader title='Updated at' style={infoStyles}>
                            <Moment
                                date={props.applicationCharge.updatedAt}
                                format='DD/MM/YYYY'
                            />
                        </SummaryHeader>
                    )}
                </Grid>
            )}
        </React.Fragment>
    );
}
