import React, { Fragment, ReactElement } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { ReportListItem } from '../../../../models/report/ReportListItem';

type Props = {
    report: Pick<ReportListItem, 'reportFromDate' | 'reportToDate'>;
};

export default function ReportDate({ report }: Props): ReactElement {
    return (
        <Fragment>
            from{' '}
            <Moment
                locale='es'
                date={report.reportFromDate}
                format='DD MMMM YYYY'
            />
            , to{' '}
            <Moment
                locale='es'
                date={moment(report.reportToDate).add(-1, 'day').toDate()}
                format='DD MMMM YYYY'
            />
        </Fragment>
    );
}
