import React, { Fragment, ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import PartnerListItems from './components/PartnerListItems';
import PartnerService from '../../../services/PartnerService';
import { Partner } from '../../../models/partner/Partner';
import { ToastVariant } from '../../../reducers/Snackbar';
import PartnerListFilters from '../../../models/PartnerListFilters';
import Cookies from 'js-cookie';
import PageHeader from '../../../components/PageHeader';
import { Button, Tooltip, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    filters: PartnerListFilters;
    partners: Partner[];
    totalPartners: number;
}

const partnerFiltersCookieName = 'partnerListFilters';

export default class PartnerList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const filtersCookie = Cookies.get(partnerFiltersCookieName);
        const filters: PartnerListFilters = filtersCookie
            ? JSON.parse(filtersCookie)
            : {
                  rowsPerPage: 10,
                  page: 0,
                  search: '',
                  sortBy: 'createdAt',
                  direction: 'desc'
              };
        this.state = {
            partners: [],
            totalPartners: 0,
            isLoading: true,
            filters
        };
    }

    componentDidMount(): void {
        this.loadPartners();
    }

    loadPartners = async (
        filterChanges?: Partial<PartnerListFilters>
    ): Promise<void> => {
        try {
            const filters = { ...this.state.filters, ...filterChanges };
            this.setState({ filters, isLoading: true });
            this.saveFilters(filters);
            const { documents, totalQuantity } =
                await PartnerService.getPartnerList(filters);
            this.setState({
                partners: documents,
                totalPartners: totalQuantity,
                isLoading: false
            });
        } catch {
            this.setState({ isLoading: false });
            this.props.showToast('error', 'Error on get partner list');
        }
    };

    onSortByChange = (sortBy: string, direction?: 'asc' | 'desc'): void => {
        this.loadPartners({ sortBy, direction });
    };

    onFilterChange = (
        filterId: keyof PartnerListFilters,
        filterValue: any
    ): void => {
        this.setState({
            filters: { ...this.state.filters, [filterId]: filterValue }
        });
        this.loadPartners({ page: 0, [filterId]: filterValue });
    };

    onPaginationChange = (page: number, rowsPerPage: number): void => {
        this.loadPartners({ page, rowsPerPage });
    };

    saveFilters = (filters: PartnerListFilters): void => {
        Cookies.set(partnerFiltersCookieName, JSON.stringify(filters));
    };

    render(): ReactElement {
        return (
            <Fragment>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>Partner list</PageHeader>
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                component={Link}
                                to='/partner/create'
                                variant='contained'
                                color='primary'
                            >
                                Add new partner
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className='cn-paper'>
                            <PartnerListItems
                                rowsTotal={this.state.totalPartners}
                                rows={this.state.partners}
                                filters={this.state.filters}
                                onPaginationChange={this.onPaginationChange}
                                onSortByChange={this.onSortByChange}
                                onFilterChange={this.onFilterChange}
                                renderActions={partner => (
                                    <Tooltip title='Edit'>
                                        <IconButton
                                            color='primary'
                                            component={Link}
                                            to={`/partner/${partner._id}/edit`}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            />
                        </Paper>
                    </Grid>
                </Container>
            </Fragment>
        );
    }
}
