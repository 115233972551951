import React, { ReactElement } from 'react';
import './InlineAlert.css';

interface Props {
    class: 'warning' | 'error';
    message: string;
}

export default function InlineAlert(props: Props): ReactElement {
    return (
        <div className={'inline-alert-' + props.class + ' inline-alert'}>
            {props.message}
        </div>
    );
}
