import React, { ReactElement } from 'react';
import BackButton from '../../../components/BackButton';
import { getBackToLink } from './getBackToLink';

type Props = {
    backTo: string;
    partnerId?: string;
    customerUuid?: string;
};

function getBackToText(backTo: string): string {
    switch (backTo) {
        case 'partner':
            return 'Partner';
        case 'customer':
            return 'Customer';
        case 'operation-log':
            return 'Operation Log';
        case 'report':
        default:
            return 'List';
    }
}

export default function ReportBackButton({
    backTo,
    partnerId,
    customerUuid
}: Props): ReactElement {
    return (
        <BackButton href={getBackToLink(backTo, partnerId, customerUuid)}>
            Back to {getBackToText(backTo)}
        </BackButton>
    );
}
