import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Customer from '../../../../models/customer/Customer';
import { CSSProperties } from '@mui/styles';
import Button from '@mui/material/Button';
import { CustomerBillingInfo } from '../../../../models/customer/CustomerBillingInfo';
import InvoiceCompanyInfo from '../../../invoice/edit/components/InvoiceCompanyInfo';
import { Divider } from '@mui/material';
import CustomerPaymentInfoEditor from './CustomerPaymentInfoEditor';
import { ClientInfo } from '../../../../models/invoice/ClientInfo';
import { CustomerPaymentInfo } from '../../../../models/customer/CustomerPaymentInfo';
import NumberField from '../../../../components/NumberField';
import { ToastVariant } from '../../../../reducers/Snackbar';

interface Props {
    customer: Customer;
    saveCustomer: () => void;
    onUpdateBillingInfo: (billingInfo: CustomerBillingInfo) => void;
    onUpdateClientInfo: (companyInfo: ClientInfo) => void;
    onUpdatePaymentInfo: (paymentInfo: CustomerPaymentInfo) => void;
    onUpdateAutomaticInvoiceChargeDisabled: (
        automaticInvoiceChargeDisabled: boolean
    ) => void;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    customer: Customer;
}

class CustomerEditor extends Component<Props, State> {
    private buttonContainerStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px'
    };

    private inputStyles: CSSProperties = {
        width: '100%'
    };

    private updateBillingInfo = (
        fieldName: keyof CustomerBillingInfo,
        newValue: any
    ) => {
        const billingInfo = { ...this.props.customer.billingInfo };
        billingInfo[fieldName] = newValue;
        this.props.onUpdateBillingInfo(billingInfo);
    };

    private isDeletedCustomer = (): boolean => !!this.props.customer.deleted;
    render(): ReactElement {
        return (
            <Paper className='cn-paper'>
                {this.props.customer && (
                    <Grid container spacing={1}>
                        <InvoiceCompanyInfo
                            info={this.props.customer.clientInfo}
                            isEditable={true}
                            type='ClientInfo'
                            onUpdateCompanyInfo={info =>
                                this.props.onUpdateClientInfo(
                                    info as ClientInfo
                                )
                            }
                            showToast={this.props.showToast}
                        >
                            Company Information
                        </InvoiceCompanyInfo>

                        <Grid item xs={12} sm={12} style={{ margin: '20px 0' }}>
                            <Divider variant='fullWidth' />
                        </Grid>

                        <CustomerPaymentInfoEditor
                            paymentInfo={this.props.customer.paymentInfo}
                            creditCardInfo={this.props.customer.creditCardInfo}
                            shopifyRecurringApplicationCharge={
                                this.props.customer
                                    .shopifyRecurringApplicationCharge
                            }
                            automaticInvoiceChargeDisabled={
                                this.props.customer
                                    .automaticInvoiceChargeDisabled
                            }
                            onUpdatePaymentInfo={this.props.onUpdatePaymentInfo}
                            onUpdateAutomaticInvoiceChargeDisabled={
                                this.props
                                    .onUpdateAutomaticInvoiceChargeDisabled
                            }
                        >
                            Payment Method:
                        </CustomerPaymentInfoEditor>

                        <Grid item xs={12} sm={12} style={{ margin: '20px 0' }}>
                            <Divider variant='fullWidth' />
                        </Grid>
                        <Grid item xs={12}>
                            <p>
                                <b>Custom VAT:</b>
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberField
                                style={this.inputStyles}
                                adornment={'%'}
                                required={false}
                                id='vat'
                                label='VAT'
                                value={this.props.customer.billingInfo.vat}
                                onChangeValue={newValue =>
                                    this.updateBillingInfo('vat', newValue)
                                }
                            />
                        </Grid>

                        <Grid
                            container
                            spacing={3}
                            justifyContent='flex-end'
                            alignItems='flex-end'
                        >
                            <Grid
                                item
                                xs={4}
                                sm={4}
                                style={this.buttonContainerStyles}
                            >
                                <Button
                                    onClick={this.props.saveCustomer}
                                    style={{ margin: '20px' }}
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                    disabled={this.isDeletedCustomer()}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Paper>
        );
    }
}
export default CustomerEditor;
