import React, { CSSProperties, ReactElement, PropsWithChildren } from 'react';
import PropTypes from 'prop-types';

type Props = PropsWithChildren<void>;

export default function PageHeader(props: Props): ReactElement {
    const titleStyles: CSSProperties = {
        fontFamily: 'Fira Sans,sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#47535d',
        marginTop: '52px',
        marginBottom: '24px',
        padding: '0',
        borderBottom: 'none'
    };

    return <h1 style={titleStyles}>{props.children}</h1>;
}

PageHeader.propTypes = {
    children: PropTypes.node
};
