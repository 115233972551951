import React, { CSSProperties, useEffect, ReactElement, useState } from 'react';
import { FormControl } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Action as ToastAction } from '../../../../../reducers/Snackbar';
import PlanService from '../../../../../services/PlanService';
import ReactSelect, {
    ReactSelectOption
} from '../../../../../components/ReactSelect';
import { useDebouncedCallback } from '../../../../../components/useDebouncedCallback';
import { InputActionMeta } from 'react-select/lib/types';

const formControlStyles: CSSProperties = {
    minWidth: '150px',
    marginRight: 10
};

const selectorStyles = {
    container: (provided: CSSProperties) => ({
        ...provided,
        marginTop: '7px',
        width: '250px',
        display: 'inline-block'
    })
};

interface Props {
    planId?: string;
    onChange: (partnerId: string) => void;
}

async function getPlanOptions(search?: string): Promise<ReactSelectOption[]> {
    const planList = await PlanService.getPlanList({
        page: 0,
        rowsPerPage: 50,
        search
    });
    const plans = planList.documents;
    return [
        { value: '', label: 'Not filtering' },
        ...plans.map(plan => ({ value: plan._id, label: plan.name }))
    ];
}

function PlanSelector(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [options, setOptions] = useState<ReactSelectOption[]>([]);
    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState(false);

    const setSearchDebounced = useDebouncedCallback(setSearch, 500);

    const onFilterPlanChange = (selectedOption: ReactSelectOption) => {
        props.onChange(selectedOption.value);
    };

    const searchPlan = (searchText: string, event: InputActionMeta) => {
        if (event.action === 'input-change') {
            setSearchDebounced(searchText);
        }

        if (event.action === 'menu-close') {
            setSearchDebounced('');
        }
    };

    const searchPlanDebounced = useDebouncedCallback(searchPlan, 500);

    useEffect(() => {
        getPlanOptions(search)
            .then(setOptions)
            .catch(() => {
                const action: ToastAction = {
                    payload: {
                        variant: 'error',
                        message: 'Error on get plan list'
                    },
                    type: 'ADD_TOAST'
                };
                dispatch(action);
            })
            .finally(() => setLoading(false));
    }, [dispatch, search]);

    const value = options.find(o => o.value === (props.planId ?? ''));

    return (
        <FormControl style={formControlStyles}>
            <ReactSelect
                styles={selectorStyles}
                placeholder={'Filter by plan'}
                onChange={onFilterPlanChange}
                onInputChange={searchPlanDebounced}
                name='planId'
                isLoading={isLoading}
                options={options}
                value={value}
                inputId='plan-filter'
            />
        </FormControl>
    );
}

export default PlanSelector;
