import RequestService from './RequestService';
import moment from 'moment';
import { OperationLog } from '../models/system/OperationLog';
import OperationLogListFilters from '../models/OperationLogListFilters';
import { PagedList } from '../models/PagedList';

const OperationLogService = {
    getOperationLogList(
        listFilters: OperationLogListFilters
    ): Promise<{ documents: OperationLog[]; totalQuantity: number }> {
        const filters: any = {
            limit: listFilters.rowsPerPage,
            page: listFilters.page + 1
        };

        if (listFilters.sortBy) {
            filters.sortKey = listFilters.sortBy;
        }

        if (listFilters.customerUUID) {
            filters.customerUUID = listFilters.customerUUID;
        }

        if (listFilters.type) {
            filters.type = listFilters.type;
        }

        if (listFilters.direction) {
            filters.sortDirection = listFilters.direction === 'asc' ? 1 : -1;
        }

        if (listFilters.fromDate) {
            filters.fromDate = moment(listFilters.fromDate)
                .startOf('day')
                .toDate();
        }

        if (listFilters.toDate) {
            filters.toDate = moment(listFilters.toDate).endOf('day').toDate();
        }

        return RequestService.cnFetch(
            '/operation-log',
            {
                method: 'GET'
            },
            filters
        ).then(async response => {
            const respObject: PagedList<OperationLog> = await response.json();
            return respObject;
        });
    }
};
export default OperationLogService;
