import React, { ReactElement } from 'react';
import { FormControlLabel, Switch } from '@mui/material';

type Props = {
    isPercentage: boolean;
    onChange: (isPercentage: boolean) => void;
    isDisabled?: boolean;
};

const style = {
    marginLeft: '5px',
    marginBottom: '-8px',
    verticalAlign: 'bottom'
};

export default function PercentageEuroSwitch({
    isPercentage,
    onChange,
    isDisabled
}: Props): ReactElement {
    return (
        <FormControlLabel
            style={style}
            control={
                <React.Fragment>
                    <span
                        className={
                            'MuiTypography-root MuiFormControlLabel-label MuiTypography-body1'
                        }
                    >
                        €
                    </span>
                    <Switch
                        checked={isPercentage}
                        onChange={event => onChange(event.target.checked)}
                        color='primary'
                        disabled={isDisabled}
                    />
                </React.Fragment>
            }
            label='%'
        />
    );
}
