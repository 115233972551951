import React from 'react';
import { Link } from 'react-router-dom';
import { InvoiceCreatedOperationLog } from '../../../../../models/system/InvoiceCreatedOperationLog';
import InvoiceLinkActiveView from './InvoiceLinkActiveView';

const InvoiceCreated = (operationLog: InvoiceCreatedOperationLog) => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerAccountEmail}
                </Link>
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>
            </p>
        )}
        <InvoiceLinkActiveView
            invoiceId={operationLog.invoiceId}
            invoiceTotalAmount={operationLog.invoiceTotalAmount}
            invoiceLinkActive={operationLog.invoiceLinkActive}
        ></InvoiceLinkActiveView>
    </div>
);

export default InvoiceCreated;
