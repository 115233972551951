export type Action = {
    type: 'ADD_TOAST' | 'CLOSE_TOAST';
    payload: { variant: ToastVariant; message: string; index?: number };
};

export type ToastVariant = 'success' | 'warning' | 'error' | 'info';

export type ActiveToast = {
    variant: ToastVariant;
    message: string;
    visible: boolean;
};

type State = {
    activeToasts: ActiveToast[];
};

const snackbarReducer = (
    state: State = { activeToasts: [] },
    action: Action
): any => {
    switch (action.type) {
        case 'ADD_TOAST':
            return {
                ...state,
                activeToasts: [
                    ...state.activeToasts,
                    {
                        variant: action.payload.variant,
                        message: action.payload.message,
                        visible: true
                    }
                ]
            };
        case 'CLOSE_TOAST':
            return {
                ...state,
                activeToasts: state.activeToasts.filter(
                    (item, j) => action.payload.index !== j
                )
            };
        default:
            return state;
    }
};

export default snackbarReducer;
