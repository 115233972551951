import React, { useState, ReactElement } from 'react';
import { Paper, Grid, Button } from '@mui/material';
import GenerateReportDialog from './GenerateReportDialog';
import Partner from '../../../../models/partner/Partner';
import PartnerReportList from './PartnerReportList';
import { PropsWithShowToast } from '../../../../PropsWithShowToast';

type Props = PropsWithShowToast<{
    partner: Partner;
    onGenerateReport: (year: number, month: number) => void;
}>;

export default function PartnerReports({
    partner,
    onGenerateReport,
    showToast
}: Props): ReactElement {
    const [isGenerateReportDialogOpen, setGenerateReportDialogOpen] =
        useState(false);

    return (
        <Paper className='cn-paper'>
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                    <p>
                        <b>Reports Generated for this Partner</b>
                    </p>
                    <Button
                        variant='contained'
                        color='primary'
                        style={{ position: 'absolute', top: 14, right: 0 }}
                        onClick={() => setGenerateReportDialogOpen(true)}
                    >
                        Generate report
                    </Button>
                </Grid>
            </Grid>
            <GenerateReportDialog
                open={isGenerateReportDialogOpen}
                onClose={() => setGenerateReportDialogOpen(false)}
                onGenerateReport={onGenerateReport}
            />
            {partner._id && (
                <PartnerReportList
                    showToast={showToast}
                    partnerId={partner._id}
                />
            )}
        </Paper>
    );
}
