import { OperationLog } from './OperationLog';

const OperationLogTypeKeys: OperationLog['type'][] = [
    'customer-created',
    'customer-deleted',
    'customer-changed-payment-method',
    'customer-plan-update',
    'customer-fulfilled-credit-card',
    'customer-removed-credit-card',
    'customer-plan-change-request',
    'customer-cancel-plan-change-request',
    'customer-changed-vat',
    'customer-analytics-tier-change-request',
    'customer-shopify-changed-account',
    'customer-name-update',
    'invoice-created',
    'invoice-emitted',
    'invoice-charged',
    'invoice-deleted',
    'invoice-charge-failed',
    'invoice-sent-by-email',
    'invoice-created-not-charged',
    'invoice-charge-failed-notification-failed',
    'partner-term-edited',
    'partner-term-unlink',
    'report-sent-by-email',
    'report-set-resolved-status',
    'report-generated',
    'report-edited',
    'report-deleted',
    'customer-shopify-recurring-subscription-updated',
    'customer-shopify-update-plan-inconsistency'
];

export const OperationLogNames: { [P in OperationLogTypeId]: string } = {
    'customer-created': 'Customer created',
    'customer-deleted': 'Customer deleted',
    'customer-changed-payment-method': 'Customer changed payment method',
    'customer-shopify-changed-account':
        'Customer changed account from Shopify shop',
    'customer-plan-update': 'Customer plan update',
    'customer-fulfilled-credit-card': 'Customer fulfilled credit card',
    'customer-removed-credit-card': 'Customer removed credit card',
    'customer-plan-change-request': 'Customer plan change request',
    'customer-cancel-plan-change-request':
        'Customer cancel plan change request',
    'customer-changed-vat': 'Customer changed vat',
    'customer-analytics-tier-change-request': 'Customer analytics tier request',
    'invoice-created': 'Invoice created',
    'invoice-emitted': 'Invoice emitted',
    'invoice-charged': 'Invoice charged',
    'invoice-deleted': 'Invoice deleted',
    'invoice-charge-failed': 'Invoice charge failed',
    'invoice-sent-by-email': 'Invoice sent by email',
    'invoice-created-not-charged': 'Invoice created but not charged',
    'invoice-charge-failed-notification-failed':
        'Invoice charge failed (Notification)',
    'report-sent-by-email': 'Report send by email',
    'report-set-resolved-status': 'Report status set to resolved',
    'report-generated': 'Report generated',
    'report-edited': 'Report edited',
    'report-deleted': 'Report deleted',
    'partner-term-edited': 'Partner term edited',
    'partner-term-unlink': 'Partner term unlink',
    'customer-name-update': 'Customer name updated',
    'customer-shopify-recurring-subscription-updated':
        'Customer Shopify recurring subscription updated',
    'customer-shopify-update-plan-inconsistency':
        'Customer Shopify update plan report'
};

export type OperationLogTypeId = (typeof OperationLogTypeKeys)[number];
export default OperationLogTypeKeys;
