import React, { ReactElement } from 'react';
import { CommissionRange } from '../../../../models/partner/CommissionRange';
import { NumericFormat } from 'react-number-format';
import CurrencyFormat from '../../../../components/CurrencyFormat';

type Props = {
    commissionRanges: CommissionRange[];
};

export default function CommissionRanges({
    commissionRanges
}: Props): ReactElement {
    return (
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
            {commissionRanges.map((range, index) => (
                <li key={index}>
                    {'From '}
                    <CurrencyFormat value={range.amountFrom} />
                    {': '}
                    <NumericFormat
                        fixedDecimalScale={true}
                        decimalScale={range.isPercentage ? 4 : 2}
                        value={range.amount}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={range.isPercentage ? '%' : '€'}
                    />
                </li>
            ))}
        </ul>
    );
}
