import React, { Fragment, useState, useEffect, ReactElement } from 'react';
import Spinner from '../../components/Spinner';
import { Button, Container, Grid, Paper } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import ReportListItems from '../partner/edit/components/ReportListItems';
import { PagedList } from '../../models/PagedList';
import { ReportListItem } from '../../models/report/ReportListItem';
import ReportListFiltersType from '../../models/ReportListFilters';
import ReportService from '../../services/ReportService';
import DateRangeFilters from '../../components/DateRangeFilters';
import { PropsWithShowToast } from '../../PropsWithShowToast';
import { saveAs } from 'file-saver';
import PartnerSelector from './PartnerSelector';
import Cookies from 'js-cookie';

type Props = PropsWithShowToast<unknown>;

const reportFiltersCookieName = 'reportListFilters';

export default function ReportList({ showToast }: Props): ReactElement {
    const [isLoading, setLoading] = useState(false);
    const [reports, setReports] = useState<PagedList<ReportListItem>>({
        totalQuantity: 0,
        documents: []
    });
    const filtersCookie = Cookies.get(reportFiltersCookieName);
    const [filters, setFilters] = useState<ReportListFiltersType>(
        filtersCookie
            ? JSON.parse(filtersCookie)
            : {
                  rowsPerPage: 10,
                  page: 0,
                  search: '',
                  sortBy: 'createdAt',
                  direction: 'desc'
              }
    );

    const refresh = () => {
        setLoading(true);
        Cookies.set(reportFiltersCookieName, JSON.stringify(filters));
        ReportService.getReportList(filters)
            .then(setReports)
            .catch(error => showToast('error', error.message))
            .finally(() => setLoading(false));
    };

    useEffect(refresh, [filters, showToast]);

    const onSortByChange = (sortBy: string, direction?: 'asc' | 'desc') => {
        setFilters({ ...filters, sortBy, direction });
    };

    const onFilterChange = (
        filterId: keyof ReportListFiltersType,
        filterValue: any
    ) => {
        setFilters({ ...filters, [filterId]: filterValue });
    };

    const onPaginationChange = (page: number, rowsPerPage: number) => {
        setFilters({ ...filters, page, rowsPerPage });
    };

    const deleteReport = async (reportId: string) => {
        await ReportService.delete(reportId);
        showToast('success', 'Report deleted');
        refresh();
    };

    const exportReports = async () => {
        try {
            const result = await ReportService.exportReportList(filters);
            const date = new Date().toISOString().split('T')[0];
            const fileName = `reports-${date}.csv`;
            saveAs(await result.blob(), fileName);
        } catch (error) {
            const message =
                'Error exporting reports' + error.message
                    ? `: ${error.message}`
                    : '';
            showToast('error', message);
        }
    };

    return (
        <Fragment>
            <Spinner visible={isLoading}></Spinner>
            <Container maxWidth='xl'>
                <Grid className='page-header-container' container spacing={3}>
                    <Grid item xs={6}>
                        <PageHeader>Report list</PageHeader>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={exportReports}
                            disabled={reports.totalQuantity === 0}
                            variant='contained'
                            color='primary'
                        >
                            Export report list
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        className='cn-paper'
                        style={{ marginBottom: '15px' }}
                    >
                        <Grid item xs={12}>
                            <PartnerSelector
                                partnerId={filters.partnerId}
                                showToast={showToast}
                                onChange={partnerId =>
                                    onFilterChange('partnerId', partnerId)
                                }
                            />
                            <DateRangeFilters
                                filters={filters}
                                onFilterChange={onFilterChange}
                            />
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className='cn-paper'>
                        <ReportListItems
                            rowsTotal={reports.totalQuantity}
                            rows={reports.documents}
                            filters={filters}
                            backTo={'report'}
                            showPartner={true}
                            showToast={showToast}
                            onPaginationChange={onPaginationChange}
                            onSortByChange={onSortByChange}
                            onFilterChange={onFilterChange}
                            onDelete={report => deleteReport(report._id)}
                            refreshReports={refresh}
                        />
                    </Paper>
                </Grid>
            </Container>
        </Fragment>
    );
}
