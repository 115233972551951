import React, { ReactElement } from 'react';
import { OperationDataFactory } from '../../../../models/system/OperationDataFactory';
import { OperationLog } from '../../../../models/system/OperationLog';

interface Props {
    operationLog: OperationLog;
    type: string;
}

export default function OperationData(props: Props): ReactElement {
    const operationDataComponent = OperationDataFactory.getComponent(
        props.type
    );

    return (
        <div style={{ minHeight: '75px' }}>
            {operationDataComponent(props.operationLog)}
        </div>
    );
}
