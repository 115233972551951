import React from 'react';
import { Link } from 'react-router-dom';
import { CustomerCancelledPlanChangeLog } from '../../../../../models/system/CustomerCancelledPlanChangeLog';
import Moment from 'react-moment';

const CustomerPlan = (operationLog: CustomerCancelledPlanChangeLog) => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerAccountEmail}
                </Link>{' '}
                - Env: {operationLog.customerEnv}
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>{' '}
                - Env: {operationLog.customerEnv}
            </p>
        )}
        {operationLog.planChangeRequest.planId && (
            <p>
                Plan Requested:{' '}
                <Link
                    to={`/plan/${operationLog.planChangeRequest.planId}/edit`}
                >
                    {operationLog.planChangeRequest.planId}
                </Link>
            </p>
        )}
        {operationLog.planChangeRequest.activities && (
            <p>
                Activities Requested:{' '}
                {operationLog.planChangeRequest.activities}{' '}
            </p>
        )}
        {operationLog.planChangeRequest.emailActivities && (
            <p>
                Emails Requested:{' '}
                {operationLog.planChangeRequest.emailActivities}{' '}
            </p>
        )}
        {operationLog.planChangeRequest.pageViews && (
            <p>
                PageViews Requested: {operationLog.planChangeRequest.pageViews}{' '}
            </p>
        )}
        <p>
            Request date:{' '}
            <Moment
                date={operationLog.planChangeRequest.requestDate}
                format='DD/MM/YYYY HH:mm:ss'
            />{' '}
        </p>
        {operationLog.type === 'customer-cancel-plan-change-request' && (
            <p>
                Cancel date:{' '}
                <Moment
                    date={operationLog.cancelDate}
                    format='DD/MM/YYYY HH:mm:ss'
                />{' '}
            </p>
        )}
    </div>
);

export default CustomerPlan;
