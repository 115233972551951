import React, { CSSProperties, ReactElement, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import { useDebouncedCallback } from './useDebouncedCallback';

type Props = {
    value?: string;
    style?: CSSProperties;
    onChange: (searchText: string) => void;
};

const formControlStyles: CSSProperties = {
    minWidth: '150px',
    marginLeft: '10px'
};

export default function SearchBox({
    value,
    style,
    onChange
}: Props): ReactElement {
    const [searchText, setSearchText] = useState(value);

    const onChangeDebounced = useDebouncedCallback(onChange, 500);

    return (
        <TextField
            style={{ ...formControlStyles, ...style }}
            label='Search'
            variant='standard'
            value={searchText}
            onChange={e => {
                onChangeDebounced(e.target.value);
                setSearchText(e.target.value);
            }}
            InputProps={{
                endAdornment: <SearchIcon />
            }}
        />
    );
}
