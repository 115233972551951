import React from 'react';
import ReactDOM from 'react-dom';
import './Index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './Store';
import Theme from './Theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider } from '@mui/material';

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={Theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <App />
            </LocalizationProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);
