import React, { CSSProperties, ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Spinner from '../../../components/Spinner';
import PlanService from '../../../services/PlanService';
import { RouterMatch } from '../../../models/RouterMatch';
import { PlanSettings } from '../../../models/plan/PlanSettings';
import PageHeader from '../../../components/PageHeader';
import BackButton from '../../../components/BackButton';
import { ToastVariant } from '../../../reducers/Snackbar';
import Plan from '../../../models/plan/Plan';
import GenericListFilters from '../../../models/GenericListFilters';
import _ from 'lodash';
import ReactSelect from '../../../components/ReactSelect';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    planSettings: PlanSettings;
    isSelectorLoading: boolean;
    selectorSearchText?: string;
    currentDefaultPlan: { value: string; label: string };
}

const selectorStyles = {
    container: (provided: any) => ({
        ...provided,
        marginTop: '8px',
        width: '350px',
        display: 'inline-block'
    })
};

class PlanSettingsPage extends Component<Props, State> {
    private plans: Plan[] = [];
    private planSelectorOptions: { value: string; label: string }[] = [];
    private timerSelectorPlans: any = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            isSelectorLoading: true,
            currentDefaultPlan: { value: '', label: '' },
            planSettings: {
                defaultPlanId: '',
                extraActivityPrice: 0,
                extraEmailPrice: 0
            }
        };
        this.init();
    }

    private buttonContainerStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
        marginRight: '26px',
        marginBottom: '10px'
    };

    init = async () => {
        await this.loadPlanSettings();
        await this.loadPlans();
        this.setState({ ...this.state, isLoading: false });
    };

    updatePlanSettings = (fieldName: keyof PlanSettings, event: any) => {
        const newValue = event.target.value;
        this.setState({
            ...this.state,
            planSettings: {
                ...this.state.planSettings,
                [fieldName]: newValue
            }
        });
    };

    loadPlanSettings = async () => {
        try {
            const planSettings = await PlanService.getPlanSettings();
            this.setState({ ...this.state, planSettings });
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                this.props.showToast('error', 'Error loading plan settings');
            });
        }
    };

    saveUpdate = async () => {
        try {
            await PlanService.setPlanSettings(this.state.planSettings);
            this.props.showToast('success', 'Plan settings saved successfully');
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                this.props.showToast('error', 'Error saving plan settings');
            });
        }
    };

    loadPlans = async (nameFilter?: string) => {
        this.timerSelectorPlans = null;
        let planList: { documents: Plan[]; totalQuantity: number } = {
            documents: [],
            totalQuantity: 0
        };
        try {
            const filters: GenericListFilters = {
                page: 0,
                rowsPerPage: 10,
                search: nameFilter ? nameFilter : ''
            };
            planList = await PlanService.getPlanList(filters);
        } catch {
            this.props.showToast('error', 'Error on get plan list');
        }
        this.plans = planList.documents;
        this.planSelectorOptions = this.plans.map(plan => {
            const option = { value: plan._id, label: plan.name };
            if (plan._id === this.state.planSettings.defaultPlanId) {
                this.setState({ ...this.state, currentDefaultPlan: option });
            }
            return option;
        });
        const currentDefaultPlan = await PlanService.getPlan(
            this.state.planSettings.defaultPlanId
        );
        const currentDefaultPlanOption = {
            label: currentDefaultPlan.name,
            value: currentDefaultPlan._id
        };
        if (!_.find(this.planSelectorOptions, currentDefaultPlanOption)) {
            this.planSelectorOptions.unshift(currentDefaultPlanOption);
            this.setState({
                ...this.state,
                currentDefaultPlan: currentDefaultPlanOption
            });
        }
        this.setState({ ...this.state, isSelectorLoading: false });
    };

    searchPlan = (searchText: string, event: any) => {
        const timeoutToRefreshOptions = 500;
        if (this.timerSelectorPlans) {
            clearTimeout(this.timerSelectorPlans);
            this.timerSelectorPlans = null;
        }
        if (event.action === 'input-change') {
            this.timerSelectorPlans = setTimeout(() => {
                this.setState({
                    ...this.state,
                    isSelectorLoading: true,
                    selectorSearchText: searchText
                });
                this.loadPlans(searchText);
            }, timeoutToRefreshOptions);
        }

        if (event.action === 'menu-close' && this.state.selectorSearchText) {
            this.setState({
                ...this.state,
                isSelectorLoading: true,
                selectorSearchText: undefined
            });
            this.loadPlans();
        }
    };

    updatePlanId = (selectedOption: any) => {
        const stateCopy = { ...this.state };
        stateCopy.planSettings.defaultPlanId = selectedOption.value;
        stateCopy.currentDefaultPlan = selectedOption;
        this.setState(stateCopy);
    };

    private inputStyles: CSSProperties = {
        width: '100%'
    };

    render(): ReactElement {
        return (
            <div className='plan-settings-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>Settings plan</PageHeader>
                        </Grid>

                        <Grid item xs={6}>
                            <BackButton href='/plan/list'>
                                Back to Plan list
                            </BackButton>
                        </Grid>
                    </Grid>

                    {this.state.planSettings && (
                        <Paper className='cn-paper'>
                            <h3>Default Settings</h3>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        className={'selector-container'}
                                    >
                                        <ReactSelect
                                            styles={selectorStyles}
                                            onChange={this.updatePlanId}
                                            onInputChange={this.searchPlan}
                                            name='planId'
                                            isLoading={
                                                this.state.isSelectorLoading
                                            }
                                            options={this.planSelectorOptions}
                                            value={
                                                this.state.currentDefaultPlan
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            style={this.inputStyles}
                                            id='extraEmailPrice'
                                            name='extraEmailPrice'
                                            label='Email Excess Cost €/u'
                                            value={
                                                this.state.planSettings
                                                    .extraEmailPrice
                                            }
                                            onChange={event =>
                                                this.updatePlanSettings(
                                                    'extraEmailPrice',
                                                    event
                                                )
                                            }
                                            type='number'
                                            inputProps={{
                                                max: 1000000,
                                                step: 0.0001
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            style={this.inputStyles}
                                            id='extraActivityPrice'
                                            name='extraActivityPrice'
                                            label='Activity Excess Cost €/u'
                                            value={
                                                this.state.planSettings
                                                    .extraActivityPrice
                                            }
                                            onChange={event =>
                                                this.updatePlanSettings(
                                                    'extraActivityPrice',
                                                    event
                                                )
                                            }
                                            type='number'
                                            inputProps={{
                                                max: 1000000,
                                                step: 0.0001
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={3}
                                    justifyContent='flex-end'
                                    alignItems='flex-end'
                                >
                                    <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        style={this.buttonContainerStyles}
                                    >
                                        <Button
                                            onClick={this.saveUpdate}
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </Container>
            </div>
        );
    }
}
export default PlanSettingsPage;
