import React, { ReactElement } from 'react';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';
import { IconButton, SxProps, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Moment } from 'moment';

type Props = {
    value?: Date;
    onChange: (value?: Date) => void;
    style?: SxProps;
} & Pick<
    DesktopDatePickerProps<Date, Date>,
    'label' | 'minDate' | 'maxDate' | 'disabled' | 'disableFuture'
>;

export default function DateSelector({
    value,
    onChange,
    style,
    ...props
}: Props): ReactElement {
    return (
        <DesktopDatePicker
            inputFormat='DD/MM/YYYY'
            value={value || null}
            onChange={date =>
                onChange((date as unknown as Moment)?.startOf('day').toDate())
            }
            {...props}
            InputProps={{
                style: { width: 180 },
                startAdornment: (
                    <IconButton
                        onClick={() => onChange(undefined)}
                        style={{
                            order: 1,
                            margin: -8,
                            marginRight: -16,
                            ...(value ? {} : { visibility: 'hidden' })
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                )
            }}
            renderInput={params => <TextField {...params} sx={style} />}
            InputAdornmentProps={{
                position: 'end',
                style: { order: 2 }
            }}
        />
    );
}
