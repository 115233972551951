import React, { CSSProperties, ReactElement } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid
} from '@mui/material';
import InvoiceListFilters from '../../../../../models/InvoiceListFilters';
import SearchBox from '../../../../../components/SearchBox';
import DateRangeFilters from '../../../../../components/DateRangeFilters';

const formControlStyles: CSSProperties = {
    minWidth: 180,
    marginRight: 10
};

const searchStyles: CSSProperties = {
    float: 'right'
};

interface Props {
    filters: InvoiceListFilters;
    onFilterChange: (
        filterId: keyof InvoiceListFilters,
        filterValue: any
    ) => void;
}

function ListFilters(props: Props): ReactElement {
    const onFilterChange =
        (filterId: keyof InvoiceListFilters) => (event: any) => {
            props.onFilterChange(filterId, event.target.value);
        };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <DateRangeFilters
                    filters={props.filters}
                    onFilterChange={props.onFilterChange}
                />
                <SearchBox
                    style={searchStyles}
                    value={props.filters.search}
                    onChange={search => props.onFilterChange('search', search)}
                />
            </Grid>
            <Grid item xs={12}>
                <DateRangeFilters
                    label='Emit'
                    filters={{
                        fromDate: props.filters.emitFromDate,
                        toDate: props.filters.emitToDate
                    }}
                    onFilterChange={(key, value) => {
                        switch (key) {
                            case 'fromDate':
                                props.onFilterChange('emitFromDate', value);
                                break;
                            case 'toDate':
                                props.onFilterChange('emitToDate', value);
                                break;
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl style={formControlStyles}>
                    <InputLabel htmlFor='payment-method'>
                        Payment Method
                    </InputLabel>
                    <Select
                        value={props.filters.paymentMethod || ''}
                        onChange={onFilterChange('paymentMethod')}
                        inputProps={{
                            name: 'Payment Method',
                            id: 'payment-method'
                        }}
                    >
                        <MenuItem value=''>Not filtering</MenuItem>
                        <MenuItem value={'bank'}>Bank</MenuItem>
                        <MenuItem value={'card'}>Card</MenuItem>
                        <MenuItem value={'shopify'}>Shopify</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={formControlStyles}>
                    <TextField
                        id='invoice-number'
                        type='number'
                        label='Invoice Number'
                        value={props.filters.invoiceNumber || ''}
                        onChange={onFilterChange('invoiceNumber')}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default ListFilters;
