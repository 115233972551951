import { InputLabel } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { ReactElement } from 'react';
import { PartnerTypeId } from '../../../../models/partner/PartnerTypeId';
import { CSSProperties } from '@mui/styles/withStyles';

type Props = {
    type?: PartnerTypeId;
    onChange: (type: PartnerTypeId) => void;
    style?: CSSProperties;
};

export default function PartnerTypeSelector({
    type,
    onChange,
    style
}: Props): ReactElement {
    return (
        <div style={style}>
            <InputLabel style={{ transform: 'scale(0.75)' }}>Type</InputLabel>
            <ToggleButtonGroup
                size='small'
                value={type}
                exclusive
                onChange={(e, value) => onChange(value)}
            >
                <ToggleButton value='agency'>Agency</ToggleButton>
                <ToggleButton value='sales'>Sales</ToggleButton>
                <ToggleButton value='other'>Other</ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
}
