import React, { ReactElement } from 'react';
import { ReportListItem } from '../../../../models/report/ReportListItem';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import ReportDate from './ReportDate';

type Props = {
    open: boolean;
    report: ReportListItem;
    onClose: () => void;
    onDelete: (reportId: ReportListItem) => void;
};

export default function DeleteReportConfirmationDialog({
    open,
    report,
    onClose,
    onDelete
}: Props): ReactElement {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete Report</DialogTitle>
            <DialogContent>
                Are you sure you want to delete the report with date{' '}
                <ReportDate report={report} /> for the partner{' '}
                {report.partnerName}?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button color='primary' onClick={() => onDelete(report)}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}
