import React, { ReactElement } from 'react';
import { NumericFormat } from 'react-number-format';

type Props = {
    value?: number;
};

export default function CurrencyFormat({ value }: Props): ReactElement {
    return value === undefined ? (
        <span>-</span>
    ) : (
        <NumericFormat
            fixedDecimalScale={true}
            decimalScale={2}
            value={value}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            suffix={'€'}
        />
    );
}
