import React, { ReactElement, useState, useEffect } from 'react';
import ReactSelect, { ReactSelectOption } from '../../components/ReactSelect';
import PartnerService from '../../services/PartnerService';
import { useDebouncedCallback } from '../../components/useDebouncedCallback';
import { PropsWithShowToast } from '../../PropsWithShowToast';

type Props = PropsWithShowToast<{
    partnerId?: string;
    onChange: (partnerId: string) => void;
}>;

async function getPartnerOptions(
    search?: string
): Promise<ReactSelectOption[]> {
    const { documents } = await PartnerService.getPartnerList({
        search,
        rowsPerPage: 50,
        page: 0
    });
    return [
        { value: '', label: 'Not filtering' },
        ...documents.map(({ _id, partnerInfo }) => ({
            value: _id as string,
            label: partnerInfo.company
        }))
    ];
}

export default function PartnerSelector({
    partnerId,
    onChange,
    showToast
}: Props): ReactElement {
    const [options, setOptions] = useState<ReactSelectOption[]>([]);
    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState(false);

    const setSearchDebounced = useDebouncedCallback(setSearch, 500);

    useEffect(() => {
        setLoading(true);
        getPartnerOptions(search)
            .then(setOptions)
            .catch(error => showToast('error', error.message))
            .finally(() => setLoading(false));
    }, [search, showToast]);

    const selectedOption = options.find(o => o.value === (partnerId ?? ''));

    return (
        <ReactSelect
            value={selectedOption}
            options={options}
            name='partnerId'
            isLoading={isLoading}
            onInputChange={(value, event) => {
                if (event.action === 'input-change') {
                    setSearchDebounced(value);
                }

                if (event.action === 'menu-close') {
                    setSearchDebounced('');
                }
            }}
            onChange={({ value }) => onChange(value)}
            styles={{
                container: provided => ({
                    ...provided,
                    marginTop: 7,
                    marginRight: 10,
                    width: 250,
                    display: 'inline-block'
                })
            }}
        />
    );
}
