import React, { CSSProperties, ReactElement } from 'react';
import { CreditCardInfo as CreditCardInfoModel } from '../models/customer/CreditCardInfo';
import { Grid } from '@mui/material';
import Moment from 'react-moment';
import SummaryHeader from './SummaryHeader';
import InlineAlert from './InlineAlert';
const MONTHS_TO_SHOW_EXPIRATION_SOON_MESSAGE = 3;

interface Props {
    creditCardInfo?: CreditCardInfoModel;
}

const cardInfoStyles: CSSProperties = {
    fontSize: '15px'
};

export default function CreditCardInfo(props: Props): ReactElement {
    const expirationSoonAlert = checkIsAboutToExpireCreditCard(
        props.creditCardInfo
    ) ? (
        <InlineAlert
            class='warning'
            message='This card is about to expire'
        ></InlineAlert>
    ) : (
        ''
    );
    const expiratedAlert = checkHasExpiredCreditCard(props.creditCardInfo) ? (
        <InlineAlert
            class='error'
            message='This card has expired'
        ></InlineAlert>
    ) : (
        ''
    );
    return (
        <React.Fragment>
            {props.creditCardInfo && (
                <Grid item xs={12}>
                    <h4>Credit card:</h4>
                    {expirationSoonAlert || expiratedAlert}
                    {props.creditCardInfo.cardNumber && (
                        <SummaryHeader
                            title='Card number'
                            style={cardInfoStyles}
                        >
                            {props.creditCardInfo.cardNumber}
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.cardHolder && (
                        <SummaryHeader
                            title='Card holder'
                            style={cardInfoStyles}
                        >
                            {props.creditCardInfo.cardHolder}
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.cardBrand && (
                        <SummaryHeader
                            title='Card brand'
                            style={cardInfoStyles}
                        >
                            {props.creditCardInfo.cardBrand}
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.cardCountry && (
                        <SummaryHeader
                            title='Card country'
                            style={cardInfoStyles}
                        >
                            {props.creditCardInfo.cardCountry}
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.cardFundingType && (
                        <SummaryHeader
                            title='Funding type'
                            style={cardInfoStyles}
                        >
                            {props.creditCardInfo.cardFundingType}
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.cardFingerprint && (
                        <SummaryHeader
                            title='Card fingerprint'
                            style={cardInfoStyles}
                        >
                            {props.creditCardInfo.cardFingerprint}
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.supportsSecure3d && (
                        <SummaryHeader
                            title='Card support secure 3D'
                            style={cardInfoStyles}
                        >
                            {props.creditCardInfo.supportsSecure3d
                                ? 'Yes'
                                : 'No'}
                        </SummaryHeader>
                    )}
                    {(props.creditCardInfo.expirationMonth ||
                        props.creditCardInfo.expirationYear) && (
                        <SummaryHeader
                            title='Card expiration'
                            style={cardInfoStyles}
                        >
                            {props.creditCardInfo.expirationMonth}/
                            {props.creditCardInfo.expirationYear}
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.createdAt && (
                        <SummaryHeader
                            title='Created at'
                            style={cardInfoStyles}
                        >
                            <Moment
                                date={props.creditCardInfo.createdAt}
                                format='DD/MM/YYYY'
                            />
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.updatedAt && (
                        <SummaryHeader
                            title='Updated at'
                            style={cardInfoStyles}
                        >
                            <Moment
                                date={props.creditCardInfo.updatedAt}
                                format='DD/MM/YYYY'
                            />
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.stripePaymentSetupIntentId && (
                        <SummaryHeader
                            title='Stripe setup intent id'
                            style={cardInfoStyles}
                        >
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={
                                    'https://dashboard.stripe.com/setup_intents/' +
                                    props.creditCardInfo
                                        .stripePaymentSetupIntentId
                                }
                            >
                                {
                                    props.creditCardInfo
                                        .stripePaymentSetupIntentId
                                }
                            </a>
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.stripePaymentMethodId && (
                        <SummaryHeader
                            title='Stripe payment method id'
                            style={cardInfoStyles}
                        >
                            {props.creditCardInfo.stripePaymentMethodId}
                        </SummaryHeader>
                    )}
                    {props.creditCardInfo.stripeCustomerId && (
                        <SummaryHeader
                            title='Stripe customer id'
                            style={cardInfoStyles}
                        >
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={
                                    'https://dashboard.stripe.com/customers/' +
                                    props.creditCardInfo.stripeCustomerId
                                }
                            >
                                {props.creditCardInfo.stripeCustomerId}
                            </a>
                        </SummaryHeader>
                    )}
                </Grid>
            )}
        </React.Fragment>
    );
}

function checkIsAboutToExpireCreditCard(
    creditCardInfo?: CreditCardInfoModel
): boolean {
    if (
        !creditCardInfo ||
        typeof creditCardInfo.expirationYear !== 'number' ||
        typeof creditCardInfo.expirationMonth !== 'number'
    ) {
        return false;
    }
    if (checkHasExpiredCreditCard(creditCardInfo)) {
        return false;
    }
    const now = new Date();
    const expirationSoonDate = new Date(creditCardInfo.expirationYear, 0, 1);
    expirationSoonDate.setMonth(
        creditCardInfo.expirationMonth - MONTHS_TO_SHOW_EXPIRATION_SOON_MESSAGE
    );
    return expirationSoonDate < now;
}

function checkHasExpiredCreditCard(
    creditCardInfo?: CreditCardInfoModel
): boolean {
    if (
        !creditCardInfo ||
        typeof creditCardInfo.expirationYear !== 'number' ||
        typeof creditCardInfo.expirationMonth !== 'number'
    ) {
        return false;
    }
    const now = new Date();
    const expirationDate = new Date(creditCardInfo.expirationYear, 0, 1);
    expirationDate.setMonth(creditCardInfo.expirationMonth);
    return expirationDate < now;
}
