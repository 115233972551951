import React from 'react';
import { Link } from 'react-router-dom';
import { CustomerShopifyChangedAccountOperationLog } from '../../../../../models/system/CustomerShopifyChangedAccountOperationLog';

const CustomerShopifyChangedAccount = (
    operationLog: CustomerShopifyChangedAccountOperationLog
) => (
    <div>
        <p>
            {!operationLog.customerName && (
                <span>
                    Customer:{' '}
                    <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                        {operationLog.customerAccountEmail}
                    </Link>
                    <br />
                </span>
            )}
            {operationLog.customerName && (
                <span>
                    Account name:{' '}
                    <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                        {operationLog.customerName}
                    </Link>
                </span>
            )}
            <br />
            {operationLog.action === 'login' && (
                <span>
                    This customer link his account to this Shopify shop:{' '}
                    {operationLog.shop}
                </span>
            )}
            {operationLog.action === 'logout' && (
                <span>
                    This customer unlink his account to this Shopify shop:{' '}
                    {operationLog.shop}
                </span>
            )}
        </p>
    </div>
);

export default CustomerShopifyChangedAccount;
