import React, { ReactElement } from 'react';
import { Component } from 'react';
import logo from '../../resources/img/logo-with-text-right.svg';
import './home.css';

interface Props {
    onUserLoginSuccess: () => void;
}

interface State {
    showValidationErrors: boolean;
}

class HomePage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showValidationErrors: false
        };
    }

    render(): ReactElement {
        return (
            <div className='App-header'>
                <img src={logo} className='App-logo' alt='logo' />

                <p>Connectif Billing</p>
            </div>
        );
    }
}
export default HomePage;
