import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Partner } from '../../../../models/partner/Partner';
import Button from '@mui/material/Button';
import InvoiceCompanyInfo from '../../../invoice/edit/components/InvoiceCompanyInfo';
import { Divider } from '@mui/material';
import { ToastVariant } from '../../../../reducers/Snackbar';
import { PartnerInfo } from '../../../../models/partner/PartnerInfo';
import PartnerTerms from './PartnerTerms';
import PartnerTypeSelector from './PartnerTypeSelector';
import DateSelector from '../../../../components/DateSelector';
import InvoicePeriodicitySelector from './InvoicePeriodicitySelector';

interface Props {
    partner: Partner;
    savePartner: () => void;
    onUpdatePartner: (partner: Partner) => void;
    onDeleteTerm: (partner: Partner, termUuid: string) => void;
    showToast: (variant: ToastVariant, message: string) => void;
}

function PartnerEditor({
    partner,
    savePartner,
    onUpdatePartner,
    onDeleteTerm,
    showToast
}: Props): ReactElement {
    return (
        <Paper className='cn-paper'>
            <Grid container spacing={1}>
                <InvoiceCompanyInfo
                    info={partner.partnerInfo}
                    isEditable={true}
                    type='PartnerInfo'
                    onUpdateCompanyInfo={info =>
                        onUpdatePartner({
                            ...partner,
                            partnerInfo: info as PartnerInfo
                        })
                    }
                    showToast={showToast}
                    extraFields={
                        <Grid item>
                            <PartnerTypeSelector
                                type={partner.type}
                                onChange={type =>
                                    type &&
                                    onUpdatePartner({ ...partner, type })
                                }
                                style={{ marginTop: 17 }}
                            />
                            <DateSelector
                                disableFuture
                                value={partner.contractSignedDate}
                                onChange={contractSignedDate =>
                                    onUpdatePartner({
                                        ...partner,
                                        contractSignedDate
                                    })
                                }
                                label='Contract signed date'
                                style={{ marginTop: 20 }}
                            />
                            <InvoicePeriodicitySelector
                                invoicePeriodicity={partner.invoicePeriodicity}
                                onChange={invoicePeriodicity =>
                                    invoicePeriodicity &&
                                    onUpdatePartner({
                                        ...partner,
                                        invoicePeriodicity
                                    })
                                }
                                style={{ marginTop: 17 }}
                            />
                        </Grid>
                    }
                >
                    Partner Information
                </InvoiceCompanyInfo>

                <Grid item xs={12} sm={12} style={{ margin: '20px 0' }}>
                    <Divider variant='fullWidth' />
                </Grid>

                <PartnerTerms
                    terms={partner.terms}
                    onUpdateTerms={terms =>
                        onUpdatePartner({ ...partner, terms })
                    }
                    onDeleteTerm={termUuid =>
                        onDeleteTerm({ ...partner }, termUuid)
                    }
                />

                <Grid
                    container
                    spacing={3}
                    justifyContent='flex-end'
                    alignItems='flex-end'
                >
                    <Grid
                        item
                        xs={4}
                        sm={4}
                        container
                        justifyContent='flex-end'
                    >
                        <Button
                            onClick={savePartner}
                            variant='contained'
                            color='primary'
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
export default PartnerEditor;
