import RequestService from './RequestService';
import { GenerateReportOptions } from '../models/report/GenerateReportOptions';
import { Report } from '../models/report/Report';
import { PagedList } from '../models/PagedList';
import ReportListFilters from '../models/ReportListFilters';
import { ReportListItem } from '../models/report/ReportListItem';
import { CustomerReportItem } from '../models/report/CustomerReportItem';
import { BillableReport } from '../models/report/BillableReport';

function transformReportListFilters(listFilters: ReportListFilters) {
    return {
        partnerId: listFilters.partnerId,
        fromDate: listFilters.fromDate,
        toDate: listFilters.toDate,
        limit: listFilters.rowsPerPage,
        page: listFilters.page + 1,
        sortKey: listFilters.sortBy,
        sortDirection: listFilters.direction === 'asc' ? 1 : -1
    };
}

const ReportService = {
    async generate(options: GenerateReportOptions): Promise<Report> {
        const response = await RequestService.cnFetch('/report/generate', {
            method: 'POST',
            body: JSON.stringify(options)
        });
        return response.json();
    },

    async getReportList(
        listFilters: ReportListFilters
    ): Promise<PagedList<ReportListItem>> {
        const response = await RequestService.cnFetch(
            '/report/',
            {
                method: 'GET'
            },
            transformReportListFilters(listFilters)
        );
        return response.json();
    },

    async exportReportList({
        partnerId,
        fromDate,
        toDate,
        sortBy,
        direction
    }: ReportListFilters): Promise<Response> {
        return RequestService.cnFetch('/report/export', undefined, {
            partnerId,
            fromDate,
            toDate,
            sortKey: sortBy,
            sortDirection: direction === 'asc' ? 1 : -1
        });
    },

    async get(reportId: string): Promise<Report> {
        const response = await RequestService.cnFetch(`/report/${reportId}`, {
            method: 'GET'
        });
        return response.json();
    },

    async getBillableInfo(reportId: string): Promise<BillableReport> {
        const response = await RequestService.cnFetch(
            `/report/${reportId}/billable-info`,
            {
                method: 'GET'
            }
        );
        return response.json();
    },

    downloadPdf(reportId: string): Promise<Response> {
        return RequestService.cnFetch(`/report/${reportId}/pdf`);
    },

    downloadBillablePdf(reportId: string): Promise<Response> {
        return RequestService.cnFetch(`/report/${reportId}/billable-pdf`);
    },

    async edit(reportId: string, report: Report): Promise<Report> {
        const { items } = report;
        const response = await RequestService.cnFetch(
            `/report/${reportId}/edit`,
            {
                method: 'POST',
                body: JSON.stringify({ items })
            }
        );
        return response.json();
    },

    async delete(reportId: string): Promise<void> {
        await RequestService.cnFetch(`/report/${reportId}`, {
            method: 'DELETE'
        });
    },

    async resolve(reportId: string): Promise<void> {
        await RequestService.cnFetch(
            `/report/${reportId}/set-resolved-status`,
            {
                method: 'POST'
            }
        );
    },

    async listCustomerReportItems(
        customerUuid: string,
        listFilters: ReportListFilters
    ): Promise<PagedList<CustomerReportItem>> {
        const response = await RequestService.cnFetch(
            `/report/customer-report-items/${customerUuid}`,
            {
                method: 'GET'
            },
            transformReportListFilters(listFilters)
        );
        return response.json();
    },

    sendByEmail(reportId: string, emails: string[]): Promise<Response> {
        const body = {
            emails
        };
        return RequestService.cnFetch(`/report/${reportId}/send-by-email`, {
            method: 'POST',
            body: JSON.stringify(body)
        });
    }
};

export default ReportService;
