import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { CustomerPlanUpdateOperationLog } from '../../../../../models/system/CustomerPlanUpdateOperationLog';

const CustomerPlanUpdate = (operationLog: CustomerPlanUpdateOperationLog) => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerAccountEmail}
                </Link>{' '}
                - Env: {operationLog.customerEnv}
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>{' '}
                - Env: {operationLog.customerEnv}
            </p>
        )}
        <p>Apply Immediate: {operationLog.applyImmediate.toString()}</p>
        <p>
            New Plan:{' '}
            <Link to={`/plan/${operationLog.customerNewPlanId}/edit`}>
                {operationLog.customerNewPlanId}
            </Link>
        </p>
        <p>
            Old Plan:{' '}
            <Link to={`/plan/${operationLog.customerOldPlanId}/edit`}>
                {operationLog.customerOldPlanId}
            </Link>
        </p>
        {operationLog.customerNewSubscriptionFinishDate && (
            <p>
                New subscription finish date:{' '}
                <Moment
                    date={operationLog.customerNewSubscriptionFinishDate}
                    format='DD/MM/YYYY HH:mm:ss'
                />
            </p>
        )}
        {operationLog.customerOldSubscriptionFinishDate && (
            <p>
                Old subscription finish date:{' '}
                <Moment
                    date={operationLog.customerOldSubscriptionFinishDate}
                    format='DD/MM/YYYY HH:mm:ss'
                />
            </p>
        )}
        <p>
            Updated by:{' '}
            {operationLog.updateRole === 'client' ? 'Client' : 'Admin'}
        </p>
        {operationLog.updateToPreassignedPlan && (
            <p>Updated to pre-assigned plan: true</p>
        )}
    </div>
);

export default CustomerPlanUpdate;
