import React, { ReactElement } from 'react';
import { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';

type Props = {
    open: boolean;
    onClose: () => void;
    onGenerateReport: (year: number, month: number) => void;
};

export default function GenerateReportDialog({
    open,
    onClose,
    onGenerateReport
}: Props): ReactElement {
    const thisMonth = moment().startOf('month');
    const [date, setDate] = useState<Moment>(thisMonth);
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Generate partner report</DialogTitle>
            <DialogContent>
                <DesktopDatePicker
                    views={['year', 'month']}
                    label='Year and Month'
                    maxDate={thisMonth}
                    value={date}
                    onChange={(date: Moment | null) => date && setDate(date)}
                    renderInput={props => <TextField {...props} />}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    color='primary'
                    onClick={() => {
                        if (date) {
                            const year = date.toDate().getFullYear();
                            const month = date.toDate().getMonth() + 1;
                            onGenerateReport(year, month);
                            onClose();
                        }
                    }}
                >
                    Generate
                </Button>
            </DialogActions>
        </Dialog>
    );
}
