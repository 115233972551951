import React, { ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import Alert from '../../../../../components/Alert';
import { CSSProperties } from '@mui/styles';
import './ListItems.css';
import { HeadRow } from '../../../../../models/list-filters/HeadRow';
import OperationLogListFilters from '../../../../../models/OperationLogListFilters';
import { OperationLog } from '../../../../../models/system/OperationLog';
import OperationData from '../../operation-data/OperationData';
import Paginator from '../../../../../components/Paginator';
import SortableTableHead, {
    SortDirection
} from '../../../../../components/SortableTableHead';
import { OperationLogNames } from '../../../../../models/system/OperationLogTypeId';

interface Props {
    rows: OperationLog[];
    rowsTotal: number;
    filters: OperationLogListFilters;
    onFilterChange: (
        filterId: keyof OperationLogListFilters,
        filterValue: any
    ) => void;
    onPaginationChange: (page: number, limit: number) => void;
    onSortByChange: (fieldId: string, direction: SortDirection) => void;
}

const columnsStyles: CSSProperties = {
    whiteSpace: 'nowrap',
    verticalAlign: 'text-top',
    paddingTop: '12px'
};

const containerTableStyle: CSSProperties = {
    overflowX: 'auto',
    width: '100%'
};

function ListItems(props: Props): ReactElement {
    const headRows: HeadRow[] = [
        { id: 'date', sortable: false, label: 'Created at' },
        { id: 'type', sortable: false, label: 'Operation type' },
        { id: 'name', sortable: false, label: 'Data' }
    ];

    return (
        <div style={{ width: '100%' }}>
            <Paginator
                page={props.filters.page}
                rowsPerPage={props.filters.rowsPerPage}
                rowsTotal={props.rowsTotal}
                onPaginationChange={props.onPaginationChange}
            ></Paginator>
            {props.rows.length > 0 && (
                <div style={containerTableStyle}>
                    <Table size='small' className={'filtered-list'}>
                        <SortableTableHead
                            headRows={headRows}
                            sortBy={props.filters.sortBy}
                            direction={props.filters.direction}
                            onSortByChange={props.onSortByChange}
                        />
                        <TableBody>
                            {props.rows.map(row => (
                                <TableRow key={row._id}>
                                    <TableCell style={columnsStyles}>
                                        <div>
                                            <Moment
                                                date={row.date}
                                                format='DD/MM/YYYY h:mm:ss'
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell style={columnsStyles}>
                                        <p>{OperationLogNames[row.type]}</p>
                                    </TableCell>
                                    <TableCell style={columnsStyles}>
                                        <OperationData
                                            operationLog={row}
                                            type={row.type}
                                        ></OperationData>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
            {props.rows.length === 0 && (
                <Alert visible={props.rows.length === 0} variant='info'>
                    No items found
                </Alert>
            )}
        </div>
    );
}

export default ListItems;
