import React, { useState, useEffect, Fragment, ReactElement } from 'react';
import {
    Paper,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@mui/material';
import { CustomerAssignedToPartner } from '../../../../models/customer/CustomerAssignedToPartner';
import CustomerService from '../../../../services/CustomerService';
import { PagedList } from '../../../../models/PagedList';
import GenericListFilters from '../../../../models/GenericListFilters';
import Paginator from '../../../../components/Paginator';
import Alert from '../../../../components/Alert';
import Partner from '../../../../models/partner/Partner';
import SortableTableHead from '../../../../components/SortableTableHead';
import { HeadRow } from '../../../../models/list-filters/HeadRow';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import PercentageFormat from '../../../../components/PercentageFormat';
import TableSubCell from '../../../../components/TableSubCell';
import DateRangeFilters from '../../../../components/DateRangeFilters';
import SearchBox from '../../../../components/SearchBox';

type Props = {
    partner: Partner;
};

const headRows: HeadRow[] = [
    { id: 'name', sortable: true, label: 'Account name' },
    { id: 'partnerTerms.assignedOn', sortable: true, label: 'Assigned Date' },
    {
        id: 'partnerTerms.startDecayDate',
        sortable: true,
        label: 'Commission Start'
    },
    { id: 'termDescription', sortable: false, label: 'Terms Description' },
    { id: 'lastReportItem.planName', sortable: false, label: 'Last Plan' },
    { id: 'lastInvoiceDate', sortable: false, label: 'Last Invoice On' },
    {
        id: 'lastReportItem.totalInvoiceCommissionableAmount',
        sortable: false,
        label: 'Last Invoice Amount'
    },
    {
        id: '',
        sortable: false,
        label: '',
        subHead: [
            {
                id: 'lastReportItem.amount',
                sortable: false,
                label: 'Last Commission Amount'
            },
            {
                id: 'lastReportItem.percentage',
                sortable: false,
                label: 'Last Commission %'
            }
        ]
    }
];

export default function PartnerCustomers({ partner }: Props): ReactElement {
    const [assignedCustomers, setAssignedCustomers] =
        useState<PagedList<CustomerAssignedToPartner>>();
    const [filters, setFilters] = useState<GenericListFilters>({
        rowsPerPage: 10,
        page: 0,
        search: '',
        sortBy: 'name',
        direction: 'asc'
    });

    useEffect(() => {
        if (partner?._id) {
            CustomerService.listAssignedToPartner(partner._id, filters).then(
                setAssignedCustomers
            );
        }
    }, [filters, partner]);

    return (
        <Paper className='cn-paper'>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <p>
                        <b>Customers Assigned to this Partner</b>
                    </p>
                    {assignedCustomers && (
                        <Fragment>
                            <Grid item xs={12}>
                                <DateRangeFilters
                                    label='Commission Start'
                                    filters={filters}
                                    onFilterChange={(filterId, filterValue) =>
                                        setFilters({
                                            ...filters,
                                            [filterId]: filterValue
                                        })
                                    }
                                />
                                <SearchBox
                                    value={filters.search}
                                    onChange={search =>
                                        setFilters({ ...filters, search })
                                    }
                                />
                            </Grid>
                            {assignedCustomers.totalQuantity === 0 && (
                                <Alert variant='info'>No customers</Alert>
                            )}
                            {assignedCustomers.totalQuantity > 0 && (
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Paginator
                                            page={filters.page}
                                            rowsPerPage={filters.rowsPerPage}
                                            rowsTotal={
                                                assignedCustomers.totalQuantity
                                            }
                                            onPaginationChange={(
                                                page,
                                                rowsPerPage
                                            ) =>
                                                setFilters({
                                                    ...filters,
                                                    page,
                                                    rowsPerPage
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Table>
                                        <SortableTableHead
                                            headRows={headRows}
                                            sortBy={filters.sortBy}
                                            direction={filters.direction}
                                            onSortByChange={(
                                                sortBy,
                                                direction
                                            ) =>
                                                setFilters({
                                                    ...filters,
                                                    sortBy,
                                                    direction
                                                })
                                            }
                                        />
                                        <TableBody>
                                            {assignedCustomers.documents.map(
                                                (assignedCustomer, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Link
                                                                to={`/customer/${assignedCustomer.uuid}/edit`}
                                                            >
                                                                {
                                                                    assignedCustomer.customerName
                                                                }
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Moment
                                                                date={
                                                                    assignedCustomer.assignedOn
                                                                }
                                                                format='DD/MM/YYYY'
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Moment
                                                                date={
                                                                    assignedCustomer.startDecayDate
                                                                }
                                                                format='DD/MM/YYYY'
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                assignedCustomer.termDescription
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                assignedCustomer
                                                                    .lastReportItem
                                                                    ?.planName
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <Moment
                                                                date={
                                                                    assignedCustomer.lastInvoiceDate
                                                                }
                                                                format='DD/MM/YYYY'
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <CurrencyFormat
                                                                value={
                                                                    assignedCustomer
                                                                        .lastReportItem
                                                                        ?.totalInvoiceCommissionableAmount
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <CurrencyFormat
                                                                value={
                                                                    assignedCustomer
                                                                        .lastReportItem
                                                                        ?.amount
                                                                }
                                                            />
                                                            <TableSubCell>
                                                                <PercentageFormat
                                                                    value={
                                                                        assignedCustomer
                                                                            .lastReportItem
                                                                            ?.percentage
                                                                    }
                                                                />
                                                            </TableSubCell>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
}
