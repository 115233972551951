import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import CustomerService from '../../../services/CustomerService';
import { RouterMatch } from '../../../models/RouterMatch';
import { Redirect } from 'react-router';
import { ToastVariant } from '../../../reducers/Snackbar';
import BackButton from '../../../components/BackButton';
import PageHeader from '../../../components/PageHeader';
import { DuplicateKeyError } from '../../../models/errors/DuplicateKeyError';
import Customer from '../../../models/customer/Customer';
import {
    Paper,
    Tabs,
    Tab,
    Zoom,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent
} from '@mui/material';
import Plan from '../../../models/plan/Plan';
import PlanService from '../../../services/PlanService';
import { CustomerBillingInfo } from '../../../models/customer/CustomerBillingInfo';
import { ClientInfo } from '../../../models/invoice/ClientInfo';
import { CustomerPaymentInfo } from '../../../models/customer/CustomerPaymentInfo';
import { CustomerResponsibleInfo } from '../../../models/customer/CustomerResponsibleInfo';
import UpdatePlanOptions from '../../../models/customer/UpdatePlanOptions';
import './CustomerEdit.css';
import PlansTab from './components/PlansTab';
import CustomerEditor from './components/CustomerEditor';
import CustomerChangePlanWizard from './components/CustomerChangePlanWizard';
import { CustomerInfo } from '../../../models/customer/CustomerInfo';
import InvoiceService from '../../../services/InvoiceService';
import { InvoiceSettings } from '../../../models/invoice/InvoiceSettings';
import SubscriptionService from '../../../services/SubscriptionService';
import { Subscription } from '../../../models/subscription/Subscription';
import ListItems from '../../invoice/list/components/list-items/ListItems';
import { InvoiceListFilters } from '../../../models/InvoiceListFilters';
import { Invoice } from '../../../models/invoice/Invoice';
import moment from 'moment';
import CustomerChangePreassignedPlanWizard from './components/CustomerChangePreassignedPlanWizard';
import CustomerPartners from './components/CustomerPartners';
import {
    AssignedPartnerTerm,
    AssignedPartnerTermUpdate
} from '../../../models/customer/AssignedPartnerTerm';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    filters: InvoiceListFilters;
    customer: Customer;
    customerSubscriptions?: Subscription[];
    subscriptionsFromDate: Date;
    customerInfo: CustomerInfo;
    existCustomer: boolean;
    isSelectorLoading: boolean;
    selectorSearchText?: string;
    currentDefaultPlan: { value: string; label: string };
    currentPlan?: Plan;
    nextPlan?: Plan;
    preassignedPlan?: Plan;
    planChangeRequestedPlan?: Plan;
    updatePlanOptions: UpdatePlanOptions;
    tabIndex: number;
    isChangePlanWizardOpen: boolean;
    isChangePreassignedPlanWizardOpen: boolean;
    isDialogRemovePreassignedPlanOpen: boolean;
    isDialogSaveCustomerOpen: boolean;
    invoiceSettings?: InvoiceSettings;
}

class CustomerEdit extends Component<Props, State> {
    private customerUUID = '';
    private onFilterChangeTimer: any;
    private invoices: Invoice[] = [];
    private totalInvoices = 0;

    constructor(props: Props) {
        super(props);
        this.state = {
            subscriptionsFromDate: moment()
                .startOf('day')
                .add(-2, 'month')
                .toDate(),
            tabIndex: 0,
            filters: {
                rowsPerPage: 10,
                page: 0,
                search: '',
                customerUUID: props.match.params.uuid
            },
            isChangePlanWizardOpen: false,
            isChangePreassignedPlanWizardOpen: false,
            isDialogRemovePreassignedPlanOpen: false,
            isDialogSaveCustomerOpen: false,
            isLoading: true,
            existCustomer: true,
            isSelectorLoading: true,
            currentDefaultPlan: { value: '', label: '' },
            customer: {
                _id: '',
                billingInfo: {},
                callbackURL: '',
                clientInfo: {
                    address: {}
                } as ClientInfo,
                createdAt: new Date(),
                paymentInfo: {} as CustomerPaymentInfo,
                planId: '',
                responsibleInfo: {} as CustomerResponsibleInfo,
                uuid: '',
                lastRenovationDate: new Date(),
                nextRenovationDate: new Date(),
                planName: '',
                automaticInvoiceChargeDisabled: false,
                name: ''
            },
            updatePlanOptions: {
                applyInmediate: true,
                billingInfo: {}
            },
            customerInfo: {} as CustomerInfo
        };
        this.customerUUID = props.match.params.uuid;
    }

    componentDidMount = () => {
        this.init();
    };

    init = async () => {
        await this.loadCustomer(this.customerUUID);
        await this.loadInvoiceSettings();
        await this.loadInvoices(this.state.filters);
    };

    private loadCustomer = async (customerUUID: string) => {
        try {
            const stateCopy = { ...this.state };
            stateCopy.customerInfo =
                await CustomerService.getCustomer(customerUUID);
            stateCopy.customer = stateCopy.customerInfo.customer;
            stateCopy.currentPlan = await PlanService.getPlan(
                stateCopy.customer.planId
            );
            stateCopy.customerSubscriptions =
                await SubscriptionService.getCustomerSubscriptionsHistory(
                    stateCopy.customer._id,
                    this.state.subscriptionsFromDate
                );
            stateCopy.nextPlan = undefined;
            if (stateCopy.customer.nextPlanId) {
                stateCopy.nextPlan = await PlanService.getPlan(
                    stateCopy.customer.nextPlanId
                );
            }
            if (stateCopy.customer.preassignedPlanInfo?.planId) {
                stateCopy.preassignedPlan = await PlanService.getPlan(
                    stateCopy.customer.preassignedPlanInfo.planId
                );
            }
            if (stateCopy.customer.planChangeRequest?.planId) {
                stateCopy.planChangeRequestedPlan = await PlanService.getPlan(
                    stateCopy.customer.planChangeRequest.planId
                );
            }
            stateCopy.isLoading = false;
            this.setState(stateCopy);
        } catch (err) {
            this.setState(
                { ...this.state, isLoading: false, existCustomer: false },
                () => {
                    this.props.showToast('error', 'Customer not found');
                }
            );
        }
    };

    private loadInvoiceSettings = async () => {
        const invoiceSettings = await InvoiceService.getInvoiceSettings();
        this.setState({ ...this.state, invoiceSettings });
    };

    private saveCustomer = async () => {
        this.setState({
            ...this.state,
            isDialogSaveCustomerOpen: false
        });
        try {
            await CustomerService.editCustomer(
                this.state.customer,
                this.state.customer.uuid
            );
            const customerSubscriptions =
                await SubscriptionService.getCustomerSubscriptionsHistory(
                    this.state.customer._id,
                    this.state.subscriptionsFromDate
                );
            this.setState({
                ...this.state,
                customerSubscriptions,
                isLoading: false
            });
            this.props.showToast('success', 'Customer saved successfully');
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                if (err instanceof DuplicateKeyError) {
                    this.props.showToast(
                        'error',
                        'Duplicate key error, only 1 default customer and only 1 customer with the same soft limits.'
                    );
                } else {
                    this.props.showToast(
                        'error',
                        'Error saving customer' +
                            (err.message ? ': ' + err.message : '')
                    );
                }
            });
        }
    };

    updatePlanId = (selectedOption: any) => {
        const stateCopy = { ...this.state };
        stateCopy.customer.planId = selectedOption.value;
        stateCopy.currentDefaultPlan = selectedOption;
        this.setState(stateCopy);
    };

    onUpdateBillingInfo = (billingInfo: CustomerBillingInfo) => {
        this.setState({
            ...this.state,
            customer: { ...this.state.customer, billingInfo }
        });
    };

    onUpdateClientInfo = (clientInfo: ClientInfo) => {
        this.setState({
            ...this.state,
            customer: { ...this.state.customer, clientInfo }
        });
    };

    onUpdatePaymentInfo = (paymentInfo: CustomerPaymentInfo) => {
        this.setState({
            ...this.state,
            customer: { ...this.state.customer, paymentInfo }
        });
    };

    onAddPartnerTerm = async (
        partnerTerm: Omit<AssignedPartnerTerm, 'assignedOn'>
    ) => {
        try {
            const addedPartnerTerm = await CustomerService.addPartnerTerm(
                this.state.customer.uuid,
                partnerTerm
            );
            const partnerTerms = [
                ...(this.state.customer.partnerTerms ?? []),
                addedPartnerTerm
            ];
            this.setState({
                ...this.state,
                customer: { ...this.state.customer, partnerTerms }
            });
        } catch (error) {
            this.props.showToast('error', error.message);
        }
    };

    onEditPartnerTerm = async (partnerTerm: AssignedPartnerTerm) => {
        try {
            const editPartnerTermUpdate: AssignedPartnerTermUpdate = {
                startDecayDate: partnerTerm.startDecayDate,
                endPartnerRelationDate: partnerTerm.endPartnerRelationDate
            };
            const partnerTermEdited: AssignedPartnerTerm =
                await CustomerService.editPartnerTerm(
                    this.state.customer.uuid,
                    partnerTerm.uuid,
                    editPartnerTermUpdate
                );
            const partnerTerms = this.state.customer.partnerTerms?.map(t =>
                t.uuid !== partnerTermEdited.uuid ? t : partnerTermEdited
            );

            this.setState({
                ...this.state,
                customer: { ...this.state.customer, partnerTerms }
            });
        } catch (error) {
            this.props.showToast('error', error.message);
        }
    };

    onRemovePartnerTerm = async (termUuid: string) => {
        try {
            await CustomerService.removePartnerTerm(
                this.state.customer.uuid,
                termUuid
            );
            const partnerTerms = this.state.customer.partnerTerms?.filter(
                t => t.uuid !== termUuid
            );
            this.setState({
                ...this.state,
                customer: { ...this.state.customer, partnerTerms }
            });
        } catch (error) {
            this.props.showToast('error', error.message);
        }
    };

    onUpdateAutomaticInvoiceChargeDisabled = (
        automaticInvoiceChargeDisabled: boolean
    ) => {
        this.setState({
            ...this.state,
            customer: { ...this.state.customer, automaticInvoiceChargeDisabled }
        });
    };

    saveUpdate = async () => {
        try {
            this.setState({ ...this.state, isLoading: true });
            await CustomerService.updatePlan(
                this.state.customer.uuid,
                this.state.customer.planId,
                this.state.updatePlanOptions
            );
            this.loadCustomer(this.customerUUID);
            this.props.showToast(
                'success',
                'Customer plan updated successfully'
            );
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                this.props.showToast('error', 'Error saving plan settings');
                this.loadCustomer(this.customerUUID);
            });
        }
    };

    removePreassignedPlan = async () => {
        try {
            this.setState({ ...this.state, isLoading: true });
            await CustomerService.removePreassignedPlan(
                this.state.customer.uuid
            );
            this.setState({
                ...this.state,
                customer: {
                    ...this.state.customer,
                    preassignedPlanInfo: undefined
                },
                isDialogRemovePreassignedPlanOpen: false
            });
            this.loadCustomer(this.customerUUID);
            this.props.showToast(
                'success',
                'Customer preassigned plan removed successfully'
            );
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                this.props.showToast(
                    'error',
                    'Error removing customer preassigned plan'
                );
                this.loadCustomer(this.customerUUID);
            });
        }
    };

    startLoading = () => {
        this.setState({ ...this.state, isLoading: true });
    };

    stopLoading = () => {
        this.setState({ ...this.state, isLoading: false });
    };

    onTabChange = (newValue: number) => {
        this.setState({ ...this.state, tabIndex: newValue });
    };

    a11yProps = (index: number) => ({
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    });

    closeWizards = async (changePlan: boolean) => {
        if (changePlan) {
            await this.loadCustomer(this.customerUUID);
        }
        this.setState({
            ...this.state,
            isChangePlanWizardOpen: false,
            isChangePreassignedPlanWizardOpen: false
        });
    };

    handleCloseRemovePreassignedPlanModal = () => {
        this.setState({
            ...this.state,
            isDialogRemovePreassignedPlanOpen: false
        });
    };

    handleCloseSaveCustomerModal = () => {
        this.setState({
            ...this.state,
            isDialogSaveCustomerOpen: false
        });
    };

    private onFilterChange = (
        filterId: keyof InvoiceListFilters,
        filterValue: any
    ) => {
        const stateCopy = { ...this.state };
        (stateCopy as any).filters[filterId] = filterValue;
        this.setState(stateCopy);
        if (this.onFilterChangeTimer) {
            clearTimeout(this.onFilterChangeTimer);
        }
        this.onFilterChangeTimer = setTimeout(() => {
            const stateCopy = { ...this.state };
            stateCopy.isLoading = true;
            stateCopy.filters.page = 0;
            this.setState(stateCopy);
            this.loadInvoices(stateCopy.filters);
            this.onFilterChangeTimer = null;
        }, 500);
    };

    private loadInvoices = async (filters: InvoiceListFilters) => {
        let invoiceList: { documents: Invoice[]; totalQuantity: number } = {
            documents: [],
            totalQuantity: 0
        };
        try {
            invoiceList = await InvoiceService.getInvoiceList(filters);
        } catch {
            this.props.showToast('error', 'Error on get invoice list');
        }
        this.invoices = invoiceList.documents;
        this.totalInvoices = invoiceList.totalQuantity;
        this.setState({ ...this.state, isLoading: false, filters });
    };

    onPaginationChange = (page: number, rowsPerPage: number) => {
        const stateCopy = { ...this.state };
        stateCopy.filters.page = page;
        stateCopy.filters.rowsPerPage = rowsPerPage;
        stateCopy.isLoading = true;
        this.setState(stateCopy);
        this.loadInvoices(stateCopy.filters);
    };

    onSortByChange = (fieldId: string, direction?: 'asc' | 'desc') => {
        const stateCopy = { ...this.state };
        stateCopy.isLoading = true;
        stateCopy.filters.sortBy = fieldId;
        stateCopy.filters.direction = direction;
        this.setState(stateCopy);
        this.loadInvoices(stateCopy.filters);
    };

    emitInvoice = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.emitInvoice(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Invoice emitted');
        } catch {
            this.props.showToast('error', 'Error on emit invoice');
        }

        this.setState({ ...this.state, isLoading: false });
    };

    sendByEmail = async (invoiceIdToSend: string, emails: string[]) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.sendByEmail(invoiceIdToSend, emails);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Email sent');
        } catch (err) {
            this.props.showToast('error', 'Error on send by email');
        }
        this.setState({ ...this.state, isLoading: false });
    };

    cancelInvoice = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.cancelInvoice(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Invoice rectified');
        } catch {
            this.props.showToast('error', 'Error on cancel invoice');
        }
        this.setState({ ...this.state, isLoading: false });
    };

    deleteInvoice = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.deleteInvoice(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Invoice deleted');
        } catch {
            this.props.showToast('error', 'Error deleting invoice');
        }
        this.setState({ ...this.state, isLoading: false });
    };

    onChangeSubscriptionFromDate = async (subscriptionsFromDate: Date) => {
        const customerSubscriptions =
            await SubscriptionService.getCustomerSubscriptionsHistory(
                this.state.customer._id,
                subscriptionsFromDate
            );
        this.setState({
            ...this.state,
            customerSubscriptions,
            subscriptionsFromDate
        });
    };

    chargeAndEmitInvoice = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.chargeAndEmitInvoice(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Invoice charged and emitted');
        } catch (err) {
            let msg = 'Error charging invoice';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false });
    };

    chargeReceipt = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.chargeReceipt(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('success', 'Receipt charged');
        } catch (err) {
            let msg = 'Error charging the receipt';
            if (err.message) {
                msg += ': ' + err.message;
            }
            this.props.showToast('error', msg);
        }
        this.setState({ ...this.state, isLoading: false });
    };

    hideToCustomerInvoice = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.hideToCustomer(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('info', 'Invoice hidden to customer');
        } catch (err) {
            this.props.showToast('error', 'Error trying to hide the invoice');
        }
        this.setState({ ...this.state, isLoading: false });
    };

    markReceiptAsPaid = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.markReceiptAsPaid(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('info', 'Receipt mark as paid');
        } catch (err) {
            this.props.showToast(
                'error',
                'Error trying to set receipt as paid'
            );
        }
        this.setState({ ...this.state, isLoading: false });
    };

    showToCustomerInvoice = async (invoiceId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await InvoiceService.showToCustomer(invoiceId);
            await this.loadInvoices(this.state.filters);
            this.props.showToast('info', 'Invoice will be visible to customer');
        } catch (err) {
            this.props.showToast(
                'error',
                'Error trying to make the invoice visible to customer'
            );
        }
        this.setState({ ...this.state, isLoading: false });
    };

    render(): ReactElement {
        return (
            <div className='customer-edit-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={9}>
                            <PageHeader>
                                {this.state.customer.deletedAt && (
                                    <span
                                        className='deleted-customer'
                                        style={{
                                            color: 'red',
                                            marginRight: '10px'
                                        }}
                                    >
                                        [DELETED]
                                    </span>
                                )}
                                Edit customer <b>{this.state.customer.name}</b>
                                {this.state.customer.paymentInfo
                                    .paymentMethod === 'shopify' &&
                                    ' (Shopify)'}
                            </PageHeader>
                        </Grid>
                        {(this.state.isChangePlanWizardOpen ||
                            this.state.isChangePreassignedPlanWizardOpen) && (
                            <Grid item xs={3}>
                                <BackButton
                                    onClick={() => this.closeWizards(false)}
                                >
                                    Back to Customer
                                </BackButton>
                            </Grid>
                        )}
                        {!this.state.isChangePlanWizardOpen &&
                            !this.state.isChangePreassignedPlanWizardOpen && (
                                <Grid item xs={3}>
                                    <BackButton href='/customer/list'>
                                        Back to Customer list
                                    </BackButton>
                                </Grid>
                            )}
                    </Grid>
                    {!this.state.isChangePlanWizardOpen &&
                        !this.state.isChangePreassignedPlanWizardOpen && (
                            <React.Fragment>
                                <Paper className={'cn-tabs'}>
                                    <Tabs
                                        value={this.state.tabIndex}
                                        onChange={(event, newValue: number) =>
                                            this.onTabChange(newValue)
                                        }
                                        indicatorColor='primary'
                                        textColor='primary'
                                    >
                                        <Tab
                                            label='Plans'
                                            {...this.a11yProps(0)}
                                        />
                                        <Tab
                                            label='Invoicing'
                                            {...this.a11yProps(1)}
                                        />
                                        <Tab
                                            label='Invoices'
                                            {...this.a11yProps(2)}
                                        />
                                        <Tab
                                            label='Partners'
                                            {...this.a11yProps(3)}
                                        />
                                    </Tabs>
                                </Paper>
                                <Grid container spacing={3}>
                                    {this.state.tabIndex === 0 &&
                                        this.state.customerInfo &&
                                        this.state.currentPlan && (
                                            <Grid item xs={12}>
                                                <PlansTab
                                                    customerSubscriptions={
                                                        this.state
                                                            .customerSubscriptions ||
                                                        []
                                                    }
                                                    subscriptionsFromDate={
                                                        this.state
                                                            .subscriptionsFromDate
                                                    }
                                                    onChangeSubscriptionFromDate={
                                                        this
                                                            .onChangeSubscriptionFromDate
                                                    }
                                                    onUpdateBillingInfo={
                                                        this.onUpdateBillingInfo
                                                    }
                                                    customer={
                                                        this.state.customer
                                                    }
                                                    currentPlan={
                                                        this.state.currentPlan
                                                    }
                                                    preassignedPlan={
                                                        this.state
                                                            .preassignedPlan
                                                    }
                                                    planChangeRequestedPlan={
                                                        this.state
                                                            .planChangeRequestedPlan
                                                    }
                                                    invoiceSettings={
                                                        this.state
                                                            .invoiceSettings
                                                    }
                                                    scheduledPlan={
                                                        this.state.nextPlan
                                                    }
                                                    saveCustomer={
                                                        this.saveCustomer
                                                    }
                                                    currentSubscription={
                                                        this.state.customerInfo
                                                            .activeSubscription
                                                    }
                                                    scheduledSubscription={
                                                        this.state.customerInfo
                                                            .nextSubscription
                                                    }
                                                    showChangePlanWizard={() =>
                                                        this.setState({
                                                            ...this.state,
                                                            isChangePlanWizardOpen:
                                                                true
                                                        })
                                                    }
                                                    showChangePreassignedPlanWizard={() =>
                                                        this.setState({
                                                            ...this.state,
                                                            isChangePreassignedPlanWizardOpen:
                                                                true
                                                        })
                                                    }
                                                    showRemovePreassignedPlanModal={() =>
                                                        this.setState({
                                                            ...this.state,
                                                            isDialogRemovePreassignedPlanOpen:
                                                                true
                                                        })
                                                    }
                                                    showSaveCustomerModal={() =>
                                                        this.setState({
                                                            ...this.state,
                                                            isDialogSaveCustomerOpen:
                                                                true
                                                        })
                                                    }
                                                ></PlansTab>
                                            </Grid>
                                        )}
                                    {this.state.tabIndex === 1 && (
                                        <Grid item xs={12}>
                                            <CustomerEditor
                                                customer={this.state.customer}
                                                saveCustomer={this.saveCustomer}
                                                onUpdateBillingInfo={
                                                    this.onUpdateBillingInfo
                                                }
                                                onUpdateClientInfo={
                                                    this.onUpdateClientInfo
                                                }
                                                onUpdatePaymentInfo={
                                                    this.onUpdatePaymentInfo
                                                }
                                                onUpdateAutomaticInvoiceChargeDisabled={
                                                    this
                                                        .onUpdateAutomaticInvoiceChargeDisabled
                                                }
                                                showToast={this.props.showToast}
                                            ></CustomerEditor>
                                        </Grid>
                                    )}
                                    {this.state.tabIndex === 2 && (
                                        <Grid item xs={12}>
                                            <Paper className='cn-paper'>
                                                <Grid container spacing={1}>
                                                    <ListItems
                                                        onFilterChange={
                                                            this.onFilterChange
                                                        }
                                                        filters={
                                                            this.state.filters
                                                        }
                                                        rowsTotal={
                                                            this.totalInvoices
                                                        }
                                                        rows={this.invoices}
                                                        onPaginationChange={
                                                            this
                                                                .onPaginationChange
                                                        }
                                                        emitInvoice={
                                                            this.emitInvoice
                                                        }
                                                        cancelInvoice={
                                                            this.cancelInvoice
                                                        }
                                                        deleteInvoice={
                                                            this.deleteInvoice
                                                        }
                                                        onSortByChange={
                                                            this.onSortByChange
                                                        }
                                                        sendByEmail={
                                                            this.sendByEmail
                                                        }
                                                        chargeAndEmitInvoice={
                                                            this
                                                                .chargeAndEmitInvoice
                                                        }
                                                        chargeReceipt={
                                                            this.chargeReceipt
                                                        }
                                                        showToCustomerInvoice={
                                                            this
                                                                .showToCustomerInvoice
                                                        }
                                                        hideToCustomerInvoice={
                                                            this
                                                                .hideToCustomerInvoice
                                                        }
                                                        markReceiptAsPaid={
                                                            this
                                                                .markReceiptAsPaid
                                                        }
                                                    ></ListItems>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {this.state.tabIndex === 3 && (
                                        <Grid item xs={12}>
                                            <Paper className='cn-paper'>
                                                <CustomerPartners
                                                    customerUuid={
                                                        this.state.customer.uuid
                                                    }
                                                    partnerTerms={
                                                        this.state.customer
                                                            .partnerTerms ?? []
                                                    }
                                                    onAddPartnerTerm={
                                                        this.onAddPartnerTerm
                                                    }
                                                    onEditPartnerTerm={
                                                        this.onEditPartnerTerm
                                                    }
                                                    onRemovePartnerTerm={
                                                        this.onRemovePartnerTerm
                                                    }
                                                    showToast={
                                                        this.props.showToast
                                                    }
                                                />
                                            </Paper>
                                        </Grid>
                                    )}
                                </Grid>
                            </React.Fragment>
                        )}
                    {this.state.isChangePlanWizardOpen && (
                        <Zoom
                            in={this.state.isChangePlanWizardOpen}
                            style={{
                                transitionDelay: this.state
                                    .isChangePlanWizardOpen
                                    ? '150ms'
                                    : '0ms'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper className={'cn-tab cn-paper'}>
                                        <CustomerChangePlanWizard
                                            customer={this.state.customer}
                                            invoiceSettings={
                                                this.state.invoiceSettings
                                            }
                                            currentSubscription={
                                                this.state.customerInfo
                                                    .activeSubscription
                                            }
                                            onCancel={this.closeWizards}
                                            showToast={this.props.showToast}
                                            startLoading={this.startLoading}
                                            stopLoading={this.stopLoading}
                                        ></CustomerChangePlanWizard>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Zoom>
                    )}
                    {this.state.isChangePreassignedPlanWizardOpen && (
                        <Zoom
                            in={this.state.isChangePreassignedPlanWizardOpen}
                            style={{
                                transitionDelay: this.state
                                    .isChangePreassignedPlanWizardOpen
                                    ? '150ms'
                                    : '0ms'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper className={'cn-tab cn-paper'}>
                                        <CustomerChangePreassignedPlanWizard
                                            customer={this.state.customer}
                                            invoiceSettings={
                                                this.state.invoiceSettings
                                            }
                                            currentSubscription={
                                                this.state.customerInfo
                                                    ?.activeSubscription
                                            }
                                            onCancel={this.closeWizards}
                                            showToast={this.props.showToast}
                                            startLoading={this.startLoading}
                                            stopLoading={this.stopLoading}
                                        ></CustomerChangePreassignedPlanWizard>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Zoom>
                    )}
                </Container>
                {!this.state.existCustomer && (
                    <div>
                        <Redirect to='/customer/list' />
                    </div>
                )}
                <Dialog
                    open={this.state.isDialogRemovePreassignedPlanOpen}
                    onClose={this.handleCloseRemovePreassignedPlanModal}
                    aria-labelledby='form-dialog-title'
                >
                    <DialogTitle id='form-dialog-title'>
                        Remove preassigned plan
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <p>
                                    You are going to remove the customer
                                    preassigned plan
                                </p>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseRemovePreassignedPlanModal}
                            color='primary'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.removePreassignedPlan}
                            color='primary'
                        >
                            Remove
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isDialogSaveCustomerOpen}
                    onClose={this.handleCloseSaveCustomerModal}
                    aria-labelledby='form-dialog-title'
                >
                    <DialogTitle id='form-dialog-title'>
                        Save Customer
                        <br />
                    </DialogTitle>
                    <DialogContent style={{ minWidth: '450px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <p>
                                    NOTE: This customer has configured "shopify"
                                    as the payment method.
                                </p>
                                {this.state.customer
                                    .shopifyRecurringApplicationCharge && (
                                    <p>
                                        The current capped amount is "
                                        {
                                            this.state.customer
                                                .shopifyRecurringApplicationCharge
                                                .cappedAmount
                                        }
                                        $".
                                    </p>
                                )}

                                <p>
                                    If we try to charge a total amount bigger
                                    than the capped amount it is possible that
                                    the charge can not be made. We can ask the
                                    customer to increase the capped amount if
                                    needed.
                                </p>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseSaveCustomerModal}
                            color='primary'
                        >
                            Cancel
                        </Button>
                        <Button onClick={this.saveCustomer} color='primary'>
                            Save Customer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
export default CustomerEdit;
