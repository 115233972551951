import React from 'react';
import { Link } from 'react-router-dom';
import { CustomerChangedPaymentMethodOperationLog } from '../../../../../models/system/CustomerChangedPaymentMethodOperationLog';

const CustomerChangedPaymentMethod = (
    operationLog: CustomerChangedPaymentMethodOperationLog
) => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerAccountEmail}
                </Link>{' '}
                - Old payment method: {operationLog.oldPaymentMethod}- New
                payment method: {operationLog.newPaymentMethod}
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>
                - Old payment method: {operationLog.oldPaymentMethod}- New
                payment method: {operationLog.newPaymentMethod}
            </p>
        )}
    </div>
);

export default CustomerChangedPaymentMethod;
