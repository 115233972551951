import React, { ReactElement } from 'react';
import Customer from '../../../../models/customer/Customer';
import { CustomerBillingInfo } from '../../../../models/customer/CustomerBillingInfo';
import { Paper, Grid, Button, Divider } from '@mui/material';
import CustomerPlanPricing from './CustomerPlanPricing';
import { CSSProperties } from '@mui/styles';
import CustomerPlanInfo from './CustomerPlanInfo/CustomerPlanInfo';
import PlanSummary from '../../../../models/plan/PlanSummary';
import Plan from '../../../../models/plan/Plan';
import { Subscription } from '../../../../models/subscription/Subscription';
import { InvoiceSettings } from '../../../../models/invoice/InvoiceSettings';
import CustomerPreassignedPlanInfo from './CustomerPreassignedPlanInfo/CustomerPreassignedPlanInfo';
import CustomerPlanChangeRequestInfo from './CustomerPlanChangeRequestInfo/CustomerPlanChangeRequestInfo';

interface Props {
    customer: Customer;
    currentPlan: Plan;
    preassignedPlan?: Plan;
    planChangeRequestedPlan?: Plan;
    scheduledPlan?: Plan;
    invoiceSettings?: InvoiceSettings;
    currentSubscription: Subscription;
    scheduledSubscription?: Subscription;
    customerSubscriptions: Subscription[];
    onUpdateBillingInfo: (customerBillingInfo: CustomerBillingInfo) => void;
    saveCustomer: () => void;
    showChangePlanWizard: () => void;
    showChangePreassignedPlanWizard: () => void;
    showRemovePreassignedPlanModal: () => void;
    showSaveCustomerModal: () => void;
    subscriptionsFromDate: Date;
    onChangeSubscriptionFromDate: (fromDate: Date) => void;
}

export default function PlansTab(props: Props): ReactElement {
    const buttonContainerStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px'
    };

    const buttonChangePlanStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '10px',
        marginBottom: '20px'
    };

    const buttonChangePreassignedPlanStyles: CSSProperties = {
        marginRight: '20px'
    };

    const buttonRemovePreassignedPlanStyles: CSSProperties = {
        marginLeft: '20px'
    };

    const divPreassignedPlanButtonsStyles: CSSProperties = {
        marginBottom: '16px',
        width: '100%'
    };

    const currentPlanSummary: PlanSummary = {
        defaultCost: props.currentPlan.price,
        vat: props.customer.billingInfo.vat,
        paymentMethod: props.customer.paymentInfo.paymentMethod,
        renovationDate: props.currentSubscription
            ? props.currentSubscription.scheduledFinishDate
            : new Date(),
        startDate: props.currentSubscription
            ? props.currentSubscription.startedAt
            : new Date(),
        selectedPlanName: props.currentPlan.name,
        hiddenDiscount: props.customer.billingInfo.hiddenDiscount,
        fixedPrice: props.customer.billingInfo.fixedPrice,
        visibleDiscount: props.customer.billingInfo.visibleDiscount,
        analyticsTier: props.customer.billingInfo.analyticsTier
    };

    const isShopifyCustomer = function (): boolean {
        return props.customer.paymentInfo.paymentMethod === 'shopify';
    };

    const isDeletedCustomer = function (): boolean {
        return !!props.customer.deleted;
    };

    return (
        <Paper className='cn-paper cn-tab'>
            <Grid container spacing={1}>
                <Grid
                    container
                    spacing={3}
                    justifyContent='flex-end'
                    alignItems='flex-end'
                >
                    <Grid item xs={4} sm={4} style={buttonChangePlanStyles}>
                        <Button
                            style={buttonChangePreassignedPlanStyles}
                            onClick={props.showChangePreassignedPlanWizard}
                            variant='contained'
                            color='primary'
                            disabled={isDeletedCustomer()}
                        >
                            Preassign Plan
                        </Button>
                        <Button
                            onClick={props.showChangePlanWizard}
                            variant='contained'
                            color='primary'
                            disabled={
                                isShopifyCustomer() || isDeletedCustomer()
                            }
                        >
                            Change Plan
                        </Button>
                    </Grid>
                </Grid>

                {props.customer.planChangeRequest && (
                    <div style={divPreassignedPlanButtonsStyles}>
                        <p>
                            <b>Plan Change Request</b>
                        </p>
                        <CustomerPlanChangeRequestInfo
                            planChangeRequest={props.customer.planChangeRequest}
                            planChangeRequestedPlan={
                                props.planChangeRequestedPlan
                            }
                        ></CustomerPlanChangeRequestInfo>
                    </div>
                )}

                {props.preassignedPlan &&
                    props.customer.preassignedPlanInfo && (
                        <div style={divPreassignedPlanButtonsStyles}>
                            <p>
                                <b>Preassigned Plan</b>
                            </p>
                            <CustomerPreassignedPlanInfo
                                invoiceSettings={props.invoiceSettings}
                                customerTimeZone={props.customer.timezone}
                                preassignedPlan={props.preassignedPlan}
                                customerBillingInfo={
                                    props.customer.preassignedPlanInfo
                                        ?.customerBillingInfo
                                }
                            ></CustomerPreassignedPlanInfo>
                            <Button
                                style={buttonRemovePreassignedPlanStyles}
                                onClick={props.showRemovePreassignedPlanModal}
                                variant='contained'
                            >
                                Remove preassigned plan
                            </Button>
                        </div>
                    )}
                <CustomerPlanInfo
                    customerSubscriptions={props.customerSubscriptions}
                    invoiceSettings={props.invoiceSettings}
                    currentPlan={currentPlanSummary}
                    subscriptionsFromDate={props.subscriptionsFromDate}
                    onChangeSubscriptionFromDate={
                        props.onChangeSubscriptionFromDate
                    }
                    customerTimeZone={props.customer.timezone}
                    customerBillingInfo={props.customer.billingInfo}
                ></CustomerPlanInfo>
                <Grid item xs={12} sm={12}>
                    <Divider variant='fullWidth' />
                </Grid>
                <CustomerPlanPricing
                    billingInfo={props.customer.billingInfo}
                    onUpdateBillingInfo={props.onUpdateBillingInfo}
                    showOveragePricing={true}
                    isDisabled={isShopifyCustomer()}
                    paymentMethod={props.customer.paymentInfo.paymentMethod}
                    shopifyRecurringApplicationCharge={
                        props.customer.shopifyRecurringApplicationCharge
                    }
                ></CustomerPlanPricing>
                <Grid item xs={12} sm={12} style={{ margin: '30px 0px' }}>
                    <Divider variant='fullWidth' />
                </Grid>
                <Grid
                    container
                    spacing={3}
                    justifyContent='flex-end'
                    alignItems='flex-end'
                >
                    <Grid item xs={4} sm={4} style={buttonContainerStyles}>
                        <Button
                            onClick={
                                props.customer.paymentInfo.paymentMethod ===
                                'shopify'
                                    ? props.showSaveCustomerModal
                                    : props.saveCustomer
                            }
                            type='submit'
                            variant='contained'
                            color='primary'
                            disabled={isDeletedCustomer()}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
