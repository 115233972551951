import React, { ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Tooltip from '@mui/material/Tooltip';
import Alert from '../../../../../components/Alert';
import { CSSProperties } from '@mui/styles';
import GenericListFilters from '../../../../../models/GenericListFilters';
import { Subscription } from '../../../../../models/subscription/Subscription';
import Moment from 'react-moment';
import { SubscriptionStatusId } from '../../../../../models/subscription/SubscriptionStatusId';
import Paginator from '../../../../../components/Paginator';
import TableSubCell from '../../../../../components/TableSubCell';

type HeadRow = {
    id: string;
    active: boolean;
    sortable: boolean;
    direction?: 'desc' | 'asc';
    label: string;
    subHead?: HeadRow[];
};

interface Props {
    rows: Subscription[];
    rowsTotal: number;
    filters: GenericListFilters;
    onPaginationChange: (page: number, limit: number) => void;
    billSubscription: (subscriptionId: string) => void;
}

const actionButtonStyles: CSSProperties = {
    display: 'inline-block'
};

const columnsStyles: CSSProperties = {
    whiteSpace: 'nowrap'
};

const containerTableStyle: CSSProperties = {
    overflowX: 'auto',
    width: '100%'
};

const multipleHeadStyle: CSSProperties = {
    width: '100%'
};

export default function ListItems(props: Props): ReactElement {
    let headRows: HeadRow[] = [
        { id: 'account', active: false, sortable: true, label: 'Account' },
        { id: 'planName', active: false, sortable: true, label: 'Plan Name' },
        { id: 'env', active: false, sortable: true, label: 'Environment' },
        {
            id: 'scheduledDate',
            active: true,
            sortable: true,
            direction: 'desc',
            label: 'Scheduled dates',
            subHead: [
                {
                    id: 'scheduledStartDate',
                    active: true,
                    sortable: true,
                    direction: 'desc',
                    label: 'Scheduled started date'
                },
                {
                    id: 'scheduledFinishDate',
                    active: false,
                    sortable: true,
                    direction: 'desc',
                    label: 'Scheduled finished date'
                }
            ]
        },
        {
            id: 'date',
            active: true,
            sortable: true,
            direction: 'desc',
            label: 'Real dates',
            subHead: [
                {
                    id: 'startedAt',
                    active: true,
                    sortable: true,
                    direction: 'desc',
                    label: 'Started date'
                },
                {
                    id: 'finishedAt',
                    active: false,
                    sortable: true,
                    direction: 'desc',
                    label: 'Finished date'
                }
            ]
        },
        { id: 'status', active: false, sortable: true, label: 'Status' },
        { id: 'actions', active: false, sortable: false, label: 'Actions' }
    ];

    if (props.filters.sortBy) {
        headRows = headRows.map((row: HeadRow) => {
            row.active = row.id === props.filters.sortBy;
            if (row.subHead) {
                row.subHead = row.subHead.map((subHead: HeadRow) => {
                    subHead.active = subHead.id === props.filters.sortBy;
                    if (subHead.active && props.filters.direction) {
                        subHead.direction = props.filters.direction;
                    }
                    return subHead;
                });
            }
            if (row.active && props.filters.direction) {
                row.direction = props.filters.direction;
            }
            return row;
        });
    }

    const canBill = (subscriptionStatus: SubscriptionStatusId) =>
        subscriptionStatus === 'pending';

    return (
        <div>
            <Paginator
                page={props.filters.page}
                rowsPerPage={props.filters.rowsPerPage}
                rowsTotal={props.rowsTotal}
                onPaginationChange={props.onPaginationChange}
            ></Paginator>
            {props.rows.length > 0 && (
                <div style={containerTableStyle}>
                    <Table size='small' className={'filtered-list'}>
                        <TableHead>
                            <TableRow>
                                {headRows.map(row => (
                                    <TableCell
                                        key={row.id}
                                        style={columnsStyles}
                                    >
                                        <div style={multipleHeadStyle}>
                                            {row.label}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.rows.map(row => (
                                <TableRow key={row._id}>
                                    <TableCell>
                                        <a
                                            href={`/customer/${row.customerUUID}/edit`}
                                        >
                                            {' '}
                                            {row.customerName}{' '}
                                        </a>
                                    </TableCell>
                                    <TableCell>{row.planName}</TableCell>
                                    <TableCell>{row.env}</TableCell>
                                    <TableCell>
                                        <Moment
                                            date={row.scheduledStartDate}
                                            format='DD/MM/YYYY HH:mm:ss'
                                        />
                                        <TableSubCell>
                                            <Moment
                                                date={row.scheduledFinishDate}
                                                format='DD/MM/YYYY HH:mm:ss'
                                            />
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell>
                                        {row.startedAt ? (
                                            <Moment
                                                date={row.startedAt}
                                                format='DD/MM/YYYY HH:mm:ss'
                                            />
                                        ) : (
                                            '-'
                                        )}
                                        <TableSubCell>
                                            {row.finishedAt ? (
                                                <Moment
                                                    date={row.finishedAt}
                                                    format='DD/MM/YYYY HH:mm:ss'
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell>
                                        {row.status === 'pending'
                                            ? 'Unbilled'
                                            : 'Billed'}
                                        {row.invoiceFailedOnEmit && (
                                            <div>
                                                <label className='failed'>
                                                    {' '}
                                                    [invoiceFailedOnEmit]
                                                </label>
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell align='right'>
                                        <div className='actions'>
                                            {canBill(row.status) && (
                                                <Tooltip
                                                    title='Mark as solved'
                                                    style={actionButtonStyles}
                                                >
                                                    <div>
                                                        <IconButton
                                                            color='primary'
                                                            disabled={
                                                                !canBill(
                                                                    row.status
                                                                )
                                                            }
                                                            aria-label='Mark as solved'
                                                            onClick={() =>
                                                                props.billSubscription(
                                                                    row._id
                                                                )
                                                            }
                                                        >
                                                            <DoneOutlineIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
            {props.rows.length === 0 && (
                <Alert visible={props.rows.length === 0} variant='info'>
                    No items found
                </Alert>
            )}
        </div>
    );
}
