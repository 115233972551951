import React, { useState, Fragment, ReactElement } from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { PartnerTerm } from '../../../../models/partner/PartnerTerm';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PredeterminedTermsDialog from './PredeterminedTermsDialog';
import EditPartnerTermDialog from './EditPartnerTermDialog';
import PartnerTermList from './PartnerTermList';
import Action from '../../../../components/Action';

interface Props {
    terms: PartnerTerm[];
    onUpdateTerms: (terms: PartnerTerm[]) => void;
    onDeleteTerm: (termUuid: string) => void;
}

export default function PartnerTerms({
    terms,
    onUpdateTerms,
    onDeleteTerm
}: Props): ReactElement {
    const [
        isPredeterminedTermSelectionDialogOpen,
        setPredeterminedTermSelectionDialogOpen
    ] = useState(false);
    const [isEditPartnerTermDialogOpen, setEditPartnerTermDialogOpen] =
        useState(false);
    const [editingPartnerTerm, setEditingPartnerTerm] = useState<PartnerTerm>();
    const [confirmDeletePartnerTerm, setConfirmDeletePartnerTerm] =
        useState<PartnerTerm>();

    function editPartnerTerm(term: PartnerTerm) {
        setEditingPartnerTerm(term);
        setEditPartnerTermDialogOpen(true);
    }

    function startAddingPartnerTerm() {
        setEditingPartnerTerm({
            uuid: crypto.randomUUID(),
            description: '',
            commissionRanges: [
                {
                    amountFrom: 0,
                    amount: 50,
                    isPercentage: true
                }
            ],
            commissionDecay: []
        });
        setEditPartnerTermDialogOpen(true);
    }

    function endPartnerTermEdit() {
        setEditingPartnerTerm(undefined);
        setEditPartnerTermDialogOpen(false);
    }

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <p>
                        <b>Partner Terms</b>
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <PartnerTermList
                        terms={terms}
                        renderActions={term => (
                            <div className='actions'>
                                <Action
                                    label='Edit'
                                    onClick={() => editPartnerTerm(term)}
                                    icon={EditIcon}
                                />
                                <Action
                                    label='Delete'
                                    onClick={() =>
                                        setConfirmDeletePartnerTerm(term)
                                    }
                                    icon={DeleteIcon}
                                />
                            </div>
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                <Grid item>
                    <Button
                        onClick={() =>
                            setPredeterminedTermSelectionDialogOpen(true)
                        }
                        variant='contained'
                        color='primary'
                    >
                        Add predetermined
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => startAddingPartnerTerm()}
                        variant='contained'
                        color='primary'
                    >
                        Add new
                    </Button>
                </Grid>
            </Grid>
            <PredeterminedTermsDialog
                open={isPredeterminedTermSelectionDialogOpen}
                onClose={() => setPredeterminedTermSelectionDialogOpen(false)}
                onAddTerm={term => {
                    setPredeterminedTermSelectionDialogOpen(false);
                    onUpdateTerms([...terms, term]);
                }}
            />
            {editingPartnerTerm && (
                <EditPartnerTermDialog
                    open={isEditPartnerTermDialogOpen}
                    term={editingPartnerTerm}
                    onClose={endPartnerTermEdit}
                    onUpdateTerm={term => {
                        endPartnerTermEdit();
                        const i = terms.findIndex(t => t.uuid === term.uuid);
                        const newTerms =
                            i < 0
                                ? [...terms, term]
                                : [
                                      ...terms.slice(0, i),
                                      term,
                                      ...terms.slice(i + 1)
                                  ];
                        onUpdateTerms(newTerms);
                    }}
                />
            )}

            <Dialog open={confirmDeletePartnerTerm !== undefined}>
                <DialogTitle>Delete Partner Term</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete the Term: '
                    {confirmDeletePartnerTerm?.description}'?
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmDeletePartnerTerm(undefined)}
                    >
                        Cancel
                    </Button>
                    {confirmDeletePartnerTerm && (
                        <Button
                            color='primary'
                            onClick={() => {
                                setConfirmDeletePartnerTerm(undefined);
                                onDeleteTerm(confirmDeletePartnerTerm.uuid);
                            }}
                        >
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
