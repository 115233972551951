import React from 'react';
import { Link } from 'react-router-dom';
import { CustomerShopifyRecurringSubscriptionUpdateOperationLog } from '../../../../../models/system/CustomerShopifyRecurringSubscriptionUpdateOperationLog';

const CustomerShopifyRecurringSubscriptionUpdated = (
    operationLog: CustomerShopifyRecurringSubscriptionUpdateOperationLog
) => (
    <div>
        <p>
            {operationLog.customerName && (
                <span>
                    Account name:{' '}
                    <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                        {operationLog.customerName}
                    </Link>
                </span>
            )}
            <br />
            {operationLog.shop && (
                <span>
                    This customer link his account to this Shopify shop:{' '}
                    {operationLog.shop}
                </span>
            )}
            <br />
            {operationLog.shopifyRecurringSubscriptionUpdate.name && (
                <span>
                    Recurring subscription name:{' '}
                    {operationLog.shopifyRecurringSubscriptionUpdate.name}
                </span>
            )}
            <br />
            {operationLog.shopifyRecurringSubscriptionUpdate.status && (
                <span>
                    Recurring subscription status:{' '}
                    {operationLog.shopifyRecurringSubscriptionUpdate.status}
                </span>
            )}
            <br />
            {operationLog.shopifyRecurringSubscriptionUpdate.updatedAt && (
                <span>
                    Recurring subscription updated at:{' '}
                    {operationLog.shopifyRecurringSubscriptionUpdate.updatedAt}
                </span>
            )}
        </p>
    </div>
);

export default CustomerShopifyRecurringSubscriptionUpdated;
