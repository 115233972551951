import React, { useState, useEffect, Fragment } from 'react';
import { Report } from '../../models/report/Report';
import ReportService from '../../services/ReportService';
import { RouterMatch } from '../../models/RouterMatch';
import Spinner from '../../components/Spinner';
import { Container, Grid } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import ReportBackButton from './components/ReportBackButton';
import ReportEditor from './components/ReportEditor';
import { PropsWithShowToast } from '../../PropsWithShowToast';
import { Redirect } from 'react-router';
import { getBackToLink } from './components/getBackToLink';

type Props = PropsWithShowToast<{
    match: RouterMatch;
}>;

export default function ReportEdit({ match, showToast }: Props): JSX.Element {
    const [report, setReport] = useState<Report>();
    const [errorLoadingReport, setReportLoadingReport] = useState(false);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const reportId = match.params.id;
        if (reportId) {
            setLoading(true);
            ReportService.get(reportId)
                .then(setReport)
                .catch(error => {
                    showToast('error', error.message);
                    setReportLoadingReport(true);
                })
                .finally(() => setLoading(false));
        }
    }, [match, showToast]);

    const saveReport = async () => {
        if (!report) {
            return;
        }
        try {
            const updatedReport = await ReportService.edit(report._id, report);
            setReport(updatedReport);
            showToast('success', 'Report saved');
        } catch (error) {
            showToast('error', error.message);
        }
    };

    const { backTo, uuid } = match.params;
    return (
        <Fragment>
            <Spinner visible={isLoading} />
            {errorLoadingReport && (
                <Redirect to={getBackToLink(backTo, report?.partnerId, uuid)} />
            )}
            {report && (
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>Edit Partner Report</PageHeader>
                        </Grid>
                        <Grid item xs={6}>
                            <ReportBackButton
                                backTo={backTo}
                                partnerId={report?.partnerId}
                                customerUuid={uuid}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ReportEditor
                            report={report}
                            viewMode={false}
                            onUpdateReport={setReport}
                            showToast={showToast}
                            saveReport={saveReport}
                        />
                    </Grid>
                </Container>
            )}
        </Fragment>
    );
}
