import React, { CSSProperties, ReactElement } from 'react';
import {
    Grid,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Switch,
    Theme
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import CreditCardInfoComponent from '../../../../components/CreditCardInfo';
import { CustomerPaymentInfo } from '../../../../models/customer/CustomerPaymentInfo';
import { BankPaymentInfo } from '../../../../models/customer/BankPaymentInfo';
import { CreditCardInfo } from '../../../../models/customer/CreditCardInfo';
import ShopifyRecurringApplicationCharge from './ShopifyRecurringApplicationCharge';
import { ShopifyRecurringApplicationCharge as ShopifyRecurringApplicationChargeModel } from '../../../../models/customer/ShopifyRecurringApplicationCharge';

interface Props {
    paymentInfo: CustomerPaymentInfo;
    automaticInvoiceChargeDisabled: boolean;
    creditCardInfo?: CreditCardInfo;
    shopifyRecurringApplicationCharge?: ShopifyRecurringApplicationChargeModel;
    children?: any;
    onUpdatePaymentInfo: (paymentInfo: CustomerPaymentInfo) => void;
    onUpdateAutomaticInvoiceChargeDisabled: (
        automaticInvoiceChargeDisabled: boolean
    ) => void;
}

const formControlStyles: CSSProperties = {
    minWidth: '115px',
    width: '100%'
};

const disableAutomaticInvoiceStyle: CSSProperties = {
    marginTop: '30px'
};

const useStyles = makeStyles<Theme>(theme => ({
    dense: {
        marginTop: 0
    },
    textField: {
        marginRight: theme.spacing(1)
    }
}));

const useStylesCheckBox = makeStyles(() => ({
    formControlLabel: {
        marginLeft: '25px',
        marginBottom: '-35px',
        verticalAlign: 'bottom'
    }
}));

export default function CustomerPaymentInfoEditor(props: Props): ReactElement {
    const classes = useStyles();
    const classesCheckBox = useStylesCheckBox();

    const updatePaymentInfo = (
        fieldName: keyof BankPaymentInfo,
        newValue: any
    ) => {
        const paymentInfo = { ...props.paymentInfo, [fieldName]: newValue };
        if (paymentInfo.paymentMethod === 'card') {
            delete (paymentInfo as any).isWireTransfer;
        }
        props.onUpdatePaymentInfo(paymentInfo);
    };

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <p>
                    <b>{props.children}</b>
                </p>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormControl style={formControlStyles}>
                            <InputLabel htmlFor='payment-method'>
                                Payment Method
                            </InputLabel>
                            <Select
                                className={clsx(classes.textField)}
                                value={
                                    props.paymentInfo.paymentMethod || 'bank'
                                }
                                onChange={event =>
                                    updatePaymentInfo(
                                        'paymentMethod',
                                        event.target.value
                                    )
                                }
                                inputProps={{
                                    name: 'Payment Method',
                                    id: 'payment-method'
                                }}
                            >
                                <MenuItem value={'bank'}>Bank</MenuItem>
                                <MenuItem value={'card'}>Card</MenuItem>
                                <MenuItem value={'shopify'} disabled>
                                    Shopify
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {props.paymentInfo.paymentMethod === 'bank' && (
                        <Grid item xs={6}>
                            <FormControlLabel
                                className={clsx(
                                    classesCheckBox.formControlLabel
                                )}
                                control={
                                    <Checkbox
                                        checked={
                                            props.paymentInfo.isWireTransfer
                                                ? true
                                                : false
                                        }
                                        onChange={event =>
                                            updatePaymentInfo(
                                                'isWireTransfer',
                                                event.target.checked
                                            )
                                        }
                                        value='IsWiredTransfer'
                                        color='primary'
                                    />
                                }
                                label='Is wire transfer'
                            />
                        </Grid>
                    )}

                    <Grid
                        container
                        spacing={1}
                        style={disableAutomaticInvoiceStyle}
                    >
                        <Grid item xs={6}>
                            <p>
                                <b>Disable automatic invoice charge:</b>
                            </p>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={
                                            !props.automaticInvoiceChargeDisabled
                                        }
                                        onChange={event =>
                                            props.onUpdateAutomaticInvoiceChargeDisabled(
                                                !event.target.checked
                                            )
                                        }
                                        value='checkedB'
                                        color='primary'
                                    />
                                }
                                labelPlacement='start'
                                label={
                                    props.automaticInvoiceChargeDisabled
                                        ? 'Automatic charge disabled'
                                        : 'Automatic charge enabled'
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item xs={6} spacing={3}>
                <CreditCardInfoComponent
                    creditCardInfo={props.creditCardInfo}
                ></CreditCardInfoComponent>
                <ShopifyRecurringApplicationCharge
                    applicationCharge={props.shopifyRecurringApplicationCharge}
                ></ShopifyRecurringApplicationCharge>
            </Grid>
        </React.Fragment>
    );
}
