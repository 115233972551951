import RequestService from './RequestService';
import Invoice from '../models/invoice/Invoice';
import { InvoiceSettings } from '../models/invoice/InvoiceSettings';
import InvoiceListFilters from '../models/InvoiceListFilters';
import moment from 'moment';

const InvoiceService = {
    getInvoiceList(
        listFilters: InvoiceListFilters
    ): Promise<{ documents: Invoice[]; totalQuantity: number }> {
        const filters: any = {
            limit: listFilters.rowsPerPage,
            page: listFilters.page + 1,
            paymentStatus: listFilters.paymentStatus,
            invoiceStatus: listFilters.invoiceStatus,
            paymentMethod: listFilters.paymentMethod,
            search: listFilters.search,
            customerUUID: listFilters.customerUUID,
            subscriptionId: listFilters.subscriptionId,
            invoiceNumber: listFilters.invoiceNumber
        };

        if (listFilters.sortBy) {
            filters.sortKey = listFilters.sortBy;
        }

        if (listFilters.direction) {
            filters.sortDirection = listFilters.direction === 'asc' ? 1 : -1;
        }

        if (listFilters.fromDate) {
            filters.fromDate = moment(listFilters.fromDate)
                .startOf('day')
                .toDate();
        }

        if (listFilters.toDate) {
            filters.toDate = moment(listFilters.toDate).endOf('day').toDate();
        }

        if (listFilters.emitFromDate) {
            filters.emitFromDate = moment(listFilters.emitFromDate)
                .startOf('day')
                .toDate();
        }

        if (listFilters.emitToDate) {
            filters.emitToDate = moment(listFilters.emitToDate)
                .endOf('day')
                .toDate();
        }

        return RequestService.cnFetch(
            '/invoice',
            {
                method: 'GET'
            },
            filters
        ).then(async response => {
            const respObject: {
                documents: Invoice[];
                totalQuantity: number;
            } = await response.json();
            return respObject;
        });
    },

    async emitInvoice(invoiceId?: string): Promise<void> {
        await RequestService.cnFetch(`/invoice/${invoiceId}/emit`, {
            method: 'POST'
        });
    },

    async deleteInvoice(invoiceId?: string): Promise<void> {
        await RequestService.cnFetch(`/invoice/${invoiceId}/delete`, {
            method: 'POST'
        });
    },

    sendByEmail(invoiceId: string, emails: string[]): Promise<Response> {
        const body = {
            emails
        };
        return RequestService.cnFetch(`/invoice/${invoiceId}/send-by-email`, {
            method: 'POST',
            body: JSON.stringify(body)
        });
    },

    async cancelInvoice(invoiceId?: string): Promise<void> {
        await RequestService.cnFetch(`/invoice/${invoiceId}/cancel`, {
            method: 'POST'
        });
    },

    getInvoice(invoiceId: string): Promise<Invoice> {
        return RequestService.cnFetch(`/invoice/${invoiceId}`, {
            method: 'GET'
        }).then(response => response.json());
    },

    async hideToCustomer(invoiceId: string): Promise<void> {
        await RequestService.cnFetch(`/invoice/${invoiceId}/hide-to-customer`, {
            method: 'POST'
        });
    },

    async markReceiptAsPaid(invoiceId: string): Promise<void> {
        await RequestService.cnFetch(`/invoice/${invoiceId}/receipt-paid`, {
            method: 'POST'
        });
    },

    async showToCustomer(invoiceId: string): Promise<void> {
        await RequestService.cnFetch(`/invoice/${invoiceId}/show-to-customer`, {
            method: 'POST'
        });
    },

    editInvoice(invoice: Invoice, invoiceId: string): Promise<Response> {
        const editInvoice = {
            clientInfo: invoice.clientInfo,
            customerUUID: invoice.customerUUID,
            invoicePrefix: invoice.invoicePrefix,
            items: invoice.items,
            paymentInfo: invoice.paymentInfo,
            vat: invoice.vat
        } as Invoice;
        return RequestService.cnFetch(`/invoice/${invoiceId}/edit`, {
            method: 'POST',
            body: JSON.stringify(editInvoice)
        });
    },

    createInvoice(invoice: Invoice): Promise<Invoice> {
        const createInvoice = {
            clientInfo: invoice.clientInfo,
            customerUUID: invoice.customerUUID,
            invoicePrefix: invoice.invoicePrefix,
            items: invoice.items,
            paymentInfo: invoice.paymentInfo,
            vat: invoice.vat
        } as Invoice;
        return RequestService.cnFetch(`/invoice`, {
            method: 'POST',
            body: JSON.stringify(createInvoice)
        }).then(response => response.json());
    },

    getInvoiceSettings(): Promise<InvoiceSettings> {
        return RequestService.cnFetch(`/settings/invoice/`, {
            method: 'GET'
        }).then(response => response.json());
    },

    setInvoiceSettings(invoiceSettings: InvoiceSettings): Promise<Response> {
        const filteredSettings = {
            defaultVat: invoiceSettings.defaultVat,
            connectifInfo: invoiceSettings.connectifInfo,
            invoiceFooter: invoiceSettings.invoiceFooter,
            invoiceSendByEmailBcc: invoiceSettings.invoiceSendByEmailBcc
        } as InvoiceSettings;
        return RequestService.cnFetch(`/settings/invoice/`, {
            method: 'POST',
            body: JSON.stringify(filteredSettings)
        });
    },

    restoreInvoiceToDraft(invoiceId: string): Promise<Response> {
        return RequestService.cnFetch(
            `/invoice/${invoiceId}/restore-to-draft`,
            {
                method: 'POST',
                body: JSON.stringify({})
            }
        );
    },

    downloadInvoice(invoiceId: string): Promise<Response> {
        return RequestService.cnFetch(`/invoice/${invoiceId}/pdf`);
    },

    exportInvoiceList(listFilters: InvoiceListFilters): Promise<Response> {
        const filters: any = {
            limit: listFilters.rowsPerPage,
            page: listFilters.page + 1,
            paymentStatus: listFilters.paymentStatus,
            invoiceStatus: listFilters.invoiceStatus,
            paymentMethod: listFilters.paymentMethod,
            search: listFilters.search,
            customerUUID: listFilters.customerUUID,
            subscriptionId: listFilters.subscriptionId,
            invoiceNumber: listFilters.invoiceNumber
        };

        if (listFilters.sortBy) {
            filters.sortKey = listFilters.sortBy;
        }

        if (listFilters.direction) {
            filters.sortDirection = listFilters.direction === 'asc' ? 1 : -1;
        }

        if (listFilters.fromDate) {
            filters.fromDate = moment(listFilters.fromDate)
                .startOf('day')
                .toDate();
        }

        if (listFilters.toDate) {
            filters.toDate = moment(listFilters.toDate).endOf('day').toDate();
        }

        if (listFilters.emitFromDate) {
            filters.emitFromDate = moment(listFilters.emitFromDate)
                .startOf('day')
                .toDate();
        }

        if (listFilters.emitToDate) {
            filters.emitToDate = moment(listFilters.emitToDate)
                .endOf('day')
                .toDate();
        }

        return RequestService.cnFetch('/invoice/export', undefined, filters);
    },

    async chargeAndEmitInvoice(invoiceId?: string): Promise<void> {
        await RequestService.cnFetch(`/invoice/${invoiceId}/charge-emit`, {
            method: 'POST'
        });
    },

    async chargeReceipt(invoiceId?: string): Promise<void> {
        await RequestService.cnFetch(
            `/invoice/${invoiceId}/retry-pending-receipt`,
            {
                method: 'POST'
            }
        );
    }
};
export default InvoiceService;
