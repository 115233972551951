import React, { ReactElement } from 'react';
import { Report } from '../../../models/report/Report';
import { Paper, Grid, Divider, Button } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import ConnectifLogo from '../../../resources/img/logo-with-text-right.svg';
import Moment from 'react-moment';
import CurrencyFormat from '../../../components/CurrencyFormat';
import ReportItems from './ReportItems';
import moment from 'moment';
import { PropsWithShowToast } from '../../../PropsWithShowToast';

type Props = PropsWithShowToast<{
    report: Report;
    viewMode: boolean;
    onUpdateReport?: (report: Report) => void;
    saveReport?: () => Promise<void>;
}>;

const headerStyles: CSSProperties = {
    margin: '20px 0px',
    lineHeight: '35px',
    display: 'inline-block'
};

export default function ReportEditor({
    report,
    viewMode,
    onUpdateReport,
    saveReport,
    showToast
}: Props): ReactElement {
    const totalAmount = viewMode
        ? report.totalAmount
        : report.items.reduce((amount, item) => amount + item.amount, 0);
    const isValid = report && report.items.length >= 1;
    return (
        <Paper className='cn-paper'>
            <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={5} sm={5}>
                        <img
                            style={headerStyles}
                            src={ConnectifLogo}
                            className='App-logo'
                            alt='logo'
                        />
                    </Grid>
                    <Grid item xs={7} sm={7}>
                        <h2 style={{ ...headerStyles, float: 'right' }}>
                            INFORME DE COMISIONES
                        </h2>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider
                            style={{ height: '5px', backgroundColor: 'black' }}
                            variant='fullWidth'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <p
                            style={{
                                fontSize: '18px',
                                marginTop: '20px',
                                marginBottom: '0px'
                            }}
                        >
                            <b>DETALLE DE ACTIVIDADES COMISIONABLES:</b>
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        Desde{' '}
                        <Moment
                            locale='es'
                            date={report.reportFromDate}
                            format='DD MMMM YYYY'
                        />
                        , hasta{' '}
                        <Moment
                            locale='es'
                            date={moment(report.reportToDate)
                                .add(-1, 'day')
                                .toDate()}
                            format='DD MMMM YYYY'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <ReportItems
                            items={report.items}
                            partnerId={report.partnerId}
                            isEditable={!viewMode}
                            onUpdateItems={items =>
                                onUpdateReport?.({ ...report, items })
                            }
                            showToast={showToast}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <p style={{ textAlign: 'right' }}>
                            Total: <CurrencyFormat value={totalAmount} />
                        </p>
                    </Grid>
                    {!viewMode && (
                        <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            justifyContent={'flex-end'}
                            style={{ marginTop: '40px' }}
                        >
                            <Button
                                onClick={saveReport}
                                variant='contained'
                                color='primary'
                                disabled={!isValid}
                            >
                                Save
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
}
