import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import PartnerService from '../../../services/PartnerService';
import Partner from '../../../models/partner/Partner';
import { RouterMatch } from '../../../models/RouterMatch';
import { Redirect } from 'react-router';
import { ToastVariant } from '../../../reducers/Snackbar';
import BackButton from '../../../components/BackButton';
import PageHeader from '../../../components/PageHeader';
import PartnerEditor from './components/PartnerEditor';
import { DuplicateKeyError } from '../../../models/errors/DuplicateKeyError';
import { PartnerInfo } from '../../../models/partner/PartnerInfo';
import { Tab, Paper } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import PartnerCustomers from './components/PartnerCustomers';
import PartnerReports from './components/PartnerReports';
import ReportService from '../../../services/ReportService';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    selectedTab: 'profile' | 'customers' | 'reports';
    partner?: Partner;
    isNewPartner: boolean;
    existPartner: boolean;
    redirectToPartnerId?: string;
    redirectToReportId?: string;
}
const tabPanelStyle = { padding: '0' };

class PartnerEdit extends Component<Props, State> {
    private partnerId = '';

    constructor(props: Props) {
        super(props);
        if (props.match.params.id) {
            this.state = {
                selectedTab: 'profile',
                isLoading: true,
                existPartner: true,
                isNewPartner: false
            };
            this.partnerId = props.match.params.id;
            this.loadPartner(this.partnerId);
        } else {
            this.state = {
                selectedTab: 'profile',
                isLoading: false,
                existPartner: true,
                isNewPartner: true,
                partner: {
                    partnerInfo: {
                        company: '',
                        address: {
                            street: '',
                            zip: '',
                            city: '',
                            state: '',
                            country: ''
                        },
                        phone: '',
                        cif: '',
                        contactEmail: ''
                    },
                    type: 'agency',
                    terms: [],
                    invoicePeriodicity: 'quarterly'
                }
            };
        }
    }

    loadPartner = async (partnerId: string): Promise<void> => {
        try {
            const partner = await PartnerService.getPartner(partnerId);
            this.setState({ isLoading: false, partner });
        } catch (err) {
            this.setState({ isLoading: false, existPartner: false }, () => {
                this.props.showToast('error', 'Partner not found');
            });
        }
    };

    onUpdatePartner = (partner: Partner): void => {
        if (!this.state.partner) {
            return;
        }
        this.setState({ partner });
    };

    onDeleteTerm = async (
        partner: Partner,
        termUuid: string
    ): Promise<void> => {
        try {
            await PartnerService.deleteTerm(partner._id as string, termUuid);
            await this.loadPartner(partner._id as string);
            this.props.showToast('success', 'Term has been deleted');
        } catch (err) {
            this.props.showToast(
                'error',
                `Error deleting term. ${err.message}`
            );
        }
    };

    onUpdatePartnerInfo = (partnerInfo: PartnerInfo): void => {
        if (!this.state.partner) {
            return;
        }
        this.setState({ partner: { ...this.state.partner, partnerInfo } });
    };

    generateReport = async (year: number, month: number): Promise<void> => {
        if (!this.state.partner?._id) {
            return;
        }

        try {
            const report = await ReportService.generate({
                partnerId: this.state.partner._id,
                year,
                month
            });
            this.setState({ redirectToReportId: report._id });
            this.props.showToast(
                'success',
                `Report created for the period: ${month}-${year}.`
            );
        } catch (error) {
            this.props.showToast('error', error.message);
        }
    };

    savePartner = async (): Promise<void> => {
        if (!this.state.partner) {
            return;
        }

        try {
            if (this.state.isNewPartner) {
                const partner = await PartnerService.createPartner(
                    this.state.partner
                );
                this.setState({
                    partner,
                    existPartner: true,
                    redirectToPartnerId: partner._id,
                    isNewPartner: false
                });
            } else {
                await PartnerService.editPartner(this.state.partner);
            }
            this.props.showToast('success', 'Partner saved successfully');
        } catch (err) {
            this.setState({ isLoading: false }, () => {
                if (err instanceof DuplicateKeyError) {
                    this.props.showToast(
                        'error',
                        'Duplicate key error, unique Tax ID.'
                    );
                } else {
                    this.props.showToast(
                        'error',
                        'Error saving partner\n' + err.message
                    );
                }
            });
        }
    };

    render(): ReactElement {
        return (
            <div className='partner-edit-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            {!this.state.isNewPartner && (
                                <PageHeader>Edit partner</PageHeader>
                            )}
                            {this.state.isNewPartner && (
                                <PageHeader>Create partner</PageHeader>
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            <BackButton href='/partner/list'>
                                Back to Partner list
                            </BackButton>
                        </Grid>
                    </Grid>
                    {this.state.partner && (
                        <TabContext value={this.state.selectedTab}>
                            <Paper className={'cn-tabs'}>
                                <TabList
                                    indicatorColor='primary'
                                    textColor='primary'
                                    onChange={(e, selectedTab) =>
                                        this.setState({ selectedTab })
                                    }
                                >
                                    <Tab label='Profile' value='profile' />
                                    <Tab label='Customers' value='customers' />
                                    <Tab label='Reports' value='reports' />
                                </TabList>
                            </Paper>
                            <TabPanel value='profile' style={tabPanelStyle}>
                                <PartnerEditor
                                    showToast={this.props.showToast}
                                    partner={this.state.partner}
                                    onUpdatePartner={this.onUpdatePartner}
                                    onDeleteTerm={this.onDeleteTerm}
                                    savePartner={this.savePartner}
                                />
                            </TabPanel>
                            <TabPanel value='customers' style={tabPanelStyle}>
                                <PartnerCustomers
                                    partner={this.state.partner}
                                />
                            </TabPanel>
                            <TabPanel value='reports' style={tabPanelStyle}>
                                <PartnerReports
                                    showToast={this.props.showToast}
                                    partner={this.state.partner}
                                    onGenerateReport={this.generateReport}
                                />
                            </TabPanel>
                        </TabContext>
                    )}
                </Container>
                {this.state.redirectToPartnerId && (
                    <Redirect
                        to={`/partner/${this.state.redirectToPartnerId}/edit`}
                    />
                )}
                {this.state.redirectToReportId && (
                    <Redirect
                        to={`/report/${this.state.redirectToReportId}/backTo/partner`}
                    />
                )}
                {!this.state.existPartner && !this.state.isNewPartner && (
                    <div>
                        <Redirect to='/partner/list' />
                    </div>
                )}
            </div>
        );
    }
}
export default PartnerEdit;
