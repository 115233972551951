import React from 'react';
import { Link } from 'react-router-dom';
import { CustomerDeletedOperationLog } from '../../../../../models/system/CustomerDeletedOperationLog';

const CustomerDeleted = (operationLog: CustomerDeletedOperationLog) => (
    <div>
        <p>
            Account name:{' '}
            <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                {operationLog.customerName}
            </Link>{' '}
        </p>
    </div>
);

export default CustomerDeleted;
