import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import ListItems from './components/list-items/ListItems';
import PlanService from '../../../services/PlanService';
import { ToastVariant } from '../../../reducers/Snackbar';
import Plan from '../../../models/plan/Plan';
import PageHeader from '../../../components/PageHeader';
import { Button } from '@mui/material';
import GenericListFilters from '../../../models/GenericListFilters';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

interface Props {
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    filters: GenericListFilters;
}

class PlanList extends Component<Props, State> {
    private plans: Plan[] = [];
    private totalPlans = 0;
    private planFiltersCookieName = 'planListFilters';

    constructor(props: Props) {
        super(props);
        const planFiltersCookie = Cookies.get(this.planFiltersCookieName);
        const filters: GenericListFilters = planFiltersCookie
            ? JSON.parse(planFiltersCookie)
            : {
                  rowsPerPage: 10,
                  page: 0,
                  search: '',
                  sortBy: 'name',
                  direction: 'desc'
              };
        this.state = {
            isLoading: true,
            filters
        };
    }

    componentDidMount() {
        this.loadPlans(this.state.filters);
    }

    loadPlans = async (filters: GenericListFilters) => {
        let planList: { documents: Plan[]; totalQuantity: number } = {
            documents: [],
            totalQuantity: 0
        };
        try {
            planList = await PlanService.getPlanList(filters);
        } catch {
            this.props.showToast('error', 'Error on get plan list');
        }
        this.plans = planList.documents;
        this.totalPlans = planList.totalQuantity;
        this.setState({ ...this.state, isLoading: false, filters });
    };

    onSortByChange = (fieldId: string, direction?: 'asc' | 'desc') => {
        const stateCopy = { ...this.state };
        stateCopy.isLoading = true;
        stateCopy.filters.sortBy = fieldId;
        stateCopy.filters.direction = direction;
        this.setState(stateCopy);
        this.loadPlans(stateCopy.filters);
    };

    onFilterChange = (filterId: keyof GenericListFilters, filterValue: any) => {
        this.setState(
            {
                ...this.state,
                filters: {
                    ...this.state.filters,
                    [filterId]: filterValue,
                    page: 0
                },
                isLoading: true
            },
            () => {
                this.loadPlans(this.state.filters);
            }
        );
    };

    onPaginationChange = (page: number, rowsPerPage: number) => {
        const stateCopy = { ...this.state };
        stateCopy.filters.page = page;
        stateCopy.filters.rowsPerPage = rowsPerPage;
        stateCopy.isLoading = true;
        this.setState(stateCopy);
        this.loadPlans(stateCopy.filters);
    };

    saveFilters = () => {
        Cookies.set(
            this.planFiltersCookieName,
            JSON.stringify(this.state.filters)
        );
    };

    render(): ReactElement {
        this.saveFilters();
        return (
            <div className='plan-list-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>Plan list</PageHeader>
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                component={Link}
                                to='/plan/create'
                                variant='contained'
                                color='primary'
                            >
                                Add new plan
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className='cn-paper'>
                            <ListItems
                                rowsTotal={this.totalPlans}
                                rows={this.plans}
                                filters={this.state.filters}
                                onPaginationChange={this.onPaginationChange}
                                onSortByChange={this.onSortByChange}
                                onFilterChange={this.onFilterChange}
                            ></ListItems>
                        </Paper>
                    </Grid>
                </Container>
            </div>
        );
    }
}
export default PlanList;
