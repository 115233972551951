import RequestService from './RequestService';
import { PlanSettings } from '../models/plan/PlanSettings';
import Plan from '../models/plan/Plan';
import GenericListFilters from '../models/GenericListFilters';
import { CustomerDiscount } from '../models/customer/CustomerDiscount';
import PlanSummary from '../models/plan/PlanSummary';
import Big from 'big.js';
import moment from 'moment';
import { CustomerAnalyticsTier } from '../models/customer/CustomerAnalyticsTier';

const PlanService = {
    getPlanList(
        listFilters: GenericListFilters
    ): Promise<{ documents: Plan[]; totalQuantity: number }> {
        const filters: any = {
            limit: listFilters.rowsPerPage,
            page: listFilters.page + 1,
            search: listFilters.search
        };

        if (listFilters.sortBy) {
            filters.sortKey = listFilters.sortBy;
        }

        if (listFilters.direction) {
            filters.sortDirection = listFilters.direction === 'asc' ? 1 : -1;
        }

        return RequestService.cnFetch(
            '/plan',
            {
                method: 'GET'
            },
            filters
        ).then(async response => {
            const respObject: {
                documents: Plan[];
                totalQuantity: number;
            } = await response.json();
            return respObject;
        });
    },

    getPlan(planId: string): Promise<Plan> {
        return RequestService.cnFetch(`/plan/${planId}`, {
            method: 'GET'
        }).then(response => response.json());
    },

    editPlan(plan: Plan, planId: string): Promise<Response> {
        // TODO: Review this
        const filteredPlan = {
            price: +plan.price,
            maxActivitiesHardLimit: +plan.maxActivitiesHardLimit,
            maxEmailsHardLimit: +plan.maxEmailsHardLimit
        } as Plan;
        return RequestService.cnFetch(`/plan/${planId}/edit`, {
            method: 'POST',
            body: JSON.stringify(filteredPlan)
        });
    },

    createPlan(plan: Plan): Promise<Response> {
        const filteredPlan = {
            name: plan.name,
            price: +plan.price,
            maxActivitiesHardLimit: +plan.maxActivitiesHardLimit,
            maxActivitiesSoftLimit: +plan.maxActivitiesSoftLimit,
            maxEmailsHardLimit: +plan.maxEmailsHardLimit,
            maxEmailsSoftLimit: +plan.maxEmailsSoftLimit
        } as Plan;
        return RequestService.cnFetch(`/plan/`, {
            method: 'POST',
            body: JSON.stringify(filteredPlan)
        });
    },

    getPlanSettings(): Promise<PlanSettings> {
        return RequestService.cnFetch(`/settings/plan/`, {
            method: 'GET'
        }).then(response => response.json());
    },

    setPlanSettings(planSettings: PlanSettings): Promise<Response> {
        return RequestService.cnFetch(`/settings/plan/`, {
            method: 'POST',
            body: JSON.stringify(planSettings)
        });
    },

    isDiscountApplicable(discount?: CustomerDiscount): boolean {
        if (
            discount &&
            !isNaN(discount.amount) &&
            discount.isActive &&
            (!discount.validUpTo ||
                moment(discount.validUpTo).isAfter(new Date(), 'day'))
        ) {
            return true;
        }
        return false;
    },

    getPlanPrice(planSummary: PlanSummary): {
        planPrice: number;
        analyticsTierPrice: number;
    } {
        let planPrice =
            planSummary.defaultCost !== undefined ? planSummary.defaultCost : 0;
        if (
            planSummary.fixedPrice &&
            this.isDiscountApplicable(planSummary.fixedPrice)
        ) {
            planPrice = planSummary.fixedPrice.amount;
        }
        const planPriceBeforeDiscounts = planPrice;

        if (
            planSummary.hiddenDiscount &&
            this.isDiscountApplicable(planSummary.hiddenDiscount)
        ) {
            if (!planSummary.hiddenDiscount.isPercentage) {
                planPrice = +Big(planPrice).minus(
                    planSummary.hiddenDiscount.amount
                );
            } else {
                planPrice = +Big(planPrice).minus(
                    +Big(planSummary.hiddenDiscount.amount)
                        .times(planPrice)
                        .div(100)
                        .toFixed(2)
                );
            }
        }

        let analyticsTierPrice = 0;
        if (
            !this.isDiscountApplicable(planSummary.fixedPrice) &&
            planPrice > 0 &&
            planSummary.analyticsTier
        ) {
            analyticsTierPrice = this.getAnalyticsTierPrice(
                planPriceBeforeDiscounts,
                planSummary.analyticsTier
            );
            planPrice = +Big(planPrice).plus(analyticsTierPrice).toFixed(2);
        }

        if (
            planSummary.visibleDiscount &&
            this.isDiscountApplicable(planSummary.visibleDiscount)
        ) {
            if (!planSummary.visibleDiscount.isPercentage) {
                planPrice = +Big(planPrice).minus(
                    planSummary.visibleDiscount.amount
                );
            } else {
                planPrice = +Big(planPrice).minus(
                    +Big(planSummary.visibleDiscount.amount)
                        .times(planPrice)
                        .div(100)
                        .toFixed(2)
                );
            }
        }

        return { planPrice, analyticsTierPrice };
    },
    getAnalyticsTierPrice(
        planPrice: number,
        analyticsTier: CustomerAnalyticsTier
    ): number {
        switch (analyticsTier) {
            case 'restricted-free':
            case 'standard-free':
            case 'premium-50-free':
                return 0;
            case 'standard':
                if (planPrice <= 0) {
                    return 0;
                }
                if (planPrice <= 300) {
                    return 32;
                }
                if (planPrice <= 1000) {
                    return Math.floor((planPrice + 99.99) / 100) * 10;
                }
                return Math.floor((planPrice + 199.99) / 200) * 20;
            case 'premium-50':
                if (planPrice <= 0) {
                    return 0;
                }
                if (planPrice <= 300) {
                    return 64;
                }
                if (planPrice <= 1000) {
                    return Math.floor((planPrice + 99.99) / 100) * 20;
                }
                return Math.floor((planPrice + 199.99) / 200) * 40;
        }
    },

    getPlanPriceWithVat(planPrice: number, vat: number): number {
        return +Big(planPrice).plus(
            +Big(planPrice).times(vat).div(100).toFixed(2)
        );
    }
};
export default PlanService;
