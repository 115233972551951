import React, { Fragment, useState, ReactElement } from 'react';
import { ReportItem } from '../../../models/report/ReportItem';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import Moment from 'react-moment';
import Action from '../../../components/Action';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import EditReportItemDialog from './EditReportItemDialog';
import { PropsWithShowToast } from '../../../PropsWithShowToast';
import CurrencyFormat from '../../../components/CurrencyFormat';

type Props = PropsWithShowToast<{
    items: ReportItem[];
    partnerId: string;
    isEditable: boolean;
    onUpdateItems: (reportItems: ReportItem[]) => void;
}>;

ReportItems.defaultProps = {
    isEditable: false
};

export default function ReportItems({
    items,
    partnerId,
    isEditable,
    onUpdateItems,
    showToast
}: Props): ReactElement {
    const [editingReportItem, setEditingReportItem] = useState<ReportItem>();
    const [editingReportIndex, setEditingReportIndex] = useState<number>();
    return (
        <Fragment>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Cuenta</TableCell>
                        <TableCell>Plan</TableCell>
                        <TableCell>Inicio relación</TableCell>
                        <TableCell align='right'>Base comisionable</TableCell>
                        <TableCell align='right'>Porcentaje</TableCell>
                        <TableCell align='right'>Total €</TableCell>
                        {isEditable && <TableCell align='right'></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {item.customerName || item.customerEmail}
                            </TableCell>
                            <TableCell>{item.planName}</TableCell>
                            <TableCell>
                                <Moment
                                    date={item.startDecayDate}
                                    format='DD/MM/YYYY'
                                />
                            </TableCell>
                            <TableCell align='right'>
                                <CurrencyFormat
                                    value={
                                        item.totalInvoiceCommissionableAmount
                                    }
                                />
                            </TableCell>
                            <TableCell align='right'>
                                {item.percentage !== undefined && (
                                    <NumericFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={item.percentage}
                                        displayType={'text'}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        suffix={'%'}
                                    />
                                )}
                            </TableCell>
                            <TableCell align='right'>
                                <CurrencyFormat value={item.amount} />
                            </TableCell>
                            {isEditable && (
                                <TableCell align='right'>
                                    <div className='actions'>
                                        <Action
                                            label='Edit'
                                            icon={EditIcon}
                                            onClick={() => {
                                                setEditingReportItem(item);
                                                setEditingReportIndex(index);
                                            }}
                                        />
                                        <Action
                                            label='Delete'
                                            icon={CancelIcon}
                                            onClick={() =>
                                                onUpdateItems([
                                                    ...items.slice(0, index),
                                                    ...items.slice(index + 1)
                                                ])
                                            }
                                        />
                                    </div>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {isEditable && (
                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                    <Button
                        onClick={() =>
                            setEditingReportItem({
                                customerName: '',
                                customerEmail: '',
                                planName: '',
                                totalInvoiceCommissionableAmount: 1,
                                amount: 1,
                                startDecayDate: new Date(),
                                customerUuid: '',
                                termUuid: ''
                            })
                        }
                        variant='contained'
                        color='primary'
                    >
                        Add
                    </Button>
                </div>
            )}
            {editingReportItem && (
                <EditReportItemDialog
                    open={true}
                    isEdit={editingReportIndex !== undefined}
                    reportItem={editingReportItem}
                    partnerId={partnerId}
                    onClose={() => {
                        setEditingReportItem(undefined);
                        setEditingReportIndex(undefined);
                    }}
                    onSaveItem={item => {
                        setEditingReportItem(undefined);
                        setEditingReportIndex(undefined);
                        const i = editingReportIndex ?? items.length;
                        onUpdateItems([
                            ...items.slice(0, i),
                            item,
                            ...items.slice(i + 1)
                        ]);
                    }}
                    showToast={showToast}
                />
            )}
        </Fragment>
    );
}
