import React, { useState, useEffect, Fragment, ReactElement } from 'react';
import { PagedList } from '../../../../models/PagedList';
import ReportService from '../../../../services/ReportService';
import Spinner from '../../../../components/Spinner';
import ReportListItems from './ReportListItems';
import ReportListFilters from '../../../../models/ReportListFilters';
import { ReportListItem } from '../../../../models/report/ReportListItem';
import { PropsWithShowToast } from '../../../../PropsWithShowToast';

type Props = PropsWithShowToast<{
    partnerId: string;
}>;

export default function PartnerReportList({
    partnerId,
    showToast
}: Props): ReactElement {
    const [isLoading, setLoading] = useState(false);
    const [reports, setReports] = useState<PagedList<ReportListItem>>({
        totalQuantity: 0,
        documents: []
    });
    const [filters, setFilters] = useState<ReportListFilters>({
        rowsPerPage: 10,
        page: 0,
        search: '',
        partnerId,
        sortBy: 'createdAt',
        direction: 'desc'
    });

    const refresh = () => {
        setLoading(true);
        ReportService.getReportList(filters)
            .then(setReports)
            .finally(() => setLoading(false));
    };

    useEffect(refresh, [filters]);

    const onSortByChange = (sortBy: string, direction?: 'asc' | 'desc') => {
        setFilters({ ...filters, sortBy, direction });
    };

    const onFilterChange = (
        filterId: keyof ReportListFilters,
        filterValue: any
    ) => {
        setFilters({ ...filters, [filterId]: filterValue });
    };

    const onPaginationChange = (page: number, rowsPerPage: number) => {
        setFilters({ ...filters, page, rowsPerPage });
    };

    const deleteReport = async (reportId: string) => {
        await ReportService.delete(reportId);
        showToast('success', 'Report deleted');
        refresh();
    };

    return (
        <Fragment>
            <Spinner visible={isLoading} />
            <ReportListItems
                rowsTotal={reports.totalQuantity}
                rows={reports.documents}
                filters={filters}
                backTo={'partner'}
                showPartner={false}
                showToast={showToast}
                onPaginationChange={onPaginationChange}
                onSortByChange={onSortByChange}
                onFilterChange={onFilterChange}
                onDelete={report => deleteReport(report._id)}
                refreshReports={refresh}
            />
        </Fragment>
    );
}
