import React, { useState, ReactElement } from 'react';
import { PartnerTerm } from '../../../../models/partner/PartnerTerm';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid
} from '@mui/material';
import CommissionRangeList from './CommissionRangeList';
import CommissionDecayList from './CommissionDecayList';
import { CommissionRange } from '../../../../models/partner/CommissionRange';
import { CommissionDecay } from '../../../../models/partner/CommissionDecay';

type Props = {
    open: boolean;
    term: PartnerTerm;
    onClose: () => void;
    onUpdateTerm: (term: PartnerTerm) => void;
};

export default function EditPartnerTermDialog({
    open,
    term,
    onClose,
    onUpdateTerm
}: Props): ReactElement {
    const [editingTerm, setEditingTerm] = useState(term);

    const isValidCommissionRange = (r: CommissionRange) =>
        r.amountFrom >= 0 &&
        r.amount >= 0 &&
        (!r.isPercentage || r.amount <= 100);
    const isValidCommissionDecay = (d: CommissionDecay) =>
        d.decayStartMonth >= 1 &&
        d.decayPercentage >= -200 &&
        d.decayPercentage <= 100;
    const isValid =
        editingTerm.description &&
        editingTerm.commissionRanges.every(
            (r, i) =>
                isValidCommissionRange(r) &&
                (i === 0 ||
                    r.amountFrom >
                        editingTerm.commissionRanges[i - 1].amountFrom)
        ) &&
        editingTerm.commissionDecay.every(
            (d, i) =>
                isValidCommissionDecay(d) &&
                (i === 0 ||
                    d.decayStartMonth >
                        editingTerm.commissionDecay[i - 1].decayStartMonth)
        );

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Partner Term</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item sm={12}>
                        <TextField
                            id='description'
                            label='Description'
                            style={{ width: '100% ' }}
                            required={true}
                            error={!editingTerm.description}
                            value={editingTerm.description}
                            onChange={event =>
                                setEditingTerm({
                                    ...editingTerm,
                                    description: event.target.value
                                })
                            }
                        />
                    </Grid>
                    <h4>Commission Ranges</h4>
                    <CommissionRangeList
                        commissionRanges={editingTerm.commissionRanges}
                        onUpdate={commissionRanges =>
                            setEditingTerm({ ...editingTerm, commissionRanges })
                        }
                    />
                    <h4>Commission Decay</h4>
                    <CommissionDecayList
                        commissionDecay={editingTerm.commissionDecay}
                        onUpdate={commissionDecay =>
                            setEditingTerm({ ...editingTerm, commissionDecay })
                        }
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    onClick={() => onUpdateTerm(editingTerm)}
                    disabled={!isValid}
                    color='primary'
                >
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
}
