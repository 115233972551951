import React, { CSSProperties, ReactElement, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    title: string;
    style?: CSSProperties;
}>;

export default function SummaryHeader(props: Props): ReactElement {
    return (
        <div className='summary-header' style={props.style}>
            <p>{props.title}</p>
            <span>{props.children}</span>
        </div>
    );
}
