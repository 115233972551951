import React, { CSSProperties, ReactElement } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { HeadRow } from '../models/list-filters/HeadRow';

export type SortDirection = 'desc' | 'asc';

type Props = {
    headRows: HeadRow[];
    sortBy?: string;
    direction?: 'asc' | 'desc';
    onSortByChange: (sortKey: string, direction: 'asc' | 'desc') => void;
};

const columnsStyles: CSSProperties = {
    whiteSpace: 'nowrap',
    verticalAlign: 'text-top',
    paddingTop: '12px'
};

const multipleHeadStyle: CSSProperties = {
    width: '100%',
    marginTop: '6px'
};

const sortStyles: CSSProperties = {
    display: 'block'
};

export default function SortableTableHead({
    headRows,
    sortBy,
    direction,
    onSortByChange
}: Props): ReactElement {
    const handleSortBy = (fieldId: string, subFieldId?: string) => {
        const sortKey = subFieldId ?? fieldId;
        const newDirection =
            sortBy === sortKey && direction === 'asc' ? 'desc' : 'asc';
        onSortByChange(sortKey, newDirection);
    };

    const SortLabel = ({ row }: { row: HeadRow }) => (
        <TableSortLabel
            style={sortStyles}
            active={row.id === sortBy}
            direction={direction}
            onClick={() => handleSortBy(row.id)}
        >
            {row.label}
        </TableSortLabel>
    );

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => {
                    if (row.sortable) {
                        return (
                            <TableCell
                                key={row.id}
                                sortDirection={direction ?? false}
                                style={columnsStyles}
                            >
                                {row.subHead &&
                                    row.subHead.map((head, i) =>
                                        head.sortable ? (
                                            <SortLabel key={i} row={head} />
                                        ) : (
                                            <div
                                                key={head.id}
                                                style={multipleHeadStyle}
                                            >
                                                {head.label}
                                            </div>
                                        )
                                    )}

                                {!row.subHead && <SortLabel row={row} />}
                            </TableCell>
                        );
                    } else {
                        return (
                            <TableCell
                                key={row.id}
                                style={columnsStyles}
                                className={row.id}
                            >
                                {row.subHead &&
                                    row.subHead.map(head => (
                                        <div
                                            key={head.id}
                                            style={multipleHeadStyle}
                                        >
                                            {head.label}
                                        </div>
                                    ))}
                                {!row.subHead && (
                                    <div style={multipleHeadStyle}>
                                        {row.label}
                                    </div>
                                )}
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        </TableHead>
    );
}
