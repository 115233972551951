import React from 'react';
import { Link } from 'react-router-dom';
import { InvoiceChargedOperationLog } from '../../../../../models/system/InvoiceChargedOperationLog';
import InvoiceLinkActiveView from './InvoiceLinkActiveView';

const InvoiceCharged = (operationLog: InvoiceChargedOperationLog) => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerAccountEmail}
                </Link>
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>
            </p>
        )}
        <InvoiceLinkActiveView
            invoiceId={operationLog.invoiceId}
            invoiceTotalAmount={operationLog.invoiceTotalAmount}
            invoiceLinkActive={operationLog.invoiceLinkActive}
        ></InvoiceLinkActiveView>
        {operationLog.stripePaymentIntentId && (
            <div>
                <p>
                    Intent:{' '}
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={
                            'https://dashboard.stripe.com/payments/' +
                            operationLog.stripePaymentIntentId
                        }
                    >
                        {operationLog.stripePaymentIntentId}
                    </a>
                </p>
                <p>
                    Paid as:{' '}
                    {operationLog.offSession ? 'off-session' : 'on-session'}
                </p>
            </div>
        )}
    </div>
);

export default InvoiceCharged;
