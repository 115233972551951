import { InputLabel } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { ReactElement } from 'react';
import { CSSProperties } from '@mui/styles/withStyles';
import { InvoicePeriodicity } from '../../../../models/partner/InvoicePeriodicity';

type Props = {
    invoicePeriodicity?: InvoicePeriodicity;
    onChange: (type: InvoicePeriodicity) => void;
    style?: CSSProperties;
};

export default function InvoicePeriodicitySelector({
    invoicePeriodicity,
    onChange,
    style
}: Props): ReactElement {
    return (
        <div style={style}>
            <InputLabel style={{ transform: 'scale(0.75)' }}>
                Invoice periodicity
            </InputLabel>
            <ToggleButtonGroup
                size='small'
                value={invoicePeriodicity}
                exclusive
                onChange={(e, value) => onChange(value)}
            >
                <ToggleButton value='monthly'>Monthly</ToggleButton>
                <ToggleButton value='quarterly'>Quarterly</ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
}
