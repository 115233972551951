import React, { useEffect, ReactElement } from 'react';
import PlanSummary from '../../../../../models/plan/PlanSummary';
import CustomerPlanSummary from '../CustomerPlanSummary';
import { InvoiceSettings } from '../../../../../models/invoice/InvoiceSettings';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    IconButton,
    Collapse,
    Chip,
    TextField
} from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { Subscription } from '../../../../../models/subscription/Subscription';
import clsx from 'clsx';
import './CustomerPlanInfo.css';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InvoiceService from '../../../../../services/InvoiceService';
import Invoice from '../../../../../models/invoice/Invoice';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { defaultTimezone } from '../../../../../Config';
import StartFinishDateCell from './StartFinishDateCell/StartFinishDateCell';
import { CustomerBillingInfo } from '../../../../../models/customer/CustomerBillingInfo';
import PlanService from '../../../../../services/PlanService';
import { Moment } from 'moment';

interface Props {
    currentPlan: PlanSummary;
    invoiceSettings?: InvoiceSettings;
    customerSubscriptions: Subscription[];
    subscriptionsFromDate: Date;
    onChangeSubscriptionFromDate: (fromDate: Date) => void;
    customerTimeZone?: string;
    customerBillingInfo: CustomerBillingInfo;
}

const chipStyles: CSSProperties = {
    height: '20px',
    marginLeft: '10px',
    fontSize: '12px'
};

const subscriptionListStyles: CSSProperties = {
    border: '1px solid rgba(224, 224, 224, 1)',
    marginBottom: '25px'
};

const actionButtonStyles: CSSProperties = {
    display: 'inline-block'
};

interface State {
    visibleSubscription?: PlanSummary;
    visibleSubscriptionId?: string;
    arePlanCostsCollapsed?: boolean;
    invoices?: Invoice[];
}

export default function CustomerPlanInfo(props: Props): ReactElement {
    const activeSubscription = props.customerSubscriptions.find(
        subscription => subscription.isActive
    );
    const defaultVisibleSubscription = activeSubscription
        ? activeSubscription
        : props.customerSubscriptions[0];
    const [state, setState] = React.useState<State>({
        arePlanCostsCollapsed: true
    });

    const getSubscriptionPlanSummary = async (
        subscriptionId: string
    ): Promise<PlanSummary> => {
        const subscription = props.customerSubscriptions.find(
            subscription => subscription._id === subscriptionId
        );

        const planSummary = {} as PlanSummary;
        if (subscription) {
            planSummary.defaultCost = subscription.planPrice;
            planSummary.paymentMethod = subscription.paymentMethod;
            planSummary.renovationDate =
                subscription.finishedAt || subscription.scheduledFinishDate;
            planSummary.selectedPlanName = subscription.planName;
            planSummary.startDate =
                subscription.startedAt || subscription.scheduledStartDate;

            if (subscription.customerBillingInfo) {
                planSummary.fixedPrice =
                    subscription.customerBillingInfo.fixedPrice;
                planSummary.hiddenDiscount =
                    subscription.customerBillingInfo.hiddenDiscount;
                planSummary.visibleDiscount =
                    subscription.customerBillingInfo.visibleDiscount;
                planSummary.vat = subscription.customerBillingInfo.vat;
                planSummary.extraItems =
                    subscription.customerBillingInfo.extraItems;
            }
            if (subscription.activitiesInfo) {
                planSummary.maxActivitiesHardLimit =
                    subscription.activitiesInfo.maxActivitiesHardLimit;
                planSummary.maxEmailsHardLimit =
                    subscription.activitiesInfo.maxEmailsHardLimit;
            }

            if (subscription.status === 'pending' && !subscription.startedAt) {
                const scheduledPlan = await PlanService.getPlan(
                    subscription.planId
                );
                planSummary.defaultCost = scheduledPlan.price;
                planSummary.fixedPrice = props.customerBillingInfo.fixedPrice;
                planSummary.hiddenDiscount =
                    props.customerBillingInfo.hiddenDiscount;
                planSummary.visibleDiscount =
                    props.customerBillingInfo.visibleDiscount;
            }
            planSummary.analyticsTier =
                subscription.customerBillingInfo?.analyticsTier ||
                props.customerBillingInfo.analyticsTier;
        }
        return planSummary;
    };

    const onClickViewSubscription = async (
        subscriptionId: string
    ): Promise<void> => {
        if (subscriptionId === state.visibleSubscriptionId) {
            setState({ ...state, visibleSubscriptionId: undefined });
            return;
        }
        const visibleSubscription =
            await getSubscriptionPlanSummary(subscriptionId);
        const invoiceList = await InvoiceService.getInvoiceList({
            subscriptionId,
            rowsPerPage: 50,
            page: 0
        });
        setState({
            ...state,
            visibleSubscription,
            visibleSubscriptionId: subscriptionId,
            invoices: invoiceList.documents
        });
    };

    const init = async () => {
        const invoiceList = await InvoiceService.getInvoiceList({
            subscriptionId: defaultVisibleSubscription._id,
            rowsPerPage: 50,
            page: 0
        });
        setState({
            ...state,
            visibleSubscriptionId: undefined,
            invoices: invoiceList.documents
        });
    };

    const onChangeSubscriptionFromDate = (date: Moment | null) => {
        if (date) {
            props.onChangeSubscriptionFromDate(
                new Date(date.startOf('day').format())
            );
        }
    };

    const onTogglePlanCosts = () => {
        setState({
            ...state,
            arePlanCostsCollapsed: !state.arePlanCostsCollapsed
        });
    };

    useEffect(() => {
        init();
    }, [props]);
    return (
        <React.Fragment>
            <p>
                <b>Subscription History</b>
            </p>
            <div style={{ width: '100%', marginBottom: '16px' }}>
                <DesktopDatePicker
                    disableFuture
                    inputFormat='DD/MM/YYYY'
                    value={props.subscriptionsFromDate}
                    onChange={onChangeSubscriptionFromDate}
                    label={'From Date'}
                    renderInput={props => <TextField {...props} />}
                />
            </div>
            <Table size='small' style={subscriptionListStyles}>
                <TableHead>
                    <TableRow>
                        <TableCell>Plan</TableCell>
                        <TableCell>Scheduled Dates</TableCell>
                        <TableCell>Real Dates</TableCell>
                        <TableCell>Emails</TableCell>
                        <TableCell>Activities</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.customerSubscriptions.map(
                        (subscription: Subscription) => {
                            const customerTimezone =
                                subscription.timezone || props.customerTimeZone;
                            const customTimezone =
                                customerTimezone !== defaultTimezone
                                    ? customerTimezone
                                    : null;
                            return (
                                <React.Fragment key={subscription._id}>
                                    <TableRow>
                                        <TableCell style={{ height: '23px' }}>
                                            {subscription.planName}
                                            <div
                                                className={clsx({
                                                    'subscription-active':
                                                        subscription.isActive,
                                                    'subscription-pending':
                                                        subscription.status ===
                                                            'pending' &&
                                                        !subscription.startedAt
                                                })}
                                                style={{
                                                    display: 'inline-block',
                                                    margin: '3px 0px'
                                                }}
                                            >
                                                {subscription.isActive && (
                                                    <Chip
                                                        style={chipStyles}
                                                        label='Active'
                                                        color='secondary'
                                                    />
                                                )}
                                                {subscription.status ===
                                                    'pending' &&
                                                    !subscription.startedAt && (
                                                        <Chip
                                                            style={chipStyles}
                                                            label='Scheduled'
                                                            color='primary'
                                                        />
                                                    )}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <StartFinishDateCell
                                                startDate={
                                                    subscription.scheduledStartDate
                                                }
                                                finishDate={
                                                    subscription.scheduledFinishDate
                                                }
                                                customTimezone={customTimezone}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <StartFinishDateCell
                                                startDate={
                                                    subscription.startedAt
                                                }
                                                finishDate={
                                                    subscription.finishedAt
                                                }
                                                customTimezone={customTimezone}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {subscription.activitiesInfo &&
                                                subscription.activitiesInfo
                                                    .maxEmailsSoftLimit &&
                                                `${
                                                    subscription.activitiesInfo
                                                        .mailActivitiesSpent ||
                                                    0
                                                } / ${
                                                    subscription.activitiesInfo
                                                        .maxEmailsSoftLimit
                                                }`}
                                            {(!subscription.activitiesInfo ||
                                                !subscription.activitiesInfo
                                                    .maxEmailsSoftLimit) &&
                                                '-'}
                                        </TableCell>
                                        <TableCell>
                                            {subscription.activitiesInfo &&
                                                subscription.activitiesInfo
                                                    .maxActivitiesSoftLimit &&
                                                `${
                                                    subscription.activitiesInfo
                                                        .activitiesSpent || 0
                                                } / ${
                                                    subscription.activitiesInfo
                                                        .maxActivitiesSoftLimit
                                                }`}
                                            {(!subscription.activitiesInfo ||
                                                !subscription.activitiesInfo
                                                    .maxActivitiesSoftLimit) &&
                                                '-'}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip
                                                title='View details'
                                                style={actionButtonStyles}
                                            >
                                                <IconButton
                                                    style={{ padding: '0px' }}
                                                    color={
                                                        state.visibleSubscriptionId ===
                                                        subscription._id
                                                            ? 'primary'
                                                            : 'default'
                                                    }
                                                    aria-label='View'
                                                    onClick={() =>
                                                        onClickViewSubscription(
                                                            subscription._id
                                                        )
                                                    }
                                                >
                                                    {state.visibleSubscriptionId ===
                                                        subscription._id && (
                                                        <ExpandLessIcon />
                                                    )}
                                                    {state.visibleSubscriptionId !==
                                                        subscription._id && (
                                                        <ExpandMoreIcon />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {state.visibleSubscription && (
                                            <TableCell
                                                colSpan={6}
                                                style={{
                                                    padding: '0px',
                                                    border: 'none'
                                                }}
                                            >
                                                <Collapse
                                                    in={
                                                        state.visibleSubscriptionId ===
                                                        subscription._id
                                                    }
                                                    timeout='auto'
                                                    unmountOnExit
                                                    style={{ width: '100%' }}
                                                >
                                                    <CustomerPlanSummary
                                                        invoiceSettings={
                                                            props.invoiceSettings
                                                        }
                                                        planSummary={
                                                            state.visibleSubscription
                                                        }
                                                        invoices={
                                                            state.invoices || []
                                                        }
                                                        arePlanCostsCollapsable={
                                                            true
                                                        }
                                                        arePlanCostsCollapsed={
                                                            state.arePlanCostsCollapsed
                                                        }
                                                        onTogglePlanCosts={() =>
                                                            onTogglePlanCosts()
                                                        }
                                                    >
                                                        Subscription Summary
                                                    </CustomerPlanSummary>
                                                </Collapse>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </React.Fragment>
                            );
                        }
                    )}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
