import React, { Fragment, ReactElement } from 'react';
import GenericListFilters from '../models/GenericListFilters';
import DateSelector from './DateSelector';

type DateRangeFilter = Pick<GenericListFilters, 'fromDate' | 'toDate'>;

type Props = {
    label: string;
    filters: DateRangeFilter;
    onFilterChange: (filterId: keyof DateRangeFilter, value?: Date) => void;
};

DateRangeFilters.defaultProps = {
    label: 'Created'
};

export default function DateRangeFilters({
    label,
    filters,
    onFilterChange
}: Props): ReactElement {
    return (
        <Fragment>
            <DateSelector
                disableFuture
                value={filters.fromDate}
                onChange={date => onFilterChange('fromDate', date)}
                maxDate={filters.toDate}
                label={`${label} From`}
            />
            <DateSelector
                disableFuture
                value={filters.toDate}
                onChange={date => onFilterChange('toDate', date)}
                minDate={filters.fromDate}
                label={`${label} To`}
                style={{ marginLeft: '10px' }}
            />
        </Fragment>
    );
}
