import React, { PropsWithChildren, ReactNode, ReactElement } from 'react';
import { CompanyInfo } from '../../../../models/invoice/CompanyInfo';
import { ClientInfo } from '../../../../models/invoice/ClientInfo';
import { Grid, TextField, Theme } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import CustomerSelector from '../../../../components/CustomerSelector';
import { ToastVariant } from '../../../../reducers/Snackbar';
import { PartnerInfo } from '../../../../models/partner/PartnerInfo';

type Props = PropsWithChildren<{
    info: CompanyInfo | ClientInfo;
    isEditable?: boolean;
    readOnly?: boolean;
    selectedCustomerUuid?: string;
    onChangeCustomerSelector?: (customerUuid: string) => void;
    type: 'ClientInfo' | 'CompanyInfo' | 'PartnerInfo';
    onUpdateCompanyInfo?: (companyInfo: CompanyInfo) => void;
    showToast?: (variant: ToastVariant, message: string) => void;
    extraFields?: ReactNode;
}>;

const useStyles = makeStyles<Theme>(theme => ({
    dense: {
        marginTop: '20px'
    },
    textField: {
        marginRight: theme.spacing(1),
        width: '100%'
    }
}));

// TODO: Add fields validators cif, phone
export default function InvoiceCompanyInfo(props: Props): ReactElement {
    const classes = useStyles();
    const readOnly = !!props.readOnly;
    const isEditable = !!props.isEditable;

    const updateCompanyInfo = (fieldName: any, event: any) => {
        const newValue = event.target.value;
        const companyInfo = { ...props.info, [fieldName]: newValue };
        props.onUpdateCompanyInfo?.(companyInfo);
    };

    const updateAddress = (
        fieldName: keyof CompanyInfo['address'],
        event: any
    ) => {
        const newValue = event.target.value;
        const companyInfo = {
            ...props.info,
            address: {
                ...(props.info.address || {}),
                [fieldName]: newValue
            }
        };
        props.onUpdateCompanyInfo?.(companyInfo);
    };
    return (
        <React.Fragment>
            {isEditable && (
                <React.Fragment>
                    <Grid item xs={8} sm={8}>
                        <p
                            style={{
                                display: 'inline-block',
                                marginRight: '15px'
                            }}
                        >
                            <b>{props.children}</b>
                        </p>
                        {props.isEditable &&
                            props.onChangeCustomerSelector &&
                            props.showToast && (
                                <div
                                    style={{
                                        width: '350px',
                                        display: 'inline-block'
                                    }}
                                >
                                    <CustomerSelector
                                        onChangeCustomer={
                                            props.onChangeCustomerSelector
                                        }
                                        selectedCustomerUuid={
                                            props.selectedCustomerUuid
                                        }
                                        showToast={props.showToast}
                                    />
                                </div>
                            )}
                    </Grid>
                    <Grid item xs={8} sm={8} style={{ paddingRight: '20px' }}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id='company'
                                label='Company Name'
                                className={clsx(
                                    classes.textField,
                                    classes.dense
                                )}
                                value={props.info.company || ''}
                                onChange={event =>
                                    updateCompanyInfo('company', event)
                                }
                                InputProps={{ readOnly }}
                            />
                            <TextField
                                id='street'
                                label='Address'
                                className={clsx(
                                    classes.textField,
                                    classes.dense
                                )}
                                value={props.info.address.street || ''}
                                onChange={event =>
                                    updateAddress('street', event)
                                }
                                InputProps={{ readOnly }}
                            />
                            <TextField
                                id='street2'
                                label='Address 2'
                                className={clsx(
                                    classes.textField,
                                    classes.dense
                                )}
                                value={props.info.address.street2 || ''}
                                onChange={event =>
                                    updateAddress('street2', event)
                                }
                                InputProps={{ readOnly }}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            sm={6}
                            style={{
                                display: 'inline-block',
                                paddingRight: '20px',
                                width: '50%'
                            }}
                        >
                            <TextField
                                id='city'
                                label='City'
                                className={clsx(
                                    classes.textField,
                                    classes.dense
                                )}
                                value={props.info.address.city || ''}
                                onChange={event => updateAddress('city', event)}
                                InputProps={{ readOnly }}
                            />
                            <TextField
                                id='phone'
                                label='Phone'
                                className={clsx(
                                    classes.textField,
                                    classes.dense
                                )}
                                value={props.info.phone || ''}
                                onChange={event =>
                                    updateCompanyInfo('phone', event)
                                }
                                InputProps={{ readOnly }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            style={{
                                display: 'inline-block',
                                width: '50%',
                                verticalAlign: 'top'
                            }}
                        >
                            <TextField
                                id='state'
                                label='State'
                                className={clsx(
                                    classes.textField,
                                    classes.dense
                                )}
                                value={props.info.address.state || ''}
                                onChange={event =>
                                    updateAddress('state', event)
                                }
                                InputProps={{ readOnly }}
                            />
                            {props.type === 'ClientInfo' && (
                                <TextField
                                    id='email'
                                    label='Emails to send invoice to (separated by commas)'
                                    placeholder='email1@example.org, email2@example.org'
                                    className={clsx(
                                        classes.textField,
                                        classes.dense
                                    )}
                                    value={
                                        (props.info as ClientInfo).email || ''
                                    }
                                    onChange={event =>
                                        updateCompanyInfo('email', event)
                                    }
                                    InputProps={{ readOnly }}
                                />
                            )}
                            {props.type === 'PartnerInfo' && (
                                <TextField
                                    id='contactEmail'
                                    label='Emails to send reports to (separated by commas)'
                                    placeholder='email1@example.org, email2@example.org'
                                    className={clsx(
                                        classes.textField,
                                        classes.dense
                                    )}
                                    value={
                                        (props.info as PartnerInfo)
                                            .contactEmail || ''
                                    }
                                    onChange={event =>
                                        updateCompanyInfo('contactEmail', event)
                                    }
                                    InputProps={{ readOnly }}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={4} style={{ paddingRight: '20px' }}>
                        <TextField
                            id='cif'
                            label='Tax ID'
                            className={clsx(classes.textField, classes.dense)}
                            value={props.info.cif || ''}
                            onChange={event => updateCompanyInfo('cif', event)}
                            InputProps={{ readOnly }}
                        />
                        <TextField
                            id='zip'
                            label='Zip'
                            className={clsx(classes.textField, classes.dense)}
                            value={props.info.address.zip || ''}
                            onChange={event => updateAddress('zip', event)}
                            InputProps={{ readOnly }}
                        />
                        <TextField
                            id='country'
                            label='Country'
                            className={clsx(classes.textField, classes.dense)}
                            value={props.info.address.country || ''}
                            onChange={event => updateAddress('country', event)}
                            InputProps={{ readOnly }}
                        />
                        {props.type === 'ClientInfo' && (
                            <TextField
                                id='countryCode'
                                label='Country Code'
                                className={clsx(
                                    classes.textField,
                                    classes.dense
                                )}
                                value={props.info.countryCode || ''}
                                InputProps={{ disabled: true }}
                            />
                        )}
                        {props.extraFields}
                    </Grid>
                </React.Fragment>
            )}
            {!isEditable && props.info && (
                <React.Fragment>
                    <Grid item xs={6} sm={6}>
                        <p>
                            <b>{props.children}</b>
                        </p>
                        <p>{props.info.company}</p>
                        <p>{props.info.address.street}</p>
                        <p>{props.info.address.street2}</p>
                        <p>
                            {props.info.address.zip} {props.info.address.city}
                        </p>
                        <p>
                            {props.info.address.state}
                            {props.info.address.state &&
                            props.info.address.country
                                ? ', '
                                : ''}
                            {props.info.address.country}
                        </p>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <div style={{ margin: '50px 0', textAlign: 'right' }}>
                            <p>
                                {props.info.cif ? 'CIF: ' + props.info.cif : ''}
                            </p>
                            <p>
                                {props.info.phone
                                    ? 'Teléf: ' + props.info.phone
                                    : ''}
                            </p>
                        </div>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
