import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Invoice from '../../../../models/invoice/Invoice';
import Moment from 'react-moment';
import ConnectifLogo from '../../../../resources/img/logo-with-text-right.svg';
import { CSSProperties } from '@mui/styles';
import Select from '@mui/material/Select';
import {
    MenuItem,
    Divider,
    Button,
    Link,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import InvoiceItems from './InvoiceItems';
import InvoiceCompanyInfo from './InvoiceCompanyInfo';
import { CompanyInfo } from '../../../../models/invoice/CompanyInfo';
import InvoiceService from '../../../../services/InvoiceService';
import Big from 'big.js';
import { InvoiceItem } from '../../../../models/invoice/InvoiceItem';
import { ClientInfo } from '../../../../models/invoice/ClientInfo';
import { NumericFormat } from 'react-number-format';
import { ToastVariant } from '../../../../reducers/Snackbar';
import CustomerService from '../../../../services/CustomerService';
import { Redirect } from 'react-router';
import 'moment/locale/es';
import NumberField from '../../../../components/NumberField';
import { BankPaymentInfo } from '../../../../models/customer/BankPaymentInfo';
import { CustomerPaymentInfo } from '../../../../models/customer/CustomerPaymentInfo';

interface Props {
    invoice?: Invoice;
    viewMode?: boolean;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    invoice: Invoice;
    invoiceSettingsVat: number;
    rectifiedInvoice?: Invoice;
    connectifInfo: CompanyInfo;
    redirectViewInvoice?: boolean;
    vatAmount: number;
    selectedCustomerUuid?: string;
    selectedCustomerPaymentMethod?: CustomerPaymentInfo['paymentMethod'];
}

class InvoiceEditor extends Component<Props, State> {
    // TODO: get from api?
    private invoicelines = [{ prefix: 'CRE' }, { prefix: 'CAI' }];

    private companyInfo: CompanyInfo = {
        address: {
            city: '',
            country: '',
            state: '',
            street: '',
            zip: ''
        },
        cif: '',
        company: '',
        phone: ''
    };

    private emptyClientInfo: ClientInfo = {
        ...this.companyInfo
    };

    constructor(props: Props) {
        super(props);
        if (this.props.invoice) {
            this.state = {
                invoiceSettingsVat: 21,
                invoice: this.props.invoice,
                connectifInfo: this.props.invoice.connectifInfo,
                vatAmount: +Big(this.props.invoice.vat)
                    .times(this.props.invoice.subtotalAmount)
                    .div(100)
                    .toFixed(2)
            };
            if (this.props.invoice.invoiceRectifiedId) {
                this.loadInvoice(this.props.invoice.invoiceRectifiedId);
            }
        } else {
            this.state = {
                invoiceSettingsVat: 21,
                vatAmount: 0,
                connectifInfo: this.companyInfo,
                invoice: {
                    _id: '',
                    items: [],
                    clientInfo: this.emptyClientInfo,
                    connectifInfo: this.companyInfo,
                    customerUUID: '',
                    invoicePrefix: 'CAI',
                    paymentInfo: { paymentMethod: 'bank' },
                    invoiceStatus: 'draft',
                    totalAmount: 0,
                    subtotalAmount: 0,
                    vat: 21,
                    accountName: ''
                } as Invoice
            };
        }
    }

    componentDidMount = () => {
        this.init();
    };

    private loadInvoice = async (invoiceId: string) => {
        try {
            const invoice = await InvoiceService.getInvoice(invoiceId);
            this.setState({ ...this.state, rectifiedInvoice: invoice });
        } catch (err) {
            this.props.showToast('error', 'Invoice not found');
        }
    };

    private getPaymentInfoText = (paymentInfo: CustomerPaymentInfo): string => {
        switch (paymentInfo.paymentMethod) {
            case 'bank':
                return paymentInfo.isWireTransfer
                    ? 'Transferencia Bancaria'
                    : 'Domiciliación Bancaria';
            case 'card':
                return 'Tarjeta';
            case 'shopify':
                return 'Shopify';
        }
    };

    private updateSelectedCustomer = async (selectedCustomerUuid: string) => {
        const { customer } =
            await CustomerService.getCustomer(selectedCustomerUuid);
        this.setState({
            selectedCustomerUuid,
            invoice: {
                ...this.state.invoice,
                clientInfo: customer.clientInfo,
                customerUUID: customer.uuid,
                paymentInfo: {
                    paymentMethod:
                        customer.paymentInfo.paymentMethod === 'shopify'
                            ? 'bank'
                            : customer.paymentInfo.paymentMethod
                },
                vat:
                    customer.billingInfo.vat !== undefined
                        ? customer.billingInfo.vat
                        : this.state.invoiceSettingsVat
            },
            selectedCustomerPaymentMethod: customer.paymentInfo.paymentMethod
        });
    };

    private init = async () => {
        const stateCopy = { ...this.state };
        const invoiceSettings = await InvoiceService.getInvoiceSettings();
        stateCopy.invoiceSettingsVat = invoiceSettings.defaultVat;
        if (!this.props.invoice) {
            stateCopy.invoice.connectifInfo = invoiceSettings.connectifInfo;
            stateCopy.connectifInfo = invoiceSettings.connectifInfo;
            stateCopy.invoice.footer = invoiceSettings.invoiceFooter || '';
        } else if (this.props.invoice) {
            stateCopy.selectedCustomerUuid = this.props.invoice.customerUUID;
        }
        this.setState(stateCopy);
    };

    private headerStyles: CSSProperties = {
        margin: '20px 0px',
        lineHeight: '35px',
        display: 'inline-block'
    };

    private selectorStyles: CSSProperties = {
        marginLeft: '10px',
        float: 'right',
        marginTop: '23px'
    };

    private invoiceNumberStyles: CSSProperties = {
        display: 'inline-block',
        marginLeft: '10px',
        fontSize: '24px',
        color: '#5e91eb',
        float: 'right'
    };

    private onChangeInvoicePrefix = (event: any) => {
        const stateCopy = { ...this.state };
        stateCopy.invoice.invoicePrefix = event.target.value;
        this.setState(stateCopy);
    };

    private onChangeVat = (newValue?: number) => {
        const stateCopy = { ...this.state };
        stateCopy.invoice.vat = newValue || 0;
        this.setState(stateCopy, () => this.updateTotals());
    };

    private saveInvoice = async () => {
        try {
            if (this.state.invoice._id) {
                await InvoiceService.editInvoice(
                    this.state.invoice,
                    this.state.invoice._id
                );
                this.setState({ ...this.state, redirectViewInvoice: true });
                this.props.showToast('success', 'Invoice Saved');
            } else {
                const invoice = await InvoiceService.createInvoice(
                    this.state.invoice
                );
                this.setState({
                    ...this.state,
                    invoice,
                    redirectViewInvoice: true
                });
                this.props.showToast('success', 'Invoice Created');
            }
        } catch (err) {
            this.props.showToast('error', err.message);
        }
    };

    private handleAddItem = (item: InvoiceItem) => {
        const stateCopy = { ...this.state };
        stateCopy.invoice.items.push(item);
        this.setState(stateCopy, () => this.updateTotals());
    };

    private handleUpdateItem = (item: InvoiceItem, indexItem: number) => {
        const stateCopy = { ...this.state };
        stateCopy.invoice.items[indexItem] = item;
        this.setState(stateCopy, () => this.updateTotals());
    };

    private handleRemoveItem = (index: number) => {
        const stateCopy = { ...this.state };
        stateCopy.invoice.items.splice(index, 1);
        this.setState(stateCopy, () => this.updateTotals());
    };

    private updateTotals = () => {
        let subtotalAmount = 0;
        const stateCopy = { ...this.state };
        this.state.invoice.items.forEach(item => {
            subtotalAmount = +Big(subtotalAmount).plus(item.amount).toFixed(2);
        });
        const vatMultiplier = Big(stateCopy.invoice.vat)
            .div(100)
            .plus(1)
            .toFixed(2);
        const totalAmount = +Big(subtotalAmount)
            .times(vatMultiplier)
            .toFixed(2);
        const vatAmount = totalAmount - subtotalAmount;
        stateCopy.invoice.subtotalAmount = subtotalAmount;
        stateCopy.vatAmount = vatAmount;
        stateCopy.invoice.totalAmount = totalAmount;
        this.setState(stateCopy);
    };

    private updatePaymentInfo = (
        fieldName: keyof BankPaymentInfo,
        newValue: any
    ) => {
        const stateCopy = { ...this.state };
        const paymentInfo = {
            ...stateCopy.invoice.paymentInfo,
            [fieldName]: newValue
        };
        if (paymentInfo.paymentMethod === 'card') {
            delete (paymentInfo as any).isWireTransfer;
        }
        stateCopy.invoice.paymentInfo = paymentInfo;
        this.setState(stateCopy);
    };

    // TODO: Add payment method selector
    render(): ReactElement {
        return (
            <Paper className='cn-paper'>
                {this.state.redirectViewInvoice && (
                    <Redirect
                        to={`/invoice/${this.state.invoice._id}`}
                    ></Redirect>
                )}
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={5} sm={5}>
                            <img
                                style={this.headerStyles}
                                src={ConnectifLogo}
                                className='App-logo'
                                alt='logo'
                            />
                        </Grid>
                        <Grid item xs={7} sm={7}>
                            {this.state.invoice.paymentInfo.paymentMethod !==
                                'shopify' && (
                                <p style={this.invoiceNumberStyles}>
                                    {this.props.viewMode &&
                                        this.state.invoice.invoicePrefix}
                                    {this.state.invoice.invoiceNumber
                                        ? this.state.invoice.invoiceNumber
                                        : 'BORRADOR'}
                                </p>
                            )}
                            {this.state.invoice.paymentInfo.paymentMethod ===
                                'shopify' && (
                                <p style={this.invoiceNumberStyles}>
                                    {new Date(
                                        this.state.invoice.createdAt as any
                                    ).getTime()}
                                </p>
                            )}
                            {!this.props.viewMode && (
                                <Select
                                    disabled={
                                        this.props.viewMode ? true : false
                                    }
                                    value={this.state.invoice.invoicePrefix}
                                    onChange={this.onChangeInvoicePrefix}
                                    name='invoicePrefix'
                                    style={this.selectorStyles}
                                >
                                    {this.invoicelines.map((line, i) => (
                                        <MenuItem key={i} value={line.prefix}>
                                            {line.prefix}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                            <h2
                                style={{ ...this.headerStyles, float: 'right' }}
                            >
                                {this.state.invoice.paymentInfo
                                    .paymentMethod === 'shopify'
                                    ? 'RECIBO: '
                                    : this.state.invoice.invoiceRectifiedId
                                      ? 'FACTURA RECTIFICADA: '
                                      : 'FACTURA: '}
                            </h2>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider
                                style={{
                                    height: '5px',
                                    backgroundColor: 'black'
                                }}
                                variant='fullWidth'
                            />
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <Moment
                                locale='es'
                                date={this.state.invoice.invoiceDate}
                                format='DD MMMM YYYY'
                                style={{
                                    marginBottom: '15px',
                                    display: 'block'
                                }}
                            />
                            <Divider />
                            {this.state.invoice.invoiceRectifiedId &&
                                this.state.rectifiedInvoice && (
                                    <React.Fragment>
                                        <p>
                                            <b>Referencia: </b>
                                        </p>
                                        <p>
                                            Factura original:
                                            <Link
                                                href={`/invoice/${this.state.invoice.invoiceRectifiedId}`}
                                            >
                                                {this.state.rectifiedInvoice
                                                    .invoicePrefix +
                                                    this.state.rectifiedInvoice
                                                        .invoiceNumber}
                                            </Link>
                                        </p>
                                        <p>
                                            Fecha factura original:
                                            <Moment
                                                date={
                                                    this.state.rectifiedInvoice
                                                        .invoiceDate
                                                }
                                                format='DD/MM/YYYY'
                                            />
                                        </p>
                                    </React.Fragment>
                                )}
                            <p style={{ marginBottom: 0 }}>
                                <b>
                                    {this.state.invoice.paymentInfo
                                        .paymentMethod !== 'shopify'
                                        ? 'TOTAL FACTURA'
                                        : 'IMPORTE'}
                                    :
                                </b>
                            </p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <p
                                style={{
                                    textAlign: 'right',
                                    fontSize: '35px',
                                    color: '#5e91eb',
                                    marginTop: '15px',
                                    marginBottom: '0px'
                                }}
                            >
                                <b>
                                    <NumericFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={this.state.invoice.totalAmount}
                                        displayType={'text'}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        suffix={'€'}
                                    />
                                </b>
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider
                                style={{
                                    height: '3px',
                                    backgroundColor: 'black'
                                }}
                                variant='fullWidth'
                            />
                        </Grid>

                        <InvoiceCompanyInfo
                            info={this.state.invoice.clientInfo}
                            isEditable={!this.props.viewMode}
                            onChangeCustomerSelector={
                                this.updateSelectedCustomer
                            }
                            selectedCustomerUuid={
                                this.state.selectedCustomerUuid
                            }
                            type='CompanyInfo'
                            onUpdateCompanyInfo={info =>
                                this.setState({
                                    ...this.state,
                                    invoice: {
                                        ...this.state.invoice,
                                        clientInfo: info as ClientInfo
                                    }
                                })
                            }
                            showToast={this.props.showToast}
                        >
                            {this.props.invoice && this.props.viewMode && (
                                <Link
                                    href={`/customer/${this.props.invoice.customerUUID}/edit`}
                                >
                                    CLIENTE: {this.props.invoice.accountName}
                                </Link>
                            )}
                            {(!this.props.invoice || !this.props.viewMode) &&
                                'CLIENTE:'}
                        </InvoiceCompanyInfo>
                        <Grid item xs={12} sm={12}>
                            <Divider
                                style={{
                                    height: '5px',
                                    backgroundColor: 'black'
                                }}
                                variant='fullWidth'
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <InvoiceItems
                                items={this.state.invoice.items}
                                isEditable={!this.props.viewMode}
                                handleAddItem={this.handleAddItem}
                                handleUpdateItem={this.handleUpdateItem}
                                handleRemoveItem={this.handleRemoveItem}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {this.state.invoice.paymentInfo.paymentMethod !==
                                'shopify' && (
                                <div>
                                    <p style={{ textAlign: 'right' }}>
                                        {'Total Parcial: '}
                                        <NumericFormat
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            value={
                                                this.state.invoice
                                                    .subtotalAmount
                                            }
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            suffix={'€'}
                                        />
                                    </p>
                                    <Divider />
                                    <p style={{ textAlign: 'right' }}>
                                        {'Base Imponible: '}
                                        <NumericFormat
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            value={
                                                this.state.invoice
                                                    .subtotalAmount
                                            }
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            suffix={'€'}
                                        />
                                    </p>
                                    <div
                                        style={{
                                            textAlign: 'right',
                                            marginBottom: '10px'
                                        }}
                                    >
                                        IVA (
                                        {!this.props.viewMode && (
                                            <NumberField
                                                required={false}
                                                adornment=''
                                                id='vat'
                                                style={{
                                                    width: '35px',
                                                    top: '-3px',
                                                    marginLeft: '5px'
                                                }}
                                                value={this.state.invoice.vat}
                                                onChangeValue={newValue =>
                                                    this.onChangeVat(newValue)
                                                }
                                            />
                                        )}
                                        {this.props.viewMode &&
                                            this.state.invoice.vat}
                                        %)
                                        <NumericFormat
                                            style={{ marginLeft: '5px' }}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            value={this.state.vatAmount}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            suffix={'€'}
                                        />
                                    </div>
                                    <Divider />
                                </div>
                            )}
                            <p
                                style={{
                                    textAlign: 'right',
                                    fontSize: '25px',
                                    color: '#5e91eb',
                                    margin: '0'
                                }}
                            >
                                {this.state.invoice.paymentInfo
                                    .paymentMethod === 'shopify'
                                    ? `IMPORTE: `
                                    : `TOTAL: `}
                                <NumericFormat
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    value={this.state.invoice.totalAmount}
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    suffix={'€'}
                                />
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider
                                style={{
                                    height: '5px',
                                    backgroundColor: 'black'
                                }}
                                variant='fullWidth'
                            />
                        </Grid>

                        <InvoiceCompanyInfo
                            info={this.state.connectifInfo}
                            type='CompanyInfo'
                        >
                            CONNECTIF:
                        </InvoiceCompanyInfo>

                        <Grid item xs={12} sm={12}>
                            <p style={{ color: '#5e91eb' }}>
                                <b>INFORMACIÓN ADICIONAL:</b>
                            </p>
                            <Divider
                                style={{
                                    height: '3px',
                                    backgroundColor: 'black'
                                }}
                                variant='fullWidth'
                            />
                            {!this.props.viewMode && (
                                <React.Fragment>
                                    <p
                                        style={{
                                            display: 'inline-block',
                                            marginRight: '15px'
                                        }}
                                    >
                                        <b>Método de Pago:</b>
                                    </p>
                                    <Select
                                        value={
                                            this.state.invoice.paymentInfo
                                                .paymentMethod || 'bank'
                                        }
                                        onChange={event =>
                                            this.updatePaymentInfo(
                                                'paymentMethod',
                                                event.target.value
                                            )
                                        }
                                        inputProps={{
                                            name: 'Payment Method',
                                            id: 'payment-method'
                                        }}
                                    >
                                        <MenuItem
                                            value={'bank'}
                                            disabled={
                                                this.state.invoice.paymentInfo
                                                    .paymentMethod === 'shopify'
                                            }
                                        >
                                            Domiciliación Bancaria
                                        </MenuItem>
                                        <MenuItem
                                            value={'card'}
                                            disabled={
                                                this.state
                                                    .selectedCustomerPaymentMethod ===
                                                    'shopify' ||
                                                this.state.invoice.paymentInfo
                                                    .paymentMethod === 'shopify'
                                            }
                                        >
                                            Tarjeta
                                        </MenuItem>
                                        <MenuItem
                                            value={'shopify'}
                                            disabled={
                                                this.state.invoice.paymentInfo
                                                    .paymentMethod !== 'shopify'
                                            }
                                        >
                                            Shopify
                                        </MenuItem>
                                    </Select>
                                    {this.state.invoice.paymentInfo
                                        .paymentMethod === 'bank' && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        this.state.invoice
                                                            .paymentInfo
                                                            .isWireTransfer
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={event =>
                                                        this.updatePaymentInfo(
                                                            'isWireTransfer',
                                                            event.target.checked
                                                        )
                                                    }
                                                    value='IsWiredTransfer'
                                                    color='primary'
                                                />
                                            }
                                            label='Es transferencia bancaria'
                                        />
                                    )}
                                </React.Fragment>
                            )}
                            {this.props.viewMode && (
                                <p>
                                    <b>Método de pago: </b>
                                    {this.getPaymentInfoText(
                                        this.state.invoice.paymentInfo
                                    )}
                                </p>
                            )}
                            {this.props.viewMode &&
                                this.state.invoice.paymentInfo.paymentMethod ===
                                    'shopify' && (
                                    <p>
                                        El importe total facilitado en este
                                        recibo podría sufrir variaciones por
                                        razones de cambio de divisa. Además, el
                                        ciclo de suscripción de Connectif puede
                                        no coincidir con el de Shopify.
                                    </p>
                                )}
                            {(this.state.invoice.paymentInfo as BankPaymentInfo)
                                .isWireTransfer && (
                                <div>
                                    <p>
                                        <b>SWIFT: </b>
                                        {this.state.invoice.connectifInfo.swift}
                                    </p>
                                    <p>
                                        <b>Cuenta bancaria: </b>
                                        {
                                            this.state.invoice.connectifInfo
                                                .accountNumber
                                        }
                                    </p>
                                </div>
                            )}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <p style={{ textAlign: 'center' }}>
                                {this.state.invoice.footer}
                            </p>
                        </Grid>
                        {!this.props.viewMode && (
                            <Grid
                                container
                                item
                                xs={12}
                                sm={12}
                                justifyContent={'flex-end'}
                            >
                                <Button
                                    onClick={this.saveInvoice}
                                    variant='contained'
                                    color='primary'
                                >
                                    Save
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}
export default InvoiceEditor;
