import React from 'react';
import { Link } from 'react-router-dom';
import { InvoiceChargeFailedOperationLog } from '../../../../../models/system/InvoiceChargeFailedOperationLog';
import InvoiceLinkActiveView from './InvoiceLinkActiveView';

const InvoiceChargeFailed = (operationLog: InvoiceChargeFailedOperationLog) => {
    const intentLink = operationLog.stripePaymentIntentId ? (
        <span>
            {' '}
            - Intent:{' '}
            <a
                target='_blank'
                rel='noopener noreferrer'
                href={
                    'https://dashboard.stripe.com/payments/' +
                    operationLog.stripePaymentIntentId
                }
            >
                {operationLog.stripePaymentIntentId}
            </a>
        </span>
    ) : (
        ''
    );
    return (
        <div>
            {!operationLog.customerName && (
                <p>
                    Customer:{' '}
                    <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                        {operationLog.customerAccountEmail}
                    </Link>
                </p>
            )}
            {operationLog.customerName && (
                <p>
                    Account name:{' '}
                    <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                        {operationLog.customerName}
                    </Link>
                </p>
            )}
            <InvoiceLinkActiveView
                invoiceId={operationLog.invoiceId}
                invoiceTotalAmount={operationLog.invoiceTotalAmount}
                invoiceLinkActive={operationLog.invoiceLinkActive}
            ></InvoiceLinkActiveView>
            <p>
                Reason: {operationLog.errorMessage}
                {intentLink}
            </p>
            <p>Notification Email: {operationLog.notificationEmailTo}</p>
        </div>
    );
};

export default InvoiceChargeFailed;
