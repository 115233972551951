import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

type Props = {
    invoiceId: string;
    invoiceTotalAmount: number;
    invoiceLinkActive: boolean;
};

const InvoiceLinkActiveView = ({
    invoiceId,
    invoiceTotalAmount,
    invoiceLinkActive
}: Props): ReactElement => (
    <>
        {(!invoiceLinkActive && (
            <p>
                Invoice: {invoiceId} - Amount: {invoiceTotalAmount}€
                <span
                    style={{
                        marginLeft: '5px',
                        fontStyle: 'italic',
                        fontSize: '10px'
                    }}
                >
                    {' '}
                    (Deleted)
                </span>
            </p>
        )) || (
            <p>
                Invoice: <Link to={`/invoice/${invoiceId}`}>{invoiceId}</Link> -
                Amount: {invoiceTotalAmount}€
            </p>
        )}
    </>
);

export default InvoiceLinkActiveView;
