import React, { useState, ReactElement } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    DialogActions,
    Button,
    InputLabel
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ReportItem } from '../../../models/report/ReportItem';
import CustomerSelector from '../../../components/CustomerSelector';
import { PropsWithShowToast } from '../../../PropsWithShowToast';
import CustomerService from '../../../services/CustomerService';
import { Moment } from 'moment';

type Props = PropsWithShowToast<{
    open: boolean;
    isEdit: boolean;
    partnerId: string;
    onClose: () => void;
    onSaveItem: (reportItem: ReportItem) => void;
    reportItem: ReportItem;
}>;

export default function EditReportItemDialog({
    open,
    isEdit,
    onClose,
    onSaveItem,
    reportItem,
    partnerId,
    showToast
}: Props): ReactElement {
    const [editingItem, setEditingItem] = useState(reportItem);
    const isValid =
        editingItem.customerName &&
        editingItem.customerUuid &&
        editingItem.termUuid &&
        editingItem.amount > 0 &&
        (editingItem.percentage === undefined ||
            (editingItem.percentage >= 0 && editingItem.percentage <= 100)) &&
        editingItem.totalInvoiceCommissionableAmount > 0;
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{isEdit ? 'Edit item' : 'Add new item'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>Customer</InputLabel>
                        <CustomerSelector
                            selectedCustomerUuid={editingItem.customerUuid}
                            partnerId={partnerId}
                            showToast={showToast}
                            onChangeCustomer={async customerUuid => {
                                const { customer } =
                                    await CustomerService.getCustomer(
                                        customerUuid
                                    );
                                const term = customer.partnerTerms?.find(
                                    t => t.partnerId === partnerId
                                );
                                setEditingItem({
                                    ...editingItem,
                                    customerName: customer.name,
                                    customerUuid: customer.uuid,
                                    termUuid: term?.uuid ?? ''
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            autoFocus
                            margin='dense'
                            required={true}
                            label='Plan'
                            onChange={e =>
                                setEditingItem({
                                    ...editingItem,
                                    planName: e.target.value
                                })
                            }
                            value={editingItem.planName}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <DesktopDatePicker
                            inputFormat='DD/MM/YYYY'
                            value={editingItem.startDecayDate}
                            onChange={(date: Moment | null) =>
                                date &&
                                setEditingItem({
                                    ...editingItem,
                                    startDecayDate: date?.toDate()
                                })
                            }
                            renderInput={props => (
                                <TextField
                                    {...props}
                                    sx={{
                                        ...props.sx,
                                        width: '100%'
                                    }}
                                />
                            )}
                            label={'Inicio de la relación'}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            margin='dense'
                            label='Base comisionable'
                            type='number'
                            InputProps={{ inputProps: { min: 0.01 } }}
                            onChange={e =>
                                setEditingItem({
                                    ...editingItem,
                                    totalInvoiceCommissionableAmount: Number(
                                        e.target.value
                                    )
                                })
                            }
                            value={editingItem.totalInvoiceCommissionableAmount}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            margin='dense'
                            type='number'
                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                            label='Porcentaje'
                            onChange={e => {
                                const { percentage, ...item } = editingItem;
                                setEditingItem({
                                    ...item,
                                    ...{
                                        percentage: e.target.value
                                            ? Number(e.target.value)
                                            : undefined
                                    }
                                });
                            }}
                            value={editingItem.percentage ?? ''}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            margin='dense'
                            label='Total'
                            type='number'
                            InputProps={{ inputProps: { min: 0.01 } }}
                            onChange={e =>
                                setEditingItem({
                                    ...editingItem,
                                    amount: Number(e.target.value)
                                })
                            }
                            value={editingItem.amount}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={!isValid}
                    onClick={() => onSaveItem(editingItem)}
                    color='primary'
                >
                    {isEdit ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
