import React, { CSSProperties, ReactElement } from 'react';
import PlanSummary from '../../../../models/plan/PlanSummary';
import Moment from 'react-moment';
import { NumericFormat } from 'react-number-format';
import { InvoiceSettings } from '../../../../models/invoice/InvoiceSettings';
import Invoice from '../../../../models/invoice/Invoice';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    IconButton,
    Collapse,
    Button
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewIcon from '@mui/icons-material/RemoveRedEye';
import PlanService from '../../../../services/PlanService';

interface Props {
    planSummary: PlanSummary;
    children?: any;
    invoices?: Invoice[];
    invoiceSettings?: InvoiceSettings;
    arePlanCostsCollapsable?: boolean;
    arePlanCostsCollapsed?: boolean;
    onTogglePlanCosts?: () => void;
}

export default function CustomerPlanSummary(props: Props): ReactElement {
    const infoBoxStyles: CSSProperties = {
        border: '1px solid rgba(224, 224, 224, 1)',
        width: '100%',
        fontSize: '14px',
        borderRadius: '2px',
        marginBottom: '20px'
    };

    const mb30: CSSProperties = {
        marginBottom: '30px'
    };

    const ml50: CSSProperties = {
        marginLeft: '50px'
    };

    const globalSettingsVat =
        props.invoiceSettings && props.invoiceSettings.defaultVat
            ? props.invoiceSettings.defaultVat
            : 0;
    const vat =
        typeof props.planSummary.vat === 'number' &&
        !isNaN(props.planSummary.vat)
            ? props.planSummary.vat
            : globalSettingsVat;
    const { planPrice: finalCost, analyticsTierPrice } =
        PlanService.getPlanPrice(props.planSummary);
    const finalCostWithVat = PlanService.getPlanPriceWithVat(finalCost, vat);
    let togglePlanCostButton = null;
    if (props.arePlanCostsCollapsable) {
        if (!props.arePlanCostsCollapsed) {
            togglePlanCostButton = (
                <Button color='primary' onClick={props.onTogglePlanCosts}>
                    Hide costs
                    <ExpandLessIcon />
                </Button>
            );
        } else {
            togglePlanCostButton = (
                <Button color='primary' onClick={props.onTogglePlanCosts}>
                    View costs
                    <ExpandMoreIcon />
                </Button>
            );
        }
    }
    return (
        <React.Fragment>
            <div style={infoBoxStyles}>
                <div style={{ padding: '10px 20px' }}>
                    <p>
                        <b>{props.children}</b>
                    </p>
                    <div className='summary-header'>
                        <p>Plan Selected</p>
                        <span>{props.planSummary.selectedPlanName}</span>
                    </div>
                    {props.planSummary.paymentMethod && (
                        <div className='summary-header'>
                            <p>Active Payment Method</p>
                            <span>
                                {props.planSummary.paymentMethod === 'bank'
                                    ? 'Bank'
                                    : 'Credit Card'}
                            </span>
                        </div>
                    )}
                    {props.planSummary.startDate && (
                        <div className='summary-header'>
                            <p>Subscription Start Date</p>
                            <span>
                                <Moment
                                    date={props.planSummary.startDate}
                                    format='DD/MM/YYYY HH:mm'
                                />
                            </span>
                        </div>
                    )}
                    {props.planSummary.renovationDate && (
                        <div className='summary-header'>
                            <p>Subscription Renovation Date</p>
                            <span>
                                <Moment
                                    date={props.planSummary.renovationDate}
                                    format='DD/MM/YYYY HH:mm'
                                />
                            </span>
                        </div>
                    )}
                    <div className='summary-header'>
                        <p>Max Activities Hard Limit</p>
                        {props.planSummary.maxActivitiesHardLimit && (
                            <span>
                                <NumericFormat
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}
                                    value={
                                        props.planSummary.maxActivitiesHardLimit
                                    }
                                />
                            </span>
                        )}
                        {!props.planSummary.maxActivitiesHardLimit && (
                            <span>n/a</span>
                        )}
                    </div>
                    <div className='summary-header' style={mb30}>
                        <p>Max Email Activities Hard Limit</p>
                        {props.planSummary.maxEmailsHardLimit && (
                            <span>
                                <NumericFormat
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}
                                    value={props.planSummary.maxEmailsHardLimit}
                                />
                            </span>
                        )}
                        {!props.planSummary.maxEmailsHardLimit && (
                            <span>n/a</span>
                        )}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        {togglePlanCostButton}
                    </div>
                    <Collapse
                        in={
                            !props.arePlanCostsCollapsable ||
                            !props.arePlanCostsCollapsed
                        }
                        timeout='auto'
                        unmountOnExit
                        style={{ width: '100%' }}
                    >
                        <div className='summary-header'>
                            <p>Default Plan Cost</p>
                            <span>
                                €
                                <NumericFormat
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}
                                    value={props.planSummary.defaultCost || 0}
                                />
                            </span>
                        </div>
                        <div className='summary-header' style={ml50}>
                            <p>Fixed Price</p>
                            {props.planSummary.fixedPrice &&
                                PlanService.isDiscountApplicable(
                                    props.planSummary.fixedPrice
                                ) && (
                                    <span>
                                        €
                                        <NumericFormat
                                            thousandSeparator={'.'}
                                            displayType={'text'}
                                            decimalSeparator={','}
                                            value={
                                                props.planSummary.fixedPrice
                                                    .amount
                                            }
                                        />
                                    </span>
                                )}
                            {(!props.planSummary.fixedPrice ||
                                !PlanService.isDiscountApplicable(
                                    props.planSummary.fixedPrice
                                )) && <span>n/a</span>}
                        </div>
                        <div className='summary-header' style={ml50}>
                            <p>Hidden Discount</p>
                            {props.planSummary.hiddenDiscount &&
                                PlanService.isDiscountApplicable(
                                    props.planSummary.hiddenDiscount
                                ) && (
                                    <span>
                                        {props.planSummary.hiddenDiscount
                                            .isPercentage
                                            ? '%'
                                            : '€'}
                                        <NumericFormat
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            value={
                                                props.planSummary.hiddenDiscount
                                                    .amount
                                            }
                                        />
                                    </span>
                                )}
                            {(!props.planSummary.hiddenDiscount ||
                                !PlanService.isDiscountApplicable(
                                    props.planSummary.hiddenDiscount
                                )) && <span>n/a</span>}
                        </div>
                        <div className='summary-header' style={ml50}>
                            <p>Visible Discount</p>
                            {props.planSummary.visibleDiscount &&
                                PlanService.isDiscountApplicable(
                                    props.planSummary.visibleDiscount
                                ) && (
                                    <span>
                                        {props.planSummary.visibleDiscount
                                            .isPercentage
                                            ? '%'
                                            : '€'}
                                        <NumericFormat
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            value={
                                                props.planSummary
                                                    .visibleDiscount.amount
                                            }
                                        />
                                    </span>
                                )}
                            {(!props.planSummary.visibleDiscount ||
                                !PlanService.isDiscountApplicable(
                                    props.planSummary.visibleDiscount
                                )) && <span>n/a</span>}
                        </div>
                        <div className='summary-header' style={ml50}>
                            <p>
                                Analytics Tier - "
                                {props.planSummary.analyticsTier}"
                            </p>
                            <span>
                                €
                                <NumericFormat
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    value={analyticsTierPrice}
                                />
                            </span>
                        </div>
                        <div
                            className='summary-header'
                            style={
                                props.planSummary.extraItems &&
                                props.planSummary.extraItems.length > 0
                                    ? ml50
                                    : {}
                            }
                        >
                            <p>Custom Plan Cost</p>
                            <span>
                                <NumericFormat
                                    prefix=' ( + VAT € '
                                    suffix=' )'
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}
                                    value={finalCostWithVat}
                                />
                                <NumericFormat
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}
                                    prefix=' € '
                                    value={finalCost}
                                />
                            </span>
                        </div>
                    </Collapse>
                    {props.planSummary.extraItems &&
                        props.planSummary.extraItems.map(item => (
                            <div className='summary-header'>
                                <p>{item.name}</p>
                                <span>
                                    <NumericFormat
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        displayType={'text'}
                                        prefix=' € '
                                        value={item.amount}
                                    />
                                </span>
                            </div>
                        ))}
                    {props.invoices && props.invoices.length > 0 && (
                        <React.Fragment>
                            <p>
                                <b>Invoices</b>
                            </p>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Invoice Date</TableCell>
                                        <TableCell>Invoice Number</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.invoices &&
                                        props.invoices.map(invoice => (
                                            <TableRow key={invoice._id}>
                                                <TableCell
                                                    style={{ height: '23px' }}
                                                >
                                                    {invoice.invoiceDate && (
                                                        <Moment
                                                            date={
                                                                invoice.invoiceDate
                                                            }
                                                            format='DD/MM/YYYY'
                                                        ></Moment>
                                                    )}
                                                    {!invoice.invoiceDate &&
                                                        '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {invoice.invoiceNumber &&
                                                        invoice.invoicePrefix +
                                                            invoice.invoiceNumber}
                                                    {!invoice.invoiceNumber &&
                                                        '-'}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        invoice.invoiceStatus
                                                    }
                                                >
                                                    {invoice.invoiceStatus}
                                                </TableCell>
                                                <TableCell>
                                                    <NumericFormat
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        displayType={'text'}
                                                        suffix='€'
                                                        value={
                                                            invoice.totalAmount ||
                                                            0
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title='View details'>
                                                        <IconButton
                                                            style={{
                                                                padding: '0px'
                                                            }}
                                                            color='primary'
                                                            aria-label='View'
                                                            href={`/invoice/${invoice._id}`}
                                                        >
                                                            <ViewIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
