import React, { ReactElement, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow
} from '@mui/material';
import CommissionRanges from './CommissionRanges';
import CommissionDecay from './CommissionDecay';
import { Term } from '../../../../models/partner/Term';
import TermService from '../../../../services/TermService';
import { PartnerTerm } from '../../../../models/partner/PartnerTerm';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Action from '../../../../components/Action';

type Props = {
    open: boolean;
    onClose: () => void;
    onAddTerm: (term: PartnerTerm) => void;
};

export default function PredeterminedTermsDialog({
    open,
    onClose,
    onAddTerm
}: Props): ReactElement {
    const [terms, setTerms] = useState<Term[]>([]);
    useEffect(() => {
        TermService.getDefaultTerms().then(setTerms);
    }, []);

    return (
        <Dialog open={open} onClose={onClose} maxWidth='md'>
            <DialogTitle>Add Predetermined Partner Term</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Ranges</TableCell>
                            <TableCell>Decay</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {terms.map((term, index) => (
                            <TableRow key={index}>
                                <TableCell>{term.description}</TableCell>
                                <TableCell>
                                    <CommissionRanges
                                        commissionRanges={term.commissionRanges}
                                    />
                                </TableCell>
                                <TableCell>
                                    <CommissionDecay
                                        commissionDecay={term.commissionDecay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <div className='actions'>
                                        <Action
                                            label='Add'
                                            onClick={() =>
                                                onAddTerm({
                                                    ...term,
                                                    uuid: crypto.randomUUID()
                                                })
                                            }
                                            icon={ArrowForwardIcon}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
