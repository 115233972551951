import React, { ReactElement } from 'react';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import { CSSProperties } from '@mui/styles';

const NumberFormatCustom = React.forwardRef((props: any, ref: any) => {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={props.decimalScale}
            maxLength={props.maxLength}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
        />
    );
});

interface Props {
    value: any;
    required: boolean;
    min?: number;
    max?: number;
    decimalScale?: number;
    maxLength?: number;
    onChangeValue: (value?: number) => void;
    label?: string;
    id: string;
    adornment: string;
    disabled?: boolean;
    style?: CSSProperties;
}

function isEmpty(value: any): boolean {
    return value === undefined || value === null || value === '';
}

export default function NumberField(props: Props): ReactElement {
    const handleChange = (event: any) => {
        const newValue =
            event.target.value === '' ? undefined : +event.target.value;
        if (newValue !== props.value) {
            props.onChangeValue(newValue);
        }
    };

    const inputError =
        (props.required && isEmpty(props.value)) ||
        (props.min !== undefined &&
            props.value !== undefined &&
            props.value < props.min) ||
        (props.max !== undefined &&
            props.value !== undefined &&
            props.value > props.max);

    return (
        <TextField
            required={props.required}
            error={inputError}
            style={props.style}
            disabled={props.disabled}
            label={props.label}
            value={props.value === undefined ? '' : props.value}
            onChange={handleChange}
            id={props.id}
            InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                    <InputAdornment position='start'>
                        {props.adornment}
                    </InputAdornment>
                ),
                inputProps: {
                    decimalScale: props.decimalScale,
                    maxLength: props.maxLength
                }
            }}
        />
    );
}
