import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Plan from '../../../../models/plan/Plan';
import { CSSProperties } from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Customer from '../../../../models/customer/Customer';
import CustomerService from '../../../../services/CustomerService';
import { ToastVariant } from '../../../../reducers/Snackbar';
import CustomerListFilters from '../../../../models/list-filters/CustomerListFilters';
import ListItems from '../../../customer/list/components/list-items/ListItems';
import NumberField from '../../../../components/NumberField';

interface Props {
    plan?: Plan;
    isNewPlan: boolean;
    savePlan: (plan: Plan) => Promise<void>;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    plan: Plan;
    isLoading: boolean;
    filters: CustomerListFilters;
}

class PlanEditor extends Component<Props, State> {
    private customers: Customer[] = [];
    private totalCustomers = 0;

    constructor(props: Props) {
        super(props);
        if (this.props.plan) {
            this.state = {
                isLoading: false,
                plan: this.props.plan,
                filters: {
                    rowsPerPage: 10,
                    page: 0,
                    search: '',
                    plan: {
                        id: this.props.plan._id,
                        name: this.props.plan.name
                    }
                }
            };
        } else {
            this.state = {
                isLoading: false,
                plan: {
                    _id: '',
                    name: 'E0-A0',
                    maxActivitiesSoftLimit: 0,
                    maxEmailsSoftLimit: 0,
                    maxActivitiesHardLimit: 0,
                    maxEmailsHardLimit: 0,
                    price: 0,
                    createdAt: new Date()
                },
                filters: {
                    rowsPerPage: 10,
                    page: 0,
                    search: ''
                }
            };
        }
    }

    componentDidMount = () => {
        this.loadCustomers(this.state.filters);
    };

    private loadCustomers = async (filters: CustomerListFilters) => {
        this.setState({ ...this.state, isLoading: true });
        let customerList: { documents: Customer[]; totalQuantity: number } = {
            documents: [],
            totalQuantity: 0
        };
        try {
            customerList = await CustomerService.getCustomerList(filters);
        } catch {
            this.props.showToast('error', 'Error on get customer list');
        }
        this.customers = customerList.documents;
        this.totalCustomers = customerList.totalQuantity;
        this.setState({ ...this.state, isLoading: false });
    };

    private onPaginationChange = (page: number, rowsPerPage: number) => {
        const stateCopy = { ...this.state };
        stateCopy.filters.page = page;
        stateCopy.filters.rowsPerPage = rowsPerPage;
        stateCopy.isLoading = true;
        this.setState(stateCopy);
        this.loadCustomers(stateCopy.filters);
    };

    onSortByChange = (fieldId: string, direction?: 'asc' | 'desc') => {
        const stateCopy = { ...this.state };
        stateCopy.isLoading = true;
        stateCopy.filters.sortBy = fieldId;
        stateCopy.filters.direction = direction;
        this.setState(stateCopy);
        this.loadCustomers(stateCopy.filters);
    };

    private onFilterChange = (
        filterId: keyof CustomerListFilters,
        filterValue: any
    ) => {
        const stateCopy = { ...this.state };
        (stateCopy as any).filters[filterId] = filterValue;
        stateCopy.isLoading = true;
        this.setState(stateCopy);
        this.loadCustomers(stateCopy.filters);
    };

    private updatePlan = (fieldName: keyof State['plan'], newValue: any) => {
        const stateCopy = { ...this.state };
        // TODO: Fix this
        (stateCopy as any).plan[fieldName] = newValue;

        this.formatPlanOnUpdate(stateCopy.plan, fieldName);

        this.setState(stateCopy);
    };

    private formatPlanOnUpdate(
        plan: Plan,
        fieldName: keyof State['plan']
    ): void {
        if (
            fieldName === 'maxActivitiesSoftLimit' ||
            fieldName === 'maxEmailsSoftLimit'
        ) {
            plan.name = `E${this.numberToStringWithSuffix(
                plan.maxEmailsSoftLimit
            )}-A${this.numberToStringWithSuffix(plan.maxActivitiesSoftLimit)}`;
            if (+plan.maxActivitiesSoftLimit > +plan.maxActivitiesHardLimit) {
                plan.maxActivitiesHardLimit = plan.maxActivitiesSoftLimit;
            }
            if (+plan.maxEmailsSoftLimit > +plan.maxEmailsHardLimit) {
                plan.maxEmailsHardLimit = plan.maxEmailsSoftLimit;
            }
        }
        if (plan.maxActivitiesSoftLimit < 0) {
            plan.maxActivitiesSoftLimit = 0;
        }
        if (plan.maxActivitiesHardLimit < 0) {
            plan.maxActivitiesHardLimit = 0;
        }
        if (plan.maxEmailsSoftLimit < 0) {
            plan.maxEmailsSoftLimit = 0;
        }
        if (plan.maxEmailsHardLimit < 0) {
            plan.maxEmailsHardLimit = 0;
        }
        if (plan.price < 0) {
            plan.price = 0;
        }
    }

    private numberToStringWithSuffix(input: number): string {
        const suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];
        const decimals = 2;

        if (isNaN(input)) {
            return '';
        }

        if (input < 1000) {
            return input.toString();
        }

        const exp = Math.floor(Math.log(input) / Math.log(1000));

        const valueWithDecimals = input / Math.pow(1000, exp);
        const noDecimals = valueWithDecimals
            .toFixed(decimals)
            .toString()
            .endsWith('00');
        const value = noDecimals
            ? valueWithDecimals.toFixed(0)
            : valueWithDecimals.toFixed(decimals);
        return value + suffixes[exp - 1];
    }

    private saveUpdate = async () => {
        await this.props.savePlan(this.state.plan);
    };

    private buttonContainerStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px'
    };

    private inputStyles: CSSProperties = {
        width: '100%'
    };

    render(): ReactElement {
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item>
                                <Paper className='cn-paper'>
                                    <h3>Default Settings</h3>
                                    {this.state.plan && (
                                        <Grid container spacing={1}>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                spacing={3}
                                            >
                                                <Grid item xs={4} sm={4}>
                                                    <TextField
                                                        style={this.inputStyles}
                                                        required
                                                        disabled={
                                                            !this.props
                                                                .isNewPlan
                                                        }
                                                        id='name'
                                                        name='name'
                                                        label='Name'
                                                        value={
                                                            this.state.plan.name
                                                        }
                                                        onChange={event =>
                                                            this.updatePlan(
                                                                'name',
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <NumberField
                                                        style={this.inputStyles}
                                                        adornment={''}
                                                        required={false}
                                                        id='maxActivitiesSoftLimit'
                                                        disabled={
                                                            !this.props
                                                                .isNewPlan
                                                        }
                                                        label='Max Activities Soft Limit'
                                                        value={
                                                            this.state.plan
                                                                .maxActivitiesSoftLimit
                                                        }
                                                        onChangeValue={newValue =>
                                                            this.updatePlan(
                                                                'maxActivitiesSoftLimit',
                                                                newValue
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <NumberField
                                                        style={this.inputStyles}
                                                        adornment={''}
                                                        required={false}
                                                        id='maxEmailsSoftLimit'
                                                        disabled={
                                                            !this.props
                                                                .isNewPlan
                                                        }
                                                        label='Max Email Soft Limit'
                                                        value={
                                                            this.state.plan
                                                                .maxEmailsSoftLimit
                                                        }
                                                        onChangeValue={newValue =>
                                                            this.updatePlan(
                                                                'maxEmailsSoftLimit',
                                                                newValue
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <NumberField
                                                        style={this.inputStyles}
                                                        adornment={'€'}
                                                        required={false}
                                                        id='price'
                                                        label='Price'
                                                        value={
                                                            this.state.plan
                                                                .price
                                                        }
                                                        onChangeValue={newValue =>
                                                            this.updatePlan(
                                                                'price',
                                                                newValue
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <NumberField
                                                        style={this.inputStyles}
                                                        adornment={''}
                                                        required={false}
                                                        id='maxActivitiesHardLimit'
                                                        label='Max Activities Hard Limit'
                                                        value={
                                                            this.state.plan
                                                                .maxActivitiesHardLimit
                                                        }
                                                        onChangeValue={newValue =>
                                                            this.updatePlan(
                                                                'maxActivitiesHardLimit',
                                                                newValue
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <NumberField
                                                        style={this.inputStyles}
                                                        adornment={''}
                                                        required={false}
                                                        id='maxEmailsHardLimit'
                                                        label='Max Email Hard Limit'
                                                        value={
                                                            this.state.plan
                                                                .maxEmailsHardLimit
                                                        }
                                                        onChangeValue={newValue =>
                                                            this.updatePlan(
                                                                'maxEmailsHardLimit',
                                                                newValue
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                spacing={3}
                                                justifyContent='flex-end'
                                                alignItems='flex-end'
                                            >
                                                <Grid
                                                    item
                                                    xs={4}
                                                    sm={4}
                                                    style={
                                                        this
                                                            .buttonContainerStyles
                                                    }
                                                >
                                                    <Button
                                                        onClick={
                                                            this.saveUpdate
                                                        }
                                                        type='submit'
                                                        variant='contained'
                                                        color='primary'
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!this.props.isNewPlan && (
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Paper className='cn-paper'>
                                <h3>Customers using this plan</h3>
                                <ListItems
                                    rowsTotal={this.totalCustomers}
                                    rows={this.customers}
                                    filters={this.state.filters}
                                    onPaginationChange={this.onPaginationChange}
                                    onSortByChange={this.onSortByChange}
                                    onFilterChange={this.onFilterChange}
                                ></ListItems>
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </React.Fragment>
        );
    }
}
export default PlanEditor;
