import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import PlanService from '../../../services/PlanService';
import Plan from '../../../models/plan/Plan';
import { RouterMatch } from '../../../models/RouterMatch';
import { Redirect } from 'react-router';
import { ToastVariant } from '../../../reducers/Snackbar';
import BackButton from '../../../components/BackButton';
import PageHeader from '../../../components/PageHeader';
import PlanEditor from './components/PlanEditor';
import { DuplicateKeyError } from '../../../models/errors/DuplicateKeyError';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    plan?: Plan;
    existPlan: boolean;
    isNewPlan: boolean;
}

class PlanEdit extends Component<Props, State> {
    private planId = '';

    constructor(props: Props) {
        super(props);
        if (props.match.params.id) {
            this.state = {
                isLoading: true,
                existPlan: true,
                isNewPlan: false
            };
            this.planId = props.match.params.id;
            this.loadPlan(this.planId);
        } else {
            this.state = {
                isLoading: false,
                existPlan: true,
                isNewPlan: true
            };
        }
    }

    loadPlan = async (planId: string) => {
        try {
            const plan = await PlanService.getPlan(planId);
            this.setState({ ...this.state, isLoading: false, plan });
        } catch (err) {
            this.setState(
                { ...this.state, isLoading: false, existPlan: false },
                () => {
                    this.props.showToast('error', 'Plan not found');
                }
            );
        }
    };

    createPlan = async (plan: Plan) => {
        try {
            await PlanService.createPlan(plan);
            this.props.showToast('success', 'Plan created successfully');
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                if (err instanceof DuplicateKeyError) {
                    this.props.showToast(
                        'error',
                        'Duplicate key error, unique name and unique soft limits.'
                    );
                } else {
                    this.props.showToast('error', 'Error creating plan');
                }
            });
        }
    };

    savePlan = async (plan: Plan) => {
        try {
            await PlanService.editPlan(plan, plan._id);
            this.props.showToast('success', 'Plan saved successfully');
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                if (err instanceof DuplicateKeyError) {
                    this.props.showToast(
                        'error',
                        'Duplicate key error, unique name and unique soft limits.'
                    );
                } else {
                    this.props.showToast('error', 'Error saving plan');
                }
            });
        }
    };

    render(): ReactElement {
        return (
            <div className='plan-edit-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            {!this.state.isNewPlan && (
                                <PageHeader>Edit plan</PageHeader>
                            )}
                            {this.state.isNewPlan && (
                                <PageHeader>Create plan</PageHeader>
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            <BackButton href='/plan/list'>
                                Back to Plan list
                            </BackButton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {this.state.plan && (
                                <PlanEditor
                                    showToast={this.props.showToast}
                                    isNewPlan={this.state.isNewPlan}
                                    plan={this.state.plan}
                                    savePlan={this.savePlan}
                                ></PlanEditor>
                            )}
                            {this.state.isNewPlan && (
                                <PlanEditor
                                    showToast={this.props.showToast}
                                    isNewPlan={this.state.isNewPlan}
                                    savePlan={this.createPlan}
                                ></PlanEditor>
                            )}
                        </Grid>
                    </Grid>
                </Container>
                {!this.state.existPlan && !this.state.isNewPlan && (
                    <div>
                        <Redirect to='/plan/list' />
                    </div>
                )}
            </div>
        );
    }
}
export default PlanEdit;
