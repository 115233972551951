import React, { Fragment, ReactElement } from 'react';
import { Grid } from '@mui/material';
import NumberField from '../../../../components/NumberField';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CommissionDecay } from '../../../../models/partner/CommissionDecay';
import Action from '../../../../components/Action';

type Props = {
    commissionDecay: CommissionDecay[];
    onUpdate: (commissionDecay: CommissionDecay[]) => void;
};

export default function CommissionDecayList({
    commissionDecay,
    onUpdate
}: Props): ReactElement {
    function addCommissionDecay(index: number) {
        const d = commissionDecay;
        const newDecay = {
            decayPercentage: d[index] ? d[index].decayPercentage : 0,
            decayStartMonth: d[index] ? d[index].decayStartMonth + 1 : 1
        };
        onUpdate([...d.slice(0, index + 1), newDecay, ...d.slice(index + 1)]);
    }

    function updateCommissionDecay(index: number, decay: CommissionDecay) {
        const d = commissionDecay;
        onUpdate([...d.slice(0, index), decay, ...d.slice(index + 1)]);
    }

    function removeCommissionDecay(index: number) {
        const d = commissionDecay;
        onUpdate([...d.slice(0, index), ...d.slice(index + 1)]);
    }

    return (
        <Fragment>
            {commissionDecay.map((decay, index) => (
                <Grid container spacing={1} key={index} item sm={12}>
                    <Grid item sm={5}>
                        <NumberField
                            adornment={''}
                            id={`decayStartMonth-${index}`}
                            label='After month'
                            required={true}
                            min={
                                index === 0
                                    ? 1
                                    : commissionDecay[index - 1]
                                          .decayStartMonth + 1
                            }
                            max={
                                index === commissionDecay.length - 1
                                    ? undefined
                                    : commissionDecay[index + 1]
                                          .decayStartMonth - 1
                            }
                            decimalScale={0}
                            maxLength={3}
                            value={decay.decayStartMonth}
                            onChangeValue={decayStartMonth =>
                                decayStartMonth !== undefined &&
                                updateCommissionDecay(index, {
                                    ...decay,
                                    decayStartMonth
                                })
                            }
                        />
                    </Grid>
                    <Grid item sm={5}>
                        <NumberField
                            adornment={'%'}
                            required={true}
                            min={-200}
                            max={100}
                            decimalScale={1}
                            maxLength={4}
                            id={`decayPercentage-${index}`}
                            label='% of decay'
                            value={decay.decayPercentage}
                            onChangeValue={decayPercentage =>
                                decayPercentage !== undefined &&
                                updateCommissionDecay(index, {
                                    ...decay,
                                    decayPercentage
                                })
                            }
                        />
                    </Grid>
                    <Grid item sm={2} container alignItems={'flex-end'}>
                        <div className='actions'>
                            <Action
                                label='Add'
                                onClick={() => addCommissionDecay(index)}
                                icon={AddIcon}
                            />
                            <Action
                                label='Remove'
                                onClick={() => removeCommissionDecay(index)}
                                icon={RemoveIcon}
                            />
                        </div>
                    </Grid>
                </Grid>
            ))}
            {commissionDecay.length === 0 && (
                <Grid container>
                    <div className='actions'>
                        <Action
                            label='Add'
                            onClick={() => addCommissionDecay(0)}
                            icon={AddIcon}
                        />
                    </div>
                </Grid>
            )}
        </Fragment>
    );
}
