import React, { ReactElement } from 'react';
import { PlanChangeRequest } from '../../../../../models/customer/PlanChangeRequest';
import { CSSProperties } from '@mui/styles';
import Moment from 'react-moment';
import { NumericFormat } from 'react-number-format';
import Plan from '../../../../../models/plan/Plan';

interface Props {
    planChangeRequest: PlanChangeRequest;
    planChangeRequestedPlan?: Plan;
}

export default function CustomerPlanChangeRequestInfo(
    props: Props
): ReactElement {
    const infoBoxStyles: CSSProperties = {
        border: '1px solid rgba(224, 224, 224, 1)',
        width: '100%',
        fontSize: '14px',
        borderRadius: '2px',
        marginBottom: '20px'
    };

    return (
        <React.Fragment>
            <div style={infoBoxStyles}>
                <div style={{ padding: '10px 20px' }}>
                    <p>
                        <b>Plan Request</b>
                    </p>
                    <div className='summary-header'>
                        <p>Plan Requested</p>
                        {props.planChangeRequestedPlan && (
                            <span>{props.planChangeRequestedPlan.name}</span>
                        )}
                        {!props.planChangeRequestedPlan && <span>???</span>}
                    </div>
                    <div className='summary-header'>
                        <p>Request Date</p>
                        <span>
                            <Moment
                                date={props.planChangeRequest.requestDate}
                                format='DD/MM/YYYY HH:mm'
                            />
                        </span>
                    </div>
                    <div className='summary-header'>
                        <p>Page visits</p>
                        {props.planChangeRequest.pageViews && (
                            <span>
                                <NumericFormat
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}
                                    value={props.planChangeRequest.pageViews}
                                />
                            </span>
                        )}
                        {!props.planChangeRequest.pageViews && <span>???</span>}
                    </div>
                    <div className='summary-header'>
                        <p>Activities</p>
                        {props.planChangeRequest.activities && (
                            <span>
                                <NumericFormat
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}
                                    value={props.planChangeRequest.activities}
                                />
                            </span>
                        )}
                        {!props.planChangeRequest.activities && (
                            <span>???</span>
                        )}
                    </div>
                    <div className='summary-header'>
                        <p>Emails</p>
                        <span>
                            <NumericFormat
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                displayType={'text'}
                                value={props.planChangeRequest.emailActivities}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
