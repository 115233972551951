import React, { ReactElement } from 'react';
import { Tooltip, IconButton, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Link } from 'react-router-dom';

type Props = {
    label: string;
    disabled?: boolean;
    icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
    onClick?: () => void;
    linkTo?: string;
};

export default function Action({
    label,
    disabled,
    icon: Icon,
    onClick,
    linkTo
}: Props): ReactElement {
    const props = {
        ...(linkTo ? { component: Link, to: linkTo } : {})
    };
    return (
        <Tooltip title={label}>
            <span>
                <IconButton
                    color='primary'
                    {...props}
                    disabled={disabled}
                    onClick={onClick}
                >
                    <Icon />
                </IconButton>
            </span>
        </Tooltip>
    );
}
