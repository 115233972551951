import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { CustomerRequestedAnalyticsTierChangeOperationLog } from '../../../../../models/system/CustomerRequestAnalyticsTierChangeOperationLog';

const CustomerAnalyticsTier = (
    operationLog: CustomerRequestedAnalyticsTierChangeOperationLog
) => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerAccountEmail}
                </Link>{' '}
                - Env: {operationLog.customerEnv}
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>{' '}
                - Env: {operationLog.customerEnv}
            </p>
        )}
        <p>
            Request date:{' '}
            <Moment date={operationLog.date} format='DD/MM/YYYY HH:mm:ss' />{' '}
        </p>
    </div>
);

export default CustomerAnalyticsTier;
