import React, { CSSProperties, ReactElement } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import OperationLogListFilters from '../../../../../models/OperationLogListFilters';
import CustomerSelector from '../../../../../components/CustomerSelector';
import { ToastVariant } from '../../../../../reducers/Snackbar';
import OperationLogTypeKeys, {
    OperationLogNames
} from '../../../../../models/system/OperationLogTypeId';
import DateRangeFilters from '../../../../../components/DateRangeFilters';

const formStyles: CSSProperties = {
    minWidth: '350px',
    marginRight: '10px'
};

interface Props {
    filters: OperationLogListFilters;
    onFilterChange: (
        filterId: keyof OperationLogListFilters,
        filterValue: any
    ) => void;
    showToast: (variant: ToastVariant, message: string) => void;
}

function ListFilters(props: Props): ReactElement {
    const onFilterChange =
        (filterId: keyof OperationLogListFilters) => (event: any) => {
            props.onFilterChange(filterId, event.target.value);
        };

    const onChangeCustomerSelector = (customerUuid: string) => {
        props.onFilterChange('customerUUID', customerUuid);
    };

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <FormControl style={formStyles}>
                    <InputLabel htmlFor='operation-type'>
                        Operation Type
                    </InputLabel>
                    <Select
                        value={props.filters.type || ''}
                        onChange={onFilterChange('type')}
                        inputProps={{
                            name: 'Operation type',
                            id: 'operation-type'
                        }}
                    >
                        <MenuItem value=''>Not filtering</MenuItem>
                        {OperationLogTypeKeys.map((operationLogTypeKey, i) => (
                            <MenuItem key={i} value={operationLogTypeKey}>
                                {OperationLogNames[operationLogTypeKey]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <DateRangeFilters
                    filters={props.filters}
                    onFilterChange={props.onFilterChange}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl style={formStyles}>
                    <CustomerSelector
                        onChangeCustomer={onChangeCustomerSelector}
                        addEmptyOption={true}
                        selectedCustomerUuid={props.filters.customerUUID}
                        showToast={props.showToast}
                    ></CustomerSelector>
                </FormControl>
            </Grid>
        </React.Fragment>
    );
}

export default ListFilters;
