import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import ListItems from './components/list-items/ListItems';
import { ToastVariant } from '../../../reducers/Snackbar';
import { RouterMatch } from '../../../models/RouterMatch';
import Cookies from 'js-cookie';
import OperationLogListFilters from '../../../models/OperationLogListFilters';
import PageHeader from '../../../components/PageHeader';
import ListFilters from './components/list-items/ListFilters';
import { OperationLog } from '../../../models/system/OperationLog';
import OperationLogService from '../../../services/OperationLogService';
import { PagedList } from '../../../models/PagedList';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    filters: OperationLogListFilters;
}

class OperationLogList extends Component<Props, State> {
    private operationLogs: OperationLog[] = [];
    private totalOperationLogs = 0;
    private operationLogFiltersCookieName = 'opLogListFilters';
    private onFilterChangeTimer: any;

    constructor(props: Props) {
        super(props);
        const operationLogsFiltersCookie = Cookies.get(
            this.operationLogFiltersCookieName
        );
        const filters: OperationLogListFilters = operationLogsFiltersCookie
            ? JSON.parse(operationLogsFiltersCookie)
            : {
                  rowsPerPage: 10,
                  page: 0,
                  search: '',
                  sortBy: 'date',
                  direction: 'desc'
              };
        this.state = {
            isLoading: true,
            filters
        };
    }

    componentDidMount() {
        this.loadOperationLogs(this.state.filters);
    }

    loadOperationLogs = async (filters: OperationLogListFilters) => {
        let operationLogList: PagedList<OperationLog> = {
            documents: [],
            totalQuantity: 0
        };
        try {
            operationLogList =
                await OperationLogService.getOperationLogList(filters);
        } catch {
            this.props.showToast('error', 'Error on get operation log list');
        }
        this.operationLogs = operationLogList.documents;
        this.totalOperationLogs = operationLogList.totalQuantity;
        this.setState({ ...this.state, isLoading: false, filters });
    };

    onPaginationChange = (page: number, rowsPerPage: number) => {
        const stateCopy = { ...this.state };
        stateCopy.filters.page = page;
        stateCopy.filters.rowsPerPage = rowsPerPage;
        stateCopy.isLoading = true;
        this.setState(stateCopy);
        this.loadOperationLogs(stateCopy.filters);
    };

    onSortByChange = (fieldId: string, direction?: 'asc' | 'desc') => {
        const stateCopy = { ...this.state };
        stateCopy.isLoading = true;
        stateCopy.filters.sortBy = fieldId;
        stateCopy.filters.direction = direction;
        this.setState(stateCopy);
        this.loadOperationLogs(stateCopy.filters);
    };

    onFilterChange = (
        filterId: keyof OperationLogListFilters,
        filterValue: any
    ) => {
        const stateCopy = { ...this.state };
        (stateCopy as any).filters[filterId] = filterValue;
        this.setState(stateCopy);
        if (this.onFilterChangeTimer) {
            clearTimeout(this.onFilterChangeTimer);
        }
        this.onFilterChangeTimer = setTimeout(() => {
            const stateCopy = { ...this.state };
            stateCopy.isLoading = true;
            stateCopy.filters.page = 0;
            this.setState(stateCopy);
            this.loadOperationLogs(stateCopy.filters);
            this.onFilterChangeTimer = null;
        }, 500);
    };

    saveFilters = () => {
        Cookies.set(
            this.operationLogFiltersCookieName,
            JSON.stringify(this.state.filters)
        );
    };

    render(): ReactElement {
        this.saveFilters();

        return (
            <div className='operation-log-list-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>Operation log</PageHeader>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            className='cn-paper'
                            style={{ marginBottom: '15px' }}
                        >
                            <ListFilters
                                filters={this.state.filters}
                                onFilterChange={this.onFilterChange}
                                showToast={this.props.showToast}
                            ></ListFilters>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className='cn-paper'>
                            <ListItems
                                onFilterChange={this.onFilterChange}
                                filters={this.state.filters}
                                rowsTotal={this.totalOperationLogs}
                                rows={this.operationLogs}
                                onPaginationChange={this.onPaginationChange}
                                onSortByChange={this.onSortByChange}
                            ></ListItems>
                        </Paper>
                    </Grid>
                </Container>
            </div>
        );
    }
}
export default OperationLogList;
