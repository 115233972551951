import decode from 'jwt-decode';
import Cookies from 'js-cookie';
import LoginResponse from '../models/LoginResponse';
import RequestService from './RequestService';

const cookieName = 'billing_accessToken';

const AuthService = {
    isUserLoggedIn(): boolean {
        const accessToken = Cookies.get(cookieName);
        if (!accessToken) {
            return false;
        }

        const tokenPayload = decode<{ exp: number }>(accessToken);
        const currentDate = new Date().getTime() / 1000;
        if (currentDate >= tokenPayload.exp) {
            Cookies.remove(cookieName);
            return false;
        }

        return true;
    },

    getAccessToken(): string | undefined {
        return Cookies.get(cookieName);
    },

    clearAccessToken(): void {
        Cookies.remove(cookieName);
    },

    async login(username: string, password: string): Promise<LoginResponse> {
        const response = await RequestService.cnFetch('/login', {
            method: 'POST',
            body: JSON.stringify({ email: username, password })
        });

        const respObject: {
            token: string;
            error?: string;
            message: string;
        } = await response.json();
        if (respObject.error) {
            return {
                success: false,
                message: respObject.message
            } as LoginResponse;
        }

        Cookies.set(cookieName, respObject.token);
        return {
            success: true,
            accessToken: respObject.token
        } as LoginResponse;
    }
};
export default AuthService;
