import RequestService from './RequestService';
import { Partner } from '../models/partner/Partner';
import { PagedList } from '../models/PagedList';
import PartnerListFilters from '../models/PartnerListFilters';
import { PartnerSettings } from '../models/partner/PartnerSettings';

const filterPartner = ({
    _id,
    partnerInfo,
    type,
    terms,
    contractSignedDate,
    invoicePeriodicity
}: any): Partner => ({
    _id,
    partnerInfo,
    type,
    terms,
    contractSignedDate: contractSignedDate && new Date(contractSignedDate),
    invoicePeriodicity
});

const PartnerService = {
    async getPartner(partnerId: string): Promise<Partner> {
        const response = await RequestService.cnFetch(`/partner/${partnerId}`, {
            method: 'GET'
        });
        return filterPartner(await response.json());
    },

    async getPartnerList(
        listFilters: PartnerListFilters
    ): Promise<PagedList<Partner>> {
        const filters = {
            limit: listFilters.rowsPerPage,
            page: listFilters.page + 1,
            search: listFilters.search,
            type: listFilters.type,
            sortKey: listFilters.sortBy,
            sortDirection: listFilters.direction === 'asc' ? 1 : -1
        };

        const response = await RequestService.cnFetch(
            `/partner/`,
            {
                method: 'GET'
            },
            filters
        );
        const { documents, totalQuantity } = await response.json();
        return {
            documents: documents.map(filterPartner),
            totalQuantity
        };
    },

    async createPartner(partner: Partner): Promise<Partner> {
        const response = await RequestService.cnFetch(`/partner/`, {
            method: 'POST',
            body: JSON.stringify(partner)
        });
        return filterPartner(await response.json());
    },

    async editPartner(partner: Partner): Promise<Partner> {
        const { _id, ...filteredPartner } = partner;
        const response = await RequestService.cnFetch(`/partner/${_id}/edit`, {
            method: 'POST',
            body: JSON.stringify(filteredPartner, (k, v) =>
                v === undefined ? null : v
            )
        });
        return filterPartner(await response.json());
    },

    getPartnerSettings(): Promise<PartnerSettings> {
        return RequestService.cnFetch(`/settings/partner/`, {
            method: 'GET'
        }).then(response => response.json());
    },

    setPartnerSettings(partnerSettings: PartnerSettings): Promise<Response> {
        return RequestService.cnFetch(`/settings/partner/`, {
            method: 'POST',
            body: JSON.stringify(partnerSettings)
        });
    },
    deleteTerm(partnerId: string, termUuid: string): Promise<Response> {
        return RequestService.cnFetch(
            `/partner/${partnerId}/term/${termUuid}`,
            {
                method: 'DELETE'
            }
        );
    }
};

export default PartnerService;
