import React, { CSSProperties, ReactElement } from 'react';
import { TablePagination } from '@mui/material';
import PageSelector from './PageSelector';

type Props = {
    page: number;
    rowsTotal: number;
    rowsPerPage: number;
    onPaginationChange: (page: number, limit: number) => void;
};

const paginationStylesDefault: CSSProperties = {
    height: '50px',
    display: 'inline-block',
    float: 'right',
    marginTop: '10px'
};

const tablePaginationStyles: CSSProperties = {
    display: 'inline-block',
    float: 'right'
};

const paginationSelectorClasses = {
    pageSelectorStyles: {
        height: '50px',
        display: 'flex',
        float: 'right',
        alignItems: 'center',
        fontSize: '0.75rem'
    } as React.CSSProperties,
    pageSelectorLabelStyles: {
        display: 'inline-block',
        marginRight: '10px',
        fontSize: '0.75rem'
    },
    pageSelectorSelectStyles: {
        fontSize: '0.75rem'
    }
};

const Paginator = ({
    page,
    rowsTotal,
    rowsPerPage,
    onPaginationChange
}: Props): ReactElement => {
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        onPaginationChange(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        const rowsPerPage = +event.target.value;
        onPaginationChange(0, rowsPerPage);
    };

    return (
        <>
            {rowsTotal > 0 && (
                <div style={paginationStylesDefault}>
                    <TablePagination
                        sx={tablePaginationStyles}
                        rowsPerPageOptions={[10, 20, 50]}
                        component='div'
                        count={rowsTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page'
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page'
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <PageSelector
                        page={page}
                        rowsTotal={rowsTotal}
                        rowsPerPage={rowsPerPage}
                        pageSelectorClasses={paginationSelectorClasses}
                        onChange={page => handleChangePage(null, page)}
                    />
                </div>
            )}
        </>
    );
};

export default Paginator;
