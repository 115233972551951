import React, { CSSProperties, ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Spinner from '../../../components/Spinner';
import { RouterMatch } from '../../../models/RouterMatch';
import { ToastVariant } from '../../../reducers/Snackbar';
import PageHeader from '../../../components/PageHeader';
import BackButton from '../../../components/BackButton';
import { PartnerSettings } from '../../../models/partner/PartnerSettings';
import PartnerService from '../../../services/PartnerService';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    partnerSettings: PartnerSettings;
}

class PartnerSettingsPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            partnerSettings: {
                reportEmailBcc: ''
            }
        };
        this.loadPartnerSettings();
    }

    private buttonContainerStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
        marginRight: '26px',
        marginBottom: '10px'
    };

    private inputStyle: CSSProperties = {
        width: '100%',
        paddingRight: '20px'
    };

    updatePartnerSettings = (event: any) => {
        const newValue = event.target.value;
        this.setState({
            ...this.state,
            partnerSettings: {
                ...this.state.partnerSettings,
                reportEmailBcc: newValue
            }
        });
    };

    loadPartnerSettings = async () => {
        try {
            const partnerSettings = await PartnerService.getPartnerSettings();
            this.setState({ ...this.state, isLoading: false, partnerSettings });
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                this.props.showToast('error', 'Error loading partner settings');
            });
        }
    };

    saveUpdate = async () => {
        try {
            await PartnerService.setPartnerSettings(this.state.partnerSettings);
            this.props.showToast(
                'success',
                'Partner settings saved successfully'
            );
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                this.props.showToast('error', 'Error saving partner settings');
            });
        }
    };

    render(): ReactElement {
        return (
            <div className='partner-settings-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>Partner Settings</PageHeader>
                        </Grid>

                        <Grid item xs={6}>
                            <BackButton href='/partner/list'>
                                Back to Partner list
                            </BackButton>
                        </Grid>
                    </Grid>

                    {this.state.partnerSettings && (
                        <Paper className='cn-paper'>
                            <h3>Send by email BCC</h3>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id='emails-bcc'
                                            label='Emails'
                                            style={this.inputStyle}
                                            multiline
                                            fullWidth
                                            value={
                                                this.state.partnerSettings
                                                    .reportEmailBcc || ''
                                            }
                                            onChange={event =>
                                                this.updatePartnerSettings(
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={3}
                                    justifyContent='flex-end'
                                    alignItems='flex-end'
                                >
                                    <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        style={this.buttonContainerStyles}
                                    >
                                        <Button
                                            onClick={this.saveUpdate}
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </Container>
            </div>
        );
    }
}
export default PartnerSettingsPage;
