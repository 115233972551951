import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { ReportSentByEmailOperationLog } from '../../../../../models/system/ReportSentByEmailOperationLog';

const ReportView = (
    operationLog: ReportSentByEmailOperationLog
): ReactElement => (
    <div>
        <p>
            Partner:{' '}
            <Link to={`/partner/${operationLog.partnerId}/edit`}>
                {operationLog.partnerCompany}
            </Link>
        </p>
        {(!operationLog.reportLinkActive && (
            <p>
                Report: {operationLog.reportId} - Amount:{' '}
                {operationLog.reportTotalAmount}€
                <span
                    style={{
                        marginLeft: '5px',
                        fontStyle: 'italic',
                        fontSize: '10px'
                    }}
                >
                    {' '}
                    (Deleted)
                </span>
            </p>
        )) || (
            <p>
                Report:{' '}
                <Link
                    to={`/report/${operationLog.reportId}/edit/backTo/operation-log`}
                >
                    {operationLog.reportId}
                </Link>{' '}
                - Amount: {operationLog.reportTotalAmount}€
            </p>
        )}
        <p>
            Period id:{' '}
            <span style={{ fontWeight: 'bold' }}> {operationLog.periodId}</span>
            <span style={{ marginLeft: '10px' }}>
                From:{' '}
                <span style={{ fontStyle: 'italic' }}>
                    <Moment
                        date={operationLog.reportFromDate}
                        format='DD/MM/YYYY'
                    />
                </span>
            </span>
            <span style={{ marginLeft: '10px', fontStyle: 'italic' }}>
                To:{' '}
                <Moment date={operationLog.reportToDate} format='DD/MM/YYYY' />
            </span>
        </p>
        {operationLog.emails && <p>Emails: {operationLog.emails}</p>}
    </div>
);

export default ReportView;
