import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { PartnerTermEditedOperationLog } from '../../../../../models/system/PartnerTermEditedOperationLog';

const PartnerTermEdited = (
    operationLog: PartnerTermEditedOperationLog
): ReactElement => (
    <div>
        <p>
            Partner:{' '}
            <Link to={`/partner/${operationLog.partnerId}/edit`}>
                {operationLog.partnerCompany}
            </Link>
        </p>
        <p>
            Term: {operationLog.termUuid} - {operationLog.termDescription}
        </p>
        <p>Edited term: {operationLog.termDescription}</p>
    </div>
);

export default PartnerTermEdited;
