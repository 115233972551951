import React, { Fragment, ReactElement } from 'react';
import Moment from 'react-moment';

type Props = {
    date?: Date;
};

export default function DateFormat({ date }: Props): ReactElement {
    return (
        <Fragment>
            {date ? (
                <Moment locale='es' date={date} format='DD MMMM YYYY' />
            ) : (
                <div style={{ width: '100%', paddingLeft: '20px' }}>-</div>
            )}
        </Fragment>
    );
}
