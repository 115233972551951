import React, { ReactElement } from 'react';
import { Paper, Grid, Divider } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import ConnectifLogo from '../../../resources/img/logo-with-text-right.svg';
import CurrencyFormat from '../../../components/CurrencyFormat';
import InvoiceCompanyInfo from '../../invoice/edit/components/InvoiceCompanyInfo';
import { BillableReport } from '../../../models/report/BillableReport';
import BillableReportItems from './BillableReportItems';

type Props = {
    billableReport: BillableReport;
};

const headerStyles: CSSProperties = {
    margin: '20px 0px',
    lineHeight: '35px',
    display: 'inline-block'
};

export default function BillableReportDetail({
    billableReport
}: Props): ReactElement {
    const totalAmount = billableReport.totalAmount;
    return (
        <Paper className='cn-paper'>
            <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={5} sm={5}>
                        <img
                            style={headerStyles}
                            src={ConnectifLogo}
                            className='App-logo'
                            alt='logo'
                        />
                    </Grid>
                    <Grid item xs={7} sm={7}>
                        <h2 style={{ ...headerStyles, float: 'right' }}>
                            INFORME DE COMISIONES
                        </h2>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider
                            style={{ height: '5px', backgroundColor: 'black' }}
                            variant='fullWidth'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <BillableReportItems
                            items={billableReport.billableItems}
                        >
                            DETALLE DE FACTURACIÓN:
                        </BillableReportItems>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <p style={{ textAlign: 'right', margin: '0px' }}>
                            Total: <CurrencyFormat value={totalAmount} />
                        </p>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <p
                            style={{
                                fontSize: '18px',
                                marginTop: '14px'
                            }}
                        >
                            <b>Importe de base imponible a facturar:</b>
                        </p>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <p
                            style={{
                                textAlign: 'right',
                                fontSize: '24px',
                                color: '#5e91eb',
                                marginTop: '10px'
                            }}
                        >
                            <b>
                                <CurrencyFormat value={totalAmount} />
                            </b>
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider
                            style={{ height: '3px', backgroundColor: 'black' }}
                            variant='fullWidth'
                        />
                    </Grid>

                    <InvoiceCompanyInfo
                        info={billableReport.connectifInfo}
                        isEditable={false}
                        type='CompanyInfo'
                    >
                        DATOS CONNECTIF:
                    </InvoiceCompanyInfo>
                    <Grid item xs={12} sm={12}>
                        <Divider
                            style={{ height: '3px', backgroundColor: 'black' }}
                            variant='fullWidth'
                        />
                    </Grid>

                    <InvoiceCompanyInfo
                        info={billableReport.partnerInfo}
                        isEditable={false}
                        type='CompanyInfo'
                    >
                        EMISOR DE LA FACTURA:
                    </InvoiceCompanyInfo>
                </Grid>
            </Grid>
        </Paper>
    );
}
