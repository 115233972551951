import React, { Fragment, ReactElement } from 'react';
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow
} from '@mui/material';
import { PartnerTerm } from '../../../../models/partner/PartnerTerm';
import CommissionRanges from './CommissionRanges';
import CommissionDecay from './CommissionDecay';
import Alert from '../../../../components/Alert';

interface Props {
    terms: PartnerTerm[];
    renderActions: (term: PartnerTerm) => JSX.Element;
}

export default function PartnerTermList({
    terms,
    renderActions
}: Props): ReactElement {
    return (
        <Fragment>
            {terms.length === 0 && (
                <Alert
                    visible={terms.length === 0}
                    variant='info'
                    style={{ margin: 0 }}
                >
                    The partner has no terms
                </Alert>
            )}
            {terms.length > 0 && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Ranges</TableCell>
                            <TableCell>Decay</TableCell>
                            <TableCell align={'right'}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {terms.map(term => (
                            <TableRow key={term.uuid}>
                                <TableCell>{term.description}</TableCell>
                                <TableCell>
                                    <CommissionRanges
                                        commissionRanges={term.commissionRanges}
                                    />
                                </TableCell>
                                <TableCell>
                                    <CommissionDecay
                                        commissionDecay={term.commissionDecay}
                                    />
                                </TableCell>
                                <TableCell align={'right'}>
                                    {renderActions(term)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </Fragment>
    );
}
