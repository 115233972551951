import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { PartnerTermUnlinkOperationLog } from '../../../../../models/system/PartnerTermUnlinkOperationLog';

const PartnerTermUnlink = (
    operationLog: PartnerTermUnlinkOperationLog
): ReactElement => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerAccountEmail}
                </Link>{' '}
                - Env: {operationLog.customerEnv}
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>{' '}
                - Env: {operationLog.customerEnv}
            </p>
        )}
        <p>
            Partner:{' '}
            <Link to={`/partner/${operationLog.partnerId}/edit`}>
                {operationLog.partnerCompany}
            </Link>
        </p>
        <p>
            Term: {operationLog.termUuid} - {operationLog.termDescription}
        </p>
        <p>Unlink term: {operationLog.termDescription}</p>
    </div>
);

export default PartnerTermUnlink;
