import React, { ReactElement } from 'react';
import PlanSummary from '../../../../../models/plan/PlanSummary';
import CustomerPlanSummary from '../CustomerPlanSummary';
import { InvoiceSettings } from '../../../../../models/invoice/InvoiceSettings';
import './CustomerPreassignedPlanInfo.css';
import Plan from '../../../../../models/plan/Plan';
import { CustomerBillingInfo } from '../../../../../models/customer/CustomerBillingInfo';

interface Props {
    invoiceSettings?: InvoiceSettings;
    customerTimeZone?: string;
    preassignedPlan?: Plan;
    customerBillingInfo?: CustomerBillingInfo;
}

export default function CustomerPreassignedPlanInfo(
    props: Props
): ReactElement {
    let currentPlan: PlanSummary = {};
    if (props.preassignedPlan && props.customerBillingInfo) {
        currentPlan = {
            defaultCost: props.preassignedPlan.price,
            vat: props.customerBillingInfo.vat,
            selectedPlanName: props.preassignedPlan.name,
            hiddenDiscount: props.customerBillingInfo.hiddenDiscount,
            visibleDiscount: props.customerBillingInfo.visibleDiscount,
            fixedPrice: props.customerBillingInfo.fixedPrice,
            maxActivitiesHardLimit:
                props.preassignedPlan.maxActivitiesHardLimit,
            maxEmailsHardLimit: props.preassignedPlan.maxEmailsHardLimit,
            analyticsTier: props.customerBillingInfo.analyticsTier
        };
    }

    return (
        <React.Fragment>
            <CustomerPlanSummary
                invoiceSettings={props.invoiceSettings}
                planSummary={currentPlan}
            >
                Plan Summary
            </CustomerPlanSummary>
        </React.Fragment>
    );
}
