import React, { ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Moment from 'react-moment';
import Alert from '../../../../../components/Alert';
import { CSSProperties } from '@mui/styles';
import { NumericFormat } from 'react-number-format';
import Plan from '../../../../../models/plan/Plan';
import GenericListFilters from '../../../../../models/GenericListFilters';
import { Link } from 'react-router-dom';
import Paginator from '../../../../../components/Paginator';
import SortableTableHead, {
    SortDirection
} from '../../../../../components/SortableTableHead';
import { HeadRow } from '../../../../../models/list-filters/HeadRow';
import TableSubCell from '../../../../../components/TableSubCell';
import SearchBox from '../../../../../components/SearchBox';
import CurrencyFormat from '../../../../../components/CurrencyFormat';

interface Props {
    rows: Plan[];
    rowsTotal: number;
    filters: GenericListFilters;
    onPaginationChange: (page: number, limit: number) => void;
    onSortByChange: (fieldId: string, direction: SortDirection) => void;
    onFilterChange: (
        filterId: keyof GenericListFilters,
        filterValue: any
    ) => void;
}

const actionButtonStyles: CSSProperties = {
    display: 'inline-block'
};

const containerTableStyle: CSSProperties = {
    overflowX: 'auto',
    width: '100%'
};

export default function ListItems(props: Props): ReactElement {
    const headRows: HeadRow[] = [
        { id: 'name', sortable: true, label: 'Name' },
        {
            id: 'emailsLimit',
            sortable: true,
            label: 'Emails',
            subHead: [
                {
                    id: 'maxEmailsSoftLimit',
                    sortable: true,
                    label: 'Emails soft'
                },
                {
                    id: 'maxEmailsHardLimit',
                    sortable: true,
                    label: 'Emails hard'
                }
            ]
        },
        {
            id: 'activitiesLimit',
            sortable: true,
            label: 'Activities',
            subHead: [
                {
                    id: 'maxActivitiesSoftLimit',
                    sortable: true,
                    label: 'Activities soft'
                },
                {
                    id: 'maxActivitiesHardLimit',
                    sortable: true,
                    label: 'Activities hard'
                }
            ]
        },
        { id: 'price', sortable: true, label: 'Price' },
        { id: 'createdAt', sortable: true, label: 'Created On' },
        {
            id: 'customersUsingPlan',
            sortable: false,
            label: 'Customers',
            subHead: [
                {
                    id: 'customersUsingCurrentPlan',
                    sortable: false,
                    label: 'Customers current period'
                },
                {
                    id: 'customersUsingNextPlan',
                    sortable: false,
                    label: 'Customers next period'
                }
            ]
        },
        { id: 'actions', sortable: false, label: 'Actions' }
    ];

    return (
        <div>
            <div>
                <SearchBox
                    value={props.filters.search}
                    onChange={search => props.onFilterChange('search', search)}
                />
                <Paginator
                    page={props.filters.page}
                    rowsPerPage={props.filters.rowsPerPage}
                    rowsTotal={props.rowsTotal}
                    onPaginationChange={props.onPaginationChange}
                />
            </div>
            {props.rows.length > 0 && (
                <div style={containerTableStyle}>
                    <Table size='small' className={'filtered-list'}>
                        <SortableTableHead
                            headRows={headRows}
                            sortBy={props.filters.sortBy}
                            direction={props.filters.direction}
                            onSortByChange={props.onSortByChange}
                        />
                        <TableBody>
                            {props.rows.map(row => (
                                <TableRow key={row._id}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>
                                        <NumericFormat
                                            value={row.maxEmailsSoftLimit}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                        />
                                        <TableSubCell>
                                            <NumericFormat
                                                value={row.maxEmailsHardLimit}
                                                displayType={'text'}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                            />
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell>
                                        <NumericFormat
                                            value={row.maxActivitiesSoftLimit}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                        />
                                        <TableSubCell>
                                            <NumericFormat
                                                value={
                                                    row.maxActivitiesHardLimit
                                                }
                                                displayType={'text'}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                            />
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyFormat value={row.price} />
                                    </TableCell>
                                    <TableCell>
                                        <Moment
                                            date={row.createdAt}
                                            format='DD/MM/YYYY'
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            <NumericFormat
                                                value={
                                                    row.customersUsingCurrentPlan
                                                }
                                                displayType={'text'}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                            />
                                        </div>
                                        <TableSubCell>
                                            <NumericFormat
                                                value={
                                                    row.customersUsingNextPlan
                                                }
                                                displayType={'text'}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                            />
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <div className='actions'>
                                            <Tooltip
                                                title='Edit'
                                                style={actionButtonStyles}
                                            >
                                                <IconButton
                                                    color='primary'
                                                    aria-label='Edit'
                                                    component={Link}
                                                    to={`/plan/${row._id}/edit`}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
            {props.rows.length === 0 && (
                <Alert visible={props.rows.length === 0} variant='info'>
                    No items found
                </Alert>
            )}
        </div>
    );
}
