import React, { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import {
    BrowserRouter,
    Route,
    Redirect,
    RouteProps,
    Switch
} from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import LoginPage from './pages/login/Login';
import HomePage from './pages/home/home';
import AuthService from './services/AuthService';
import Sidebar from './components/Sidebar';
import InvoiceList from './pages/invoice/list/InvoiceList';
import PlanList from './pages/plan/list/PlanList';
import InvoiceEdit from './pages/invoice/edit/InvoiceEdit';
import CustomerList from './pages/customer/list/CustomerList';
import Notifier from './components/Notifier';
import InvoiceSettingsPage from './pages/invoice/settings/InvoiceSettings';
import { ToastVariant } from './reducers/Snackbar';
import PlanSettingsPage from './pages/plan/settings/PlanSettings';
import PlanEdit from './pages/plan/edit/PlanEdit';
import CustomerEdit from './pages/customer/edit/CustomerEdit';
import InvoiceView from './pages/invoice/view/InvoiceView';
import SubscriptionList from './pages/subscription/list/SubscriptionList';
import OperationLogList from './pages/system/operation-log/OperationLogList';
import PartnerEdit from './pages/partner/edit/PartnerEdit';
import PartnerList from './pages/partner/list/PartnerList';
import ReportView from './pages/report/ReportView';
import ReportList from './pages/report/ReportList';
import ReportEdit from './pages/report/ReportEdit';
import PartnerSettingsPage from './pages/partner/settings/PartnerSettings';
import BillableReportView from './pages/report/BillableReportView';

interface Props {
    addToast?(payload: any): void;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
    addToast: (payload: any) => dispatch({ type: 'ADD_TOAST', payload })
});

interface State {
    isSidebarExpanded: boolean;
    isUserLoggedIn: boolean;
    showToast: boolean;
}

class App extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const isUserLoggedIn = AuthService.isUserLoggedIn();
        this.state = {
            isSidebarExpanded: false,
            isUserLoggedIn,
            showToast: false
        };
    }

    onUserLoginSuccess() {
        this.setState({ ...this.state, isUserLoggedIn: true });
    }

    logOut() {
        AuthService.clearAccessToken();
        this.setState({ ...this.state, isUserLoggedIn: false });
    }

    expandMenu() {
        this.setState({
            ...this.state,
            isSidebarExpanded: !this.state.isSidebarExpanded
        });
    }

    onShowToast(variant: ToastVariant, message: string) {
        if (this.props.addToast) {
            this.props.addToast({ variant, message });
        }
    }

    render() {
        const PrivateRoute = (props: RouteProps) => {
            const RouteComponent = props.component as any;
            const { component, ...rest } = props;
            return (
                <Route
                    {...rest}
                    render={props =>
                        this.state.isUserLoggedIn === true ? (
                            <RouteComponent
                                {...props}
                                showToast={this.onShowToast.bind(this)}
                            />
                        ) : (
                            <Redirect to='/login' />
                        )
                    }
                />
            );
        };

        // TODO add route 404 or not match redirect
        return (
            <Fragment>
                <BrowserRouter>
                    <CompatRouter>
                        <Switch>
                            <Fragment>
                                <div
                                    className={
                                        this.state.isUserLoggedIn
                                            ? 'app-body'
                                            : ''
                                    }
                                >
                                    <Route
                                        path='/login'
                                        render={props => (
                                            <LoginPage
                                                {...props}
                                                isUserLoggedIn={
                                                    this.state.isUserLoggedIn
                                                }
                                                onUserLoginSuccess={this.onUserLoginSuccess.bind(
                                                    this
                                                )}
                                            />
                                        )}
                                    />
                                    <Switch>
                                        <PrivateRoute
                                            exact
                                            path='/'
                                            component={HomePage}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/plan/list'
                                            component={PlanList}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/plan/settings'
                                            component={PlanSettingsPage}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/plan/create'
                                            component={PlanEdit}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/plan/:id/edit'
                                            component={PlanEdit}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/customer/list'
                                            component={CustomerList}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/customer/:uuid/edit'
                                            component={CustomerEdit}
                                        />

                                        <PrivateRoute
                                            exact
                                            path='/invoice/settings'
                                            component={InvoiceSettingsPage}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/invoice/list'
                                            component={InvoiceList}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/invoice/create'
                                            component={InvoiceEdit}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/invoice/:id'
                                            component={InvoiceView}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/invoice/:id/edit'
                                            component={InvoiceEdit}
                                        />

                                        <PrivateRoute
                                            exact
                                            path='/subscription/notbilled'
                                            component={SubscriptionList}
                                        />

                                        <PrivateRoute
                                            exact
                                            path='/partner/list'
                                            component={PartnerList}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/partner/create'
                                            component={PartnerEdit}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/partner/:id/edit'
                                            component={PartnerEdit}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/partner/settings'
                                            component={PartnerSettingsPage}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/report/list'
                                            component={ReportList}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/report/:id/backTo/:backTo/:uuid?'
                                            component={ReportView}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/report/:id/billable-info/backTo/:backTo/:uuid?'
                                            component={BillableReportView}
                                        />
                                        <PrivateRoute
                                            exact
                                            path='/report/:id/edit/backTo/:backTo'
                                            component={ReportEdit}
                                        />

                                        <PrivateRoute
                                            exact
                                            path='/system/operation-log'
                                            component={OperationLogList}
                                        />
                                    </Switch>
                                </div>
                            </Fragment>
                        </Switch>
                        {!this.state.isUserLoggedIn && <Redirect to='/login' />}
                        {this.state.isUserLoggedIn && (
                            <Sidebar onUserLogout={this.logOut.bind(this)} />
                        )}
                    </CompatRouter>
                </BrowserRouter>
                <Notifier />
            </Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
