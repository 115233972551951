import React, { CSSProperties, ReactElement } from 'react';
import { Component } from 'react';
import { InvoiceItem } from '../../../../models/invoice/InvoiceItem';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Grid,
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ReactMoment from 'react-moment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Big from 'big.js';
import { NumericFormat } from 'react-number-format';
import * as moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
    items?: InvoiceItem[];
    isEditable?: boolean;
    handleAddItem?: (item: InvoiceItem) => void;
    handleUpdateItem: (item: InvoiceItem, indexItem: number) => void;
    handleRemoveItem?: (index: number) => void;
}

interface State {
    invoiceItems: InvoiceItem[];
    isDialogOpen: boolean;
    modalItem: InvoiceItem;
    itemEditIndex?: number;
    isItemEdit: boolean;
}

const formControlStyles: CSSProperties = {
    marginTop: '5px'
};

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}))(Tooltip);

class InvoiceItems extends Component<Props, State> {
    private isEditable = !!this.props.isEditable;
    private emptyItem: InvoiceItem = {
        amount: 0,
        finishDisplayDate: moment.utc().startOf('day').toDate(),
        startDisplayDate: moment.utc().startOf('day').toDate(),
        name: '',
        quantity: 0,
        unitPrice: 0,
        type: 'plan'
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            invoiceItems: [],
            isDialogOpen: false,
            isItemEdit: false,
            modalItem: { ...this.emptyItem }
        };
    }

    private actionButtonStyles: CSSProperties = {
        display: 'inline-block'
    };

    private itemShopifyInfo: CSSProperties = {
        marginLeft: '15px',
        verticalAlign: 'middle'
    };

    private handleCloseModal = () => {
        this.setState({ ...this.state, isDialogOpen: false });
    };

    private handleOpenModal = (itemIndex?: number) => {
        const stateCopy = { ...this.state };
        stateCopy.modalItem = { ...this.emptyItem };
        if (
            itemIndex !== undefined &&
            this.props.items &&
            this.props.items.length > itemIndex
        ) {
            stateCopy.modalItem = { ...this.props.items[itemIndex] };
            stateCopy.itemEditIndex = itemIndex;
            stateCopy.isItemEdit = true;
        }
        stateCopy.isDialogOpen = true;
        this.setState(stateCopy);
    };

    private handleRemoveItemList = (index: number) => {
        if (this.props.handleRemoveItem) {
            this.props.handleRemoveItem(index);
        }
    };

    private handleAddItemModal = () => {
        if (this.props.handleAddItem) {
            this.props.handleAddItem(this.state.modalItem);
        }
        this.setState({
            ...this.state,
            isDialogOpen: false,
            modalItem: { ...this.emptyItem }
        });
    };

    private updateModalItem = (fieldName: keyof InvoiceItem, event: any) => {
        const newValue = event.target.value;
        const stateCopy = { ...this.state };
        (stateCopy.modalItem[fieldName] as any) = newValue;
        if (
            (fieldName === 'quantity' && stateCopy.modalItem.quantity) ||
            (fieldName === 'unitPrice' && stateCopy.modalItem.unitPrice)
        ) {
            stateCopy.modalItem.amount = +Big(stateCopy.modalItem.quantity)
                .times(stateCopy.modalItem.unitPrice)
                .toFixed(2);
            stateCopy.modalItem.unitPrice = +Big(stateCopy.modalItem.unitPrice);
        }
        if (fieldName === 'amount' && stateCopy.modalItem.amount) {
            stateCopy.modalItem.amount = +Big(
                stateCopy.modalItem.amount
            ).toFixed(2);
        }
        this.setState(stateCopy);
    };

    private updateItem = () => {
        const stateCopy = { ...this.state };
        const itemIndex = this.state.itemEditIndex;
        if (
            itemIndex !== undefined &&
            this.props.items &&
            this.props.items.length > itemIndex
        ) {
            this.props.handleUpdateItem(stateCopy.modalItem, itemIndex);
        }
        stateCopy.modalItem = { ...this.emptyItem };
        stateCopy.isItemEdit = false;
        stateCopy.isDialogOpen = true;
        stateCopy.isDialogOpen = false;
        this.setState(stateCopy);
    };

    private updateDate = (
        fieldName: keyof InvoiceItem,
        date?: moment.Moment | null
    ) => {
        if (date) {
            const value = date.utc().startOf('day').format();
            this.setState({
                modalItem: {
                    ...this.state.modalItem,
                    [fieldName]: value
                }
            });
        }
    };

    render(): ReactElement {
        return (
            <React.Fragment>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Concepto</TableCell>
                            <TableCell>Periodo</TableCell>
                            <TableCell align='right'>Unidades</TableCell>
                            <TableCell align='right'>
                                Precio unitario €
                            </TableCell>
                            <TableCell align='right'>Precio total €</TableCell>
                            <TableCell align='right'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.items &&
                            this.props.items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>
                                        {item.startDisplayDate &&
                                            item.finishDisplayDate && (
                                                <div>
                                                    <ReactMoment
                                                        date={
                                                            item.startDisplayDate
                                                        }
                                                        format='DD/MM/YYYY'
                                                    />{' '}
                                                    -{' '}
                                                    <ReactMoment
                                                        date={
                                                            item.finishDisplayDate
                                                        }
                                                        format='DD/MM/YYYY'
                                                    />
                                                </div>
                                            )}
                                    </TableCell>
                                    <TableCell align='right'>
                                        <NumericFormat
                                            fixedDecimalScale={true}
                                            decimalScale={0}
                                            value={item.quantity}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                        />
                                    </TableCell>
                                    <TableCell align='right'>
                                        <NumericFormat
                                            fixedDecimalScale={true}
                                            decimalScale={
                                                item.unitPrice
                                                    .toString()
                                                    .indexOf('.') >= 0
                                                    ? Math.max(
                                                          item.unitPrice
                                                              .toString()
                                                              .split('.')[1]
                                                              .length,
                                                          2
                                                      )
                                                    : 2
                                            }
                                            value={item.unitPrice}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            suffix={'€'}
                                        />
                                    </TableCell>
                                    <TableCell align='right'>
                                        <NumericFormat
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            value={item.amount}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            suffix={'€'}
                                        />
                                        {item.shopifyReceiptUsageChargeItemResult && (
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <div
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                marginBottom:
                                                                    '5px'
                                                            }}
                                                        >
                                                            {
                                                                'Item Charge Result '
                                                            }
                                                        </div>
                                                        <div>
                                                            {'Id: '}
                                                            {
                                                                item
                                                                    .shopifyReceiptUsageChargeItemResult
                                                                    ?.id
                                                            }
                                                        </div>
                                                        <div>
                                                            {'Status: '}
                                                            {
                                                                item
                                                                    .shopifyReceiptUsageChargeItemResult
                                                                    ?.status
                                                            }
                                                        </div>
                                                        {item
                                                            .shopifyReceiptUsageChargeItemResult
                                                            ?.status ===
                                                            'error' &&
                                                            item
                                                                .shopifyReceiptUsageChargeItemResult
                                                                .errorMessage && (
                                                                <div>
                                                                    {
                                                                        'Error message: '
                                                                    }
                                                                    {
                                                                        item
                                                                            .shopifyReceiptUsageChargeItemResult
                                                                            .errorMessage
                                                                    }
                                                                </div>
                                                            )}
                                                        {item
                                                            .shopifyReceiptUsageChargeItemResult
                                                            ?.status ===
                                                            'error' &&
                                                            item
                                                                .shopifyReceiptUsageChargeItemResult
                                                                .errorCode && (
                                                                <div>
                                                                    {
                                                                        'Error code: '
                                                                    }
                                                                    {
                                                                        item
                                                                            .shopifyReceiptUsageChargeItemResult
                                                                            .errorCode
                                                                    }
                                                                </div>
                                                            )}
                                                    </React.Fragment>
                                                }
                                            >
                                                <span
                                                    style={{
                                                        ...this.itemShopifyInfo,
                                                        color:
                                                            item
                                                                .shopifyReceiptUsageChargeItemResult
                                                                .status !==
                                                            'error'
                                                                ? 'green'
                                                                : 'red'
                                                    }}
                                                >
                                                    <InfoIcon />
                                                </span>
                                            </HtmlTooltip>
                                        )}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {this.isEditable && (
                                            <div className='actions'>
                                                <Tooltip
                                                    title='Edit'
                                                    style={
                                                        this.actionButtonStyles
                                                    }
                                                >
                                                    <div>
                                                        <IconButton
                                                            color='primary'
                                                            aria-label='Delete'
                                                            onClick={() =>
                                                                this.handleOpenModal(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip
                                                    title='Delete'
                                                    style={
                                                        this.actionButtonStyles
                                                    }
                                                >
                                                    <div>
                                                        <IconButton
                                                            color='primary'
                                                            aria-label='Delete'
                                                            onClick={() =>
                                                                this.handleRemoveItemList(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <CancelIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {this.isEditable && (
                    <div style={{ textAlign: 'right', marginTop: '20px' }}>
                        <Button
                            onClick={() => this.handleOpenModal()}
                            variant='contained'
                            color='primary'
                        >
                            Add
                        </Button>
                    </div>
                )}
                <Dialog
                    open={this.state.isDialogOpen}
                    onClose={this.handleCloseModal}
                    aria-labelledby='form-dialog-title'
                >
                    <DialogTitle id='form-dialog-title'>
                        Add new item
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={9} sm={9}>
                                <TextField
                                    autoFocus
                                    margin='dense'
                                    required={true}
                                    error={!this.state.modalItem.name}
                                    id='name'
                                    label='Concepto'
                                    onChange={event =>
                                        this.updateModalItem('name', event)
                                    }
                                    value={this.state.modalItem.name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <FormControl style={formControlStyles}>
                                    <InputLabel htmlFor='type'>Type</InputLabel>
                                    <Select
                                        value={this.state.modalItem.type}
                                        onChange={event =>
                                            this.updateModalItem('type', event)
                                        }
                                        inputProps={{
                                            name: 'Type',
                                            id: 'type'
                                        }}
                                        fullWidth
                                        required={true}
                                        error={!this.state.modalItem.type}
                                    >
                                        <MenuItem value={'plan'}>Plan</MenuItem>
                                        <MenuItem value={'plan-discount'}>
                                            Plan Discount
                                        </MenuItem>
                                        <MenuItem value={'plan-overage'}>
                                            Plan Overage
                                        </MenuItem>
                                        <MenuItem value={'plan-refund'}>
                                            Plan Refund
                                        </MenuItem>
                                        <MenuItem value={'performance'}>
                                            Performance
                                        </MenuItem>
                                        <MenuItem value={'infrastructure'}>
                                            Infrastructure
                                        </MenuItem>
                                        <MenuItem value={'other'}>
                                            Other
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    margin='dense'
                                    id='quantity'
                                    label='Unidades'
                                    type='number'
                                    onChange={event =>
                                        this.updateModalItem('quantity', event)
                                    }
                                    value={this.state.modalItem.quantity}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    margin='dense'
                                    id='unitPrice'
                                    type='number'
                                    label='Precio unitario'
                                    onChange={event =>
                                        this.updateModalItem('unitPrice', event)
                                    }
                                    value={this.state.modalItem.unitPrice}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    margin='dense'
                                    type='number'
                                    id='amount'
                                    label='Total'
                                    onChange={event =>
                                        this.updateModalItem('amount', event)
                                    }
                                    value={this.state.modalItem.amount}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <DesktopDatePicker
                                    inputFormat='DD/MM/YYYY'
                                    value={
                                        this.state.modalItem.startDisplayDate
                                    }
                                    onChange={(date: moment.Moment | null) =>
                                        this.updateDate(
                                            'startDisplayDate',
                                            date
                                        )
                                    }
                                    renderInput={props => (
                                        <TextField
                                            {...props}
                                            sx={{
                                                ...props.sx,
                                                width: '100%'
                                            }}
                                        />
                                    )}
                                    label={'Desde'}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <DesktopDatePicker
                                    inputFormat='DD/MM/YYYY'
                                    value={
                                        this.state.modalItem.finishDisplayDate
                                    }
                                    onChange={(date: moment.Moment | null) =>
                                        this.updateDate(
                                            'finishDisplayDate',
                                            date
                                        )
                                    }
                                    renderInput={props => (
                                        <TextField
                                            {...props}
                                            sx={{
                                                ...props.sx,
                                                width: '100%'
                                            }}
                                        />
                                    )}
                                    label={'Hasta'}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseModal} color='primary'>
                            Cancel
                        </Button>
                        {!this.state.isItemEdit && (
                            <Button
                                disabled={
                                    !this.state.modalItem.name ||
                                    !this.state.modalItem.type
                                }
                                onClick={this.handleAddItemModal}
                                color='primary'
                            >
                                Add
                            </Button>
                        )}
                        {this.state.isItemEdit && (
                            <Button
                                disabled={
                                    !this.state.modalItem.name ||
                                    !this.state.modalItem.type
                                }
                                onClick={this.updateItem}
                                color='primary'
                            >
                                Update
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}
export default InvoiceItems;
