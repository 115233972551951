import { createTheme, SimplePaletteColorOptions } from '@mui/material';

const primaryColor: SimplePaletteColorOptions = {
    main: '#5e91eb'
};
const Theme = createTheme({
    typography: {
        fontSize: 12
    },
    palette: {
        primary: primaryColor
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard'
            }
        }
    }
});

export default Theme;
