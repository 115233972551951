import React, { ReactElement } from 'react';
import { CommissionDecay as CommissionDecayType } from '../../../../models/partner/CommissionDecay';
import { NumericFormat } from 'react-number-format';

type Props = {
    commissionDecay: CommissionDecayType[];
};

export default function CommissionDecay({
    commissionDecay
}: Props): ReactElement {
    return (
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
            {commissionDecay.map((decay, index) => (
                <li key={index}>
                    {'After month '}
                    <NumericFormat
                        fixedDecimalScale={true}
                        value={decay.decayStartMonth}
                        displayType={'text'}
                    />
                    {': '}
                    <NumericFormat
                        fixedDecimalScale={true}
                        decimalScale={1}
                        value={decay.decayPercentage}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={'%'}
                    />
                </li>
            ))}
        </ul>
    );
}
