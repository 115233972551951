import RequestService from './RequestService';
import { Term } from '../models/partner/Term';

const TermService = {
    async getDefaultTerms(): Promise<Term[]> {
        const response = await RequestService.cnFetch(`/term/default`, {
            method: 'GET'
        });
        return response.json();
    }
};

export default TermService;
