import React from 'react';
import { Link } from 'react-router-dom';
import { CustomerNameUpdateOperationLog } from '../../../../../models/system/CustomerNameUpdateOperationLog';

const CustomerNameUpdate = (operationLog: CustomerNameUpdateOperationLog) => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerId}
                </Link>
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>
            </p>
        )}
        <p>New name: "{operationLog.customerNewName}"</p>
    </div>
);

export default CustomerNameUpdate;
