import React, { ReactElement } from 'react';
import { CSSProperties } from '@mui/styles';
import { Component } from 'react';
import { Tooltip } from '@mui/material';
import Moment from 'react-moment';
import moment from 'moment-timezone';

interface Props {
    date?: Date;
    customTimezone?: string | null;
    style?: CSSProperties;
}

const dateOtherTimeZoneStyle: CSSProperties = {
    marginLeft: '5px'
};

const format = 'DD/MM/YYYY HH:mm';

class DateBlock extends Component<Props> {
    render(): ReactElement {
        const { date, customTimezone, style } = this.props;
        const utcName = this.getUtcName(date, customTimezone);
        return date ? (
            <div style={style}>
                <Moment date={date} format={format} />
                {customTimezone && (
                    <Tooltip
                        title={'Timezone: ' + customTimezone}
                        placement='top'
                    >
                        <span style={dateOtherTimeZoneStyle}>
                            [
                            <Moment
                                date={date}
                                format={format}
                                tz={customTimezone}
                            />{' '}
                            {utcName} ]
                        </span>
                    </Tooltip>
                )}
            </div>
        ) : (
            <div>-</div>
        );
    }

    getUtcName(date?: Date, customTimezone?: string | null): string {
        const offset: number | null =
            date && customTimezone
                ? (moment(date) as any).tz(customTimezone)._offset
                : null;
        if (typeof offset === 'number') {
            const hoursOffset = offset / 60;
            const minutesOffset = Math.abs(offset % 60);
            const hoursOffsetText = (hoursOffset >= 0 ? '+' : '') + hoursOffset;
            if (minutesOffset !== 0) {
                return `UTC${hoursOffsetText}:${minutesOffset}`;
            }
            return `UTC${hoursOffsetText}`;
        }
        return '';
    }
}

export default DateBlock;
