import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { Component, CSSProperties } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AuthService from '../../services/AuthService';
import ValidatorService from '../../services/ValidatorService';
import logo from '../../resources/img/logo-with-text-right.svg';
import './Login.css';

// Resources
import bkLoginImage from '../../resources/img/login-bg.jpeg';

interface Props {
    isUserLoggedIn: boolean;
    onUserLoginSuccess: () => void;
}

interface State {
    isUserLoggedIn: boolean;
    password: string;
    showValidationErrors: boolean;
    email: string;
    emailHelperText: string;
    emailInputError: boolean;
    invalidCredentials: boolean;
}

const bgContainerStyles: CSSProperties = {
    backgroundImage: `url(${bkLoginImage})`
};

class LoginPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showValidationErrors: false,
            isUserLoggedIn: this.props.isUserLoggedIn,
            password: '',
            email: '',
            emailHelperText: '',
            emailInputError: false,
            invalidCredentials: false
        };
    }

    handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault();

        try {
            const response = await AuthService.login(
                this.state.email,
                this.state.password
            );
            if (!response.success) {
                this.setState({ ...this.state, invalidCredentials: true });
                return;
            }

            if (response.success && response.accessToken) {
                this.props.onUserLoginSuccess();
                this.setState({ ...this.state, isUserLoggedIn: true });
            }
        } catch {
            this.setState({ ...this.state, invalidCredentials: true });
        }
    };

    updateEmailInput = (event: any) => {
        const email = event.target.value;
        let emailHelperText = '';
        let emailInputError = false;
        if (!ValidatorService.validateEmail(email)) {
            emailInputError = true;
            emailHelperText = 'Invalid email';
        }
        this.setState({
            ...this.state,
            email,
            emailInputError,
            emailHelperText
        });
    };

    updatePasswordInput = (event: any) => {
        this.setState({ ...this.state, password: event.target.value });
    };

    render(): ReactElement {
        if (this.state.isUserLoggedIn === true) {
            return <Redirect to='/' />;
        }

        return (
            <div className={'login-bg-container'} style={bgContainerStyles}>
                <div className={'login-container'}>
                    <Container component='main' maxWidth='xs'>
                        <CssBaseline />
                        <Card>
                            <CardContent>
                                <div className='logo-container'>
                                    <img
                                        src={logo}
                                        className='App-logo'
                                        alt='logo'
                                    />
                                </div>
                                <div className='logo-title'>
                                    <Typography component='h1' variant='h5'>
                                        BILLING
                                    </Typography>
                                </div>

                                {this.state.invalidCredentials && (
                                    <Typography
                                        component='h5'
                                        className={'error'}
                                    >
                                        Invalid credentials
                                    </Typography>
                                )}

                                <form noValidate onSubmit={this.handleSubmit}>
                                    <TextField
                                        variant='outlined'
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='email'
                                        label='Email Address'
                                        name='email'
                                        autoComplete='email'
                                        helperText={this.state.emailHelperText}
                                        error={this.state.emailInputError}
                                        onChange={this.updateEmailInput}
                                        autoFocus
                                    />
                                    <TextField
                                        variant='outlined'
                                        margin='normal'
                                        required
                                        fullWidth
                                        name='password'
                                        label='Password'
                                        type='password'
                                        id='password'
                                        onChange={this.updatePasswordInput}
                                        autoComplete='current-password'
                                    />
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                    >
                                        Sign In
                                    </Button>
                                </form>
                            </CardContent>
                        </Card>
                    </Container>
                </div>
            </div>
        );
    }
}
export default LoginPage;
