import React, { useState, useEffect, Fragment, ReactElement } from 'react';
import ReportService from '../../services/ReportService';
import { RouterMatch } from '../../models/RouterMatch';
import Spinner from '../../components/Spinner';
import { Container, Grid } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import ReportBackButton from './components/ReportBackButton';
import { PropsWithShowToast } from '../../PropsWithShowToast';
import { Redirect } from 'react-router';
import { getBackToLink } from './components/getBackToLink';
import BillableReportDetail from './components/BillableReportDetail';
import { BillableReport } from '../../models/report/BillableReport';

type Props = PropsWithShowToast<{
    match: RouterMatch;
}>;

export default function BillableReportView({
    match,
    showToast
}: Props): ReactElement {
    const [billableReport, setBillableReport] = useState<BillableReport>();
    const [errorLoadingReport, setReportLoadingReport] = useState(false);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const reportId = match.params.id;
        if (reportId) {
            setLoading(true);
            ReportService.getBillableInfo(reportId)
                .then(setBillableReport)
                .catch(error => {
                    showToast('error', error.message);
                    setReportLoadingReport(true);
                })
                .finally(() => setLoading(false));
        }
    }, [match, showToast]);

    const { backTo, uuid } = match.params;
    return (
        <Fragment>
            <Spinner visible={isLoading} />
            {errorLoadingReport && (
                <Redirect
                    to={getBackToLink(backTo, billableReport?.partnerId, uuid)}
                />
            )}
            {billableReport && (
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>View Partner Report</PageHeader>
                        </Grid>
                        <Grid item xs={6}>
                            <ReportBackButton
                                backTo={backTo}
                                partnerId={billableReport?.partnerId}
                                customerUuid={uuid}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <BillableReportDetail billableReport={billableReport} />
                    </Grid>
                </Container>
            )}
        </Fragment>
    );
}
