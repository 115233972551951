import React, { Fragment, ReactElement } from 'react';
import { Grid } from '@mui/material';
import PercentageEuroSwitch from '../../../../components/PercentageEuroSwitch';
import NumberField from '../../../../components/NumberField';
import { CommissionRange } from '../../../../models/partner/CommissionRange';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Action from '../../../../components/Action';

type Props = {
    commissionRanges: CommissionRange[];
    onUpdate: (commissionRanges: CommissionRange[]) => void;
};

export default function CommissionRangeList({
    commissionRanges,
    onUpdate
}: Props): ReactElement {
    function addCommissionRange(index: number) {
        const r = commissionRanges;
        const newRange: CommissionRange = {
            ...r[index],
            amountFrom: r[index].amountFrom + 1
        };
        onUpdate([...r.slice(0, index + 1), newRange, ...r.slice(index + 1)]);
    }

    function updateCommissionRange(index: number, range: CommissionRange) {
        const r = commissionRanges;
        onUpdate([...r.slice(0, index), range, ...r.slice(index + 1)]);
    }

    function removeCommissionRange(index: number) {
        const r = commissionRanges;
        onUpdate([...r.slice(0, index), ...r.slice(index + 1)]);
    }

    return (
        <Fragment>
            {commissionRanges.map((range, index) => (
                <Grid container spacing={1} key={index} item xs={12} sm={12}>
                    <Grid item sm={5}>
                        <NumberField
                            adornment={'€'}
                            required={true}
                            disabled={index === 0}
                            min={
                                index === 0
                                    ? undefined
                                    : commissionRanges[index - 1].amountFrom +
                                      0.01
                            }
                            max={
                                index === commissionRanges.length - 1
                                    ? undefined
                                    : commissionRanges[index + 1].amountFrom -
                                      0.01
                            }
                            decimalScale={2}
                            maxLength={10}
                            id={`amountFrom-${index}`}
                            label='Amount From'
                            value={range.amountFrom}
                            onChangeValue={amountFrom =>
                                amountFrom !== undefined &&
                                updateCommissionRange(index, {
                                    ...range,
                                    amountFrom
                                })
                            }
                        />
                    </Grid>
                    <Grid item sm={5}>
                        <NumberField
                            adornment={range.isPercentage ? '%' : '€'}
                            required={true}
                            min={0}
                            max={range.isPercentage ? 100 : undefined}
                            decimalScale={range.isPercentage ? 4 : 2}
                            maxLength={range.isPercentage ? 7 : 10}
                            id={`amount-${index}`}
                            label='Amount'
                            style={{ width: '120px' }}
                            value={range.amount}
                            onChangeValue={amount =>
                                amount !== undefined &&
                                updateCommissionRange(index, {
                                    ...range,
                                    amount
                                })
                            }
                        />
                        <PercentageEuroSwitch
                            isPercentage={range.isPercentage}
                            onChange={isPercentage =>
                                updateCommissionRange(index, {
                                    ...range,
                                    isPercentage
                                })
                            }
                        />
                    </Grid>
                    <Grid item sm={2} container alignContent={'flex-end'}>
                        <div className='actions'>
                            <Action
                                label='Add'
                                onClick={() => addCommissionRange(index)}
                                icon={AddIcon}
                            />
                            {index > 0 && (
                                <Action
                                    label='Remove'
                                    onClick={() => removeCommissionRange(index)}
                                    icon={RemoveIcon}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            ))}
        </Fragment>
    );
}
