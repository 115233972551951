import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { InvoiceSentByEmailOperationLog } from '../../../../../models/system/InvoiceSentByEmailOperationLog';
import InvoiceLinkActiveView from './InvoiceLinkActiveView';

const InvoiceSentByEmail = (
    operationLog: InvoiceSentByEmailOperationLog
): ReactElement => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerAccountEmail}
                </Link>
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>
            </p>
        )}
        <InvoiceLinkActiveView
            invoiceId={operationLog.invoiceId}
            invoiceTotalAmount={operationLog.invoiceTotalAmount}
            invoiceLinkActive={operationLog.invoiceLinkActive}
        ></InvoiceLinkActiveView>
        <p>Sent to: {operationLog.emails}</p>
    </div>
);

export default InvoiceSentByEmail;
