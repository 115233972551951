import React, { useState, useEffect, ReactElement } from 'react';
import {
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    TextField
} from '@mui/material';
import PartnerTermSelectDialog from './PartnerTermSelectDialog';
import { AssignedPartnerTerm } from '../../../../models/customer/AssignedPartnerTerm';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '../../../../components/Alert';
import ReactMoment from 'react-moment';
import Partner from '../../../../models/partner/Partner';
import PartnerService from '../../../../services/PartnerService';
import CommissionRanges from '../../../partner/edit/components/CommissionRanges';
import CommissionDecay from '../../../partner/edit/components/CommissionDecay';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Action from '../../../../components/Action';
import CustomerReports from './CustomerReports';
import { Link } from 'react-router-dom';
import { PropsWithShowToast } from '../../../../PropsWithShowToast';
import DateSelector from '../../../../components/DateSelector';
import { Moment } from 'moment';

type Props = PropsWithShowToast<{
    customerUuid: string;
    partnerTerms: AssignedPartnerTerm[];
    onAddPartnerTerm: (
        partnerTerm: Omit<AssignedPartnerTerm, 'assignedOn'>
    ) => void;
    onEditPartnerTerm: (partnerTerm: AssignedPartnerTerm) => void;
    onRemovePartnerTerm: (partnerTermUuid: string) => void;
}>;

export default function CustomerPartners({
    customerUuid,
    partnerTerms,
    onAddPartnerTerm,
    onEditPartnerTerm,
    onRemovePartnerTerm,
    showToast
}: Props): ReactElement {
    const [isSelectPartnerTermDialogOpen, setSelectPartnerTermDialogOpen] =
        useState(false);
    const [confirmDeletePartnerTerm, setConfirmDeletePartnerTerm] =
        useState<AssignedPartnerTerm>();
    const [editPartnerTerm, setEditPartnerTerm] =
        useState<AssignedPartnerTerm>();
    const [startDecayDateEdit, setStartDecayDateEdit] = useState<
        Date | undefined
    >();
    const [endPartnerRelationDateEdit, setEndPartnerRelationDateEdit] =
        useState<Date | undefined>();

    const getEditPartner = (
        editPartnerTerm: AssignedPartnerTerm
    ): AssignedPartnerTerm => ({
        ...editPartnerTerm,
        startDecayDate: startDecayDateEdit as Date,
        endPartnerRelationDate: endPartnerRelationDateEdit as Date
    });

    function PartnerTermRow({
        partnerTerm
    }: {
        partnerTerm: AssignedPartnerTerm;
    }) {
        const [partner, setPartner] = useState<Partner>();
        useEffect(() => {
            PartnerService.getPartner(partnerTerm.partnerId).then(setPartner);
        }, [partnerTerm]);

        const term = partner?.terms.find(t => t.uuid === partnerTerm.uuid);

        return (
            <TableRow>
                <TableCell>
                    <Link to={`/partner/${partner?._id}/edit`}>
                        {partner?.partnerInfo.company}
                    </Link>
                </TableCell>
                <TableCell>{partner?.type}</TableCell>
                <TableCell>
                    <ReactMoment
                        date={partnerTerm.assignedOn}
                        format='DD/MM/YYYY'
                    />
                </TableCell>
                <TableCell>
                    <ReactMoment
                        date={partnerTerm.startDecayDate}
                        format='DD/MM/YYYY'
                    />
                </TableCell>
                <TableCell>
                    {partnerTerm.endPartnerRelationDate && (
                        <ReactMoment
                            date={partnerTerm.endPartnerRelationDate}
                            format='DD/MM/YYYY'
                        />
                    )}
                </TableCell>
                <TableCell>{term?.description}</TableCell>
                <TableCell>
                    {term && (
                        <CommissionRanges
                            commissionRanges={term.commissionRanges}
                        />
                    )}
                </TableCell>
                <TableCell>
                    {term && (
                        <CommissionDecay
                            commissionDecay={term.commissionDecay}
                        />
                    )}
                </TableCell>
                <TableCell>current</TableCell>
                <TableCell>
                    <div className='actions'>
                        <Action
                            label='Edit'
                            onClick={() => {
                                setEditPartnerTerm(partnerTerm);
                                setStartDecayDateEdit(
                                    partnerTerm.startDecayDate
                                );
                                setEndPartnerRelationDateEdit(
                                    partnerTerm.endPartnerRelationDate
                                );
                            }}
                            icon={EditIcon}
                        />
                        <Action
                            label='Delete'
                            onClick={() =>
                                setConfirmDeletePartnerTerm(partnerTerm)
                            }
                            icon={DeleteIcon}
                        />
                    </div>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <b>Assigned Partners</b>
                </Grid>
                {partnerTerms.length === 0 && (
                    <Alert variant='info'>No assigned Partner Terms</Alert>
                )}
                {partnerTerms.length > 0 && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Partner</TableCell>
                                <TableCell>Partner Type</TableCell>
                                <TableCell>Assigned On</TableCell>
                                <TableCell>Start Decay Date</TableCell>
                                <TableCell>End Relation Date</TableCell>
                                <TableCell>Terms Description</TableCell>
                                <TableCell>Ranges</TableCell>
                                <TableCell>Decay</TableCell>
                                <TableCell>Current</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {partnerTerms.map(partnerTerm => (
                                <PartnerTermRow
                                    key={partnerTerm.uuid}
                                    partnerTerm={partnerTerm}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <Button
                    onClick={() => setSelectPartnerTermDialogOpen(true)}
                    variant='contained'
                    color='primary'
                >
                    Add New
                </Button>
                <PartnerTermSelectDialog
                    open={isSelectPartnerTermDialogOpen}
                    onAssignPartnerTerm={partnerTerm => {
                        onAddPartnerTerm(partnerTerm);
                        setSelectPartnerTermDialogOpen(false);
                    }}
                    onClose={() => setSelectPartnerTermDialogOpen(false)}
                />
            </Grid>
            <Grid item xs={12} sm={12} style={{ margin: '20px 0' }}>
                <Divider variant='fullWidth' />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <b>Commissions Paid</b>
                </Grid>
                <CustomerReports
                    customerUuid={customerUuid}
                    showToast={showToast}
                />
            </Grid>
            <Dialog open={confirmDeletePartnerTerm !== undefined}>
                <DialogTitle>Delete Partner Term</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete the Partner Term?
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmDeletePartnerTerm(undefined)}
                    >
                        Cancel
                    </Button>
                    {confirmDeletePartnerTerm && (
                        <Button
                            color='primary'
                            onClick={() => {
                                setConfirmDeletePartnerTerm(undefined);
                                onRemovePartnerTerm(
                                    confirmDeletePartnerTerm.uuid
                                );
                            }}
                        >
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={editPartnerTerm !== undefined}>
                <DialogTitle>Edit assigned partner term</DialogTitle>
                <DialogContent>
                    <DesktopDatePicker
                        closeOnSelect
                        inputFormat='DD/MM/YYYY'
                        value={startDecayDateEdit}
                        onChange={(date: Moment | null) =>
                            setStartDecayDateEdit(date?.toDate())
                        }
                        renderInput={props => <TextField {...props} />}
                        label={'Start decay date'}
                    />

                    <DateSelector
                        value={endPartnerRelationDateEdit}
                        onChange={date => setEndPartnerRelationDateEdit(date)}
                        label={'End partner relation date'}
                        style={{ marginLeft: '10px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditPartnerTerm(undefined)}>
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        onClick={() => {
                            setEditPartnerTerm(undefined);
                            onEditPartnerTerm(
                                getEditPartner(
                                    editPartnerTerm as AssignedPartnerTerm
                                )
                            );
                        }}
                    >
                        Edit
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
