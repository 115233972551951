import React from 'react';
import { Link } from 'react-router-dom';
import { CustomerCreatedOperationLog } from '../../../../../models/system/CustomerCreatedOperationLog';

const CustomerCreated = (operationLog: CustomerCreatedOperationLog) => (
    <div>
        {!operationLog.customerName && (
            <p>
                Customer:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerAccountEmail}
                </Link>{' '}
                - Env: {operationLog.customerEnv}
            </p>
        )}
        {operationLog.customerName && (
            <p>
                Account name:{' '}
                <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                    {operationLog.customerName}
                </Link>{' '}
                - Env: {operationLog.customerEnv}
            </p>
        )}
    </div>
);

export default CustomerCreated;
