import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ConnectifListUsers(props: SvgIconProps): ReactElement {
    return (
        <SvgIcon {...props}>
            <path
                d='M12,7.849c2.033,0,3.662-1.641,3.662-3.674C15.662,2.141,14.033,0.5,12,0.5c-2.034,0-3.675,1.641-3.675,3.675
				C8.325,6.208,9.966,7.849,12,7.849z M12,10.299c-2.854,0-8.574,1.432-8.574,4.287v3.063h17.148v-3.063
				C20.574,11.731,14.854,10.299,12,10.299z M7.101,21.663c0,1.017-0.822,1.837-1.838,1.837c-1.015,0-1.837-0.82-1.837-1.837
				s0.822-1.838,1.837-1.838C6.278,19.825,7.101,20.646,7.101,21.663z M20.574,21.663c0,1.017-0.822,1.837-1.838,1.837
				s-1.838-0.82-1.838-1.837s0.822-1.838,1.838-1.838S20.574,20.646,20.574,21.663z M13.836,21.663c0,1.017-0.82,1.837-1.836,1.837
				c-1.014,0-1.836-0.82-1.836-1.837s0.822-1.838,1.836-1.838C13.016,19.825,13.836,20.646,13.836,21.663z'
            />
        </SvgIcon>
    );
}
