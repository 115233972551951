import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const useStyles1 = makeStyles<Theme>(theme => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.dark
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
}));

interface Props {
    className?: string;
    message: string;
    onClose: () => void;
    variant: 'success' | 'warning' | 'error' | 'info';
}

const MySnackbarContentWrapper = React.forwardRef<HTMLDivElement, Props>(
    (props: Props, ref): ReactElement => {
        const classes = useStyles1();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                ref={ref}
                className={clsx(classes[variant], className)}
                aria-describedby='client-snackbar'
                message={
                    <span id='client-snackbar' className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key='close'
                        aria-label='Close'
                        color='inherit'
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                ]}
                {...other}
            />
        );
    }
);

export default function Notifier(): ReactElement {
    const activeToasts = useSelector(
        state => (state as any).snackbar.activeToasts
    ) as { variant: any; message: string; visible: boolean }[];
    const dispatch = useDispatch();

    function handleClose(index: number) {
        dispatch({ type: 'CLOSE_TOAST', payload: { index } });
    }

    return (
        <div className={'snacks'}>
            {activeToasts &&
                activeToasts.length > 0 &&
                activeToasts.map((toast, i) => (
                    <Snackbar
                        style={{ bottom: `${80 * i + 24}px` }}
                        key={i}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        open={toast.visible}
                        autoHideDuration={5000}
                        onClose={() => handleClose(i)}
                    >
                        <MySnackbarContentWrapper
                            onClose={() => handleClose(i)}
                            variant={toast.variant}
                            message={toast.message}
                        />
                    </Snackbar>
                ))}
        </div>
    );
}
