import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Alert from '../../../../components/Alert';
import { CSSProperties } from '@mui/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Menu,
    MenuItem,
    TextField
} from '@mui/material';
import ReportListFilters from '../../../../models/ReportListFilters';
import DateFormat from '../../../../components/DateFormat';
import CurrencyFormat from '../../../../components/CurrencyFormat';
import { ReportListItem } from '../../../../models/report/ReportListItem';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteReportConfirmationDialog from './DeleteReportConfirmationDialog';
import Action from '../../../../components/Action';
import { Link } from 'react-router-dom';
import ReportDate from './ReportDate';
import ReportService from '../../../../services/ReportService';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import SortableTableHead, {
    SortDirection
} from '../../../../components/SortableTableHead';
import { HeadRow } from '../../../../models/list-filters/HeadRow';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import Spinner from '../../../../components/Spinner';
import PartnerService from '../../../../services/PartnerService';
import { PropsWithShowToast } from '../../../../PropsWithShowToast';
import Paginator from '../../../../components/Paginator';
import { ReportStatusId } from '../../../../models/report/ReportStatusId';

type Props = PropsWithShowToast<{
    rows: ReportListItem[];
    rowsTotal: number;
    filters: ReportListFilters;
    backTo: 'partner' | 'report';
    showPartner: boolean;
    onPaginationChange: (page: number, limit: number) => void;
    onSortByChange: (fieldId: string, direction: SortDirection) => void;
    onFilterChange: (
        filterId: keyof ReportListFilters,
        filterValue: any
    ) => void;
    onDelete: (report: ReportListItem) => void;
    refreshReports: () => void;
}>;

const containerTableStyle: CSSProperties = {
    overflowX: 'auto',
    width: '100%'
};

export default function ReportListItems(props: Props): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const [currentReportOptions, setRow] = React.useState<
        ReportListItem | undefined
    >(undefined);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [emailsToSend, setEmailsToSend] = React.useState('');
    const [isLoadingPartner, setIsLoadingPartner] = React.useState(false);
    const [reportIdToSend, setReportIdToSend] = React.useState<
        string | undefined
    >(undefined);

    const open = Boolean(anchorEl);

    const headRows: HeadRow[] = [
        { id: 'reportFromDate', sortable: true, label: 'Date' },
        ...(props.showPartner
            ? [
                  {
                      id: 'partnerName',
                      sortable: true,
                      label: 'Partner'
                  } as HeadRow
              ]
            : []),
        { id: 'totalAmount', sortable: true, label: 'Amount' },
        { id: 'itemCount', sortable: true, label: 'Item count' },
        { id: 'createdAt', sortable: true, label: 'Created At' },
        { id: 'reportStatus', sortable: true, label: 'Status' },
        { id: 'sentByEmailAt', sortable: true, label: 'Sent At' },
        { id: 'actions', sortable: false, label: 'Actions' }
    ];

    const [confirmDeleteReport, setConfirmDeleteReport] =
        useState<ReportListItem>();

    const downloadPdf = async (reportId: string) => {
        const result = await ReportService.downloadPdf(reportId);
        window.open(
            URL.createObjectURL(await result.blob()),
            '_blank',
            'status=1'
        );
    };

    const downloadBillablePdf = async (reportId: string) => {
        const result = await ReportService.downloadBillablePdf(reportId);
        window.open(
            URL.createObjectURL(await result.blob()),
            '_blank',
            'status=1'
        );
    };

    const resolve = async (reportId: string) => {
        setIsLoadingPartner(true);
        try {
            await ReportService.resolve(reportId);
            props.refreshReports();
            props.showToast('success', 'Report resolved');
        } catch (err) {
            props.showToast('error', 'Error on resolving report');
        }
        setIsLoadingPartner(false);
    };

    const canResolve = (reportStatus: ReportStatusId) =>
        reportStatus === 'draft' || reportStatus === 'sent';

    const handleOpenMenu = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        report: ReportListItem
    ) => {
        setAnchorEl(event.currentTarget as Element);
        setRow(report);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setRow(undefined);
    };

    const handleCloseModal = () => {
        setIsDialogOpen(false);
        setEmailsToSend('');
        setReportIdToSend(undefined);
    };

    const handleOpenModal = async (partnerId: string, reportId: string) => {
        setIsDialogOpen(true);
        setIsLoadingPartner(true);
        const {
            partnerInfo: { contactEmail }
        } = await PartnerService.getPartner(partnerId);
        setIsLoadingPartner(false);
        setEmailsToSend(contactEmail || '');
        setReportIdToSend(reportId);
        handleCloseMenu();
    };

    const handleSendInvoiceByEmail = async () => {
        setIsDialogOpen(false);
        const emails = emailsToSend.split(' ').join('');
        await sendByEmail(reportIdToSend as string, emails.split(','));
        handleCloseModal();
    };

    const sendByEmail = async (reportId: string, emails: string[]) => {
        setIsLoadingPartner(true);
        try {
            await ReportService.sendByEmail(reportId, emails);
            props.refreshReports();
            props.showToast('success', 'Email sent');
        } catch (err) {
            props.showToast('error', 'Error on send by email');
        }
        setIsLoadingPartner(false);
    };

    return (
        <div style={{ width: '100%' }}>
            <Paginator
                page={props.filters.page}
                rowsPerPage={props.filters.rowsPerPage}
                rowsTotal={props.rowsTotal}
                onPaginationChange={props.onPaginationChange}
            ></Paginator>
            {props.rows.length > 0 && (
                <div style={containerTableStyle}>
                    <Table size='small' className={'filtered-list'}>
                        <SortableTableHead
                            headRows={headRows}
                            sortBy={props.filters.sortBy}
                            direction={props.filters.direction}
                            onSortByChange={props.onSortByChange}
                        />
                        <TableBody>
                            {props.rows.map(report => (
                                <TableRow key={report._id}>
                                    <TableCell>
                                        <ReportDate report={report} />
                                    </TableCell>
                                    {props.showPartner && (
                                        <TableCell>
                                            <Link
                                                to={`/partner/${report.partnerId}/edit`}
                                            >
                                                {report.partnerName}
                                            </Link>
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <CurrencyFormat
                                            value={report.totalAmount}
                                        />
                                    </TableCell>
                                    <TableCell>{report.itemCount}</TableCell>
                                    <TableCell>
                                        <DateFormat date={report.createdAt} />
                                    </TableCell>
                                    <TableCell>{report.reportStatus}</TableCell>
                                    <TableCell>
                                        <DateFormat
                                            date={report.sentByEmailAt}
                                        />
                                    </TableCell>
                                    <TableCell align='right'>
                                        <div className='actions'>
                                            <Action
                                                label='Informe de cuentas'
                                                linkTo={`/report/${report._id}/backTo/${props.backTo}`}
                                                icon={RemoveRedEyeIcon}
                                            />
                                            <Action
                                                label='Informe de comisiones'
                                                linkTo={`/report/${report._id}/billable-info/backTo/${props.backTo}`}
                                                icon={RemoveRedEyeIcon}
                                                disabled={
                                                    !report.hasBillableReport
                                                }
                                            />
                                            <Action
                                                label='Edit'
                                                linkTo={`/report/${report._id}/edit/backTo/${props.backTo}`}
                                                icon={EditIcon}
                                            />
                                            <Action
                                                label='Resolve'
                                                disabled={
                                                    !canResolve(
                                                        report.reportStatus
                                                    )
                                                }
                                                onClick={() =>
                                                    resolve(report._id)
                                                }
                                                icon={DoneOutlineIcon}
                                            />
                                            <IconButton
                                                aria-label='more'
                                                onClick={(
                                                    event: React.MouseEvent<
                                                        HTMLButtonElement,
                                                        MouseEvent
                                                    >
                                                ) =>
                                                    handleOpenMenu(
                                                        event,
                                                        report
                                                    )
                                                }
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {currentReportOptions && (
                        <Menu
                            id='long-menu'
                            anchorEl={anchorEl as Element}
                            keepMounted
                            open={open}
                            onClose={handleCloseMenu}
                            PaperProps={{
                                style: {
                                    maxHeight: 400,
                                    width: 200
                                }
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setConfirmDeleteReport(
                                        currentReportOptions
                                    );
                                    handleCloseMenu();
                                }}
                            >
                                <IconButton color='primary' aria-label='Delete'>
                                    <DeleteIcon />
                                </IconButton>
                                Delete
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    handleOpenModal(
                                        (currentReportOptions as ReportListItem)
                                            .partnerId,
                                        (currentReportOptions as ReportListItem)
                                            ._id
                                    )
                                }
                            >
                                <IconButton
                                    color='primary'
                                    aria-label='SendByEmail'
                                >
                                    <SendIcon />
                                </IconButton>
                                Send by email
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    downloadPdf(
                                        (currentReportOptions as ReportListItem)
                                            ._id
                                    )
                                }
                            >
                                <IconButton
                                    color='primary'
                                    aria-label='PDF Informe de cuentas'
                                >
                                    <PictureAsPdfIcon />
                                </IconButton>
                                Informe de cuentas
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    downloadBillablePdf(
                                        (currentReportOptions as ReportListItem)
                                            ._id
                                    )
                                }
                                disabled={
                                    !(currentReportOptions as ReportListItem)
                                        .hasBillableReport
                                }
                            >
                                <IconButton
                                    color='primary'
                                    aria-label='PDF Informe de comisiones'
                                    disabled={
                                        !(
                                            currentReportOptions as ReportListItem
                                        ).hasBillableReport
                                    }
                                >
                                    <PictureAsPdfIcon />
                                </IconButton>
                                Informe de comisiones
                            </MenuItem>
                        </Menu>
                    )}
                </div>
            )}
            {confirmDeleteReport && (
                <DeleteReportConfirmationDialog
                    open={true}
                    report={confirmDeleteReport}
                    onClose={() => setConfirmDeleteReport(undefined)}
                    onDelete={report => {
                        props.onDelete(report);
                        setConfirmDeleteReport(undefined);
                    }}
                />
            )}
            {props.rows.length === 0 && (
                <Alert variant='info'>No items found</Alert>
            )}
            <Dialog
                open={isDialogOpen}
                onClose={handleCloseModal}
                aria-labelledby='form-dialog-title'
            >
                <DialogTitle id='form-dialog-title'>
                    Send report by email to:
                    <br />
                    <span style={{ fontSize: '14px', color: 'gray' }}>
                        *Multiple emails split by ","
                    </span>
                </DialogTitle>
                <DialogContent style={{ minWidth: '450px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoFocus
                                margin='dense'
                                required={!isLoadingPartner}
                                error={!emailsToSend || emailsToSend === ''}
                                id='name'
                                label='Emails'
                                onChange={event =>
                                    setEmailsToSend(event.target.value)
                                }
                                value={emailsToSend}
                                fullWidth
                            />
                            <Spinner visible={isLoadingPartner}></Spinner>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        disabled={!emailsToSend || emailsToSend === ''}
                        onClick={handleSendInvoiceByEmail}
                        color='primary'
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
