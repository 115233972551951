import React, { ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Spinner from '../../../components/Spinner';
import ListItems from './components/list-items/ListItems';
import SubscriptionService from '../../../services/SubscriptionService';
import { ToastVariant } from '../../../reducers/Snackbar';
import { RouterMatch } from '../../../models/RouterMatch';
import Cookies from 'js-cookie';
import PageHeader from '../../../components/PageHeader';
import GenericListFilters from '../../../models/GenericListFilters';
import { Subscription } from '../../../models/subscription/Subscription';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    filters: GenericListFilters;
}

class SubscriptionList extends Component<Props, State> {
    private subscriptions: Subscription[] = [];
    private totalSubscriptions = 0;
    private subscriptionFiltersCookieName = 'subscriptionListFilters';

    constructor(props: Props) {
        super(props);
        const subscriptionFiltersCookie = Cookies.get(
            this.subscriptionFiltersCookieName
        );
        const filters: GenericListFilters = subscriptionFiltersCookie
            ? JSON.parse(subscriptionFiltersCookie)
            : {
                  rowsPerPage: 10,
                  page: 0,
                  search: ''
              };
        this.state = {
            isLoading: true,
            filters
        };
    }

    componentDidMount() {
        this.loadSubscriptions(this.state.filters);
    }

    loadSubscriptions = async (filters: GenericListFilters) => {
        let subscriptionList: {
            documents: Subscription[];
            totalQuantity: number;
        } = { documents: [], totalQuantity: 0 };
        try {
            subscriptionList =
                await SubscriptionService.getNotBilledSubscriptionList(filters);
        } catch {
            this.props.showToast('error', 'Error on get subscription list');
        }
        this.subscriptions = subscriptionList.documents;
        this.totalSubscriptions = subscriptionList.totalQuantity;
        this.setState({ ...this.state, isLoading: false, filters });
    };

    onPaginationChange = (page: number, rowsPerPage: number) => {
        const stateCopy = { ...this.state };
        stateCopy.filters.page = page;
        stateCopy.filters.rowsPerPage = rowsPerPage;
        stateCopy.isLoading = true;
        this.setState(stateCopy);
        this.loadSubscriptions(stateCopy.filters);
    };

    billSubscription = async (subscriptionId: string) => {
        this.setState({ ...this.state, isLoading: true });
        try {
            await SubscriptionService.billSubscription(subscriptionId);
            await this.loadSubscriptions(this.state.filters);
            this.props.showToast('success', 'Subscription emitted');
        } catch {
            this.props.showToast('error', 'Error on emit subscription');
        }

        this.setState({ ...this.state, isLoading: false });
    };

    saveFilters = () => {
        Cookies.set(
            this.subscriptionFiltersCookieName,
            JSON.stringify(this.state.filters)
        );
    };

    render(): ReactElement {
        this.saveFilters();

        return (
            <div className='subscription-list-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>Subscriptions not billed</PageHeader>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className='cn-paper'>
                            <ListItems
                                filters={this.state.filters}
                                rowsTotal={this.totalSubscriptions}
                                rows={this.subscriptions}
                                onPaginationChange={this.onPaginationChange}
                                billSubscription={this.billSubscription}
                            ></ListItems>
                        </Paper>
                    </Grid>
                </Container>
            </div>
        );
    }
}
export default SubscriptionList;
