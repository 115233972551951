import React, { CSSProperties, ReactElement } from 'react';
import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Spinner from '../../../components/Spinner';
import InvoiceService from '../../../services/InvoiceService';
import { RouterMatch } from '../../../models/RouterMatch';
import { InvoiceSettings } from '../../../models/invoice/InvoiceSettings';
import { ToastVariant } from '../../../reducers/Snackbar';
import PageHeader from '../../../components/PageHeader';
import BackButton from '../../../components/BackButton';

interface Props {
    match: RouterMatch;
    showToast: (variant: ToastVariant, message: string) => void;
}

interface State {
    isLoading: boolean;
    invoiceSettings: InvoiceSettings;
}

class InvoiceSettingsPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            invoiceSettings: {
                invoicePrefix: '',
                invoiceCancelPrefix: '',
                defaultVat: 0,
                connectifInfo: {
                    company: '',
                    address: {
                        street: '',
                        zip: '',
                        city: '',
                        state: '',
                        country: ''
                    },
                    phone: '',
                    cif: '',
                    accountNumber: '',
                    swift: ''
                }
            }
        };
        this.loadInvoiceSettings();
    }

    private buttonContainerStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
        marginRight: '26px',
        marginBottom: '10px'
    };

    private inputStyle: CSSProperties = {
        width: '100%',
        paddingRight: '20px'
    };

    updateInvoiceSettings = (
        fieldName: keyof State['invoiceSettings'],
        event: any
    ) => {
        const newValue = event.target.value;
        this.setState({
            ...this.state,
            invoiceSettings: {
                ...this.state.invoiceSettings,
                [fieldName]: newValue
            }
        });
    };

    updateConnectifInfo = (
        fieldName: keyof State['invoiceSettings']['connectifInfo'],
        event: any
    ) => {
        const newValue = event.target.value;
        const invoiceSettings = this.state.invoiceSettings;
        invoiceSettings.connectifInfo[fieldName] = newValue;
        this.setState({ ...this.state, invoiceSettings });
    };

    updateAddress = (
        fieldName: keyof State['invoiceSettings']['connectifInfo']['address'],
        event: any
    ) => {
        const newValue = event.target.value;
        const invoiceSettings = this.state.invoiceSettings;
        invoiceSettings.connectifInfo.address[fieldName] = newValue;
        this.setState({ ...this.state, invoiceSettings });
    };

    loadInvoiceSettings = async () => {
        try {
            const invoiceSettings = await InvoiceService.getInvoiceSettings();
            this.setState({ ...this.state, isLoading: false, invoiceSettings });
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                this.props.showToast('error', 'Error loading invoice settings');
            });
        }
    };

    saveUpdate = async () => {
        try {
            await InvoiceService.setInvoiceSettings(this.state.invoiceSettings);
            this.props.showToast(
                'success',
                'Invoice settings saved successfully'
            );
        } catch (err) {
            this.setState({ ...this.state, isLoading: false }, () => {
                this.props.showToast('error', 'Error saving invoice settings');
            });
        }
    };

    render(): ReactElement {
        return (
            <div className='invoice-settings-component'>
                <Spinner visible={this.state.isLoading}></Spinner>
                <Container maxWidth='xl'>
                    <Grid
                        className='page-header-container'
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <PageHeader>Invoice Settings</PageHeader>
                        </Grid>

                        <Grid item xs={6}>
                            <BackButton href='/invoice/list'>
                                Back to Invoice list
                            </BackButton>
                        </Grid>
                    </Grid>

                    {this.state.invoiceSettings && (
                        <Paper className='cn-paper'>
                            <h3>Default Settings</h3>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='invoicePrefix'
                                            name='invoicePrefix'
                                            label='Invoice line prefix'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .invoicePrefix || ''
                                            }
                                            onChange={event =>
                                                this.updateInvoiceSettings(
                                                    'invoicePrefix',
                                                    event
                                                )
                                            }
                                            InputProps={{
                                                disabled: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='invoiceCancelPrefix'
                                            name='invoiceCancelPrefix'
                                            label='Rectify line prefix'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .invoiceCancelPrefix || ''
                                            }
                                            onChange={event =>
                                                this.updateInvoiceSettings(
                                                    'invoiceCancelPrefix',
                                                    event
                                                )
                                            }
                                            InputProps={{
                                                disabled: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='defaultVat'
                                            name='defaultVat'
                                            type='number'
                                            label='Default VAT'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .defaultVat || ''
                                            }
                                            onChange={event =>
                                                this.updateInvoiceSettings(
                                                    'defaultVat',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <h3>Connectif billing info</h3>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='cif'
                                            name='cif'
                                            label='CIF'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .connectifInfo.cif || ''
                                            }
                                            onChange={event =>
                                                this.updateConnectifInfo(
                                                    'cif',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='company'
                                            name='company'
                                            label='Company name'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .connectifInfo.company || ''
                                            }
                                            onChange={event =>
                                                this.updateConnectifInfo(
                                                    'company',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='phone'
                                            name='phone'
                                            label='Phone'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .connectifInfo.phone || ''
                                            }
                                            onChange={event =>
                                                this.updateConnectifInfo(
                                                    'phone',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='swift'
                                            name='swift'
                                            label='SWIFT'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .connectifInfo.swift || ''
                                            }
                                            onChange={event =>
                                                this.updateConnectifInfo(
                                                    'swift',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='accountNumber'
                                            name='accountNumber'
                                            label='Bank Account Number'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .connectifInfo
                                                    .accountNumber || ''
                                            }
                                            onChange={event =>
                                                this.updateConnectifInfo(
                                                    'accountNumber',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <h4>Address</h4>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='city'
                                            name='city'
                                            label='City'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .connectifInfo.address
                                                    .city || ''
                                            }
                                            onChange={event =>
                                                this.updateAddress(
                                                    'city',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='country'
                                            name='country'
                                            label='Country'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .connectifInfo.address
                                                    .country || ''
                                            }
                                            onChange={event =>
                                                this.updateAddress(
                                                    'country',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='state'
                                            name='state'
                                            label='State'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .connectifInfo.address
                                                    .state || ''
                                            }
                                            onChange={event =>
                                                this.updateAddress(
                                                    'state',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='street'
                                            name='street'
                                            label='Street'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .connectifInfo.address
                                                    .street || ''
                                            }
                                            onChange={event =>
                                                this.updateAddress(
                                                    'street',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            required
                                            id='zip'
                                            name='zip'
                                            label='Zip'
                                            style={this.inputStyle}
                                            value={
                                                this.state.invoiceSettings
                                                    .connectifInfo.address
                                                    .zip || ''
                                            }
                                            onChange={event =>
                                                this.updateAddress('zip', event)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <h4>Send by email BCC</h4>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id='emails-bcc'
                                            label='Emails'
                                            style={this.inputStyle}
                                            multiline
                                            fullWidth
                                            value={
                                                this.state.invoiceSettings
                                                    .invoiceSendByEmailBcc || ''
                                            }
                                            onChange={event =>
                                                this.updateInvoiceSettings(
                                                    'invoiceSendByEmailBcc',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <h4>Footer</h4>
                                <Grid container item xs={12} spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id='invoice-footer'
                                            label='Invoice footer'
                                            style={this.inputStyle}
                                            multiline
                                            fullWidth
                                            value={
                                                this.state.invoiceSettings
                                                    .invoiceFooter || ''
                                            }
                                            onChange={event =>
                                                this.updateInvoiceSettings(
                                                    'invoiceFooter',
                                                    event
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={3}
                                    justifyContent='flex-end'
                                    alignItems='flex-end'
                                >
                                    <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        style={this.buttonContainerStyles}
                                    >
                                        <Button
                                            onClick={this.saveUpdate}
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </Container>
            </div>
        );
    }
}
export default InvoiceSettingsPage;
