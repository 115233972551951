import React, { ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Invoice from '../../../../../models/invoice/Invoice';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Moment from 'react-moment';
import { InvoiceStatusId } from '../../../../../models/invoice/InvoiceStatusId';
import Alert from '../../../../../components/Alert';
import { CSSProperties } from '@mui/styles';
import './ListItems.css';
import {
    TextField,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
    Button
} from '@mui/material';
import InvoiceListFilters from '../../../../../models/InvoiceListFilters';
import InvoiceService from '../../../../../services/InvoiceService';
import { HeadRow } from '../../../../../models/list-filters/HeadRow';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CustomerService from '../../../../../services/CustomerService';
import Spinner from '../../../../../components/Spinner';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import Paginator from '../../../../../components/Paginator';
import TableSubCell from '../../../../../components/TableSubCell';
import CurrencyFormat from '../../../../../components/CurrencyFormat';
import SortableTableHead, {
    SortDirection
} from '../../../../../components/SortableTableHead';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';

interface Props {
    rows: Invoice[];
    rowsTotal: number;
    filters: InvoiceListFilters;
    onFilterChange: (
        filterId: keyof InvoiceListFilters,
        filterValue: any
    ) => void;
    onPaginationChange: (page: number, limit: number) => void;
    onSortByChange: (fieldId: string, direction: SortDirection) => void;
    emitInvoice: (invoiceId: string) => void;
    cancelInvoice: (invoiceId: string) => void;
    deleteInvoice: (invoiceId: string) => void;
    showToCustomerInvoice: (invoiceId: string) => void;
    hideToCustomerInvoice: (invoiceId: string) => void;
    chargeAndEmitInvoice: (invoiceId: string) => void;
    sendByEmail: (invoiceIdToSend: string, emails: string[]) => void;
    markReceiptAsPaid: (invoiceId: string) => void;
    chargeReceipt: (invoiceId: string) => void;
}

const actionButtonStyles: CSSProperties = {
    display: 'inline-block'
};

const columnsStyles: CSSProperties = {
    whiteSpace: 'nowrap',
    verticalAlign: 'text-top',
    paddingTop: '12px'
};

const backgroundGrey: CSSProperties = {
    backgroundColor: '#EAEAEA'
};

const containerTableStyle: CSSProperties = {
    overflowX: 'auto',
    width: '100%'
};

function ListItems(props: Props): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentInvoiceOptions, setRow]: [Invoice | undefined, any] =
        React.useState(undefined);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isDialogChargeAndEmitOpen, setIsDialogChargeAndEmitOpen] =
        React.useState(false);
    const [isDialogChargeReceiptOpen, setIsDialogChargeReceiptOpen] =
        React.useState(false);
    const [isDialogConfirmEmitOpen, setIsDialogConfirmEmitOpen] =
        React.useState(false);
    const [emailsToSend, setEmailsToSend] = React.useState('');
    const [invoiceIdToSend, setInvoiceIdToSend] = React.useState(null);
    const [isLoadingCustomer, setIsLoadingCustomer] = React.useState(false);
    const [invoiceIdToEmit, setInvoiceIdToEmit] = React.useState(null);
    const [receiptIdToCharge, setReceiptIdToCharge] = React.useState(null);
    const [invoiceAccountToEmit, setInvoiceAccountToEmit] =
        React.useState(null);
    const [receiptAccountToCharge, setReceiptAccountToCharge] =
        React.useState(null);
    const [isDialogConfirmDeleteOpen, setIsDialogConfirmDeleteOpen] =
        React.useState(false);
    const [invoiceIdToDelete, setInvoiceIdToDelete] = React.useState(null);
    const [invoiceAccountToDelete, setInvoiceAccountToDelete] =
        React.useState(null);
    const [invoiceToEmitTotalAmount, setInvoiceToEmitTotalAmount] =
        React.useState(null);
    const [receiptToChargeTotalAmount, setReceiptToChargeTotalAmount] =
        React.useState(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (event: any, row: Invoice) => {
        setAnchorEl(event.currentTarget);
        setRow(row);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setRow(undefined);
    };

    const headRows: HeadRow[] = [
        {
            id: 'date',
            sortable: true,
            label: 'Created At',
            subHead: [
                { id: 'createdAt', sortable: true, label: 'Created On' },
                { id: 'invoiceDate', sortable: true, label: 'Invoice Date' }
            ]
        },
        { id: 'subscriptionPeriod', sortable: false, label: 'Subscription' },
        { id: 'invoiceNumber', sortable: true, label: 'Invoice No' },
        { id: 'accountName', sortable: false, label: 'Account name' },
        {
            id: 'companyInfo',
            sortable: true,
            label: 'Company info',
            subHead: [
                {
                    id: 'clientInfo.company',
                    sortable: true,
                    label: 'Company Name'
                },
                {
                    id: 'clientInfo.address.zip',
                    sortable: false,
                    label: 'Company Zip'
                }
            ]
        },
        { id: 'invoiceStatus', sortable: true, label: 'Invoice Status' },
        { id: 'paymentMethod', sortable: true, label: 'PM' },
        {
            id: 'totals',
            sortable: true,
            label: 'Subtotal Amount',
            subHead: [
                {
                    id: 'subtotalAmount',
                    sortable: true,
                    label: 'Subtotal Amount'
                },
                { id: 'totalAmount', sortable: true, label: 'Total Amount' }
            ]
        },
        { id: 'sentByEmailAt', sortable: false, label: 'Enviado' },
        { id: 'actions', sortable: false, label: 'Actions' }
    ];

    const [isEmitting, setIsEmitting] = React.useState(false);
    const [isChargingAndEmitting, setIsChargingAndEmitting] =
        React.useState(false);

    const [isChargingReceipt, setIsChargingReceipt] = React.useState(false);

    const canEmit = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus === 'draft' || invoiceStatus === 'payment-pending';

    const canCharge = (invoiceTotalAmount: number) => invoiceTotalAmount > 0;

    const canCancel = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus === 'emitted';

    const canEdit = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus === 'draft' || invoiceStatus === 'receipt-charged';

    const canDelete = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus === 'draft';

    const canShowHideInvoice = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus !== 'draft';

    const canSendInvoiceByEmail = (invoiceStatus: InvoiceStatusId) =>
        invoiceStatus !== 'draft';

    const hideToCustomer = (invoiceId: string) => {
        props.hideToCustomerInvoice(invoiceId);
    };

    const showToCustomer = (invoiceId: string) => {
        props.showToCustomerInvoice(invoiceId);
    };

    const downloadInvoice = async (invoiceId: string) => {
        const result = await InvoiceService.downloadInvoice(invoiceId);
        window.open(
            URL.createObjectURL(await result.blob()),
            '_blank',
            'status=1'
        );
    };

    const markReceiptAsPaid = (invoiceId: string) => {
        props.markReceiptAsPaid(invoiceId);
    };

    const handleSendInvoiceByEmail = () => {
        setIsDialogOpen(false);
        const emails = emailsToSend.replace(' ', '');
        props.sendByEmail(invoiceIdToSend as any, emails.split(','));
        handleCloseModal();
    };

    const handleOpenModal = async (invoiceId: string, customerId: string) => {
        setIsDialogOpen(true);
        setIsLoadingCustomer(true);
        const { customer } = await CustomerService.getCustomer(customerId);
        setIsLoadingCustomer(false);
        setEmailsToSend(customer.clientInfo.email || '');
        setInvoiceIdToSend(invoiceId as any);
        handleCloseMenu();
    };

    const handleCloseModal = () => {
        setIsDialogOpen(false);
        setEmailsToSend('');
        setInvoiceIdToSend(null);
    };

    const handleCloseConfirmEmitModal = () => {
        setInvoiceIdToEmit(null);
        setInvoiceAccountToEmit(null);
        setIsDialogConfirmEmitOpen(false);
    };

    const handleOpenConfirmEmitModal = (invoiceId: string, account: string) => {
        setIsDialogConfirmEmitOpen(true);
        setInvoiceIdToEmit(invoiceId as any);
        setInvoiceAccountToEmit(account as any);
        setIsEmitting(false);
    };
    const handleEmit = () => {
        setIsEmitting(true);
        props.emitInvoice(invoiceIdToEmit as any);
        handleCloseConfirmEmitModal();
    };

    const handleCloseConfirmDeleteModal = () => {
        setInvoiceIdToDelete(null);
        setInvoiceAccountToDelete(null);
        setIsDialogConfirmDeleteOpen(false);
    };

    const handleOpenConfirmDeleteModal = (
        invoiceId: string,
        account: string
    ) => {
        setIsDialogConfirmDeleteOpen(true);
        setInvoiceIdToDelete(invoiceId as any);
        setInvoiceAccountToDelete(account as any);
    };
    const handleDelete = () => {
        props.deleteInvoice(invoiceIdToDelete as any);
        handleCloseConfirmDeleteModal();
    };
    const chargeAndEmitInvoice = () => {
        props.chargeAndEmitInvoice(invoiceIdToEmit as any);
    };
    const handleCloseConfirmChargeAndEmitModal = () => {
        setIsDialogChargeAndEmitOpen(false);
        setInvoiceIdToEmit(null);
        setInvoiceAccountToEmit(null);
    };
    const handleChargeAndEmit = () => {
        setIsChargingAndEmitting(true);
        chargeAndEmitInvoice();
        handleCloseConfirmChargeAndEmitModal();
    };
    const handleOpenConfirmChargeAndEmitModal = (
        invoiceId: string,
        account: string,
        totalAmount: number
    ) => {
        setIsDialogChargeAndEmitOpen(true);
        setInvoiceIdToEmit(invoiceId as any);
        setInvoiceAccountToEmit(account as any);
        setInvoiceToEmitTotalAmount(totalAmount as any);
        setIsChargingAndEmitting(false);
    };

    const chargeReceipt = () => {
        props.chargeReceipt(receiptIdToCharge as any);
    };
    const handleCloseConfirmChargeReceiptModal = () => {
        setIsDialogChargeReceiptOpen(false);
        setReceiptIdToCharge(null);
        setReceiptAccountToCharge(null);
    };
    const handleChargeReceipt = () => {
        setIsChargingReceipt(true);
        chargeReceipt();
        handleCloseConfirmChargeReceiptModal();
    };
    const handleOpenConfirmChargeReceiptModal = (
        invoiceId: string,
        account: string,
        totalAmount: number
    ) => {
        setIsDialogChargeReceiptOpen(true);
        setReceiptIdToCharge(invoiceId as any);
        setReceiptAccountToCharge(account as any);
        setReceiptToChargeTotalAmount(totalAmount as any);
        setIsChargingReceipt(false);
    };

    return (
        <div style={{ width: '100%' }}>
            <ToggleButtonGroup
                size='small'
                value={props.filters.invoiceStatus}
                style={{ position: 'absolute', marginTop: '15px' }}
                exclusive
                onChange={(event, value) =>
                    props.onFilterChange('invoiceStatus', value)
                }
            >
                <ToggleButton key={1} value='emitted'>
                    Emitted
                </ToggleButton>
                <ToggleButton key={2} value='draft'>
                    Draft
                </ToggleButton>
                <ToggleButton key={3} value='rectified'>
                    Rectified
                </ToggleButton>
                <ToggleButton key={4} value='payment-pending'>
                    Payment pending
                </ToggleButton>
                <ToggleButton key={5} value='receipt'>
                    Receipt
                </ToggleButton>
                <ToggleButton key={6} value='receipt-charged'>
                    Receipt charged
                </ToggleButton>
                <ToggleButton key={7} value='receipt-pending'>
                    Receipt pending
                </ToggleButton>
                <ToggleButton key={8} value='receipt-paid'>
                    Receipt paid
                </ToggleButton>
            </ToggleButtonGroup>
            <Paginator
                page={props.filters.page}
                rowsPerPage={props.filters.rowsPerPage}
                rowsTotal={props.rowsTotal}
                onPaginationChange={props.onPaginationChange}
            ></Paginator>
            {props.rows.length > 0 && (
                <div style={containerTableStyle}>
                    <Table size='small' className={'filtered-list'}>
                        <SortableTableHead
                            headRows={headRows}
                            sortBy={props.filters.sortBy}
                            direction={props.filters.direction}
                            onSortByChange={props.onSortByChange}
                        />
                        <TableBody>
                            {props.rows.map(row => (
                                <TableRow
                                    key={row._id}
                                    style={row.isHidden ? backgroundGrey : {}}
                                >
                                    <TableCell style={columnsStyles}>
                                        <Moment
                                            date={row.createdAt}
                                            format='DD/MM/YYYY'
                                        />
                                        <TableSubCell>
                                            {row.invoiceDate ? (
                                                <Moment
                                                    date={row.invoiceDate}
                                                    format='DD/MM/YYYY'
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell style={columnsStyles}>
                                        <Moment
                                            date={row.subscriptionStartedAt}
                                            format='DD/MM/YYYY'
                                        />
                                        <TableSubCell>
                                            {row.subscriptionFinishedAt ? (
                                                <Moment
                                                    date={
                                                        row.subscriptionFinishedAt
                                                    }
                                                    format='DD/MM/YYYY'
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell style={columnsStyles}>
                                        {row.paymentInfo.paymentMethod ===
                                        'shopify'
                                            ? new Date(
                                                  row.createdAt as any
                                              ).getTime()
                                            : row.invoiceNumber
                                              ? row.invoicePrefix +
                                                row.invoiceNumber
                                              : '-'}
                                    </TableCell>
                                    <TableCell style={columnsStyles}>
                                        {row.accountName && (
                                            <a
                                                href={`/customer/${row.customerUUID}/edit`}
                                                title={row.accountName}
                                            >
                                                {row.accountName.length <= 56 &&
                                                    row.accountName}
                                                {row.accountName.length > 56 &&
                                                    row.accountName.substring(
                                                        0,
                                                        26
                                                    ) +
                                                        '...' +
                                                        row.accountName.substring(
                                                            row.accountName
                                                                .length - 26
                                                        )}
                                            </a>
                                        )}
                                    </TableCell>
                                    <TableCell style={columnsStyles}>
                                        <div title={row.clientInfo.company}>
                                            {row.clientInfo.company &&
                                                row.clientInfo.company.length <=
                                                    24 &&
                                                row.clientInfo.company}
                                            {row.clientInfo.company &&
                                                row.clientInfo.company.length >
                                                    24 &&
                                                row.clientInfo.company.substring(
                                                    0,
                                                    12
                                                ) +
                                                    '...' +
                                                    row.clientInfo.company.substring(
                                                        row.clientInfo.company
                                                            .length - 12
                                                    )}
                                        </div>
                                        <TableSubCell>
                                            {row.clientInfo.address &&
                                                row.clientInfo.address.zip}
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell style={columnsStyles}>
                                        {row.invoiceStatus === 'rectified' && (
                                            <div>
                                                <label className='emitted'>
                                                    emitted
                                                </label>
                                                <label> [rectified]</label>
                                            </div>
                                        )}
                                        {row.invoiceStatus !== 'rectified' && (
                                            <div>
                                                <label
                                                    className={
                                                        row.invoiceStatus
                                                    }
                                                >
                                                    {row.invoiceStatus}
                                                </label>
                                                {row.invoiceRectifiedId && (
                                                    <label>
                                                        {' '}
                                                        [rectification]
                                                    </label>
                                                )}
                                                {row.failedOnEmit && (
                                                    <label className='failed'>
                                                        {' '}
                                                        [failedOnEmit]
                                                    </label>
                                                )}
                                            </div>
                                        )}
                                        <TableSubCell>
                                            {row.invoiceRectificationId && (
                                                <a
                                                    href={`/invoice/${row.invoiceRectificationId}`}
                                                >
                                                    Rectification invoice
                                                </a>
                                            )}
                                            {row.invoiceRectifiedId && (
                                                <a
                                                    href={`/invoice/${row.invoiceRectifiedId}`}
                                                >
                                                    Original invoice
                                                </a>
                                            )}
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell style={columnsStyles}>
                                        <div>
                                            {row.paymentInfo.paymentMethod}
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        style={columnsStyles}
                                        className={'amount-data'}
                                    >
                                        <CurrencyFormat
                                            value={row.subtotalAmount}
                                        />
                                        <TableSubCell>
                                            <CurrencyFormat
                                                value={row.totalAmount}
                                            />
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell style={columnsStyles}>
                                        {row.sentByEmailAt ? (
                                            <Moment
                                                date={row.sentByEmailAt}
                                                format='DD/MM/YYYY'
                                            />
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell align='right'>
                                        <div className='actions'>
                                            <Tooltip
                                                title='View'
                                                style={actionButtonStyles}
                                            >
                                                <div>
                                                    <IconButton
                                                        color='primary'
                                                        aria-label='View'
                                                        href={`/invoice/${row._id}`}
                                                    >
                                                        <RemoveRedEyeIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                            <Tooltip
                                                title='Edit'
                                                style={actionButtonStyles}
                                            >
                                                <div>
                                                    <IconButton
                                                        color='primary'
                                                        disabled={
                                                            !canEdit(
                                                                row.invoiceStatus
                                                            )
                                                        }
                                                        aria-label='Edit'
                                                        href={`/invoice/${row._id}/edit`}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                            {row.paymentInfo.paymentMethod ===
                                                'bank' && (
                                                <Tooltip
                                                    title='Emit'
                                                    style={actionButtonStyles}
                                                >
                                                    <div>
                                                        <IconButton
                                                            color='primary'
                                                            disabled={
                                                                !canEmit(
                                                                    row.invoiceStatus
                                                                )
                                                            }
                                                            aria-label='Emit'
                                                            onClick={() =>
                                                                handleOpenConfirmEmitModal(
                                                                    row._id,
                                                                    row.accountName
                                                                )
                                                            }
                                                        >
                                                            <DoneOutlineIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            )}
                                            {row.paymentInfo.paymentMethod ===
                                                'card' && (
                                                <Tooltip
                                                    title='Charge and emit'
                                                    style={actionButtonStyles}
                                                >
                                                    <div>
                                                        <IconButton
                                                            color='primary'
                                                            disabled={
                                                                !canEmit(
                                                                    row.invoiceStatus
                                                                ) ||
                                                                !canCharge(
                                                                    row.totalAmount
                                                                )
                                                            }
                                                            aria-label='Charge and emit'
                                                            onClick={() =>
                                                                handleOpenConfirmChargeAndEmitModal(
                                                                    row._id,
                                                                    row.accountName,
                                                                    row.totalAmount
                                                                )
                                                            }
                                                        >
                                                            <EuroSymbolIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            )}
                                            <IconButton
                                                aria-label='more'
                                                aria-controls='long-menu'
                                                aria-haspopup='true'
                                                onClick={(event: any) =>
                                                    handleOpenMenu(event, row)
                                                }
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {currentInvoiceOptions && (
                        <Menu
                            id='long-menu'
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleCloseMenu}
                            PaperProps={{
                                style: {
                                    maxHeight: 400,
                                    width: 200
                                }
                            }}
                        >
                            {(currentInvoiceOptions as Invoice).paymentInfo
                                .paymentMethod === 'card' && (
                                <MenuItem
                                    disabled={
                                        !canEmit(
                                            (currentInvoiceOptions as Invoice)
                                                .invoiceStatus
                                        )
                                    }
                                    onClick={() =>
                                        handleOpenConfirmEmitModal(
                                            (currentInvoiceOptions as Invoice)
                                                ._id,
                                            (currentInvoiceOptions as Invoice)
                                                .accountName
                                        )
                                    }
                                >
                                    <IconButton
                                        color='primary'
                                        disabled={
                                            !canEmit(
                                                (
                                                    currentInvoiceOptions as Invoice
                                                ).invoiceStatus
                                            )
                                        }
                                        aria-label='Emit'
                                    >
                                        <DoneOutlineIcon />
                                    </IconButton>
                                    Emit
                                </MenuItem>
                            )}
                            {(currentInvoiceOptions as Invoice).paymentInfo
                                .paymentMethod === 'bank' && (
                                <MenuItem
                                    disabled={true}
                                    onClick={() =>
                                        handleOpenConfirmChargeAndEmitModal(
                                            (currentInvoiceOptions as Invoice)
                                                ._id,
                                            (currentInvoiceOptions as Invoice)
                                                .accountName,
                                            (currentInvoiceOptions as Invoice)
                                                .totalAmount
                                        )
                                    }
                                >
                                    <IconButton
                                        color='primary'
                                        disabled={true}
                                        aria-label='Charge and Emit'
                                    >
                                        <EuroSymbolIcon />
                                    </IconButton>
                                    Charge and Emit
                                </MenuItem>
                            )}
                            <MenuItem
                                onClick={() => {
                                    downloadInvoice(
                                        (currentInvoiceOptions as Invoice)._id
                                    );
                                    handleCloseMenu();
                                }}
                            >
                                <IconButton color='primary' aria-label='PDF'>
                                    <PictureAsPdfIcon />
                                </IconButton>
                                PDF
                            </MenuItem>
                            {(
                                currentInvoiceOptions as Invoice
                            ).invoiceStatus.includes('receipt') && (
                                <MenuItem
                                    disabled={
                                        ![
                                            'receipt-charged',
                                            'receipt-pending'
                                        ].includes(
                                            (currentInvoiceOptions as Invoice)
                                                .invoiceStatus
                                        )
                                    }
                                    onClick={() => {
                                        markReceiptAsPaid(
                                            (currentInvoiceOptions as Invoice)
                                                ._id
                                        );
                                        handleCloseMenu();
                                    }}
                                >
                                    <IconButton
                                        disabled={
                                            ![
                                                'receipt-charged',
                                                'receipt-pending'
                                            ].includes(
                                                (
                                                    currentInvoiceOptions as Invoice
                                                ).invoiceStatus
                                            )
                                        }
                                        color='primary'
                                        aria-label='Mark as paid'
                                    >
                                        <PlaylistAddCheck />
                                    </IconButton>
                                    Mark as paid
                                </MenuItem>
                            )}

                            {(
                                currentInvoiceOptions as Invoice
                            ).invoiceStatus.includes('receipt') && (
                                <MenuItem
                                    disabled={
                                        (currentInvoiceOptions as Invoice)
                                            .invoiceStatus !== 'receipt' &&
                                        (currentInvoiceOptions as Invoice)
                                            .invoiceStatus !== 'receipt-pending'
                                    }
                                    onClick={() => {
                                        handleOpenConfirmChargeReceiptModal(
                                            (currentInvoiceOptions as Invoice)
                                                ._id,
                                            (currentInvoiceOptions as Invoice)
                                                .accountName,
                                            (currentInvoiceOptions as Invoice)
                                                .totalAmount
                                        );
                                        handleCloseMenu();
                                    }}
                                >
                                    <IconButton
                                        disabled={
                                            (currentInvoiceOptions as Invoice)
                                                .invoiceStatus !== 'receipt' &&
                                            (currentInvoiceOptions as Invoice)
                                                .invoiceStatus !==
                                                'receipt-pending'
                                        }
                                        color='primary'
                                        aria-label='Charge Receipt'
                                    >
                                        <EuroSymbolIcon />
                                    </IconButton>
                                    Charge Receipt
                                </MenuItem>
                            )}

                            <MenuItem
                                disabled={
                                    !canCancel(
                                        (currentInvoiceOptions as Invoice)
                                            .invoiceStatus
                                    )
                                }
                                onClick={() => {
                                    props.cancelInvoice(
                                        (currentInvoiceOptions as Invoice)._id
                                    );
                                    handleCloseMenu();
                                }}
                            >
                                <IconButton
                                    color='primary'
                                    disabled={
                                        !canCancel(
                                            (currentInvoiceOptions as Invoice)
                                                .invoiceStatus
                                        )
                                    }
                                    aria-label='Rectify'
                                >
                                    <CancelPresentationIcon />
                                </IconButton>
                                Rectify
                            </MenuItem>
                            <MenuItem
                                disabled={
                                    !canDelete(
                                        (currentInvoiceOptions as Invoice)
                                            .invoiceStatus
                                    )
                                }
                                onClick={() => {
                                    handleOpenConfirmDeleteModal(
                                        (currentInvoiceOptions as Invoice)._id,
                                        (currentInvoiceOptions as Invoice)
                                            .accountName
                                    );
                                    handleCloseMenu();
                                }}
                            >
                                <IconButton
                                    color='primary'
                                    disabled={
                                        !canDelete(
                                            (currentInvoiceOptions as Invoice)
                                                .invoiceStatus
                                        )
                                    }
                                    aria-label='Delete'
                                >
                                    <DeleteIcon />
                                </IconButton>
                                Delete
                            </MenuItem>
                            <MenuItem
                                disabled={
                                    !canSendInvoiceByEmail(
                                        (currentInvoiceOptions as Invoice)
                                            .invoiceStatus
                                    )
                                }
                                onClick={() =>
                                    handleOpenModal(
                                        (currentInvoiceOptions as Invoice)._id,
                                        (currentInvoiceOptions as Invoice)
                                            .customerUUID
                                    )
                                }
                            >
                                <IconButton color='primary' aria-label='Delete'>
                                    <SendIcon />
                                </IconButton>
                                Send by email
                            </MenuItem>
                            {!(currentInvoiceOptions as Invoice).isHidden && (
                                <MenuItem
                                    disabled={
                                        !canShowHideInvoice(
                                            (currentInvoiceOptions as Invoice)
                                                .invoiceStatus
                                        )
                                    }
                                    onClick={() => {
                                        hideToCustomer(
                                            (currentInvoiceOptions as Invoice)
                                                ._id
                                        );
                                        handleCloseMenu();
                                    }}
                                >
                                    <IconButton
                                        color='primary'
                                        aria-label='Show'
                                    >
                                        <VisibilityOffIcon />
                                    </IconButton>
                                    Hide to customer
                                </MenuItem>
                            )}
                            {(currentInvoiceOptions as Invoice).isHidden && (
                                <MenuItem
                                    disabled={
                                        !canShowHideInvoice(
                                            (currentInvoiceOptions as Invoice)
                                                .invoiceStatus
                                        )
                                    }
                                    onClick={() => {
                                        showToCustomer(
                                            (currentInvoiceOptions as Invoice)
                                                ._id
                                        );
                                        handleCloseMenu();
                                    }}
                                >
                                    <IconButton
                                        color='primary'
                                        aria-label='Show'
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                    Make visible
                                </MenuItem>
                            )}
                        </Menu>
                    )}
                </div>
            )}
            {props.rows.length === 0 && (
                <Alert
                    visible={props.rows.length === 0}
                    variant='info'
                    style={{ marginTop: '65px' }}
                >
                    No items found
                </Alert>
            )}
            <Dialog
                open={isDialogOpen}
                onClose={handleCloseModal}
                aria-labelledby='form-dialog-title'
            >
                <DialogTitle id='form-dialog-title'>
                    Send invoice by email to:
                    <br />
                    <span style={{ fontSize: '14px', color: 'gray' }}>
                        *Multiple emails split by ","
                    </span>
                </DialogTitle>
                <DialogContent style={{ minWidth: '450px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                autoFocus
                                margin='dense'
                                required={!isLoadingCustomer}
                                error={!emailsToSend || emailsToSend === ''}
                                id='name'
                                label='Emails'
                                onChange={event =>
                                    setEmailsToSend(event.target.value)
                                }
                                value={emailsToSend}
                                fullWidth
                            />
                            <Spinner visible={isLoadingCustomer}></Spinner>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        disabled={!emailsToSend || emailsToSend === ''}
                        onClick={handleSendInvoiceByEmail}
                        color='primary'
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDialogConfirmEmitOpen}
                onClose={handleCloseConfirmEmitModal}
                aria-labelledby='form-dialog-title'
            >
                <DialogTitle id='form-dialog-title'>
                    Emit Invoice
                    <br />
                </DialogTitle>
                <DialogContent style={{ minWidth: '450px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            Emitting invoice for the account "
                            {invoiceAccountToEmit}"
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseConfirmEmitModal}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleEmit}
                        color='primary'
                        disabled={isEmitting}
                    >
                        Emit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDialogConfirmDeleteOpen}
                onClose={handleCloseConfirmDeleteModal}
                aria-labelledby='form-dialog-title'
            >
                <DialogTitle id='form-dialog-title'>
                    Delete Invoice
                    <br />
                </DialogTitle>
                <DialogContent style={{ minWidth: '450px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            Deleting invoice for the account "
                            {invoiceAccountToDelete}"
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseConfirmDeleteModal}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color='primary'>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDialogChargeAndEmitOpen}
                onClose={handleCloseConfirmChargeAndEmitModal}
                aria-labelledby='form-dialog-title'
            >
                <DialogTitle id='form-dialog-title'>
                    Charge and Emit Invoice
                    <br />
                </DialogTitle>
                <DialogContent style={{ minWidth: '450px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <p>
                                Charging and Emit invoice for the account "
                                {invoiceAccountToEmit}"
                            </p>
                            <p>Amount: {invoiceToEmitTotalAmount} €</p>
                            <p>
                                NOTE: This process will emit the invoice if the
                                charge succeeded. In case of an error, it will
                                send an email to the customer requiring the
                                action to pay and it will set the invoice status
                                as payment-pending. Invoices with this status
                                will be visible for the customer.
                            </p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseConfirmChargeAndEmitModal}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleChargeAndEmit}
                        color='primary'
                        disabled={isChargingAndEmitting}
                    >
                        Charge and Emit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isDialogChargeReceiptOpen}
                onClose={handleCloseConfirmChargeReceiptModal}
                aria-labelledby='form-dialog-title'
            >
                <DialogTitle id='form-dialog-title'>
                    Charge Receipt
                    <br />
                </DialogTitle>
                <DialogContent style={{ minWidth: '450px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <p>
                                Sending to Shopify the info to charging the
                                receipt for the account "
                                {receiptAccountToCharge}"
                            </p>

                            <p>Amount: {receiptToChargeTotalAmount} €</p>
                            <p>
                                NOTE: This process will set the invoice as
                                receipt-charged if the charge succeeded. The
                                receipt will be paid when the shopify period
                                ends.
                            </p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseConfirmChargeReceiptModal}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleChargeReceipt}
                        color='primary'
                        disabled={isChargingReceipt}
                    >
                        Charge Receipt
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ListItems;
