import React, { Fragment, PropsWithChildren, ReactElement } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import Moment from 'react-moment';
import moment from 'moment';
import CurrencyFormat from '../../../components/CurrencyFormat';
import { BillableReportItem } from '../../../models/report/BillableReportItem';

type Props = PropsWithChildren<{
    items: BillableReportItem[];
}>;

export default function BillableReportItems(props: Props): ReactElement {
    const { items } = props;
    return (
        <Fragment>
            <p>
                <b>{props.children}</b>
            </p>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 600 }}>
                            Período
                        </TableCell>
                        <TableCell align='right' style={{ fontWeight: 600 }}>
                            Comisión
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                Desde{' '}
                                <Moment
                                    locale='es'
                                    date={item.reportFromDate}
                                    format='DD MMMM YYYY'
                                />
                                , hasta{' '}
                                <Moment
                                    locale='es'
                                    date={moment(item.reportToDate)
                                        .add(-1, 'day')
                                        .toDate()}
                                    format='DD MMMM YYYY'
                                />
                            </TableCell>
                            <TableCell align='right'>
                                <CurrencyFormat value={item.amount} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Fragment>
    );
}
